import React, { Component, useEffect, useState } from "react";
import { DataContext } from "./contexts/dataContexts";
import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import dotenv from "dotenv";
import AppRoutes from "./routes/index";
import Spinner from "./utils/spinner";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddVessel from "./pages/vessel/addVessel";
import MainLayout from "./layouts/mainLayout";
import { getQueryObjectFromString } from "./utils/utilityFunctions";
import WatchRouteWrapper from "./components/general/WatchRouteWrapper";

dotenv.config();

function App() {
  const [globalState, setGlobalState] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

 

  const addItem = (key, value) => {
    let _globalState = globalState;
    _globalState[key] = value;
    setGlobalState(_globalState);
    
  };

  const setLoggedInUser = (_user, error) => {
    addItem("user", _user);
    setLoading(false);
  };

  return errorMessage ? (
    <p>{errorMessage}</p>
  ) : loading ? (
    <></>
  ) : (
    // <Spinner />
    <DataContext.Provider value={{ globalState, addItem }}>
      <ToastContainer />
      <BrowserRouter basename={process.env.REACT_APP_SUBFOLDER}>
        <Routes>
          {AppRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.route}
              exact
              element={
                <WatchRouteWrapper
                  setLoggedInUser={setLoggedInUser}
                  requiredPermission={
                    route.requiredPermission ? route.requiredPermission : ""
                  }
                >
                  <route.component />
                </WatchRouteWrapper>
              }
            />
          ))}
        </Routes>
      </BrowserRouter>
    </DataContext.Provider>
  );
}

export default App;
