import React, { useEffect, useState } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import BookingRequestService from "../../services/bookingRequestService";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../fileInput";
import Spinner from "../../utils/spinner";
import { Validator, ValidateGroup, ClearValidator } from "../validator";
import toastr from "../../utils/toastr";
import VoyageService from "../../services/voyageService";
import VoyageActivityService from "../../services/voyageActivityService";
import VoyageActivitiesListComponent from "./voyageActivitiesList";
import SliderComponent from "../sliderComponent";
import BulkListComponent from "../voyage/bulkList";
import DeckListComponent from "../voyage/deckList";
import ReactTooltip from "react-tooltip";
import EquipmentService from "../../services/equipmentService";
import CustomModal from "../../utils/customModal";
import {
  toReadableDate,
  toReadableDateTime,
} from "../../utils/utilityFunctions";
import { camelizeKeys, dynamicSort } from "../../utils/utilityFunctions";
import { forEach } from "lodash";
import Carousel from "react-elastic-carousel";
import portImg from "../../images/port.png";
import vesselImg from "../../images/vessel.png";
import offshoreImg from "../../images/oil-platform.png";
import oceanImg from "../../images/sea-wave.png";
import moment from "moment";
import LocationService from "../../services/locationService";

export default function TerminalActivitiesComponent({ locationId }) {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [initializing, setInit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [payload, setPayload] = useState({});
  const [saving, setSaving] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(1000);
  const [voyagePlanningId, setVoyagePlanningId] = useState("");
  const [selectedTerminalId, setSelectedTerminalId] = useState("");
  const [totalRecords, setTotalRecords] = useState(15);
  const [terminalActivities, setVoyageActivities] = useState([]);
  const [displayedVoyageActivities, setDisplayedVoyageActivities] = useState(
    []
  );
  const [voyage, setVoyage] = useState([]);
  const [terminalOptions, setTerminalOptions] = useState([]);
  const [filter, setFilter] = useState(null);

  useEffect(async () => {
    await init();
  }, []);

  const init = async () => {
    await loadData();
  };

  const loadData = async (_page, _pageSize, _voyagePlanningId) => {
    setLoading(true);
    _page = _page || page;
    _pageSize = _pageSize || pageSize;
    const _terminalActivities = await LocationService.geTerminalActivitiesList(
      _page,
      _pageSize,
      _voyagePlanningId,
      locationId
    );
    let modifiedDataList = camelizeKeys(
      _terminalActivities.voyageActivities || []
    );
    if (_terminalActivities.TotalCount) {
      setTotalRecords(_terminalActivities.TotalCount);
    }
    modifiedDataList = modifiedDataList.map((voyageActivity) => ({
      ...voyageActivity,
      plannedStartDate: toReadableDateTime(voyageActivity.plannedStartDate),
      endDate: toReadableDateTime(voyageActivity.endDate),
      overflowComponent: overflowMenu(voyageActivity),
      imageComponent: image(voyageActivity),
    }));
    setVoyageActivities(modifiedDataList || []);
    setDisplayedVoyageActivities(modifiedDataList || []);

    page != _page && setPage(_page);
    pageSize != _pageSize && setPageSize(_pageSize);
    setVoyagePlanningId(_voyagePlanningId || "");

    let _terminal = modifiedDataList?.map((terminalActivity) => [
      {
        terminalIcon: terminalActivity.imageComponent,
        voyagePlanningId: terminalActivity.voyagePlanningId,
        terminalName: terminalActivity.locationName,
        terminalActivityStartDate: terminalActivity.plannedStartDate,
        terminalActivityEndDate: terminalActivity.endDate,
        vesselName: terminalActivity.vesselName,
      },
    ]);
    let _terminalFlat = _terminal.flat(2);

    const groupBy = (arr, key) =>
      arr.reduce(
        (result, item) => (result[item[key]].push(item), result),
        arr.reduce((result, item) => ((result[item[key]] = []), result), {})
      );
    const returnLowHigh = (comp) => (a, b) =>
      a.localeCompare(b) === comp ? a : b;
    const lowest = returnLowHigh(-1);
    const highest = returnLowHigh(1);
    const _terminalReduced = Object.values(
      groupBy(_terminalFlat, "voyagePlanningId")
    ).map((items) =>
      items.reduce(
        (
          result,
          {
            voyagePlanningId,
            terminalIcon,
            terminalName,
            vesselName,
            terminalActivityStartDate,
            terminalActivityEndDate,
          }
        ) => ({
          voyagePlanningId,
          terminalIcon,
          terminalName,
          vesselName,
          terminalActivityStartDate: lowest(
            result.terminalActivityStartDate,
            terminalActivityStartDate
          ),
          terminalActivityEndDate: highest(
            result.terminalActivityEndDate,
            terminalActivityEndDate
          ),
        })
      )
    );

    setTerminalOptions(_terminalReduced || []);

    setLoading(false);
  };

  const overflowMenu = (voyageActivity) => {
    return (
      <ul className="nk-tb-actions gx-2">
        {null && (
          <li className="nk-tb-action-hidden">
            <a
              href="#"
              className="btn btn-sm btn-icon btn-trigger"
              data-toggle="tooltip"
              data-placement="top"
              title="Suspend"
            >
              <em className="icon ni ni-user-cross-fill" />
            </a>
          </li>
        )}
        <li>
          <div className="drodown">
            <a
              href="#"
              className="btn btn-sm btn-icon btn-trigger dropdown-toggle"
              data-toggle="dropdown"
            >
              <em className="icon ni ni-more-h" />
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <ul className="link-list-opt no-bdr">
                <li>
                  <Link
                    to={`/voyage-activity-details/${voyageActivity.voyageActivityId}`}
                  >
                    <em className="icon ni ni-eye" />
                    <span>View Details</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    );
  };

  const image = (voyageActivity) => {
    if (voyageActivity) {
      return <img src={vesselImg} alt="vessel_img" />;
    }
  };

  const filterByTerminal = (id) => {
    setLoading(true);
    let filteredActivities = terminalActivities.filter(
      (activity) => id == activity.voyagePlanningId
    );
    setDisplayedVoyageActivities(filteredActivities);
    setLoading(false);
    // await loadData(1, null, _terminalId);
  };

  const clearFilter = () => {
    setLoading(true);
    setDisplayedVoyageActivities(terminalActivities);
    setLoading(false);
    // await loadData(1, null, _terminalId);
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1024, itemsToShow: 4 },
  ];

  return (
    <>
      {loading ? (
        <div className="w-100 text-center center my-5 py-5">
          <Spinner />
        </div>
      ) : (
        <div className="card-inner p-5">
          <form>
            <span className="preview-title-lg overline-title text-center">
              Terminal View{" "}
            </span>
            <div className="code-block border-gray">
              <Carousel breakPoints={breakPoints}>
                {terminalOptions.map((terminalActivity, index) => (
                  <a
                    className="code-block border-gray"
                    // href="#"
                    role="button"
                    onClick={() =>
                      filterByTerminal(terminalActivity.voyagePlanningId)
                    }
                    key={index}
                  >
                    <div
                      className="text-center"
                      key={terminalActivity.voyagePlanningId}
                    >
                      {terminalActivity.terminalIcon}
                      <p>{terminalActivity.terminalName}</p>
                      <p>{terminalActivity.vesselName}</p>
                      <p>ETA: {terminalActivity.terminalActivityStartDate}</p>
                      <p>ETD: {terminalActivity.terminalActivityEndDate}</p>
                    </div>
                  </a>
                ))}
              </Carousel>
            </div>
            <div className="gap" />
            <div className="table code-block border-info">
              <div className="text-center">
                <a
                  role="button"
                  className="btn btn-white btn-outline-light"
                  onClick={clearFilter}
                >
                  Remove Filter
                </a>
              </div>
              <div className="gap" />
              <VoyageActivitiesListComponent
                dataList={displayedVoyageActivities}
              />
            </div>
            <div className="gap" />
          </form>
          <div className="gap" />
          <hr className="preview-hr" />
        </div>
      )}
    </>
  );
}
