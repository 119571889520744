import React, { useEffect, useState } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import VoyageService from "../../services/voyageService";
import VoyageActivitiesComponent from "../../components/voyageReporting/voyageActivitiesComponent";
import VoyageDetailsActivityComponent from "../../components/voyageReporting/voyageDetailsActivityComponent";
import AddLocationCostComponent from "../../components/voyage/addLocationCostComponent";
import AddVesselCostComponent from "../../components/voyage/addVesselCostComponent";
import Tabs from "../../utils/tabs";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../../components/fileInput";
import Spinner from "../../utils/spinner";
import {
  Validator,
  ValidateGroup,
  ClearValidator,
} from "../../components/validator";
import toastr from "../../utils/toastr";
import ReactTooltip from "react-tooltip";
import CustomModal from "../../utils/customModal";
import {
  camelizeKeys,
  toReadableDateTime,
  toReadableDate,
  durationByHours,
  checkUserPermission,
} from "../../utils/utilityFunctions";
import { forEach } from "lodash";
import VoyageActivityService from "../../services/voyageActivityService";
import FuelUsageReportComponent from "../../components/voyage/FuelUsageReportComponent";
import WaterUsageReportComponent from "../../components/voyage/WaterUsageReportComponent";

export default function ReviewVoyagePage() {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({});
  const [saving, setSaving] = useState(false);
  const [voyage, setVoyage] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [createdActivities, setCreatedActivities] = useState([]);
  const [waterConsumptionHistory, setWaterConsumptionHistory] = useState([]);
  const [fuelConsumptionHistory, setFuelConsumptionHistory] = useState([]);

  useEffect(async () => {
    setLoading(true);
    await init();
    await retrieveVoyage();
    await retrieveVoyageActivities();
    setLoading(false);
  }, [params.voyagePlanningId]);

  const init = async () => { };

  const retrieveVoyage = async () => {
    const _voyage = await VoyageService.get(params.voyagePlanningId);
    setVoyage(_voyage || []);
  };

  const retrieveVoyageActivities = async () => {
    let _voyageActivities =
      await VoyageActivityService.getVoyageActivitiesWithSailing(
        params.voyagePlanningId
      );

    let modifiedDataList = camelizeKeys(_voyageActivities || []);
    modifiedDataList = modifiedDataList?.map((voyageActivity) => ({
      ...voyageActivity,
      intendedStartDate: toReadableDateTime(voyageActivity.estimatedStartTime),
      intendedEndDate: toReadableDateTime(voyageActivity.estimatedEndDate),
      activityDuration: durationByHours(voyageActivity.durationOfActivity),
      isParallel: voyageActivity.allowsParallel == true ? "Yes" : "No",
      approximateSailingDistance: (voyageActivity?.sailingDistance).toFixed(2),
    }));
    setCreatedActivities(modifiedDataList);

    const consumptionLog = await VoyageService.getConsumptionLog(params.voyagePlanningId);


      	//The line below sums up the Quantity of for objects with
	//the same keys(ConsumptionType)
    const aggregatedArray = Array.from(
      consumptionLog.reduce(
        (list, { ConsumptionType, Quantity }) =>
          list.set(ConsumptionType, (list.get(ConsumptionType) || 0) + Quantity),
        new Map()
      ),
      ([ConsumptionType, Quantity]) => ({ ConsumptionType, Quantity })
    );
    const totalFuelConsumption = aggregatedArray?.find((list) => list.ConsumptionType.toLowerCase() === "fuel")?.Quantity
    const totalWaterConsumption = aggregatedArray?.find((list) => list.ConsumptionType.toLowerCase() === "water")?.Quantity
    let waterUsage = consumptionLog?.filter((list) => list.ConsumptionType.toLowerCase() === "water");
    waterUsage = waterUsage?.map((list) => ({
      Quantity: list?.Quantity,
      DateConsumed: toReadableDate(list.DateCreated),
      totalConsumption: totalWaterConsumption
    }))
  
    let fuelUsage = consumptionLog?.filter((list) => list.ConsumptionType.toLowerCase() === "fuel")

    fuelUsage = fuelUsage?.map((list) => ({
      Quantity: list?.Quantity,
      DateConsumed: toReadableDate(list.DateCreated),
      totalConsumption: totalFuelConsumption
    }))
    // ConsumptionType:"water"
    setWaterConsumptionHistory(waterUsage);
    setFuelConsumptionHistory(fuelUsage);

  };

  const reviewVoyage = async () => {
    let _reviewVoyage = await VoyageService.updateVoyageStatus(
      params.voyagePlanningId,
      "Reviewed"
    );
    if (_reviewVoyage) {
      toastr("success", "Voyage reviewed");
      setSelectedRecord(null);
      await retrieveVoyage();
      if(checkUserPermission("voyage-planning_finalize_voyage")){
        setRedirectUrl(`/finalize-voyage/${params.voyagePlanningId}`);
      }
    }

    setLoading(false);
  };

  const tabHeaders = [
    { icon: "icon ni ni-files", text: "Voyage Details" },
    { icon: "icon ni ni-view-grid-wd", text: "Voyage Activities" },
    { icon: "icon ni ni-money", text: "Location Cost" },
    { icon: "icon ni ni-money", text: "Vessel Cost" },
    { icon: "icon ni ni-task", text: "Fuel Consumption Log" },
    { icon: "icon ni ni-task", text: "Water Consumption Log" },
  ];

  const tabContent = [
    <VoyageDetailsActivityComponent
      voyage={voyage}
      voyagePlanningId={params.voyagePlanningId}
      createdActivities={createdActivities}
    />,
    <VoyageActivitiesComponent
      voyagePlanningId={params.voyagePlanningId}
      voyage={voyage}
    />,
    <AddLocationCostComponent
      voyagePlanningId={params.voyagePlanningId}
      voyageUnderView={voyage}
    />,
    <AddVesselCostComponent voyagePlanningId={params.voyagePlanningId} />,
    <FuelUsageReportComponent
      voyagePlanningId={params.voyagePlanningId}
      voyageUnderView={voyage}
      fuelConsumptionHistory={fuelConsumptionHistory}
    />,
    <WaterUsageReportComponent
      voyagePlanningId={params.voyagePlanningId}
      voyageUnderView={voyage}
      waterConsumptionHistory={waterConsumptionHistory}
    />,
  ];

  const _tabView = (
    <Tabs headers={tabHeaders} contents={tabContent} contentPadding="3" />
  );

  const pageActions = (
    <div className="toggle-wrap nk-block-tools-toggle">
     
      <div className="toggle-expand-content" data-content="pageMenu">
        <ul className="nk-block-tools g-3">
          {voyage.VoyagePlanningStatus !== "Completed" ? null : (
            <>
              <li>
                <Link
                  to={`/add-activity/${params.voyagePlanningId}`}
                  className="btn btn-white btn-outline-light  btn-sm"
                >
                  <em className="icon ni ni-plus" />
                  <span>Add Voyage Activity</span>
                </Link>
              </li>

              <li>
                <a
                  role="button"
                  className="btn btn-white btn-outline-light  btn-sm"
                  onClick={() => setSelectedRecord(voyage)}
                >
                  <em className="icon ni ni-check" />
                  <span>Confirm Voyage Review</span>
                </a>
              </li>
            </>
          )}

          <li>
            <Link to="/voyages" className="btn btn-white btn-outline-light btn-sm">
              <em className="icon ni ni-arrow-left" />
              <span>Back to Voyages</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout
      title={<span style={{fontSize:"1.55rem"}}>Review Voyage <small style={{fontWeight: "normal"}}>{voyage?.VoyageCode}</small></span>}
      pageActions={pageActions}
      loading={loading}
      showFullLoader={loading}
    >
      <div className="" style={{marginTop: "-15px", marginLeft: "15px"}}>{_tabView}</div>

      {selectedRecord && voyage && (
        <CustomModal
          title={voyage.VoyageCode}
          content={
            <>
              <div className="">
                <div className="text-center">
                  <h5>Has voyage review been completed?</h5>
                  <h6>Changes can still be made before voyage is finalized</h6>
                  <br />
                  <p>1. Ensure all voyage activities are completed.</p>
                  <p>2. Ensure all required costs have been allocated.</p>
                </div>
                <br />
                <div className="row">
                  <div className="col-6 text-center">
                    <a
                      role="button"
                      className="btn btn-lg btn-success active"
                      onClick={reviewVoyage}
                    >
                      Yes
                    </a>
                  </div>

                  <div className="col-6 text-center">
                    <a
                      role="button"
                      className="btn btn-lg btn-danger active"
                      onClick={() => setSelectedRecord(null)}
                    >
                      Hold on
                    </a>
                  </div>
                </div>
              </div>
            </>
          }
          onClose={() => setSelectedRecord(null)}
          isClosed={false}
        />
      )}
    </MainLayout>
  );
}
