import React, { useEffect, useState } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import PayloadUpdater from "../../utils/payloadUpdater";
import Spinner from "../../utils/spinner";
import toastr from "../../utils/toastr";
import StartEndVoyageActivityListComponent from "./startEndVoyageActivityList";
import ParallelVoyageActivityListComponent from "./parallelVoyageActivitiesList";
import VoyageActivityService from "../../services/voyageActivityService";
import VoyageService from "../../services/voyageService";
import SailingService from "../../services/sailingService";
import CustomModal from "../../utils/customModal";
import {
  convertToUTC,
  extractDetailsFromBulkCargo,
  toReadableDate,
  toReadableDateTime,
} from "../../utils/utilityFunctions";
import { camelizeKeys, dynamicSort } from "../../utils/utilityFunctions";
import { forEach } from "lodash";
import CargoHandlingService from "../../services/cargoHandlingService";
import VoyageActivityPortBulkCargoList from "./voyageActivityPortBulkCargo";
import VoyageActivityDeckCargoList from "./voyageActivityDeckCargoList";
import VoyageActivityBulkCargoList from "./voyageActivityBulkCargoList";
import VoyageBulkCargoForPortConsumptionBulk from "./voyageBulkCargoListPortConspBulk";
import UpdateCargoLogsComponent from "./updateCargoLogsComponent";
import moment from "moment/moment";

export default function EndVoyageActivityComponent({ voyageActivityId }) {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [initializing, setInit] = useState(true);
  const [payload, setPayload] = useState({});
  const [saving, setSaving] = useState(false);
  const [locations, setLocations] = useState([]);
  const [
    deckCargoHandlingEquipmentSaving,
    setDeckCargoHandlingEquipmentSaving,
  ] = useState(false);
  const [voyageActivity, setVoyageActivity] = useState([]);
  const [totalRecords, setTotalRecords] = useState(15);
  const [parallelVoyageActivities, setParallelVoyageActivities] = useState([]);
  const [bulkCargos, setBulkCargos] = useState([]);
  const [loadingBulkCargos, setLoadingBulkCargos] = useState([]);
  const [dischargingBulkCargos, setDischargingBulkCargos] = useState([]);
  const [offshoreBulkCargos, setOffshoreBulkCargos] = useState([]);
  const [payloadForDeckCargoHandling, setPayloadForDeckCargoHandling] =
    useState([]);
  const [modalIsClosed, setModalIsClosed] = useState(true);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const addPayload = new PayloadUpdater({
    payload: payload,
    setPayload: setPayload,
  }).update;
  const validationGroup = "endactivity";

  useEffect(async () => {
    await init();
  }, []);



  const init = async () => {
    setInit(true);
    const _voyageActivity = await VoyageActivityService.getVoyageActivity(
      voyageActivityId
    );
    let modifiedDataList = camelizeKeys(_voyageActivity?.Data || []);
    let dataList = [];
    dataList.push({ ...modifiedDataList });
    let voyageActivityData = dataList?.map((voyageActivity) => ({
      ...voyageActivity,
      locationNameAndType: `${voyageActivity.locationName} - ${voyageActivity.locationType}`,
      proposedStartDate: toReadableDateTime(voyageActivity.estimatedStartTime),
      proposedEndDate: toReadableDateTime(voyageActivity.estimatedEndDate),
    }));
    setVoyageActivity(voyageActivityData || []);
    let _voyage = await VoyageService.get(modifiedDataList.voyagePlanningId);
    const _sailingLocations = await SailingService.getSailingLocationBySailing(_voyage?.SailingId);
    setLocations(_sailingLocations || []);

    if (
      voyageActivityData &&
      voyageActivityData[0].activityName === "Port Loading Bulk cargo"
    ) {
      let bulkInfo = await VoyageActivityService.getPortLoadingBulk(voyageActivityData[0]?.voyageActivityId)

      if (bulkInfo) {

        let _loadingBulkCargo = bulkInfo.map((cargo) => [
          {
            ...extractDetailsFromBulkCargo(cargo, _sailingLocations, voyageActivityId),
            updateButtonComponent: endActivityUpdates(cargo)
          },
        ]);
        setLoadingBulkCargos(_loadingBulkCargo.flat(2) || []);
        setBulkCargos(_loadingBulkCargo.flat(2) || []);
      }
    }


    if (
      voyageActivityData &&
      voyageActivityData[0].activityName === "Port Loading Consumption bulk"
    ) {
      const _loadingActivity = await CargoHandlingService.getLoadingCargos(
        voyageActivityData[0].voyagePlanningId,
        voyageActivityData[0].locationId
      );

      if (_loadingActivity) {
        let _loadingBulkCargo = _loadingActivity?.BulkCargos.map((cargo) => [
          {
            ...extractDetailsFromBulkCargo(cargo, _sailingLocations, voyageActivityId),
            updateButtonComponent: endActivityUpdates(cargo)
          },
        ]);

        setLoadingBulkCargos(_loadingBulkCargo.flat(2) || []);
        setBulkCargos(_loadingBulkCargo.flat(2) || []);
      }
    }

    if (
      voyageActivityData &&
      voyageActivityData[0].activityName === "Port Loading Deck cargo"
    ) {
      const _loadingActivity =
        await CargoHandlingService.getPortLoadingBookingEquipmentForDeckCargos(
          voyageActivityData[0].voyagePlanningId,
          voyageActivityData[0].locationId
        );

      if (_loadingActivity) {
        const _savedNumberOfLifts =
          await CargoHandlingService.getNumberOfLiftsForBookingEquipmentByVoyageActivity(
            voyageActivityId
          );

        const _deckCargoEquipment = _loadingActivity?.map((equipment) => ({
          ...equipment,
          label:
            equipment.CargoCarryingEquipmentType.toLowerCase() ===
              "offshore container"
              ? `${equipment?.BookingreferenceNo} - ${equipment?.CargoCarryingEquipmentType} - ${equipment?.CCUNumber}`
              : `${equipment?.BookingreferenceNo} - ${equipment?.CargoCarryingEquipmentType} - ${equipment?.Description}`,
          numberOfLifts:
            _savedNumberOfLifts.length > 0
              ? _savedNumberOfLifts.find(
                (activity) =>
                  activity.BookingEquipmentDetailId ===
                  equipment.BookingEquipmentDetailId
              ).NumberOfLifts
              : 0,
        }));

        const _deckCargoEquipmentHandlingPayload = _deckCargoEquipment.map(
          (equipment) => ({
            bookingEquipmentDetailId: equipment.BookingEquipmentDetailId,
            label: equipment.label,
            operatorName: equipment.OperatorName,
            offshoreInstallation: equipment.OffshoreInstallationName || "",
            voyageActivityId: voyageActivityId,
            numberOfLifts: equipment.numberOfLifts,
          })
        );
        setPayloadForDeckCargoHandling(_deckCargoEquipmentHandlingPayload);
      }
    }

    if (
      voyageActivityData &&
      voyageActivityData[0].activityName === "Port Discharging Bulk cargo"
    ) {
      const _dischargeActivity = await VoyageActivityService.getPortDischargingBulk(voyageActivityData[0]?.voyageActivityId)

      if (_dischargeActivity) {
        let _dischargingBulkCargo = _dischargeActivity.map(
          (cargo) => [
            {
              ...extractDetailsFromBulkCargo(cargo, _sailingLocations, voyageActivityId),
              updateButtonComponent: endActivityUpdates(cargo)
            },
          ]
        );
        setDischargingBulkCargos(_dischargingBulkCargo.flat(2) || []);
        setBulkCargos(_dischargingBulkCargo.flat(2) || []);
      }
    }

    if (
      voyageActivityData &&
      voyageActivityData[0].activityName === "Port Discharging Deck cargo"
    ) {
      let _dischargeActivity =
        await CargoHandlingService.getPortDischargingBookingEquipmentForDeckCargos(
          voyageActivityData[0].voyagePlanningId,
          voyageActivityData[0].locationId
        );

      if (_dischargeActivity) {
        const _savedNumberOfLifts =
          await CargoHandlingService.getNumberOfLiftsForBookingEquipmentByVoyageActivity(
            voyageActivityId
          );

        const _deckCargoEquipment = _dischargeActivity?.map((equipment) => ({
          ...equipment,
          label:
            equipment.CargoCarryingEquipmentType.toLowerCase() ===
              "offshore container"
              ? `${equipment?.BookingreferenceNo} - ${equipment?.CargoCarryingEquipmentType} - ${equipment?.CCUNumber}`
              : `${equipment?.BookingreferenceNo} - ${equipment?.CargoCarryingEquipmentType} - ${equipment?.Description}`,
          numberOfLifts:
            _savedNumberOfLifts.length > 0
              ? _savedNumberOfLifts.find(
                (activity) =>
                  activity.BookingEquipmentDetailId ===
                  equipment.BookingEquipmentDetailId
              ).NumberOfLifts
              : 0,
        }));

        const _deckCargoEquipmentHandlingPayload = _deckCargoEquipment.map(
          (equipment) => ({
            bookingEquipmentDetailId: equipment.BookingEquipmentDetailId,
            label: equipment.label,
            operatorName: equipment.OperatorName,
            offshoreInstallation: equipment.OffshoreInstallationName || "",
            voyageActivityId: voyageActivityId,
            numberOfLifts: equipment.numberOfLifts,
          })
        );
        setPayloadForDeckCargoHandling(_deckCargoEquipmentHandlingPayload);
      }
    }


    if (
      voyageActivityData &&
      voyageActivityData[0].activityName == "Offshore Handling Bulk cargo"
    ) {
      const _offshoreActivity = await CargoHandlingService.getOffshoreCargos(
        voyageActivityData[0].voyagePlanningId,
        voyageActivityData[0].locationId
      );


      if (_offshoreActivity) {

        let _offshoreBulkCargo = _offshoreActivity?.BulkCargos.map((cargo) => [
          {
            ...extractDetailsFromBulkCargo(cargo, _sailingLocations, voyageActivityId),
            updateButtonComponent: endActivityUpdates(cargo)
          },
        ]);

        setOffshoreBulkCargos(_offshoreBulkCargo.flat(2) || []);
        setBulkCargos(_offshoreBulkCargo.flat(2) || []);
      }
    }

    if (
      voyageActivityData &&
      voyageActivityData[0].activityName == "Offshore Handling Deck cargo"
    ) {
      let _offshoreActivity =
        await CargoHandlingService.getOffshoreHandlingBookingEquipmentForDeckCargos(
          voyageActivityData[0].voyagePlanningId,
          voyageActivityData[0].locationId
        );

      if (_offshoreActivity) {
        const _savedNumberOfLifts =
          await CargoHandlingService.getNumberOfLiftsForBookingEquipmentByVoyageActivity(
            voyageActivityId
          );

        const _deckCargoEquipment = _offshoreActivity?.map((equipment) => ({
          ...equipment,
          label:
            equipment.CargoCarryingEquipmentType.toLowerCase() ===
              "offshore container"
              ? `${equipment?.BookingreferenceNo} - ${equipment?.CargoCarryingEquipmentType} - ${equipment?.CCUNumber}`
              : `${equipment?.BookingreferenceNo} - ${equipment?.CargoCarryingEquipmentType} - ${equipment?.Description}`,
          numberOfLifts:
            _savedNumberOfLifts.length > 0
              ? _savedNumberOfLifts.find(
                (activity) =>
                  activity.BookingEquipmentDetailId ===
                  equipment.BookingEquipmentDetailId
              ).NumberOfLifts
              : 0,
        }));

        const _deckCargoEquipmentHandlingPayload = _deckCargoEquipment.map(
          (equipment) => ({
            bookingEquipmentDetailId: equipment.BookingEquipmentDetailId,
            label: equipment.label,
            operatorName: equipment.OperatorName,
            offshoreInstallation: equipment.OffshoreInstallationName || "",
            voyageActivityId: voyageActivityId,
            numberOfLifts: equipment.numberOfLifts,
          })
        );
        setPayloadForDeckCargoHandling(_deckCargoEquipmentHandlingPayload);
      }
    }

    const _voyageActivities =
      await VoyageActivityService.getVoyageActivitiesList(
        1,
        1000,
        voyageActivityData[0].voyagePlanningId
      );
    let voyageActivitiesDataList = camelizeKeys(
      _voyageActivities?.Data.voyageActivities || []
    );
    if (_voyageActivities?.Data.TotalCount) {
      setTotalRecords(_voyageActivities.Data.TotalCount);
    }
    voyageActivitiesDataList = voyageActivitiesDataList
      ?.filter(
        (activity) =>
          activity.activityName !== voyageActivityData[0].activityName &&
          activity.locationId == voyageActivityData[0].locationId &&
          activity.allowsParallel == true &&
          activity.activityStatus == "in-progress"
      )
      .map((voyageActivity) => ({
        ...voyageActivity,
        locationNameAndType: `${voyageActivity.locationName} - ${voyageActivity.locationType}`,
        proposedStartDate: toReadableDateTime(voyageActivity.estimatedStartTime),
        proposedEndDate: toReadableDateTime(voyageActivity.estimatedEndDate),
      }));
    setParallelVoyageActivities(voyageActivitiesDataList || []);

    setInit(false);
  };

  const editCallback = async () => {
    onModalClose();
    await init();
  };

  const onModalClose = () => {
    setModalIsClosed(true);
  };

  const endActivityUpdates = (cargo) => {
    return (
      <div>
        <a
          role="button"
          className="btn btn-sm btn-warning active"
          onClick={() => {
            setModalIsClosed(false);
            setSelectedRecord(cargo);
          }}
        >
          Update
        </a>
      </div>
    );
  };

  const endActivity = async () => {
    const _payload = {
      voyageActivityId: voyageActivityId,
      endTime: payload.actualEndDate,
    };

    if (!_payload.endTime) {
      toastr("error", "Pick actual end date");
      return;
    }

    setSaving(true);



    const endActivityResponse = await VoyageActivityService.endVoyageActivity(
      _payload
    );
    if (endActivityResponse && endActivityResponse.Success == true) {
      setPayload({});
      setRedirectUrl(
        `/voyage-activities/${voyageActivity[0].voyagePlanningId}`
      );
      toastr("success", "Voyage activity ended successfully");
    } else if (
      endActivityResponse &&
      endActivityResponse.data.Success == false
    ) {
      toastr("error", `${endActivityResponse.data.Message[0]}`);
      setSaving(false);
    }

    setSaving(false);
  };

  const isDeckCargoHandlingActivity = (activity) => {
    if (
      activity?.toLowerCase() === "port loading deck cargo" ||
      activity?.toLowerCase() === "port discharging deck cargo" ||
      activity?.toLowerCase() === "offshore handling deck cargo"
    ) {
      return true;
    } else {
      return false;
    }
  };


  const submitCargoHandlingForm = async (e) => {
    e.preventDefault();
    const payload = payloadForDeckCargoHandling?.map((item) => ({
      bookingEquipmentDetailId: item.bookingEquipmentDetailId,
      voyageActivityId: item.voyageActivityId,
      numberOfLifts: item.numberOfLifts,
    }));
    const _payload = [...payload];

    setDeckCargoHandlingEquipmentSaving(true);
    const response =
      await CargoHandlingService.postNumberOfLiftsForDeckCargoBookingEquipment(
        _payload
      );
    if (response) {
      toastr("success", "Number of lifts updated successfully");
      // await init();
    } else {
      toastr("error", "Number of lifts not updated");
      setDeckCargoHandlingEquipmentSaving(false);
      return;
    }
    setDeckCargoHandlingEquipmentSaving(false);
  };

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <>
      {initializing ? (
        <div className="w-100 text-center center my-5 py-5">
          <Spinner />
        </div>
      ) : (
        <div className="card ">
          <div className="card-inner p-5">
            <div className="gap gap-20px" />
            <div className="table  border-lighter p-0">
              <span className="preview-title-lg overline-title">
                Selected Activity{" "}
              </span>
              <StartEndVoyageActivityListComponent dataList={voyageActivity} />
            </div>

            {isDeckCargoHandlingActivity(voyageActivity[0]?.activityName) && (
              <>
                {payloadForDeckCargoHandling.length !== 0 && (
                  <>
                    <div className="px-0 mt-5">
                      <span className="preview-title-lg overline-title">
                        Enter Number Of Lifts
                      </span>
                    </div>
                    <form onSubmit={submitCargoHandlingForm}>
                      <div className="card-inner px-0 pt-1">
                        {payloadForDeckCargoHandling?.map(
                          (equipment, index) => (
                            <div key={index}>
                              <>
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label className="form-label small">
                                        Booking Equipment
                                      </label>
                                      <div className="form-control-wrap">
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={equipment?.label}
                                          disabled
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <label className="form-label small">
                                        Operator
                                      </label>
                                      <div className="form-control-wrap">
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={equipment?.operatorName}
                                          disabled
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <label className="form-label small">
                                        Offshore Installation
                                      </label>
                                      <div className="form-control-wrap">
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={
                                            equipment?.offshoreInstallation
                                          }
                                          disabled
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-2">
                                    <div className="form-group ">
                                      <label className="form-label small">
                                        Number of Lifts
                                      </label>
                                      <div className="form-control-wrap">
                                        <input
                                          type="number"
                                          className="form-control"
                                          defaultValue={
                                            equipment?.numberOfLifts
                                          }
                                          required
                                          step="any"
                                          onChange={(e) => {
                                            setPayloadForDeckCargoHandling(
                                              (prev) => {
                                                prev[index].numberOfLifts =
                                                  e.target.value;
                                                return prev;
                                              }
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="gap" />
                                </div>
                              </>
                            </div>
                          )
                        )}
                      </div>

                      {deckCargoHandlingEquipmentSaving && (
                        <div className="">
                          <div className="form-group px-0 m-0">
                            <button
                              type="button"
                              className="btn btn-md btn-link m-1"
                            >
                              <Spinner size="1.5" />
                            </button>
                          </div>
                        </div>
                      )}
                      {!deckCargoHandlingEquipmentSaving && (
                        <div className="">
                          <div className="form-group px-0 m-0">
                            <button
                              type="submit"
                              className="btn btn-md btn-dark btn-wide px-5 mr-3"
                            >
                              Update Lifts
                            </button>
                          </div>
                        </div>
                      )}
                    </form>
                  </>
                )}
              </>
            )}

            {voyageActivity[0]?.activityName !==
              "Port Loading Bulk cargo" ? null : (
              <>
                <div className="gap gap-20px" />
                <div className="table border-lighter p-0 mt-3">
                  <span className="preview-title-lg overline-title">
                    Bulk Cargos loaded at port{" "}
                  </span>
                  <VoyageActivityPortBulkCargoList dataList={loadingBulkCargos} />
                </div>
              </>
            )}
            {voyageActivity[0]?.activityName !==
              "Offshore Handling Bulk cargo" ? null : (
              <>
                <div className="gap gap-20px" />
                <div className="table border-lighter p-0 mt-3">
                  <VoyageActivityBulkCargoList dataList={offshoreBulkCargos} voyageActivity={voyageActivity[0]} />
                </div>
              </>
            )}
            {voyageActivity[0]?.activityName !==
              "Port Loading Consumption bulk" ? null : (
              <>
                <div className="gap gap-20px" />
                <div className="table border-lighter p-0 mt-3">
                  <span className="preview-title-lg overline-title">
                    Bulk Cargos to load at Port{" "}
                  </span>
                  <VoyageBulkCargoForPortConsumptionBulk dataList={loadingBulkCargos} voyageActivityId={voyageActivityId} />
                </div>
              </>
            )}

            {voyageActivity[0]?.activityName !==
              "Port Discharging Bulk cargo" ? null : (
              <>
                <div className="gap gap-20px" />
                <div className="table border-lighter p-0 mt-3">
                  <VoyageActivityBulkCargoList
                    dataList={dischargingBulkCargos} voyageActivity={voyageActivity[0]}
                  />
                </div>
              </>
            )}

            {parallelVoyageActivities.length == 0 ? null : (
              <>
                <div className="gap gap-30px" />
                {/* <hr className="preview-hr" /> */}
                <div className="table border-lighter p-0 mt-3">
                  <span className="preview-title-lg overline-title">
                    Other current activities{" "}
                  </span>
                  <ParallelVoyageActivityListComponent
                    dataList={parallelVoyageActivities}
                  />
                </div>
              </>
            )}
            <hr className="preview-hr" />

            {/* Actual end time input below hidden as the system considers the exact time the end acivity endpoint is called as the actual end time. There is a possibility that it might be recalled in future */}
            <form>
              <div className="row align-items-center ml-0 mr-2">
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fw-last-name">
                      Actual end date / time
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="datetime-local"
                        data-msg="Required"
                        className="form-control required"
                        id="fw-last-name"
                        name="fw-last-name"
                        required=""
                        onChange={(e) =>
                          addPayload(
                            "actualEndDate",
                            convertToUTC(e.target.value)
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <hr className="preview-hr" />

            <div className="float-right">
              {saving ? (
                <div className="form-group p-2 m-3">
                  <Spinner size="1.5" />
                </div>
              ) : (
                <div className="form-group p-2 m-3">
                  <button
                    type="button"
                    onClick={() => endActivity()}
                    className="btn btn-md btn-dark btn-wide px-5 mr-3"
                  >
                    End Activity
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-link m-1"
                    onClick={() => window.history.back()}
                  >
                    Cancel
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {!modalIsClosed && selectedRecord && voyageActivity && (
        <CustomModal
          title="Update Cargo Logs"
          content={
            <>
              <UpdateCargoLogsComponent
                cargo={selectedRecord}
                callback={editCallback}
                activityName={voyageActivity[0].activityName}
                voyageActivityId={params.voyageActivityId}
                selectedBulkCargoDetailId={
                  selectedRecord?.BulkCargoDetailId || null
                }
                selectedDeckCargoDetailId={
                  selectedRecord?.DeckCargoDetailId || null
                }
                locationList={locations}
                voyageActivity={voyageActivity[0]}
              />
            </>
          }
          onClose={() => setSelectedRecord(null)}
          isClosed={modalIsClosed}
        />
      )}
    </>
  );
}
