import React, { useEffect, useState, PureComponent } from "react";
import "../../styles/dataTable.scss";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Spinner from "../../utils/spinner";
import toastr from "../../utils/toastr";
import { generateQueryString } from "../../utils/utilityFunctions";
import FleetService from "../../services/fleetService";
import VesselService from "../../services/vesselService";
import CustomModal from "../../utils/customModal";
import EmptyRecords from "../general/EmptyRecords";
import {
	BarChart,
	Bar,
	Cell,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
	Pie,
	PieChart,
} from "recharts";

export default function VesselUtilizationReport() {
	const [loading, setLoading] = useState(false);
	const [barChartData, setBarChartData] = useState([]);
	const navigate = useNavigate();
	const [filterObject, setFilterObject] = useState([]);
	const [vesselList, setVesselList] = useState([]);
	const [poolList, setPoolList] = useState([]);
	const [chartData, setChartData] = useState([{}]);
	const [hideBar, setHideBar] = useState(false);

	useEffect(async () => {
		await init();
	}, []);

	const init = async () => {
		setLoading(true);
		await loadData();
		let listOfVessels = await VesselService.listAllVessels();
		listOfVessels = listOfVessels?.map((list) => ({
			vesselSpecificationId: list?.VesselSpecificationId,
			vesselName: list?.VesselName,
		}));
		setVesselList(listOfVessels);

		//fetch list of Pools
		const pools = await FleetService.listFleet();
		setPoolList(pools);
		setLoading(false);
	};
	let queryStr = generateQueryString(filterObject);
	const clearFilters = async () => {
		queryStr = "";
		setFilterObject({
			dateFrom: "",
			dateTo: "",
			vesselSpecificationId: "",
			poolId: "",
		});
		await loadData(1, null);
	};
	const filterData = async () => {
		await loadData(queryStr);
	};

	const loadData = async (query = queryStr) => {
		setLoading(true);

		const utilizationDetails = await VesselService.utilizationReport(query);
		if (!utilizationDetails) {
			toastr("error", "there are installations within the selected to");
			setLoading(false);
			return;
		}

		let chartArray = [
			{
				name: "Sailing Time",
				value: utilizationDetails?.SailingTime,
			},
			{
				name: "Idle Time",
				value: utilizationDetails?.IdleTime,
			},
			{
				name: "Time In Port",
				value: utilizationDetails?.TimeInPort,
			},
			{
				name: "Waiting Time",
				value: utilizationDetails?.WaitingTime,
			},
			{
				name: "Offshore Installation Servicing Time",
				value: utilizationDetails?.OffshoreInstallationServicingTime,
			},
		];
		setChartData(chartArray);
		let barData = [{ ...utilizationDetails }];
		setBarChartData(barData);
		setLoading(false);
	};

	const colors = [
		"#0047AB",
		"#FF5733",
		"#088F8F",
		"#89CFF0",
		"#6082B6",
		"#0437F2",
		"#40B5AD",
	];

	// this function formats the legend being used below the chart and it also maps the legend color to the bars for use in the tooltips
	const legendFormatter = (value, entry, index) => {
		return value.split(/(?=[A-Z])/).join(" ");
	};
	const tooltipFormatter = (value, entry, index) => {
		// return value.split(/(?=[A-Z])/).join(" ");
		const tooltipString = `${entry.split(/(?=[A-Z])/).join(" ")} ${value}`;
		return tooltipString;
	};

	const CustomTooltip2 = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			return (
				<div className="custom-tooltip bg-white p-3">
					{chartData.map((dataObject) => {
						return (
							<p className="label">{`${dataObject.name} : ${dataObject.value}`}</p>
						);
					})}
				</div>
			);
		}

		return null;
	};

	const pieCustomLabel = ({
		cx,
		cy,
		midAngle,
		innerRadius,
		outerRadius,
		NetAmount,
		index,
	}) => {
		const RADIAN = Math.PI / 180;
		// eslint-disable-next-line
		const radius = 25 + innerRadius + (outerRadius - innerRadius);
		// eslint-disable-next-line
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		// eslint-disable-next-line
		const y = cy + radius * Math.sin(-midAngle * RADIAN);

		return (
			<text
				x={x}
				y={y}
				fill="8884d8"
				textAnchor={x > cx ? "start" : "end"}
				dominantBaseline="central">
				{chartData[index].name} -{" "}
				{chartData[index].value.toLocaleString(undefined)}
			</text>
		);
	};

	return (
		<>
			{loading ? (
				<div className="w-100 text-center center my-5 py-5">
					<Spinner />
				</div>
			) : (
				<>
					<div className="card-inner-group">
						<h5 className="text-center pt-3 pb-0 pl-1">
							Vessel Utilization Report
						</h5>
						{chartData.length < 0 ? null : (
							// The filter and pagination component

							<div className="card-inner position-relative card-tools-toggle">
								{
									<div className="card-title-group">
										<div className="card-tools ml-n1">
											<ul className="btn-toolbar gx-1">
												<li>
													<div className="dropdown">
														<a
															href="#"
															className="btn btn-trigger btn-icon dropdown-toggle "
															data-toggle="dropdown">
															<em className="icon ni ni-filter-alt" />
														</a>
														<div className="dropdown-menu dropdown-menu-xl dropdown-menu-left p-1 filterPosition">
															<form style={{ zoom: "75%" }} className="">
																<div className="dropdown-head">
																	<span className="sub-title dropdown-title">
																		Filter List
																	</span>
																	<div className="dropdown">
																		<a
																			className="btn btn-sm btn-icon"
																			data-toggle="dropdown">
																			<em
																				className="icon ni ni-cross"
																				title="close"
																			/>
																		</a>
																	</div>
																</div>
																<div className="dropdown-body dropdown-body-rg">
																	<div className="row gx-6 gy-3">
																		{!filterObject.poolId && (
																			<div className="col-12">
																				<div
																					className="form-group"
																					onClick={(e) => e.stopPropagation()}>
																					<label className="overline-title overline-title-alt">
																						Vessel Name
																					</label>
																					<Select
																						id="test"
																						className="border-transparent"
																						placeholder=""
																						maxMenuHeight={160}
																						value={vesselList
																							?.map((e) => ({
																								...e,
																								label: e.vesselName,
																								value: e.vesselSpecificationId,
																							}))
																							.find(
																								(a) =>
																									a.value ===
																									filterObject.vesselSpecificationId
																							)}
																						options={vesselList?.map((e) => ({
																							...e,
																							label: e.vesselName,
																							value: e.vesselSpecificationId,
																						}))}
																						onChange={(option) =>
																							setFilterObject((prev) => ({
																								...prev,
																								vesselSpecificationId:
																									option.value,
																							}))
																						}
																					/>
																				</div>
																			</div>
																		)}
																		{!filterObject.vesselSpecificationId && (
																			<div className="col-12">
																				<div
																					className="form-group"
																					onClick={(e) => e.stopPropagation()}>
																					<label className="overline-title overline-title-alt">
																						Select a Pool
																					</label>
																					<Select
																						id="test"
																						className="border-transparent"
																						placeholder=""
																						maxMenuHeight={160}
																						value={poolList
																							?.map((e) => ({
																								...e,
																								label: e.FleetName,
																								value: e.FleetId,
																							}))
																							.find(
																								(a) =>
																									a.value ===
																									filterObject.FleetId
																							)}
																						options={poolList?.map((e) => ({
																							...e,
																							label: e.FleetName,
																							value: e.FleetId,
																						}))}
																						onChange={(option) =>
																							setFilterObject((prev) => ({
																								...prev,
																								poolId: option.value,
																							}))
																						}
																					/>
																				</div>
																			</div>
																		)}

																		<div className="col-6">
																			<div className="form-group">
																				<label
																					className="form-label"
																					htmlFor="fw-last-name">
																					From
																				</label>
																				<div className="">
																					<input
																						type="date"
																						className="form-control "
																						placeholder=""
																						id="from-date-filter"
																						value={filterObject.dateFrom}
																						onChange={(option) =>
																							setFilterObject((prev) => ({
																								...prev,
																								dateFrom: option.target.value,
																							}))
																						}
																					/>
																				</div>
																			</div>
																		</div>

																		<div className="col-6">
																			<div className="form-group">
																				<label
																					className="form-label"
																					htmlFor="fw-last-name">
																					To
																				</label>
																				<div className="form-control-wrap">
																					<input
																						type="date"
																						className="form-control "
																						placeholder=""
																						id="from-date-filter"
																						value={filterObject.dateTo}
																						onChange={(option) =>
																							setFilterObject((prev) => ({
																								...prev,
																								dateTo: option.target.value,
																							}))
																						}
																					/>
																				</div>
																			</div>
																		</div>

																		<div className="gap gap-10px" />

																		<div className="col-12">
																			<div className="form-group">
																				<button
																					type="button"
																					onClick={filterData}
																					className="btn btn-secondary">
																					<span>Filter</span>
																				</button>
																				<a
																					className="clickable ml-2"
																					onClick={() => clearFilters()}>
																					Reset Filter
																				</a>
																			</div>
																		</div>
																		<div className="gap gap-20px" />
																	</div>
																</div>
															</form>
														</div>
													</div>
												</li>
											</ul>
										</div>
									</div>
								}
							</div>
						)}
					</div>
					{hideBar && (
						<div>
							<div className="text-center">
								<button
									type="button"
									className="btn btn-sm btn-link m-1"
									onClick={() => setHideBar(false)}>
									View as a bar chart
								</button>
							</div>
							{!!chartData.length ? (
								<div className="text-center" style={{ width: "100%" }}>
									<PieChart width={700} height={300} className="mx-auto">
										<Pie
											data={chartData}
											cx="35%"
											cy="50%"
											label={pieCustomLabel}>
											{chartData.map((entry, index) => (
												<Cell key={`cell-${index}`} fill={colors[index]} />
											))}
										</Pie>
										<Tooltip />
									</PieChart>
								</div>
							) : (
								<EmptyRecords message="No data for time frame" />
							)}
						</div>
					)}
				</>
			)}
			{!hideBar && (
				<div className="text-center ">
					<div className="text-center">
						<button
							type="button"
							className="btn btn-sm btn-link m-1"
							onClick={() => setHideBar(true)}>
							View as a pie chart
						</button>
					</div>
					<BarChart
						width={600}
						height={230}
						data={barChartData}
						margin={{
							top: 20,
							right: 30,
							left: 30,
							bottom: 5,
						}}>
						<CartesianGrid strokeDasharray="3 3" />

						<XAxis dataKey="name" type="category" />
						<YAxis />
						<Tooltip
							formatter={tooltipFormatter}
							content={<CustomTooltip2 />}
						/>
						<Legend formatter={legendFormatter} />
						{barChartData.map((dataObject) =>
							Object.keys(dataObject).map((name, index) => {
								return (
									<Bar
										dataKey={name}
										// stackId="a"
										fill={colors[index % colors.length]}
									/>
								);
							})
						)}
					</BarChart>
				</div>
			)}
		</>
	);
}
