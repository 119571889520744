import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/mainLayout";
import { toReadableDate } from "../../utils/utilityFunctions";
import PartyService from "../../services/partyService";
import { useParams } from "react-router-dom";
import PaymentService from "../../services/paymentService";
import PaymentDetailsComponent from "../../components/payments/PaymentDetailsComponent";

export default function PaymentDetails() {
  const [initializing, setInit] = useState(true);
  const [details, setDetails] = useState({});

  const params = useParams();

  useEffect(async () => {
    await getPaymentDetails();
  }, []);

  const getPaymentDetails = async () => {
    setInit(true);
    const _res = await PaymentService.search(
      null,
      null,
      `PaymentReferenceNo=${params.PaymentReferenceNo}`
    );
    setDetails(_res?.res[0] || {});
    setInit(false);
  };

  return (
    <MainLayout
      title={`Payment Reciept`}
      subTitle={`Reciept NO: ${details?.ReceiptNo || ""}`}
      loading={initializing}
      showFullLoader={initializing}
      details={details}
    >
      <div>
        {!initializing && <PaymentDetailsComponent details={details} />}
      </div>
    </MainLayout>
  );
}
