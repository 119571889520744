import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import WaterUsageReportComponent from "../../components/voyage/WaterUsageReportComponent";
import VoyageService from "../../services/voyageService";
import { toReadableDate } from "../../utils/utilityFunctions";

export default function WaterUsageReportPage() {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [waterConsumptionHistory, setWaterConsumptionHistory] = useState([]);

  useEffect(async () => {
    await init();
  }
    , [])

  const init = async () => {
    const consumptionLog = await VoyageService.getConsumptionLog(params.voyageId);
    //The line below sums up the Quantity of for objects with
	//the same keys(ConsumptionType)
  const aggregatedArray = Array.from(
    consumptionLog.reduce(
      (list, { ConsumptionType, Quantity }) =>
        list.set(ConsumptionType, (list.get(ConsumptionType) || 0) + Quantity),
      new Map()
    ),
    ([ConsumptionType, Quantity]) => ({ ConsumptionType, Quantity })
  );
  const totalWaterConsumption = aggregatedArray?.find((list) => list.ConsumptionType.toLowerCase() === "water")?.Quantity
  let waterUsage = consumptionLog?.filter((list) => list.ConsumptionType.toLowerCase() === "water");
  waterUsage = waterUsage?.map((list) => ({
    Quantity: list?.Quantity,
    DateConsumed: toReadableDate(list.DateCreated),
    totalConsumption: totalWaterConsumption
  }))
  setWaterConsumptionHistory(waterUsage);

  }
  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout title={`Water consumption log`} loading={loading}>
      <>
        <WaterUsageReportComponent voyagePlanningId={params.voyageId} waterConsumptionHistory={waterConsumptionHistory} />
      </>
    </MainLayout>
  );
}
