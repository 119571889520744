import React, { useEffect, useState } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import Spinner from "../../utils/spinner";
import { Validator, ValidateGroup, ClearValidator } from "../validator";
import toastr from "../../utils/toastr";
import VoyageActivityService from "../../services/voyageActivityService";
import VoyageService from "../../services/voyageService";
import ReactTooltip from "react-tooltip";
import LocationService from "../../services/locationService";
import SailingService from "../../services/sailingService";
import { camelizeKeys, convertToUTC, toReadableDateTime } from "../../utils/utilityFunctions";
import moment from "moment/moment";

export default function StartSailingVoyageActivityComponent({
  voyagePlanningId,
}) {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [initializing, setInit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [payload, setPayload] = useState({
    voyagePlanningId: "",
    etaToNextLocation: "",
    nextLocationId: "",
    actualEndDateOfRunningActivity: "",
    actualSailingStartDate: "",
  });
  const [saving, setSaving] = useState(false);
  const [locations, setLocations] = useState([]);
  const [voyage, setVoyage] = useState([]);
  const [nextSailingToBegin, setNextSailingToBegin] = useState({});
  const [listOfSailingsToBegin, setListOfSailingsToBegin] = useState([]);
  const [priorActivitiesToSailing, setPriorActivitiesToSailing] = useState([]);
  const dummySailingObject = {
    isDummySailing: true,
    locationName: "DUMMY LOCATION",
    locationType: "",
    vesselName: "",
    sailingDistance: 0.0,
    durationOfActivityExpression: "0hrs 0mins",
    activityName: "Sailing",
    activityShortName: "S",
    activityDescription: "Time spend on sailing on economic speed.",
    locationGroup: null,
    locationTypeId: null,
    allowsParallel: false,
    voyageActivityId: "",
    voyagePlanningId: "",
    activityId: "",
    locationId: "",
    activityCategory: "",
    productivityOfActivity: null,
    durationOfActivity: "0hrs",
    endDate: "",
    plannedStartDate: "",
    activityStatus: "not-started",
    activityType: "planned",
    actualStartDate: "",
    actualEndDate: "",
    estimatedStartTime: "",
    estimatedEndDate: "",
  };

  const addPayload = new PayloadUpdater({
    payload: payload,
    setPayload: setPayload,
  }).update;
  const validationGroup = "startactivity";

  useEffect(async () => {
    await init();
  }, []);

  const init = async (_page, _pageSize) => {
    setInit(true);

    let _voyage = await VoyageService.get(voyagePlanningId);
    setVoyage(_voyage || []);

    await retrieveLocations(_voyage.SailingId);
    await retrieveVoyageActivities(voyagePlanningId);

    setInit(false);
  };

  const retrieveLocations = async (sailingId) => {
    const _sailingLocations = await SailingService.getSailingLocationBySailing(
      sailingId
    );

    const _sailingLocationIds = _sailingLocations.map(
      (location) => location.LocationId
    );

    let _locations = await LocationService.list(1, 1000);
    _locations = (_locations || []).filter(
      (location) =>
        location.LocationTypeName.toLowerCase() == "port" ||
        location.LocationTypeName.toLowerCase() == "port terminal" ||
        _sailingLocationIds.includes(location.LocationId)
    );

    let _requiredLocations = _locations.map((e) => ({
      ...e,
      label: e.LocationTypeName
        ? e.LocationName + " - " + e.LocationTypeName
        : e.LocationName + " - " + e.LocationType,
      value: e.LocationId,
    }));
    _requiredLocations = [...new Set(_requiredLocations)];

    setLocations(_requiredLocations || []);
  };

  const retrieveVoyageActivities = async () => {
    setLoading(true);

    const _voyageActivities =
      await VoyageActivityService.getVoyageActivitiesWithSailing(
        voyagePlanningId
      );
    const _camelizedVoyageActivities = camelizeKeys(_voyageActivities || []);
    const _dataListWithDummySailing = addDummySailingsToDataList(
      _camelizedVoyageActivities
    );

    let modifiedDataList = _dataListWithDummySailing;

    modifiedDataList = modifiedDataList.map((voyageActivity, index) => {
      // to make location of next sailing match next activity location

      if (
        modifiedDataList[index - 1] &&
        modifiedDataList[index + 1] &&
        modifiedDataList[index]?.activityName.toLowerCase() === "sailing" &&
        modifiedDataList[index]?.isDummySailing === true
      ) {
        modifiedDataList[index]["locationName"] =
          modifiedDataList[index + 1]?.locationName;
        modifiedDataList[index]["locationId"] =
          modifiedDataList[index + 1]?.locationId;
      }
      return {
        ...voyageActivity,
        index: index,
      };
    });

    const _nextSailingsToBegin = modifiedDataList.filter(
      (voyageActivity) => voyageActivity.isDummySailing === true
    );

    setListOfSailingsToBegin(_nextSailingsToBegin || []);
    setNextSailingToBegin(_nextSailingsToBegin[0] || {});

    const _priorActivitiesToSailing = modifiedDataList.filter(
      (voyageActivity) =>
        voyageActivity.activityStatus !== "completed" &&
        voyageActivity.index < _nextSailingsToBegin[0]?.index
    );
    setPriorActivitiesToSailing(_priorActivitiesToSailing || []);
  };

  const addDummySailingsToDataList = (data) => {
    let originalNewData = [];
    data = data.map((e) => {
      if (e) {
        originalNewData.push(e);
      }
      if (
        originalNewData.at(-1)?.locationId !==
          originalNewData.at(-2)?.locationId &&
        originalNewData.at(-1)?.activityName.toLowerCase() !== "sailing" &&
        originalNewData.at(-2)
      ) {
        originalNewData?.splice(
          originalNewData.length - 1,
          0,
          dummySailingObject
        );
      }
      return { ...e };
    });
    return originalNewData;
  };

  const startSailingActivity = async () => {
    payload.voyagePlanningId = voyagePlanningId || "";
    payload.actualEndDateOfRunningActivity = payload.actualSailingStartDate;
    // payload.actualEndDateOfRunningActivity = moment().format();
    // payload.actualSailingStartDate = moment().format();
    // payload.actualEndDateOfRunningActivity = new Date().toISOString();
    // payload.actualSailingStartDate = new Date().toISOString();

    const _payload = { ...payload };

    if (priorActivitiesToSailing.length !== 0) {
      toastr(
        "error",
        `Other planned activities exist that have to be completed before Sailing Activity to ${nextSailingToBegin.locationName} can commence`
      );
      return;
    }

    if (!_payload.actualSailingStartDate) {
      toastr("error", "Pick actual start date");
      return;
    }

    if (new Date(_payload.actualSailingStartDate) > new Date()) {
      toastr(
        "error",
        "Actual start date cannot be later than the present time"
      );
      return;
    }

    if (listOfSailingsToBegin.length === 0 && !_payload.nextLocationId) {
      toastr("error", "Select next location");
      return;
    } else if (listOfSailingsToBegin.length !== 0) {
      _payload.nextLocationId = nextSailingToBegin.locationId;
    }

    if (!_payload.etaToNextLocation) {
      toastr("error", "Pick ETA at next location");
      return;
    }

    setSaving(true);

    const request = await VoyageActivityService.startSailing(_payload);
    if (request) {
      toastr("success", "Sailing activity started");
      setRedirectUrl(`/voyage-activities/${voyagePlanningId}`);
    } else {
      toastr("error", "Sailing activity not started");
    }

    setSaving(false);
  };

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <>
      {initializing ? (
        <div className="w-100 text-center center my-5 py-5">
          <Spinner />
        </div>
      ) : (
        <div className="card ">
          <div className="card-inner p-5">
            <div className="gap gap-20px" />
            {/* <hr className="preview-hr" /> */}
           
            <div className="gap gap-20px" />
            <form>
              <div className="row align-items-center">
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fw-last-name">
                      Next Location
                    </label>
                    <div className="form-control-wrap">
                      {listOfSailingsToBegin.length === 0 ? (
                        <Select
                          onChange={(item) =>
                            addPayload(["nextLocationId"], [item.value])
                          }
                          options={locations}
                          placeholder="select location"
                        />
                      ) : (
                        <Select
                          value={locations.find(
                            (location) =>
                              location.value === nextSailingToBegin.locationId
                          )}
                          options={locations}
                          placeholder="select location"
                          isDisabled={true}
                        />
                      )}
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="form-label" htmlFor="fw-last-name">
                      Actual start date / time
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="datetime-local"
                        data-msg="Required"
                        className="form-control required"
                        id="fw-last-name"
                        name="fw-last-name"
                        required=""
                        onChange={(e) =>
                          addPayload(
                            "actualSailingStartDate",
                            convertToUTC(e.target.value)
                          )
                        }
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="form-label" htmlFor="fw-last-name">
                      ETA
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="datetime-local"
                        data-msg="Required"
                        className="form-control required"
                        id="fw-last-name"
                        name="fw-last-name"
                        required=""
                        onChange={(e) =>
                          addPayload(
                            "etaToNextLocation",
                            convertToUTC(e.target.value)
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <hr className="preview-hr" />

            {
              <div className="float-right">
                {saving ? (
                  <div className="form-group p-2 m-3">
                    <Spinner size="1.5" />
                  </div>
                ) : (
                  <div className="form-group p-2 m-3">
                    <ReactTooltip className="text-center" id="main">
                      Starting this activity will end the
                      <br /> currently running main activity
                    </ReactTooltip>
                    <button
                      className="btn btn-md btn-dark btn-wide px-5 mr-3"
                      onClick={() => startSailingActivity()}
                      data-toggle="tooltip"
                      data-placement="top"
                      data-original-title="Start main activity"
                      data-tip
                      data-for="main"
                    >
                      Start Activity
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-link m-1"
                      onClick={() => window.history.back()}
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
            }
          </div>
        </div>
      )}
    </>
  );
}
