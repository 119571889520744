import React, { useDisclosure, useEffect, useState } from "react";
import Select from "react-select";
import toastr from "../../utils/toastr";
import MainLayout from "../../layouts/mainLayout";
import EquipmentService from "../../services/equipmentService";

import ReactModal from "react-modal";
import { defaultModalStyle } from "../../utils/customStyles";

export default function CreateShipEquipment({ callback }) {
  const [showModal, setShowModal] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isNewEquipment, setIsNewEquipment] = useState(false);
  const [equipments, setEquipments] = useState([]);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [measurementUnits, setMeasurementUnits] = useState([]);
  const [measurementUnitTypes, setMeasurementUnitTypes] = useState([]);
  const [newEquipmentData, setNewEquipmentData] = useState({
    VesselSpecificationId: null,
    ProductId: null,
    EquipmentTypeId: null,
    EquipmentCount: 1,
    CapacityMeasure: 0,
    MeasurementUnitId: null,
    MeasurementUnitTypeId: null,
    EquipmentId: null,
    Description: null,
    EquipmentName: null,
  });
  const [finalSaveloading, setFinalSaveLoading] = useState(false);
  const [filteredMeasurementUnits, setFilteredMeasurementUnits] = useState([]);

  useEffect(async () => {
    await retrieveMeasurementUnits();
    await retrieveEquipments();
    await retrieveEquipmentTypes();
    await retrieveMeasurementUnitTypes();
  }, []);

  const retrieveMeasurementUnitTypes = async () => {
    const types = await EquipmentService.listMeasurementUnitTypes();
    if (types == null) setMeasurementUnitTypes({});
    else
      setMeasurementUnitTypes(
        types.map((e) => ({
          ...e,
          label: e.MeasurementUnitType,
          value: e.MeasurementUnitTypeId,
          id: "MeasurementUnitTypeId",
        }))
      );
  };

  const retrieveEquipmentTypes = async () => {
    const EquipmentTypes = await EquipmentService.listEquipmentTypes();
    if (EquipmentTypes == null) setEquipmentTypes({});
    else
      setEquipmentTypes(
        EquipmentTypes.map((e) => ({
          ...e,
          label: e.EquipmentType,
          value: e.EquipmentTypeId,
          id: "EquipmentTypeId",
        }))
      );
  };

  const retrieveMeasurementUnits = async () => {
    const units = await EquipmentService.listMeasurementUnits();
    if (units == null) setMeasurementUnits({});
    else
      setMeasurementUnits(
        units.map((e) => ({
          ...e,
          label: e.MeasurementUnitSymbol,
          value: e.MeasurementUnitId,
          id: "MeasurementUnitId",
        }))
      );
  };

  const filterMeasurementUnits = async (equipment) => {
    const units = await EquipmentService.listMeasurementUnits();

    if (equipment.label == "Storage Tank") {
      setFilteredMeasurementUnits(
        units
          .map((e) => ({
            ...e,
            label: e.MeasurementUnitSymbol,
            value: e.MeasurementUnitId,
            id: "MeasurementUnitId",
          }))
          ?.filter((unit) => unit.UnitType == "volume")
      );
    } else if (equipment.label == "Pump") {
      setFilteredMeasurementUnits(
        units
          .map((e) => ({
            ...e,
            label: e.MeasurementUnitSymbol,
            value: e.MeasurementUnitId,
            id: "MeasurementUnitId",
          }))
          ?.filter((unit) => unit.UnitType == "pumping_rate")
      );
    } else {
      setFilteredMeasurementUnits(
        units.map((e) => ({
          ...e,
          label: e.MeasurementUnitSymbol,
          value: e.MeasurementUnitId,
          id: "MeasurementUnitId",
        }))
      );
    }
  };
  const retrieveEquipments = async () => {
    const equips = await EquipmentService.listEquipment();
    if (equips == null) setEquipments({});
    else
      setEquipments(
        equips.map((e) => ({
          ...e,
          label: e.EquipmentName,
          value: e.EquipmentId,
          id: "EquipmentId",
        }))
      );
  };

  const handleCloseModal = () => {
    setIsOpen(false);

    setNewEquipmentData({
      VesselSpecificationId: null,
      ProductId: null,
      EquipmentTypeId: null,
      EquipmentCount: 1,
      CapacityMeasure: 0,
      MeasurementUnitId: null,
      MeasurementUnitTypeId: null,
      EquipmentId: null,
      Description: null,
      EquipmentName: null,
    });
    setShowModal(false);
  };
  const onClose = () => {
    setIsOpen(false);
    setNewEquipmentData({
      VesselSpecificationId: null,
      ProductId: null,
      EquipmentTypeId: null,
      EquipmentCount: 1,
      CapacityMeasure: 0,
      MeasurementUnitId: null,
      MeasurementUnitTypeId: null,
      EquipmentId: null,
      Description: null,
      EquipmentName: null,
    });
    setShowModal(false);
  };
  const onOpen = () => {
    setIsOpen(true);
  };

  const addNewEquipmentData = async () => {
    if (isNewEquipment) {
      var newEquip = {
        EquipmentName: newEquipmentData.EquipmentName,
        MeasurementUnitTypeId: newEquipmentData.MeasurementUnitTypeId,
        EquipmentTypeId: newEquipmentData.EquipmentTypeId,
      };
      const equipAdded = await EquipmentService.addNewEquipment(newEquip);
      if (!equipAdded) {
        toastr("error", "New Equipment could not be added");
      } else {
        setNewEquipmentData({
          ...newEquipmentData,
          EquipmentId: equipAdded.EquipmentId,
        });

        var equips = equipments;
        equips.push({
          ...equipAdded,
          label: equipAdded.EquipmentName,
          value: equipAdded.EquipmentId,
          id: "EquipmentId",
        });
        setEquipments(equips);
      }
    }

    let newEquipment = [].map((e) => ({
      VesselSpecificationId: newEquipmentData.VesselSpecificationId,
      ProductId: newEquipmentData.ProductId,
      EquipmentTypeId: newEquipmentData.EquipmentTypeId,
      EquipmentCount: newEquipmentData.EquipmentCount,
      CapacityMeasure: newEquipmentData.CapacityMeasure,
      MeasurementUnitId: newEquipmentData.MeasurementUnitId,
      MeasurementUnitTypeId: newEquipmentData.MeasurementUnitTypeId,
      EquipmentId: newEquipmentData.EquipmentId,
      Description: newEquipmentData.Description,
      EquipmentName: newEquipmentData.EquipmentName,
    }));

    var hasFieldErrs = false;

    if (hasFieldErrs) return;
    setFinalSaveLoading(true);
    callback && callback(newEquipmentData);
    handleCloseModal();
    setIsNewEquipment(false);
  };

  const handleChange = (e) => {
    if (e.id === "EquipmentId") {
      setNewEquipmentData({
        ...newEquipmentData,
        EquipmentName: e.label,
        [e.id]: e.value,
      });
    } else {
      setNewEquipmentData({
        ...newEquipmentData,
        [e.id]: e.value,
      });
    }
  };
  const effectInput = (id, value) => {
    setNewEquipmentData({
      ...newEquipmentData,
      [id]: value,
    });
  };

  return (
    <>
      <button
        className="btn btn-sm text-primary my-3"
        bg="#006886"
        border="none"
        onClick={(e) => {
          e.preventDefault();
          onOpen();
        }}
      >
        Add Vessel Equipment
      </button>

      <ReactModal
        isOpen={isOpen}
        onClose={onClose}
        contentLabel=""
        style={(defaultModalStyle, { overlay: { zIndex: "1200" } })}
      >
        <MainLayout
          preTitle={
            <span className="ml-2">
              <em
                className="icon ni ni-arrow-left pointer"
                onClick={() => handleCloseModal()}
              />
              <span className="pointer" onClick={() => handleCloseModal()}>
                Return
              </span>
              <h4 className="ml-2">Add Equipment</h4>
            </span>
          }
        >
          <div className="card-inner p-5">
            <form>
              {!isNewEquipment ? (
                <div className="row align-items-center">
                  <div className="col-sm-6" id="DivToHide1">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-first-name">
                        Select Equipment
                      </label>
                      <div className="form-control-wrap">
                        <Select
                          id="EquipmentId"
                          name="EquipmentId"
                          onChange={(item) => {
                            handleChange(item);
                            filterMeasurementUnits(item);
                          }}
                          options={equipments}
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn btn-sm text-primary my-3"
                    bg="#006886"
                    border="none"
                    onClick={() => setIsNewEquipment(true)}
                  >
                    {" "}
                    * Or add new Equipment{" "}
                  </button>
                  <div className="gap" />
                </div>
              ) : (
                <div className="nk-wizard-content">
                  <div className="row gy-3">
                    <div className="col-sm-3" id="DivToHide1">
                      <div className="form-group">
                        <label className="form-label" htmlFor="fw-first-name">
                          Equipment Name
                        </label>
                        <div className="form-control-wrap">
                          <input
                            onChange={(e) => {
                              effectInput("EquipmentName", e.target.value);
                            }}
                            type="text"
                            data-msg="Required"
                            className="form-control required"
                            id="EquipmentName"
                            name="EquipmentName"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3" id="DivToHide1">
                      <div className="form-group">
                        <label className="form-label" htmlFor="fw-first-name">
                          Equipment Type
                        </label>
                        <div className="form-control-wrap">
                          <Select
                            id="EquipmentTypeId"
                            name="EquipmentTypeId"
                            onChange={(item) => {
                              handleChange(item);
                            }}
                            options={equipmentTypes}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3" id="DivToHide1">
                      <div className="form-group">
                        <label className="form-label" htmlFor="fw-first-name">
                          MeasurementUnit Type
                        </label>
                        <div className="form-control-wrap">
                          <Select
                            id="MeasurementUnitTypeId"
                            name="MeasurementUnitTypeId"
                            onChange={(item) => {
                              handleChange(item);
                            }}
                            options={measurementUnitTypes}
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      className="btn btn-sm text-primary my-3"
                      bg="#006886"
                      border="none"
                      onClick={() => setIsNewEquipment(false)}
                    >
                      {" "}
                      *Select existing Equipment{" "}
                    </button>
                  </div>
                </div>
              )}
              <div className="nk-wizard-content">
                <div className="row gy-3">
                  <div className="col-sm-6" id="DivToHide1">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-first-name">
                        Capacity Measure
                      </label>
                      <div className="form-control-wrap">
                        <input
                          onChange={(e) => {
                            effectInput("CapacityMeasure", e.target.value);
                          }}
                          type="number"
                          data-msg="Required"
                          className="form-control required"
                          id="CapacityMeasure"
                          name="CapacityMeasure"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6" id="DivToHide1">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-first-name">
                        Measurement Unit
                      </label>
                      <div className="form-control-wrap">
                        <Select
                          id="MeasurementUnitId"
                          name="MeasurementUnitId"
                          onChange={(item) => {
                            handleChange(item);
                          }}
                          options={filteredMeasurementUnits}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6" id="DivToHide2">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-last-name">
                        Equipment Count
                      </label>
                      <div className="form-control-wrap">
                        <input
                          onChange={(e) => {
                            effectInput("EquipmentCount", e.target.value);
                          }}
                          type="number"
                          data-msg="Required"
                          className="form-control required"
                          id="EquipmentCount"
                          name="EquipmentCount"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6" id="Tog2">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-last-name">
                        Description
                      </label>
                      <div className="form-control-wrap">
                        <textarea
                          onChange={(e) => {
                            effectInput("Description", e.target.value);
                          }}
                          placeholder="Fill in description"
                          className="form-control no-resize"
                          id="Description"
                          defaultValue={""}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nk-tb-col">
                <span>&nbsp;</span>
              </div>
              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={() => addNewEquipmentData()}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-danger m-1"
                  onClick={() => handleCloseModal()}
                >
                  Cancel
                </button>
              </div>
            </form>
            <hr className="preview-hr" />
          </div>
        </MainLayout>
      </ReactModal>
    </>
  );
}
