import apiCall from "../utils/apiCall";
import { dynamicSort, clearAPICache } from "../utils/utilityFunctions";

export default class SailingService {
  static uploadSchedule = async (payload) => {
    clearAPICache(`/api/Sailing`);
    return await apiCall(`/api/Sailing/Upload`, "POST", payload);
  };
  static getSailingSchedule = async () => {
    return await apiCall(`/api/Sailing/Get`, "GET", null, null, null, true);
  };
  static getSchedule = async (startDate, endDate) => {
    return await apiCall(`/api/Sailing/GetScheduleByDateRange`, "GET", null, null, null, true);
  };
  static getSchedule2 = async (startDate, endDate) => {
    return await apiCall(
      `/api/Sailing/GetScheduleByDateRange?startDate=${startDate}&endDate=${endDate}`
    );
  };
  static getSailingLocation = async () => {
    let response = await apiCall(`/api/SailingLocation/Get`, "GET", null, null, null, true);
    if (response) {
      response = response.sort(dynamicSort("Sequence"));
    }
    return response;
  };
  static getSailingLocationBySailing = async (sailingId) => {
    let response = await apiCall(
      `/api/SailingLocation/GetSailingLocationsBySailingId?sailingId=${sailingId}`
    );
    if (response) {
      response = response.sort(dynamicSort("Sequence"));
    }
    return response;
    }
    static getUnlockedSailingDetails = async () => {
        return await apiCall(`/api/Sailing/GetUnlockedSailingsDetails?startDate`, "GET", null, null, null, true);

    }
  static countSailingLocations = async (sailingId) => {
    return await apiCall(
      `/api/SailingLocation/CountSailingLocations?sailingId=${sailingId}`
    );
  };

  static updateSailingLocation = async (payload) => {
    clearAPICache(`/api/Sailing`);
    return await apiCall(
      `/api/SailingLocation/PUT/${payload.SailingLocationId}`,
      "PUT",
      payload
    );
  };
  static addSailingLocation = async (payload) => {
    clearAPICache(`/api/Sailing`);
    return await apiCall(`/api/SailingLocation/POST`, "POST", payload);
  };
  static updateSailing = async (payload) => {
    clearAPICache(`/api/Sailing`);
    return await apiCall(
      `/api/Sailing/PUT/${payload.SailingId}`,
      "PUT",
      payload
    );
  };

  static getUnlockedSailings = async (page, pageSize) => {
    page = page || 1;
    pageSize = pageSize || 200;
    let response = await apiCall(
      `/api/Sailing/GetUnlockedSailings?pageCount=${page}&noPerPage=${pageSize}`,
      "GET"
    );
    return response;
  };

  static listSailingLocations = async (sailingId) => {
    let response = await apiCall(`/api/SailingLocation/ListBySailing?sailingId=${sailingId}`, "GET", null, null, null, true);
    if (response) {
      response = response.sort(dynamicSort("Sequence"));
    }
    return response;
  };
}
