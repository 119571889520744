import apiCall from "../utils/apiCall";
import { dynamicSort } from "../utils/utilityFunctions";

export default class EquipmentService {
  static listEquipmentTypes = async () => {
    let response = await apiCall(`/api/EquipmentType/Get`, "GET", null, null, null, true);

    if (response?.Data) {
      response = response.Data;
    }

    return response;
  };
  static listCargoEquipmentTypes = async () => {
    return await apiCall(`/api/EquipmentType/GetCargoEquipmentTypes`, "GET", null, null, null, true);
  };
  static listMeasurementUnits = async () => {
    return await apiCall(
      `/api/EquipmentMeasurement/GetUnits`,
      null,
      null,
      false,
      false,
      true
    );
  };
  static listMeasurementUnitTypes = async () => {
    return await apiCall(
      `/api/EquipmentMeasurement/GetUnitTypes`,
      null,
      null,
      false,
      false,
      true
    );
  };
  static listMeasurementTypes = async () => {
    return await apiCall(`/api/EquipmentMeasurement/GetTypes`, "GET", null, null, null, true);
  };
  // static listMeasurementTypes = async () => {
  //   return await apiCall(
  //     `/api/EquipmentMeasurement/GetTypes`,
  //     "GET",
  //     null,
  //     false,
  //     false,
  //     true
  //   );
  // };
  static cargoCarryingEquipmentTypes = async () => {
    return await apiCall(`/api/CargoCarryingEquipmentTypes/Get`, "GET", null, null, null, true);
  };
  static equipmentTypesByParentId = async () => {
    return await apiCall(`/api/EquipmentType/GetEquipmentTypeByParentId`);
  };
  static listEquipment = async () => {
    const response = await apiCall(`/api/Equipment/Get`, "GET", null, null, null, true);
    return response?.Data;
  };
  static listShipSideEquipment = async () => {
    return await apiCall(`/api/ShipSideEquipment/Get`, "GET", null, null, null, true);
  };
  static listDockSideEquipment = async () => {
    return await apiCall(`/api/DockSideEquipment/Get`, "GET", null, null, null, true);
  };
  static addEquipmentToVessel = async (payload) => {
    return await apiCall(`/api/ShipSideEquipment`, "POST", payload);
  };
  static addNewEquipment = async (payload) => {
    return await apiCall(`/api/Equipment/Post`, "POST", payload);
  };
  static createEquipment = async (payload) => {
    return await apiCall(
      `/api/ShipSideEquipment/AddEquipment`,
      "POST",
      payload
    );
  };
  static editShipEquipment = async (payload) => {
    return await apiCall(
      `/api/ShipSideEquipment/Put/${payload.VesselSpecificationId}`,
      "PUT",
      payload
    );
  };
  static createEquipments = async (payload) => {
    return await apiCall(
      `/api/ShipSideEquipment/AddEquipments`,
      "POST",
      payload
    );
  };
  static removeVesselEquipment = async (id) => {
    return await apiCall(`/api/ShipSideEquipment/Delete/${id}`, "DELETE");
  };
  static listVesselEquipment = async (vesselSpecId) => {
    return await apiCall(
      `/api/ShipSideEquipment/GetEquipmentByVesselSpecId?vesselSpecId=${vesselSpecId}`
    );
  };
  static listVesselEquipmentV2 = async (vesselSpecId) => {
    return await apiCall(
      `/api/ShipSideEquipment/GetByVessel?vesselSpecId=${vesselSpecId}`
    );
  };

  static addEquipmentMeasurements = async (payload) => {
    return await apiCall(
      `/api/EquipmentMeasurement/AddMeasurements`,
      "POST",
      payload
    );
  };
  static fleetByVessel = async () => {
    return await apiCall(`/api/VesselFleet/Get`, "GET", null, null, null, true);
  };
  static getEquipmentCategories = async () => {
    return await apiCall(`/api/Equipment/GetEquipmentCategories`, "GET", null, null, null, true);
  };

  static createCargoEquipment = async (payload) => {
    return await apiCall(`/api/CargoCarryingEquipment/POST`, "POST", payload);
  };
  static updateCargoEquipment = async (payload) => {
    return await apiCall(
      `/api/CargoCarryingEquipment/PUT/${payload.CargoCarryingEquipmentId}`,
      "PUT",
      payload
    );
  };
  static getCargoCarryingEquipment = async (
    currentPage = 1,
    PageSize = 10,
    query = "",
    equipmentTypeId = "",
    categoryId = "",
    partyId = ""
  ) => {
    return await apiCall(
      `/api/CargoCarryingEquipment/List?pageCount=${currentPage}&noPerPage=${PageSize}&searchParam=${query}&equipmentTypeId=${equipmentTypeId}&categoryId=${categoryId}&partyId=${partyId}`
    );
  };

  static countCargoEquipments = async () => {
    return await apiCall(`/api/CargoCarryingEquipment/CountEquipment`);
  };
  static getCargoCarryingEquipmentById = async (id) => {
    return await apiCall(`/api/CargoCarryingEquipment/GetExtended?id=${id}`);
  };

  static getOffShoreContainerSizes = async () => {
    return await apiCall(`/api/Equipment/ContainerSizes`, "GET", null, null, null, true);
  };

  static getOffShoreContainerTypes = async () => {
    let response = await apiCall(`/api/Equipment/ContainerTypes`, "GET", null, null, null, true);
    return response.sort(dynamicSort("EquipmentType"));
  };
}
