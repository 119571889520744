import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../../components/fileInput";
import Spinner from "../../utils/spinner";
import {
  Validator,
  ValidateGroup,
  ClearValidator,
} from "../../components/validator";
import toastr from "../../utils/toastr";
import LocationService from "../../services/locationService";
import VoyageService from "../../services/voyageService";
import ReactTooltip from "react-tooltip";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import VesselService from "../../services/vesselService";
import CommercialService from "../../services/commercialService";
import PartyService from "../../services/partyService";
import PartyModal from "../Modals/partyModal";
import CustomModal from "../../utils/customModal";
import { toReadableDate } from "../../utils/utilityFunctions";
import { forEach } from "lodash";
import { dateToYYYY_MM_DD } from "../../utils/utilityFunctions";

export default function CreateCommercialPage() {
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({});
  const [party, setParty] = useState({});
  const [partyTypes, setPartyTypes] = useState({});
  const [partyList, setPartyList] = useState([{}]);
  const [selectedPartyId, setSelectedPartyId] = useState(null);
  const [availableBookings, setAvailableBookings] = useState([]);
  const [saving, setSaving] = useState(false);
  const [vesselDetail, setVesselDetail] = useState(null);
  const [imoSearching, setImoSearching] = useState(false);
  const [myEventsList, setMyEventsList] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);
  const localizer = momentLocalizer(moment);
  const [schedules, setSchedules] = useState([]);
  const [startDate, setStartDate] = useState(new Date().toLocaleDateString());
  const [endDate, setEndDate] = useState(new Date().toLocaleDateString());
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsClosed, setModalIsClosed] = useState(false);
  const [vessels, setVessels] = useState([]);
  const [selectedVesselSpecificationId, setSelectedVesselSpecificationId] =
    useState("");
  const [tab, setTab] = useState(1);
  const [currency, setCurrency] = useState([]);
  const [contractType, setContractType] = useState([]);
  const [vesselParty, setVesselParty] = useState([]);
  const [vesselOwner, setVesselOwmer] = useState({
    partyName: "",
    partyId: "",
  });

  const addPayload = new PayloadUpdater({
    payload: payload,
    setPayload: setPayload,
  }).update;
  const validationGroup = "createcommercial";

  useEffect(async () => {
    await init();

    await configurations();

    await retrieveParty();

    const _partyTypes = await PartyService.listPartyTypes();
    setPartyTypes(_partyTypes || []);
  }, []);

  const init = async () => {
    setLoading(true);

    let _vessels = await VesselService.listAllVessels();
    if (_vessels) {
      // _vessels = _vessels.map((c) => ({
      //   ...c,
      //   label: c.VesselName,
      //   value: c.VesselSpecificationId,
      // }));
      setVessels(_vessels || []);
    }

    setLoading(false);
  };

  const vesselOwnership = async (option) => {
    let _vesselPartiesList = await PartyService.listByVesselId(option);

    if (_vesselPartiesList) {
      let _vesselParty = _vesselPartiesList?.find(
        (l) => l.PartyType.toLowerCase() == "vessel owner"
      );
      setVesselParty(_vesselParty);
    }
  };

  let selectedParty = vesselParty?.Party || [];

  vesselOwner.partyName = selectedParty.PartyName || "";
  vesselOwner.partyId = selectedParty.PartyId || "";
  payload.ownerPartyId = selectedParty.PartyId || "";

  const configurations = async () => {
    let _configList = await CommercialService.getConfigList();

    if (_configList) {
      let _currency = _configList?.filter(
        (l) =>
          l.CorrespondingData.toLowerCase() == "usd" ||
          l.CorrespondingData.toLowerCase() == "naira" ||
          l.CorrespondingData.toLowerCase() == "gbp"
      );
      setCurrency(_currency);
    }

    if (_configList) {
      let _contractType = _configList?.filter(
        (l) =>
          l.CorrespondingData.toLowerCase() == "long term" ||
          l.CorrespondingData.toLowerCase() == "spot charter"
      );
      setContractType(_contractType);
    }
  };

  const addPartyModalCallback = async (partyAndContactPerson) => {
    if (partyAndContactPerson?.party) {
      const _partyWithContactList =
        await PartyService.getPartyWithContactPerson(
          partyAndContactPerson.party.partyId ||
          partyAndContactPerson.party.PartyId
        );
      const _selectedContactPerson = _partyWithContactList &&
        _partyWithContactList.ContactPersons && {
        ..._partyWithContactList.ContactPersons[0],
      };
    }
    await retrieveParty();
  };

  const retrieveParty = async () => {
    const party_List = await PartyService.listParty();
    setPartyList(party_List);
  };

  const renewalOptions = [
    { label: "One-off deal", value: "one_off" },
    { label: "Recurring", value: "recurring" },
    { label: "Subject to approval", value: "subject_to_approval" },
  ];

  const commercialStatusOptions = [
    { label: "Active", value: "active" },
    { label: "Future", value: "future" },
    { label: "Extended", value: "extended" },
    { label: "Cancelled", value: "cancelled" },
    { label: "Terminated", value: "terminated" },
  ];

  const submitForm = async () => {
    const _payload = { ...payload };

    if (_payload && currency) {
      _payload.currencyConfigurationId = currency.find(
        (e) => e.CorrespondingData.toLowerCase() === "usd"
      )?.ConfigurationId;
    }

    if (!_payload.vesselSpecificationId) {
      toastr("error", "Vessel required");
      return;
    }

    if (!_payload.ownerPartyId) {
      toastr("error", "Vessel owner required");
      return;
    }

    if (!_payload.chartererPartyId) {
      toastr("error", "Select a charterer");
      return;
    }

    if (!_payload.dailyRate) {
      toastr("error", "Enter daily rate");
      return;
    }

    // if (!_payload.currencyConfigurationId) {
    //   toastr("error", "Select currency");
    //   return;
    // }

    // if (!_payload.commercialStatus) {
    //     toastr("error", "Select commercial status");
    //     return;
    // }

    if (!_payload.contractTypeConfigurationId) {
      toastr("error", "Select contract type");
      return;
    }

    if (!_payload.contractStartDate) {
      toastr("error", "Select contract start date");
      return;
    }

    if (!_payload.contractEndDate) {
      toastr("error", "Select contract end date");
      return;
    }

    if (!_payload.contractOptions) {
      toastr("error", "Select a contract option");
      return;
    }

    // if (!_payload.contractDocumentBase64String) {
    //   toastr("error", "Select a file to upload");
    //   return;
    // }
    setSaving(true);

    const response = await CommercialService.postCommercial(_payload);
    if (response) {
      setPayload({});
      setRedirectUrl("/commercials");
      toastr("success", "Commercial added successfully");
    }
    setSaving(false);
  };

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout
      title="Create Commercial"
      loading={loading}
      showFullLoader={loading}
    >
      <form>
        {tab === 1 && (
          <div className="card-inner">
            <div>
              <div className="row">
                <div className="col-12">
                  <p>
                    <span className="preview-title-lg overline-title">
                      Vessel Details
                    </span>{" "}
                  </p>
                </div>

                <div className="col-md-4">
                  <div className="form-group" style={{ zIndex: "50" }}>
                    <label className="form-label small">Vessel Name</label>
                    <div className="form-control-wrap">
                      {/* <input
                        type="text"
                        className="form-control required"
                        required
                        onChange={(e) =>
                          addPayload("vesselSpecificationId", e.target.value)
                        }
                      /> */}
                      <Select
                        value={vessels
                          .map((c) => ({
                            ...c,
                            label: c.VesselName,
                            value: c.VesselSpecificationId,
                          }))
                          .find(
                            (a) => a.value == payload?.vesselSpecificationId
                          )}
                        options={vessels.map((c) => ({
                          ...c,
                          label: c.VesselName,
                          value: c.VesselSpecificationId,
                        }))}
                        onChange={(e) => {
                          addPayload("vesselSpecificationId", e.value);
                          vesselOwnership(e.value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div
                    className="form-group"
                  // style={{ zIndex: "1000" }}
                  >
                    <label className="form-label small">Vessel Owner</label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control"
                        value={vesselOwner.partyName}
                        onChange={(e) =>
                          addPayload("ownerPartyId", e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div className="row">
                <div className="col-12">
                  <p>
                    <span className="preview-title-lg overline-title">
                      Charterer's Information
                    </span>{" "}
                  </p>
                </div>

                <div className="col-md-4">
                  <div className="form-group" style={{ zIndex: "40" }}>
                    <label className="form-label small">Charterer's Name</label>
                    <div className="form-control-wrap">
                      <Select
                        options={partyList.map((e) => ({
                          ...e,
                          label: e.PartyName,
                          value: e.PartyId,
                        }))}
                        value={partyList
                          .map((e) => ({
                            ...e,
                            label: e.PartyName,
                            value: e.PartyId,
                          }))
                          .find((a) => a.value == selectedPartyId)}
                        onChange={(p) =>
                          addPayload("chartererPartyId", p.PartyId)
                        }
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="col-sm-4 col-lg-3 mb-3"
                  style={{ paddingTop: "8px" }}
                >
                  <div className="form-control-wrap">
                    <PartyModal
                      addNewLabel="+ Add Charterer"
                      callback={async (_party_contact) =>
                        await addPartyModalCallback(_party_contact)
                      }
                    />
                  </div>
                </div>
              </div>
              <br />

              <div className="row">
                <div className="col-12">
                  <p>
                    <span className="preview-title-lg overline-title">
                      Contract Timeline
                    </span>{" "}
                  </p>
                </div>

                <div className="col-md-4">
                  <div className="form-group" style={{ zIndex: "10" }}>
                    <label className="form-label small">
                      (Expected) Start Date
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="datetime-local"
                        className="form-control required"
                        onChange={(e) =>
                          addPayload("contractStartDate", e.target.value)
                        }
                      // value={dateToYYYY_MM_DD(
                      //   payload.issueDate || payload.IssueDate
                      // )}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group" style={{ zIndex: "10" }}>
                    <label className="form-label small">
                      Comments on starting conditions
                    </label>
                    <div className="form-control-wrap">
                      <textarea
                        // type="text"
                        className="form-control"
                        style={{ height: "100px" }}
                        onChange={(e) =>
                          addPayload("contractStartComments", e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div className="row">
                <div className="col-md-4">
                  <div className="form-group" style={{ zIndex: "10" }}>
                    <label className="form-label small">
                      (Expected) End Date
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="datetime-local"
                        className="form-control required"
                        onChange={(e) =>
                          addPayload("contractEndDate", e.target.value)
                        }
                      // value={dateToYYYY_MM_DD(
                      //   payload.issueDate || payload.IssueDate
                      // )}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group" style={{ zIndex: "10" }}>
                    <label className="form-label small">
                      Comments on ending conditions
                    </label>
                    <div className="form-control-wrap">
                      <textarea
                        // type="text"
                        className="form-control"
                        style={{ height: "100px" }}
                        onChange={(e) =>
                          addPayload("contractEndComments", e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div className="row">
                <div className="col-12">
                  <p>
                    <span className="preview-title-lg overline-title">
                      Charter Value
                    </span>{" "}
                  </p>
                </div>

                <div className="col-md-4">
                  <div className="form-group ">
                    <label className="form-label small">Daily Rate</label>
                    <div className="form-control-wrap">
                      <input
                        type="number"
                        className="form-control required"
                        required
                        onChange={(e) =>
                          addPayload("dailyRate", e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group" style={{ zIndex: "60" }}>
                    <label className="form-label small">Currency</label>
                    <div className="form-control-wrap">
                      {/* <Select
                        // options={currencyOptions}
                        onChange={(e) =>
                          addPayload("currencyConfigurationId", e.value)
                        }
                      /> */}
                      <Select
                        value={currency
                          .map((c) => ({
                            ...c,
                            label: c.CorrespondingData,
                            value: c.ConfigurationId,
                          }))
                          .find((a) => a.label.toLowerCase() === "usd")}
                        options={currency.map((c) => ({
                          ...c,
                          label: c.CorrespondingData,
                          value: c.ConfigurationId,
                        }))}
                        isDisabled={true}
                      // onChange={(e) => {
                      //   addPayload("currencyConfigurationId", e.value);
                      // }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div className="row">
                <div className="col-12">
                  <p>
                    <span className="preview-title-lg overline-title">
                      Contract Options
                    </span>{" "}
                  </p>
                </div>

                <div className="col-md-4">
                  <div className="form-group" style={{ zIndex: "40" }}>
                    <label className="form-label small">Contract Type</label>
                    <div className="form-control-wrap">
                      {/* <Select
                        // options={contractOptions}
                        onChange={(e) =>
                          addPayload("contractTypeConfigurationId", e.value)
                        }
                      /> */}
                      <Select
                        value={contractType
                          .map((c) => ({
                            ...c,
                            label: c.CorrespondingData,
                            value: c.ConfigurationId,
                          }))
                          .find((a) => a.value == payload?.ConfigurationId)}
                        options={contractType.map((c) => ({
                          ...c,
                          label: c.CorrespondingData,
                          value: c.ConfigurationId,
                        }))}
                        onChange={(e) => {
                          addPayload("contractTypeConfigurationId", e.value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group" style={{ zIndex: "50" }}>
                    <label className="form-label small">
                      Options for renewal/extension
                    </label>
                    <div className="form-control-wrap">
                      <Select
                        options={renewalOptions}
                        onChange={(e) => addPayload("contractOptions", e.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div className="row">
                <div className="col-md-4">
                  <div className="form-group ">
                    <label className="form-label small">
                      Contract Document
                    </label>
                    <div className="form-control-wrap">
                      <FileInput
                        onChange={(file) => {
                          addPayload(
                            [
                              "contractDocumentBase64String",
                              "contractDocumentFilename",
                              "contractDocumentMime",
                            ],
                            [file.base64, `${file.name}`, `${file.type}`]
                          );
                        }}
                        fileValue={{
                          name: payload.contractDocumentFilename,
                          size:
                            payload.contractDocumentBase64String?.length || 0,
                          fileContent: payload.contractDocumentBase64String,
                          type: payload.contractDocumentMime,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {saving && (
          <div className="form-group p-2 m-3">
            <button type="button" className="btn btn-md btn-link m-1">
              <Spinner size="1.5" />
            </button>
          </div>
        )}
        {!saving && (
          <div className="form-group p-2 m-3">
            <button
              type="button"
              className="btn btn-md btn-dark btn-wide px-5 mr-3"
              onClick={() => submitForm()}
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-sm btn-link m-1"
              onClick={() => window.history.back()}
            >
              Cancel
            </button>
          </div>
        )}
      </form>
      <hr className="preview-hr" style={{ visibility: "hidden" }} />
    </MainLayout>
  );
}
