import React, { useEffect, useMemo, useState } from "react";
import { randomString, toReadableDate } from "../../utils/utilityFunctions";
import TableComponent from "../tableComponent";
import Paginate from "../paginate";
import { checkUserPermission } from "../../utils/utilityFunctions";

export default function VesselListComponent({
  _apiResponse,
  onSearchChange,
  reload,
  page,
  pageSize,
  setLoading, search
}) {
  const [tableArguments, setTableArguments] = useState({
    columns: [
      {
        label: "Vessel Name",
      },
      {
        label: "IMO",
        size: "md",
      },
      {
        label: "Owner's Name",
        size: "sm",
      },
      {
        label: "Vessel Type",
        size: "md",
      },
      {
        label: "Fleet",
        size: "lg",
      },
      {
        label: "Year Build",
        size: "lg",
      },
      {
        label: "Last Active",
        size: "lg",
      },
      {
        label: "Status",
      },
    ],
    overflowActions: [
      {
        title: "",
        icon: "",
        eventHanler: (arg) => {},
      },
      {
        title: "",
        icon: "",
        contentMenuItems: [
          {
            title: "",
            icon: "",
            eventHanler: (arg) => {},
          },
        ],
      },
    ],

    rowData: [{}],
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [listOfVessels, setListOfVessels] = useState([]);

  const nameInitialStyles = {
    1: "success",
    2: "purple",
    3: "danger",
    4: "dark",
    5: "warning",
  };

  useEffect(async () => {
    let restructuredData = [];
    if (_apiResponse && _apiResponse.Data) {
      restructuredData = _apiResponse.Data.map((element) => ({
        ...element,
        ...element.specification,
      }));
      setTotalRecords(_apiResponse?.TotalCount ||  0);
    } else {
      setTotalRecords(0);
    }

    const listVessels = restructuredData || [];
    setListOfVessels(listVessels);

    setTableArguments({
      ...tableArguments,
      rowData: listVessels.map((data, index) => ({
        name: data?.VesselName,
        imo: data?.Imo,
        ownersName: data.owner && (
          <div className="user-card">
            <div
              className={`user-avatar xs bg-${
                nameInitialStyles[Math.floor(Math.random() * 5) + 1]
              }`}
            >
              <span>
                {data.owner && data.owner.substring(0, 2).toUpperCase()}
              </span>
            </div>
            <div className="user-name">
              <span className="tb-lead">{data.owner}</span>
            </div>
          </div>
        ),
        vesselType: data.vesselType,
        fleet: data.fleet,
        yearBuild: data?.YearOfBuild,
        lastActive:
          (data.lastActivityTime && toReadableDate(data.lastActivityTime)) ||
          "N/A",
        status:
          (data.status && (
            <span className="tb-status text-warning">{data.status}</span>
          )) ||
          "N/A",
        vesselId: data.VesselSpecificationId,
        activeVoyagePlanningId: data.activeVoyagePlanningId || "",
      })),
    });
  }, [_apiResponse]);

  const cacheKey = useMemo(() => randomString(10), []);

  return (
    <>
      <TableComponent
        {...tableArguments}
        onSearchChange={onSearchChange}
        reload={reload}
        cacheKey={cacheKey}
        setLoading={setLoading}  search={search}
      />
      {listOfVessels.length <= 0 && (
        <p className="p-4">Nothing to show on this page</p>
      )}

      {reload &&
        // checkUserPermission("vessel_add_vessel") &&
        // checkUserPermission("vessel_download_vessels") && 
        (
          <div className="card-inner">
            <Paginate
              onChange={(p) => reload(null, p)}
              currentPage={page}
              pageSize={pageSize}
              totalRecordsCount={totalRecords}
            />
          </div>
        )}
    </>
  );
}
