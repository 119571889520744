import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import LocationService from "../../services/locationService";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import Spinner from "../../utils/spinner";
import { Validator, ValidateGroup, ClearValidator } from "../validator";
import toastr from "../../utils/toastr";
import ProductService from "../../services/productService";
import EquipmentService from "../../services/equipmentService";
import UsageReportComponent from "../product/usageReport";
import VoyageService from "../../services/voyageService";
import { currencyFormat } from "../../utils/utilityFunctions";

export default function VoyageFinalizationFuelAndWaterUsageComponent({
	voyageUnderView,
}) {
	const params = useParams();
	const [redirectUrl, setRedirectUrl] = useState(null);
	const [loading, setLoading] = useState(false);
	const [products, setProducts] = useState([]);
	const [units, setUnits] = useState([]);
	const [payload, setPayload] = useState({});
	const [saving, setSaving] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [voyage, setVoyage] = useState({});
	const [waterConsumedByVessel, setWaterConsumedByVessel] = useState("");

	const addPayload = new PayloadUpdater({
		payload: payload,
		setPayload: setPayload,
	}).update;
	const validationGroup = "fuel_consumption_log";

	useEffect(async () => {
		await init();

		return () => {
			ClearValidator(validationGroup);
		};
	}, []);

	const init = async () => {
		setLoading(true);

		let _voyage = voyageUnderView;
		setVoyage(_voyage || []);
		//retrieving quantity of water consumed by the vessel
		const consumptionLog = await VoyageService.getConsumptionLog(
			params.voyagePlanningId
		);
		//The line below sums up the Quantity of for objects with
		//the same keys(ConsumptionType)
		const aggregatedArray = Array.from(
			consumptionLog.reduce(
				(list, { ConsumptionType, Quantity }) =>
					list.set(
						ConsumptionType,
						(list.get(ConsumptionType) || 0) + Quantity
					),
				new Map()
			),
			([ConsumptionType, Quantity]) => ({ ConsumptionType, Quantity })
		);
		const totalWaterConsumption = aggregatedArray?.find(
			(list) => list.ConsumptionType.toLowerCase() === "water"
		)?.Quantity;
		setWaterConsumedByVessel(totalWaterConsumption);

		setLoading(false);
	};
	return redirectUrl ? (
		<Navigate to={redirectUrl} replace={true} />
	) : (
		<>
			{loading && (
				<div className="w-100 text-center center my-5 py-5">
					<Spinner />
				</div>
			)}
			{!loading && (
				<div className="p-2">
					<div className="card-inner">
						<div className="row">
							<div className="col-3 border text-center">
								<h6 className="my-3 fw-400 mt-4 pt-2" style={{fontWeight: "normal"}}>Quantity of Fuel Consumed</h6>
								<h5 className="my-3">
									{currencyFormat(voyage?.QuantityofFuelConsumed,0)}{" "}
									{voyage?.FuelMeasurementUnitSymbol || "L"}
								</h5>
							</div>
							<div className="col-1"></div>
							<div className="col-3 border text-center">
							<h6 className="my-3 fw-400 mt-4 pt-2" style={{fontWeight: "normal"}}>Quantity of Water Consumed</h6>
								<h5 className="my-3">
									{currencyFormat(waterConsumedByVessel,0)}{" "}
									{/* {voyage?.QuantityofWaterOnboard}{" "} */}
									{voyage?.WaterMeasurementUnitSymbol || "L"}
								</h5>
							</div>
						</div>
						<div>
							{
								// products.map((product, index)=> <div style={{zIndex: (products.length - index)}} key={index} className="my-2"><UsageReportComponent productId={product.ProductId}  /></div>)
							}
						</div>
					</div>
				</div>
			)}
		</>
	);
}
