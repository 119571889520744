import React, { useEffect, useState, useContext } from "react";
import VesselUtilizationDashboard from "../../components/dashboard/VesselUtilization";
import OffshoreInstallationDashboard from "../../components/dashboard/OffShoreAllocationCost";
import MainLayout from "../../layouts/mainLayout";
import DraftInvoices from "../../components/dashboard/DraftInvoices";
import OutstandingPayments from "../../components/dashboard/OutstandingPayments";
import OffshoreInstallationEfficiency from "../../components/dashboard/OffshoreInstallationEfficiency";

import { VoyageTimelineChart } from "../../components/dashboard/VoyageTimelineChart";
import VoyageCostDashboardComponent from "../../components/dashboard/VoyageCostDashboardComponent";
import VoyageFinalizationDashboardComponent from "../../components/dashboard/VoyageFinalizationDashboardComponent";
import BookingRequestDashboard from "../../components/dashboard/BookingRequestDashboard";
import { checkUserPermission } from "../../utils/utilityFunctions";
import SailingScheduleCalendarComponent from "../../components/sailingSchedule/sailingScheduleCalendarComponent";
import { DataContext } from "../../contexts/dataContexts";
import VesselUtilizationReport from "../../components/dashboard/VesselUtilizationReport";

export default function DashboardPage() {
	const [loading, setLoading] = useState(true);

	const user = useContext(DataContext);
	const isAdmin = user.globalState.user.isAdmin;

	const DashboardHeader = ({ title, enableToggle }) => {
		return (
			<div className="card-title-group mt-n1">
				<div className="card-title">
					<h6 className="title">{title}</h6>
				</div>
				<div className="card-tools me-n1">
					<div className="dropdown">
						<a
							href="#"
							className="dropdown-toggle btn btn-icon btn-trigger show"
							data-bs-toggle="dropdown"
							aria-expanded="true">
							<em className="icon ni ni-more-h" />
						</a>
					</div>
				</div>
			</div>
		);
	};

	return (
		<MainLayout title="Dashboard" transparentBackground={true}>
			<div className="nk-block" style={{ zoom: "85%" }}>
				<div className="row g-gs">
				{checkUserPermission("dashboard_vessel_utilization") && (
						<div className="col-md-12">
							<div className="card card-bordered h-100 pt-5 pb-5">
								<div className="analytic-ov  disappearing-scrollbar   pt-0 dashboardView dashboardView-vessel-util">
									<VesselUtilizationReport />
									{/* </div> */}
								</div>
							</div>
						</div>
					)}
					{/* {checkUserPermission("dashboard_vessel_utilization") && (
						<div className="col-md-6">
							<div className="card card-bordered h-100 ">
								<div className="analytic-ov  disappearing-scrollbar   pt-0 dashboardView">
									<VesselUtilizationDashboard />
								</div>
							</div>
						</div>
					)} */}
					{checkUserPermission("dashboard_sailing_schedule") && (
						<div className=" col-xxl-12 ">
							<div className="card card-bordered  h-100">
								<div className="analytic-ov  disappearing-scrollbar pb-2  pt-0 timelineChart">
									<VoyageTimelineChart />
								</div>
							</div>
						</div>
					)}
					{isAdmin && checkUserPermission("dashboard_finalize_voyage") && (
						<div className="col-md-6">
							<div className="card card-bordered h-100 ">
								<div className="analytic-ov   disappearing-scrollbar  pt-0 dashboardView">
									<VoyageFinalizationDashboardComponent />
								</div>
							</div>
						</div>
					)}
					{checkUserPermission("dashboard_voyage_additional_cost") && (
						<div className="col-md-6">
							<div className="card card-bordered h-100 ">
								<div className="analytic-ov  disappearing-scrollbar   pt-0 dashboardView">
									<VoyageCostDashboardComponent />
								</div>
							</div>
						</div>
					)}

					{checkUserPermission("dashboard_installation_efficiency") && (
						<div className=" col-xxl-12">
							<div className="card card-bordered h-100 ">
								<div className="analytic-ov   disappearing-scrollbar  pt-0 dashboardView">
									<OffshoreInstallationEfficiency />
									{/* </div> */}
								</div>
							</div>
						</div>
					)}
					{!isAdmin && checkUserPermission("dashboard_finalize_voyage") && (
						<div className="col-md-12">
							<div className="card card-bordered h-100 ">
								<div className="analytic-ov   disappearing-scrollbar  pt-0 dashboardView">
									<VoyageFinalizationDashboardComponent />
								</div>
							</div>
						</div>
					)}
					{checkUserPermission("dashboard_installation_allocated_cost") && (
						<div className="col-md-12">
							<div className="card card-bordered h-100 ">
								<div className="analytic-ov   disappearing-scrollbar  pt-0 dashboardView">
									<OffshoreInstallationDashboard />
								</div>
							</div>
						</div>
					)}

					{/* {checkUserPermission("dashboard_process_booking_request") && ( */}
					<div className="col-md-12">
						<div className="card card-bordered h-100 ">
							<div className="analytic-ov  disappearing-scrollbar   pt-0 dashboardView">
								<SailingScheduleCalendarComponent />
							</div>
						</div>
					</div>
					{/* )} */}
					{checkUserPermission("dashboard_process_booking_request") && (
						<div className="col-md-12">
							<div className="card card-bordered h-100 ">
								<div className="analytic-ov  disappearing-scrollbar   pt-0 dashboardView">
									<BookingRequestDashboard />
								</div>
							</div>
						</div>
					)}
					{checkUserPermission("dashboard_draft_invoices") && (
						<div className="col-md-6">
							<div className="card card-bordered h-100 ">
								<div className="analytic-ov   disappearing-scrollbar  pt-0 dashboardView">
									<DraftInvoices />
								</div>
							</div>
						</div>
					)}
					{checkUserPermission("dashboard_outstanding_payments") && (
						<div className="col-md-6">
							<div className="card card-bordered h-100 ">
								<div className="analytic-ov  disappearing-scrollbar   pt-0 dashboardView">
									<OutstandingPayments />
									{/* </div> */}
								</div>
							</div>
						</div>
					)}
					
				</div>
				<div className="gap gap-20px" />
			</div>
		</MainLayout>
	);
}
