import React, { useEffect, useState } from "react";
import MyTable from "../dataTable";
import { toReadableDate } from "../../utils/utilityFunctions";
import Spinner from "../../utils/spinner";
import VesselService from "../../services/vesselService";
import PayloadUpdater from "../../utils/payloadUpdater";
import toastr from "../../utils/toastr";
import Select from "react-select";
import CountryService from "../../services/countriesService";
import DPService from "../../services/dynamicPositioning";

export default function EditVesselDetailsComponent({
  vesselSpecId,
  callback,
  vesselTypes,
  dynamicPositions,
  countryList,
  vessel,
}) {
  // const [vessel, setVessel] = useState({});
  // const [countryList, setCountry] = useState([]);
  // const [vesselTypes, setVesselTypes] = useState([{}]);
  // const [dynamicPositions, setDp] = useState([{}]);
  const [payload, setUpdatePayload] = useState({});
  const [initializing, setInit] = useState(true);
  const [updateLoading, setUpdateLoading] = useState(false);

  const updatePayload = new PayloadUpdater({
    payload: payload,
    setPayload: setUpdatePayload,
  }).update;

  useEffect(async () => {
    setInit(true);

    // const _vessel = await VesselService.fetchVesselExtended(vesselSpecId);
    // setVessel(_vessel || {});

    // const _vesselTypes = await VesselService.fetchVesselTypes();
    // setVesselTypes(_vesselTypes);

    // const _countryList = await CountryService.listCountry();
    // setCountry(_countryList || []);

    // const dyp_List = await DPService.dpList();
    // setDp(dyp_List || []);

    // if (_vessel) {
    if (vessel) {
      // let v = { ..._vessel };
      let v = { ...vessel };
      delete v.specification;
      setUpdatePayload(v);
    }

    setInit(false);
  }, [vesselSpecId]);

  const submitUpdate = async () => {
    setUpdateLoading(true);
    let _payload = { ...payload };
    delete _payload.vesselType;
    delete _payload.status;
    delete _payload.owner;
    delete _payload.lastActivityTime;
    delete _payload.fleet;
    let response = await VesselService.postEditVessel(
      // vessel.VesselSpecificationId,
      vesselSpecId,
      _payload
    );
    if (response) {
      toastr("success", "Vessel updated");
      callback && callback();
    } else {
      toastr("error", "Vessel update failed");
    }

    setUpdateLoading(false);
  };

  const formLabel = (label, toolTip) => {
    return (
      <label className="form-label" title={toolTip || label}>
        <small>{label}</small>
      </label>
    );
  };

  return initializing ? (
    <div className="w-100 text-center my-5 py-5">
      <Spinner />
    </div>
  ) : (
    <form
      action="#"
      className="form-validate is-alter "
      noValidate="novalidate"
    >
      <div
        style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden" }}
        className="small-scrollbar pr-2"
      >
        <div className="form-group row">
          <div className="col">
            {formLabel("Beam")}
            <div className="form-control-wrap">
              <input
                type="text"
                className="form-control "
                required
                defaultValue={vessel.Beam}
                onChange={(e) => updatePayload("beam", e.target.value)}
              />
            </div>
          </div>

          <div className="col">
            {formLabel("BHP")}
            <div className="form-control-wrap">
              <input
                type="number"
                className="form-control "
                required
                defaultValue={vessel.Bhp}
                onChange={(e) => updatePayload("Bhp", e.target.value)}
              />
            </div>
          </div>

          <div className="col">
            {formLabel("Call sign")}
            <div className="form-control-wrap">
              <input
                type="text"
                className="form-control "
                required
                defaultValue={vessel.CallSign}
                onChange={(e) => updatePayload("callSign", e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="form-group row">
          <div className="col">
            {formLabel("Country")}
            <div className="form-control-wrap">
              <Select
                options={countryList.map((e) => ({
                  ...e,
                  label: e.CountryName,
                  value: e.CountryId,
                }))}
                value={countryList
                  .map((e) => ({
                    ...e,
                    label: e.CountryName,
                    value: e.CountryId,
                  }))
                  .find(
                    (a) => a.value == (payload.CountryId || payload.countryId)
                  )}
                onChange={(option) => {
                  updatePayload("CountryId", option.value);
                }}
              />
            </div>
          </div>

          <div className="col">
            {formLabel("Crew cabins")}
            <div className="form-control-wrap">
              <input
                type="number"
                className="form-control "
                required
                defaultValue={vessel.NoOfCrewCabins}
                onChange={(e) =>
                  updatePayload("noOfCrewCabins", e.target.value)
                }
              />
            </div>
          </div>

          <div className="col">
            {formLabel("Dead weight")}
            <div className="form-control-wrap">
              <input
                type="text"
                className="form-control "
                required
                defaultValue={vessel.SummerDwt}
                onChange={(e) => updatePayload("summerDwt", e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="form-group row">
          <div className="col">
            {formLabel("Deck area")}
            <div className="form-control-wrap">
              <input
                type="text"
                className="form-control"
                required
                defaultValue={vessel.DeckArea}
                onChange={(e) => updatePayload("deckArea", e.target.value)}
              />
            </div>
          </div>
          <div className="col">
            {formLabel("Deck cargo capacity")}
            <div className="form-control-wrap">
              <input
                type="text"
                className="form-control"
                required
                defaultValue={vessel.DeckCargoCapacity}
                onChange={(e) =>
                  updatePayload("deckCargoCapacity", e.target.value)
                }
              />
            </div>
          </div>

          <div className="col">
            {formLabel("Displacement Summer")}
            <div className="form-control-wrap">
              <input
                type="number"
                className="form-control "
                required
                defaultValue={vessel.DisplacementSummer}
                onChange={(e) =>
                  updatePayload("DisplacementSummer", e.target.value)
                }
              />
            </div>
          </div>
        </div>
        <div className="form-group row">
          <div className="col">
            {formLabel("Draught")}
            <div className="form-control-wrap">
              <input
                type="text"
                className="form-control "
                required
                defaultValue={vessel.Draught}
                onChange={(e) => updatePayload("draught", e.target.value)}
              />
            </div>
          </div>

          <div className="col">
            {formLabel("Dynamic Positioning")}
            <div className="form-control-wrap">
              <Select
                options={dynamicPositions.map((e) => ({
                  ...e,
                  label: e.DynamicPositioning,
                  value: e.DynamicPositioningId,
                }))}
                value={dynamicPositions
                  .map((e) => ({
                    ...e,
                    label: e.DynamicPositioning,
                    value: e.DynamicPositioningId,
                  }))
                  .find(
                    (a) =>
                      a.value ==
                      (payload.DynamicPositioningId ||
                        payload.dynamicPositioningId)
                  )}
                onChange={(option) => {
                  updatePayload("DynamicPositioningId", option.value);
                }}
              />
            </div>
          </div>

          <div className="col">
            {formLabel("Extreme Breadth")}
            <div className="form-control-wrap">
              <input
                type="number"
                className="form-control "
                required
                defaultValue={vessel.BreadthExtreme}
                onChange={(e) =>
                  updatePayload("BreadthExtreme", e.target.value)
                }
              />
            </div>
          </div>
        </div>

        <div className="form-group row">
          <div className="col">
            {formLabel("Fuel Consumption")}
            <div className="form-control-wrap">
              <input
                type="text"
                className="form-control "
                required
                defaultValue={vessel.FuelConsumption}
                onChange={(e) =>
                  updatePayload("FuelConsumption", e.target.value)
                }
              />
            </div>
          </div>

          <div className="col">
            {formLabel("GRT", "Gross registered Tonnage")}
            <div className="form-control-wrap">
              <input
                type="number"
                className="form-control "
                defaultValue={vessel.GrossRegisteredTonnage}
                onChange={(e) =>
                  updatePayload("GrossRegisteredTonnage", e.target.value)
                }
              />
            </div>
          </div>

          <div className="col">
            {formLabel("LBP", "Length between perpendicular")}
            <div className="form-control-wrap">
              <input
                type="number"
                className="form-control "
                required
                defaultValue={vessel.LengthBetweenPerpendicular}
                onChange={(e) =>
                  updatePayload("lengthBetweenPerpendicular", e.target.value)
                }
              />
            </div>
          </div>
        </div>

        <div className="form-group row">
          <div className="col">
            {formLabel("Length overall")}
            <div className="form-control-wrap">
              <input
                type="number"
                className="form-control "
                required
                defaultValue={vessel.LengthOverall}
                onChange={(e) => updatePayload("lengthOverall", e.target.value)}
              />
            </div>
          </div>

          <div className="col">
            {formLabel("Liquid Oil")}
            <div className="form-control-wrap">
              <input
                type="text"
                className="form-control "
                required
                defaultValue={vessel.LiquidOil}
                onChange={(e) => updatePayload("LiquidOil", e.target.value)}
              />
            </div>
          </div>

          <div className="col">
            {formLabel("Max Draught")}
            <div className="form-control-wrap">
              <input
                type="number"
                className="form-control "
                required
                defaultValue={vessel.DraughtMax}
                onChange={(e) => updatePayload("draughtMax", e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="form-group row">
          <div className="col">
            {formLabel("Max Speed")}
            <div className="form-control-wrap">
              <input
                type="number"
                className="form-control "
                required
                defaultValue={vessel.SpeedMax}
                onChange={(e) => updatePayload("SpeedMax", e.target.value)}
              />
            </div>
          </div>

          <div className="col">
            {formLabel("NRT", "Net registered Tonnage")}
            <div className="form-control-wrap">
              <input
                type="number"
                className="form-control "
                defaultValue={vessel.NetRegisteredTonnage}
                onChange={(e) =>
                  updatePayload("NetRegisteredTonnage", e.target.value)
                }
              />
            </div>
          </div>

          <div className="col">
            {formLabel("Passenger cabins")}
            <div className="form-control-wrap">
              <input
                type="number"
                className="form-control "
                required
                defaultValue={vessel.NoOfPassengerCabins}
                onChange={(e) =>
                  updatePayload("noOfPassengerCabins", e.target.value)
                }
              />
            </div>
          </div>
        </div>

        <div className="form-group row">
          <div className="col">
            {formLabel("Place Built")}
            <div className="form-control-wrap">
              <input
                type="text"
                className="form-control "
                required
                defaultValue={vessel.PlaceOfBuild}
                onChange={(e) => updatePayload("PlaceOfBuild", e.target.value)}
              />
            </div>
          </div>

          <div className="col">
            {formLabel("Rate of turn")}
            <div className="form-control-wrap">
              <input
                type="number"
                className="form-control "
                required
                defaultValue={vessel.RateOfTurn}
                onChange={(e) => updatePayload("RateOfTurn", e.target.value)}
              />
            </div>
          </div>

          <div className="col">
            {formLabel("Vessel Type")}
            <div className="form-control-wrap">
              <Select
                options={vesselTypes.map((e) => ({
                  ...e,
                  label: e.VesselType,
                  value: e.VesselTypeId,
                }))}
                value={vesselTypes
                  .map((e) => ({
                    ...e,
                    label: e.VesselType,
                    value: e.VesselTypeId,
                  }))
                  .find(
                    (a) =>
                      a.value == (payload.VesselTypeId || payload.vesselTypeId)
                  )}
                onChange={(option) => {
                  updatePayload("VesselTypeId", option.value);
                }}
              />
            </div>
          </div>
        </div>

        <div className="form-group row">
          <div className="col">
            {formLabel("Width")}
            <div className="form-control-wrap">
              <input
                type="number"
                className="form-control "
                required
                defaultValue={vessel.Width}
                onChange={(e) => updatePayload("width", e.target.value)}
              />
            </div>
          </div>

          <div className="col">
            {formLabel("Year of build")}
            <div className="form-control-wrap">
              <input
                type="number"
                className="form-control "
                required
                defaultValue={vessel.YearOfBuild}
                onChange={(e) => updatePayload("yearOfBuild", e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="form-group row">
          <div className="col">
            {formLabel("Vessel Email")}
            <div className="form-control-wrap">
              <input
                type="email"
                className="form-control "
                required
                defaultValue={vessel.VesselEmail}
                onChange={(e) => updatePayload("vesselEmail", e.target.value)}
              />
            </div>
          </div>

          <div className="col">
            {formLabel("Vessel Satelite Phone")}
            <div className="form-control-wrap">
              <input
                type="number"
                className="form-control "
                required
                defaultValue={vessel.VesselSatelitePhone}
                onChange={(e) =>
                  updatePayload("vesselSatelitePhone", e.target.value)
                }
              />
            </div>
          </div>
        </div>

        <div className="form-group">
          <button
            onClick={() => submitUpdate()}
            type="button"
            disabled={updateLoading}
            className="btn  btn-primary"
          >
            {updateLoading ? <Spinner size="1" color="white" /> : "Submit"}
          </button>
        </div>
      </div>
    </form>
  );
}
