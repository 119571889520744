import React, { useEffect, useState, ReactDOM, useRef } from "react";
import Select from "react-select";
import toastr from "../../utils/toastr";
import MainLayout from "../../layouts/mainLayout";
import EquipmentService from "../../services/equipmentService";
import PartyService from "../../services/partyService";
import CertificateService from "../../services/certificateService";
import { Navigate, useParams } from "react-router-dom";
import PartyModal from "../Modals/partyModal";

export default function CreateEquipment() {
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [partyList, setParty] = useState([{}]);
  const [certificates, setCertificates] = useState([]);
  const [measurementUnits, setMeasurementUnits] = useState([]);
  const [volumeMeasurementUnits, setVolumeMeasurementUnits] = useState([]);
  const [lengthMeasurementUnits, setLengthMeasurementUnits] = useState([]);
  const [massMeasurementUnits, setMassMeasurementUnits] = useState([]);
  const [areaMeasurementUnits, setAreaMeasurementUnits] = useState([]);
  const [categories, setCategories] = useState([]);
  const [parentCategories, setParentCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [newEquipmentData, setNewEquipmentData] = useState({
    CargoCarryingEquipmentTypeId: "",
    PartyId: "",
    EquipmentName: "",
    EquipmentDescription: "",
    ContainerSizeId: "",
    TubularCategoryId: null,
    TubularSubCategoryId: null,
    Ccunumber: null,
    EquipmentName: "",
    OffshoreContainerEquipmentTypeId: "",
  });
  const [equipmentMeasurements, setEquipmentMeasurements] = useState([]);
  const [measurementTypes, setMeasurementTypes] = useState([]);
  const [measurementUnitTypes, setMeasurementUnitTypes] = useState([]);
  const [finalSaveloading, setFinalSaveLoading] = useState(false);
  const [initializing, setInit] = useState(true);
  const [containerSizes, setContainerSizes] = useState([]);
  const [containerTypes, setContainerTypes] = useState([]);
  const [diameterUnit, setDiameterUnit] = useState();
  const [lengthUnit, setLengthUnit] = useState();
  const [volumeUnit, setVolumeUnit] = useState();
  const diameterRef = useRef(null);
  const lengthRef = useRef(null);
  const widthRef = useRef(null);
  const heightRef = useRef(null);
  const payloadRef = useRef(null);
  const tareMassRef = useRef(null);
  const maxGrossMaxRef = useRef(null);
  const volumeCapacityRef = useRef(null);



  useEffect(async () => {
    setInit(true);
    await retrieveEquipmentTypes();
    await retrieveParty();
    await retrieveCertificates();
    await retrieveMeasurementUnits();
    await retrieveMeasurementTypes();
    await retrieveCategories();
    await retrieveEquipmentSizes();
    await retrieveContainerTypes();
    setInit(false);
    document
      .getElementById("DivOwner")
      .style.setProperty("display", "none", "important");
    document
      .getElementById("DivAddOwner")
      .style.setProperty("display", "none", "important");
  }, []);

  const retrieveCategories = async () => {
    const categories = await EquipmentService.getEquipmentCategories();
    if (categories == null) setCategories({});
    else
      setCategories(
        categories.map((e) => ({
          ...e,
          label: e.TubularCategory,
          value: e.TubularCategoryId,
          id: "TubularCategoryId",
        }))
      );

    let _parentCategories = categories
      ?.map((e) => ({
        ...e,
        label: e.TubularCategory,
        value: e.TubularCategoryId,
        id: "TubularCategoryId",
      }))
      .filter(
        (category) =>
          category.ParentTubularCategoryId ==
          "00000000-0000-0000-0000-000000000000"
      );

    let _filteredChildrenCategories = categories?.filter(
      (category) =>
        category.ParentTubularCategoryId !==
        "00000000-0000-0000-0000-000000000000"
    );

    let _parentTubularForChildCategories = _filteredChildrenCategories?.map(
      (e) => ({
        ...e,
        parentTubularCategory: _parentCategories
          .map((p) => ({
            TubularCategory: p.TubularCategory,
            TubularCategoryId: p.TubularCategoryId,
          }))
          .find((x) => x.TubularCategoryId == e.ParentTubularCategoryId),
      })
    );
    let _childCategories = _parentTubularForChildCategories?.map((e) => ({
      ...e,
      label:
        e.parentTubularCategory.TubularCategory + " - " + e.TubularCategory,
      value: e.TubularCategoryId,
      id: "TubularSubCategoryId",
    }));
    setParentCategories(_parentCategories);
    setSubCategories(_childCategories);
  };
  //The next few lines extracted the selected tubular category to filter results for sub-category
  let subCat = subCategories.filter(
    (list) =>
      list?.ParentTubularCategoryId === newEquipmentData?.TubularCategoryId
  );
  let subCategoryOptions = [];
  if (subCat.length === 0) {
    subCategoryOptions.push(subCategories);
    subCategoryOptions.flat();
  } else if (subCat.length > 0) {
    subCategoryOptions.push(subCat);
    subCategoryOptions.flat();
  }
  subCategoryOptions = subCategoryOptions.flat();

  const retrieveEquipmentTypes = async () => {
    const EquipmentTypes = await EquipmentService.listCargoEquipmentTypes();
    if (EquipmentTypes == null) setEquipmentTypes([]);
    else
      setEquipmentTypes(
        EquipmentTypes.map((e) => ({
          ...e,
          label: e.EquipmentType,
          value: e.EquipmentTypeId,
          id: "CargoCarryingEquipmentTypeId",
        }))
      );
  };
  const retrieveMeasurementUnits = async () => {
    const _measurementUnits = await EquipmentService.listMeasurementUnits();
    const _measurementUnitTypes =
      await EquipmentService.listMeasurementUnitTypes();

    if (_measurementUnitTypes == null) setMeasurementUnitTypes({});
    else setMeasurementUnitTypes(_measurementUnitTypes);

    if (_measurementUnits == null) setMeasurementUnits({});
    else
      setMeasurementUnits(
        _measurementUnits.map((e) => ({
          ...e,
          label: e.MeasurementUnitSymbol,
          value: e.MeasurementUnitId,
          id: "MeasurementUnitId",
        }))
      );

    let massUnitType = _measurementUnitTypes?.find(
      (unitType) => unitType.MeasurementUnitType == "Mass/Weight"
    );

    let lengthUnitType = _measurementUnitTypes?.find(
      (unitType) => unitType.MeasurementUnitType == "Length/Diameter"
    );

    let volumeUnitType = _measurementUnitTypes?.find(
      (unitType) => unitType.MeasurementUnitType == "Volume"
    );

    let areaUnitType = _measurementUnitTypes?.find(
      (unitType) => unitType.MeasurementUnitType == "Area"
    );

    let massUnits = _measurementUnits
      ?.map((e) => ({
        ...e,
        label: e.MeasurementUnitSymbol,
        value: e.MeasurementUnitId,
        id: "MeasurementUnitId",
      }))
      .filter(
        (unit) =>
          unit.MeasurementUnitTypeId == massUnitType.MeasurementUnitTypeId
      );

    let lengthUnits = _measurementUnits
      ?.map((e) => ({
        ...e,
        label: e.MeasurementUnitSymbol,
        value: e.MeasurementUnitId,
        id: "MeasurementUnitId",
      }))
      .filter(
        (unit) =>
          unit.MeasurementUnitTypeId == lengthUnitType.MeasurementUnitTypeId
      );

    let volumeUnits = _measurementUnits
      ?.map((e) => ({
        ...e,
        label: e.MeasurementUnitSymbol,
        value: e.MeasurementUnitId,
        id: "MeasurementUnitId",
      }))
      .filter(
        (unit) =>
          unit.MeasurementUnitTypeId == volumeUnitType.MeasurementUnitTypeId
      );

    let areaUnits = _measurementUnits
      ?.map((e) => ({
        ...e,
        label: e.MeasurementUnitSymbol,
        value: e.MeasurementUnitId,
        id: "MeasurementUnitId",
      }))
      .filter(
        (unit) =>
          unit.MeasurementUnitTypeId == areaUnitType.MeasurementUnitTypeId
      );

    setMassMeasurementUnits(massUnits || []);
    setLengthMeasurementUnits(lengthUnits || []);
    setVolumeMeasurementUnits(volumeUnits || []);
    setAreaMeasurementUnits(areaUnits || []);
  };

  const retrieveMeasurementTypes = async () => {
    const types = await EquipmentService.listMeasurementTypes();
    if (types == null) setMeasurementTypes({});
    else setMeasurementTypes(types);
  };

  const retrieveEquipmentSizes = async () => {
    const _equipmentSizes = await EquipmentService.getOffShoreContainerSizes();
    if (_equipmentSizes == null) setContainerSizes({});
    else
      setContainerSizes(
        _equipmentSizes.map((e) => ({
          ...e,
          label: e.Size,
          value: e.ContainerSizeId,
          id: "ContainerSizeId",
        }))
      );
  };

  const retrieveContainerTypes = async () => {
    const _containerTypes = await EquipmentService.getOffShoreContainerTypes();
    if (_containerTypes == null) setContainerTypes({});
    else
      setContainerTypes(
        _containerTypes.map((e) => ({
          ...e,
          label: e.EquipmentType,
          value: e.EquipmentTypeId,
          id: "OffshoreContainerEquipmentTypeId",
        }))
      );
  };

  const retrieveParty = async () => {
    const party_List = await PartyService.listParty();
    if (party_List == null) setParty({});
    else
      setParty(
        party_List
          .map((e) => ({
            ...e,
            label: e.PartyName,
            value: e.PartyId,
            id: "PartyId",
          }))
          .filter((party) => party.PartyType == "CCU Owner")
      );
  };

  const retrieveCertificates = async () => {
    const _certificates = await CertificateService.listAll();
    if (_certificates == null) setCertificates({});
    else
      setCertificates(
        _certificates.map((e) => ({
          ...e,
          label: e.CertificateName,
          value: e.CertificateNameId,
          id: "CertificateId",
        }))
      );
  };

  const addPartyModalCallback = async (partyAndContactPerson) => {
    if (partyAndContactPerson?.party) {
      const _partyWithContactList =
        await PartyService.getPartyWithContactPerson(
          partyAndContactPerson.party.partyId ||
          partyAndContactPerson.party.PartyId
        );
      const _selectedContactPerson = _partyWithContactList &&
        _partyWithContactList.ContactPersons && {
        ..._partyWithContactList.ContactPersons[0],
      };
    }
    await retrieveParty();
  };

  const describeEquipment = (id) => {
    if (id == "") return;
    const ChosenEquip = equipmentTypes.find(
      (a) => a.EquipmentTypeId == id
    ).EquipmentType;

    if (ChosenEquip == "Offshore Container") {
      return `${containerTypes?.find(
        (type) =>
          type.EquipmentTypeId ==
          newEquipmentData.OffshoreContainerEquipmentTypeId
      )?.label
        } - ${containerSizes?.find(
          (size) => size.ContainerSizeId == newEquipmentData.ContainerSizeId
        )?.label
        }`;
    } else if (ChosenEquip == "Tubular") {
      return `${selectedCategory?.label} - ${subCategories?.find(
        (subCategory) =>
          subCategory.TubularCategoryId ==
          newEquipmentData.TubularSubCategoryId
      )?.TubularCategory
        } - ${newEquipmentData.Diameter}${diameterRef.current?.state?.value?.label || " "
        } - ${newEquipmentData.Length}${lengthRef.current?.state?.value?.label || " "
        }`;
    } else if (ChosenEquip == "BigBag") {
      return `BigBag - ${newEquipmentData.VolumeCapacity}${volumeCapacityRef.current?.state?.value?.label || " "
        }`;
    }
  };

  const postNewEquipmentData = async () => {
    newEquipmentData.EquipmentDescription = describeEquipment(
      newEquipmentData.CargoCarryingEquipmentTypeId
    );

    let hasFieldErrs = false;

    if (hasFieldErrs) return;
    setFinalSaveLoading(true);
    const vesselAdded = await EquipmentService.createCargoEquipment(
      newEquipmentData
    );
    if (!vesselAdded) {
      toastr("error", "Equipment could not be added");
      setFinalSaveLoading(false);
      return;
    } else {
      toastr("success", "Equipment successfully added");
      setFinalSaveLoading(false);
      let id = vesselAdded.CargoCarryingEquipmentId;
      await postEquipmentMeasurements(id);
    }
  };
  const postEquipmentMeasurements = async (id) => {
    let hasFieldErrs = false;

    const _payload = equipmentMeasurements.map((e) => ({
      ...e,
      CargoCarryingEquipmentId: id,
    }));
    setEquipmentMeasurements(_payload);

    if (hasFieldErrs) return;
    setFinalSaveLoading(true);
    const measurementsAdded = await EquipmentService.addEquipmentMeasurements(
      _payload
    );
    if (!measurementsAdded) {
      toastr("error", "Equipment could not be added");
      setFinalSaveLoading(false);
      return;
    } else {
      setRedirectUrl("/equipment-list");
    }
  };

  const handleChange = (e, type) => {
    if (type === "Diameter") {
      setDiameterUnit(e.label);
    }
    else if (type === "Length") {
      setLengthUnit(e.label);
    }
    else if(type === "VolumeCapacity"){
      setVolumeUnit(e.label)
    }

    if (e.id == "MeasurementUnitId") {
     
      let measurements = equipmentMeasurements;
      let typeId = null;
      let value = 0;
      const measurementType = measurementTypes.find(
        (a) => a.MeasurementType == type
      );
      if (measurementType) {
        typeId = measurementType.MeasurementTypeId;
        value = newEquipmentData[type];
        measurements = measurements.filter(
          (m) => m.MeasurementTypeId != typeId
        );
      } else return;
      measurements.push({
        [e.id]: e.value,
        EquipmentMeasurementId: "00000000-0000-0000-0000-000000000000",
        MeasurementTypeId: typeId,
        MeasurementValue: value,
        label: type
      });
      setEquipmentMeasurements(measurements);
      return;
    }
    setNewEquipmentData({
      ...newEquipmentData,
      [e.id]: e.value,
    });
    if (e.id == "CargoCarryingEquipmentTypeId") HideThreeDivs(e.value);
  };
  const effectInput = (id, value, type = null) => {
    if (type != null) {
      let measurements = equipmentMeasurements;
      let typeId = null;
      const measurementType = measurementTypes.find(
        (a) => a.MeasurementType == type
      );
      if (measurementType) {
        typeId = measurementType.MeasurementTypeId;
        measurements = measurements.filter(
          (m) => m.MeasurementTypeId != typeId
        );
      } else {
        toastr("warning", `Missing equipment type ${measurementType}`)
        return;
      }
      measurements.concat({
        MeasurementValue: value,
      });
      setEquipmentMeasurements(measurements);
    }
    setNewEquipmentData({
      ...newEquipmentData,
      [id]: value,
    });
  };

  const HideThreeDivs = (id) => {
    if (id == "") return;
    const ChosenEquip = equipmentTypes.find(
      (a) => a.EquipmentTypeId == id
    ).EquipmentType;
    document.getElementById("EntireForm").style.display = "block";

    if (ChosenEquip == "Offshore Container") {
      document.getElementById("Tog1").style.display = "block";
      document.getElementById("Tog2").style.display = "block";
      document.getElementById("Tog3").style.display = "block";
      document.getElementById("Tog5").style.display = "block";
      document.getElementById("Tog5a").style.display = "block";
      document.getElementById("Tog6").style.display = "block";
      document.getElementById("Tog6a").style.display = "block";
      document.getElementById("Tog7").style.display = "block";
      document.getElementById("Tog7a").style.display = "block";
      document.getElementById("Tog8").style.display = "block";
      document.getElementById("Tog8a").style.display = "block";
      document.getElementById("Tog9").style.display = "none";
      document.getElementById("Tog9a").style.display = "none";
      document.getElementById("Tog11").style.display = "none";
      document.getElementById("Tog11a").style.display = "none";
      document.getElementById("Tog12").style.display = "none";
      document.getElementById("Tog12a").style.display = "none";
      document.getElementById("Tog13").style.display = "none";
      document.getElementById("Tog13a").style.display = "none";
      document
        .getElementById("Tog14")
        .style.setProperty("display", "none", "important");
      document
        .getElementById("Tog15")
        .style.setProperty("display", "none", "important");
      document.getElementById("DivOwner").style.display = "block";
      document
        .getElementById("DivAddOwner")
        .style.setProperty("display", "block", "important");
      document.getElementById("DivToHide1").style.display = "none";
      document.getElementById("DivToHide1a").style.display = "none";
      document.getElementById("DivToHide2").style.display = "none";
      document.getElementById("DivToHide2a").style.display = "none";
      document.getElementById("DivToHide3").style.display = "none";
      document.getElementById("DivToHide3a").style.display = "none";
      document.getElementById("DivToHide4").style.display = "block";
    } else if (ChosenEquip == "BigBag") {
      document.getElementById("DivOwner").style.display = "none";
      document
        .getElementById("DivAddOwner")
        .style.setProperty("display", "none", "important");
      document
        .getElementById("DivToHide2")
        .style.setProperty("display", "none", "important");
      document
        .getElementById("DivToHide2a")
        .style.setProperty("display", "none", "important");
      document.getElementById("DivToHide3").style.display = "none";
      document.getElementById("DivToHide3a").style.display = "none";
      document
        .getElementById("DivToHide4")
        .style.setProperty("display", "none", "important");
      document.getElementById("Tog1").style.display = "none";
      document.getElementById("Tog2").style.display = "none";
      document.getElementById("Tog3").style.display = "none";
      document.getElementById("Tog5").style.display = "none";
      document.getElementById("Tog5a").style.display = "none";
      document.getElementById("Tog6").style.display = "none";
      document.getElementById("Tog6a").style.display = "none";
      document.getElementById("Tog7").style.display = "none";
      document.getElementById("Tog7a").style.display = "none";
      document.getElementById("Tog8").style.display = "none";
      document.getElementById("Tog8a").style.display = "none";
      document
        .getElementById("Tog9")
        .style.setProperty("display", "none", "important");
      document
        .getElementById("Tog9a")
        .style.setProperty("display", "none", "important");
      document
        .getElementById("Tog11")
        .style.setProperty("display", "none", "important");
      document
        .getElementById("Tog11a")
        .style.setProperty("display", "none", "important");
      document.getElementById("Tog12").style.display = "block";
      document.getElementById("Tog12a").style.display = "block";
      document.getElementById("Tog13").style.display = "block";
      document.getElementById("Tog13a").style.display = "block";
      document.getElementById("Tog14").style.display = "block";
      document
        .getElementById("Tog15")
        .style.setProperty("display", "none", "important");
      document.getElementById("DivToHide1").style.display = "block";
      document.getElementById("DivToHide1a").style.display = "block";
    } else if (ChosenEquip == "Tubular") {
      document.getElementById("DivOwner").style.display = "none";
      document
        .getElementById("DivAddOwner")
        .style.setProperty("display", "none", "important");
      document.getElementById("DivToHide1").style.display = "none";
      document.getElementById("DivToHide1a").style.display = "none";
      document.getElementById("DivToHide2").style.display = "none";
      document.getElementById("DivToHide2a").style.display = "none";
      document.getElementById("DivToHide3").style.display = "none";
      document.getElementById("DivToHide3a").style.display = "none";
      document.getElementById("DivToHide4").style.display = "none";
      document.getElementById("Tog1").style.display = "none";
      document.getElementById("Tog2").style.display = "none";
      document.getElementById("Tog3").style.display = "none";
      document.getElementById("Tog5").style.display = "none";
      document.getElementById("Tog5a").style.display = "none";
      document.getElementById("Tog6").style.display = "none";
      document.getElementById("Tog6a").style.display = "none";
      document.getElementById("Tog7").style.display = "none";
      document.getElementById("Tog7a").style.display = "none";
      document.getElementById("Tog8").style.display = "none";
      document.getElementById("Tog8a").style.display = "none";
      document.getElementById("Tog9").style.display = "block";
      document.getElementById("Tog9a").style.display = "block";
      document.getElementById("Tog11").style.display = "block";
      document.getElementById("Tog11a").style.display = "block";
      document.getElementById("Tog12").style.display = "block";
      document.getElementById("Tog12a").style.display = "block";
      document.getElementById("Tog13").style.display = "none";
      document.getElementById("Tog13a").style.display = "none";
      document
        .getElementById("Tog14")
        .style.setProperty("display", "none", "important");
      document.getElementById("Tog15").style.display = "block";
    } else {
      document.getElementById("DivOwner").style.display = "none";
      document
        .getElementById("DivAddOwner")
        .style.setProperty("display", "none", "important");
      document.getElementById("DivToHide1").style.display = "none";
      document.getElementById("DivToHide1a").style.display = "none";
      document
        .getElementById("DivToHide2")
        .style.setProperty("display", "none", "important");
      document
        .getElementById("DivToHide2a")
        .style.setProperty("display", "none", "important");
      document.getElementById("DivToHide3").style.display = "none";
      document.getElementById("DivToHide3a").style.display = "none";
      document
        .getElementById("DivToHide4")
        .style.setProperty("display", "none", "important");
      document.getElementById("Tog1").style.display = "none";
      document.getElementById("Tog2").style.display = "none";
      document.getElementById("Tog3").style.display = "none";
      document.getElementById("Tog5").style.display = "none";
      document.getElementById("Tog5a").style.display = "none";
      document.getElementById("Tog6").style.display = "none";
      document.getElementById("Tog6a").style.display = "none";
      document.getElementById("Tog7").style.display = "none";
      document.getElementById("Tog7a").style.display = "none";
      document.getElementById("Tog8").style.display = "none";
      document.getElementById("Tog8a").style.display = "none";
      document
        .getElementById("Tog9")
        .style.setProperty("display", "none", "important");
      document
        .getElementById("Tog9a")
        .style.setProperty("display", "none", "important");
      document
        .getElementById("Tog11")
        .style.setProperty("display", "none", "important");
      document
        .getElementById("Tog11a")
        .style.setProperty("display", "none", "important");
      document.getElementById("Tog13").style.display = "none";
      document.getElementById("Tog13a").style.display = "none";
      document.getElementById("Tog14").style.display = "none";
      document.getElementById("Tog15").style.display = "none";
    }
  };


  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout
      title="Add Equipment"
      loading={initializing}
      showFullLoader={initializing}
    >
      <div className="card-inner p-5">
        <form>
          <div className="row align-items-center">
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Equipment Type</label>
                <div className="form-control-wrap">
                  <Select
                    onChange={(item) => {
                      handleChange(item);
                    }}
                    value={equipmentTypes?.filter(function (option) {
                      return (
                        option.value ===
                        newEquipmentData?.CargoCarryingEquipmentTypeId
                      );
                    })}
                    id="CargoCarryingEquipmentTypeId"
                    name="CargoCarryingEquipmentTypeId"
                    options={equipmentTypes}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6" id="DivToHide4">
              <div className="form-group">
                <label className="form-label" htmlFor="fw-last-name">
                  CCU Number
                </label>
                <div className="form-control-wrap">
                  <input
                    onChange={(e) => {
                      effectInput("Ccunumber", e.target.value);
                    }}
                    type="text"
                    data-msg="Required"
                    className="form-control required"
                    id="Ccunumber"
                    name="Ccunumber"
                  />
                </div>
              </div>
            </div>
            <div className="gap" />
          </div>
          <div
            id="EntireForm"
            style={{ display: "none" }}
            className="nk-wizard-content"
          >
            <div className="row"></div>
            <div className="row gy-3">
              <div className="col-sm-4" id="DivToHide1">
                <div className="form-group">
                  <label className="form-label" htmlFor="fw-first-name">
                    Volume capacity
                  </label>
                  <div className="form-control-wrap">
                    <input
                      onChange={(e) => {
                        effectInput(
                          "VolumeCapacity",
                          e.target.value,
                          "VolumeCapacity"
                        );
                      }}
                      type="text"
                      data-msg="Required"
                      className="form-control required"
                      id="VolumeCapacity"
                      name="VolumeCapacity"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-2" id="DivToHide1a">
                <div className="form-group">
                  <label className="form-label" htmlFor="fw-first-name">
                    Unit
                  </label>
                  <div className="form-control-wrap">
                    <Select
                      id="VolumeCapacityMeasurementUnit"
                      name="VolumeCapacityMeasurementUnit"
                      onChange={(item) => {
                        handleChange(item, "VolumeCapacity");
                      }}
                      options={volumeMeasurementUnits}
                      ref={volumeCapacityRef}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-4" id="DivToHide2">
                <div className="form-group">
                  <label className="form-label" htmlFor="fw-last-name">
                    Load-carrying capacity
                  </label>
                  <div className="form-control-wrap">
                    <input
                      onChange={(e) => {
                        effectInput(
                          "LoadCarryingCapacity",
                          e.target.value,
                          "LoadCarryingCapacity"
                        );
                      }}
                      type="text"
                      data-msg="Required"
                      className="form-control required"
                      id="LoadCarryingCapacity"
                      name="LoadCarryingCapacity"
                      required
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-2" id="DivToHide2a">
                <div className="form-group">
                  <label className="form-label" htmlFor="fw-first-name">
                    Unit
                  </label>
                  <div className="form-control-wrap">
                    <Select
                      id="LoadCarryingCapacityMeasurementUnit"
                      name="LoadCarryingCapacityMeasurementUnit"
                      onChange={(item) => {
                        handleChange(item, "LoadCarryingCapacity");
                      }}
                      options={massMeasurementUnits}
                      isDisabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-4" id="DivToHide3">
                <div className="form-group ">
                  <label className="form-label" htmlFor="fw-last-name">
                    Space-Area Capacity
                  </label>
                  <div className="form-control-wrap">
                    <input
                      onChange={(e) => {
                        effectInput(
                          "SpaceAreaCapacity",
                          e.target.value,
                          "SpaceAreaCapacity"
                        );
                      }}
                      type="text"
                      className="form-control "
                      id="SpaceAreaCapacity"
                      name="SpaceAreaCapacity"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-2" id="DivToHide3a">
                <div className="form-group">
                  <label className="form-label" htmlFor="fw-first-name">
                    Unit
                  </label>
                  <div className="form-control-wrap">
                    <Select
                      id="SpaceAreaCapacityMeasurementUnit"
                      name="SpaceAreaCapacityMeasurementUnit"
                      onChange={(item) => {
                        handleChange(item, "SpaceAreaCapacity");
                      }}
                      options={areaMeasurementUnits}
                      isDisabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-4" id="DivOwner">
                <div className="form-group">
                  <label className="form-label" htmlFor="fw-first-name">
                    Owner
                  </label>
                  <div className="form-control-wrap">
                    <Select
                      id="PartyId"
                      name="PartyId"
                      onChange={(item) => {
                        handleChange(item);
                      }}
                      options={partyList}
                      value={partyList?.filter(function (option) {
                        return option.value === newEquipmentData.PartyId;
                      })}
                    />
                  </div>
                </div>
              </div>

              <div className="col-sm-2 mt-1 align-center" id="DivAddOwner">
                <div className="form-control-wrap">
                  <PartyModal
                    addNewLabel="+ Add Owner"
                    callback={async (_party_contact) =>
                      await addPartyModalCallback(_party_contact)
                    }
                  />
                </div>
              </div>
              <div className="col-sm-6" id="Tog1">
                <div className="form-group">
                  <label className="form-label" htmlFor="fw-last-name">
                    Certification
                  </label>
                  <div className="form-control-wrap">
                    <Select
                      id="CertificateId"
                      name="CertificateId"
                      onChange={(item) => {
                        handleChange(item);
                      }}
                      options={certificates}
                      value={certificates.find(
                        (certificate) =>
                          certificate.CertificateId ==
                          newEquipmentData.CertificateId
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6" id="Tog2">
                <div className="form-group">
                  <label className="form-label" htmlFor="fw-last-name">
                    Type
                  </label>
                  <div className="form-control-wrap">
                    <Select
                      id="OffshoreContainerEquipmentTypeId"
                      name="OffshoreContainerEquipmentTypeId"
                      onChange={(item) => {
                        handleChange(item);
                      }}
                      options={containerTypes}
                      value={containerTypes.find(
                        (equipment) =>
                          equipment.EquipmentTypeId ==
                          newEquipmentData.OffshoreContainerEquipmentTypeId
                      )}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="fw-last-name">
                    Size
                  </label>
                  <div className="form-control-wrap">
                    <Select
                      id="ContainerSizeId"
                      name="ContainerSizeId"
                      onChange={(item) => {
                        handleChange(item);
                      }}
                      options={containerSizes}
                      value={containerSizes.find(
                        (equipment) =>
                          equipment.ContainerSizeId ==
                          newEquipmentData.ContainerSizeId
                      )}
                    />
                    {/* <input
                      type="text"
                      data-msg="Required"
                      className="form-control required"
                      id="fw-last-name"
                      name="fw-last-name"
                      disabled
                      value={
                        containerSizes?.find(
                          (size) =>
                            size.ContainerSizeId ==
                            newEquipmentData.ContainerSizeId
                        )?.label == undefined
                          ? null
                          : `(${(
                            containerSizes?.find(
                              (size) =>
                                size.ContainerSizeId ==
                                newEquipmentData.ContainerSizeId
                            )?.LengthInmm / 1000
                          ).toFixed(2)}m by ${(
                            containerSizes?.find(
                              (size) =>
                                size.ContainerSizeId ==
                                newEquipmentData.ContainerSizeId
                            )?.WidthInmm / 1000
                          ).toFixed(2)}m)`
                      }
                    /> */}
                  </div>
                </div>
              </div>
              <div className="col-sm-6" id="Tog3">
                <div className="form-group">
                  <label className="form-label" htmlFor="fw-last-name">
                    Container Description
                  </label>
                  <div className="form-control-wrap">
                    <textarea
                      placeholder="Fill in description"
                      className="form-control no-resize"
                      id="EquipmentDescription"
                      name="EquipmentDescription"
                      disabled
                      value={
                        containerSizes?.find(
                          (size) =>
                            size.ContainerSizeId ==
                            newEquipmentData.ContainerSizeId
                        )?.label == undefined
                          ? null
                          : `${containerTypes?.find(
                            (type) =>
                              type.EquipmentTypeId ==
                              newEquipmentData.OffshoreContainerEquipmentTypeId
                          )?.label
                          } - ${containerSizes?.find(
                            (size) =>
                              size.ContainerSizeId ==
                              newEquipmentData.ContainerSizeId
                          )?.label
                          }`
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-4" id="Tog5">
                <div className="form-group">
                  <label className="form-label" htmlFor="fw-last-name">
                    Max. Gross Mass
                  </label>
                  <div className="form-control-wrap">
                    <input
                      onChange={(e) => {
                        effectInput(
                          "MaxGrossMass",
                          e.target.value,
                          "MaxGrossMass"
                        );
                      }}
                      type="number"
                      data-msg="Required"
                      className="form-control required"
                      id="MaxGrossMass"
                      name="MaxGrossMass"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-2" id="Tog5a">
                <div className="form-group">
                  <label className="form-label" htmlFor="fw-first-name">
                    Unit
                  </label>
                  <div className="form-control-wrap">
                    <Select
                      id="MaxGrossMaxMeasurementUnit"
                      name="MaxGrossMaxMeasurementUnit"
                      onChange={(item) => {
                        handleChange(item, "MaxGrossMass");
                      }}
                      options={massMeasurementUnits}
                      ref={maxGrossMaxRef}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-4" id="Tog6">
                <div className="form-group">
                  <label className="form-label" htmlFor="fw-last-name">
                    TareMass
                  </label>
                  <div className="form-control-wrap">
                    <input
                      onChange={(e) => {
                        effectInput("TareMass", e.target.value, "TareMass");
                      }}
                      type="number"
                      data-msg="Required"
                      className="form-control required"
                      id="TareMass"
                      name="TareMass"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-2" id="Tog6a">
                <div className="form-group">
                  <label className="form-label" htmlFor="fw-first-name">
                    Unit
                  </label>
                  <div className="form-control-wrap">
                    <Select
                      id="TareMassMeasurementUnit"
                      name="TareMassMeasurementUnit"
                      onChange={(item) => {
                        handleChange(item, "TareMass");
                      }}
                      options={massMeasurementUnits}
                      ref={tareMassRef}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-4" id="Tog7">
                <div className="form-group">
                  <label className="form-label" htmlFor="fw-last-name">
                    Payload
                  </label>
                  <div className="form-control-wrap">
                    <input
                      onChange={(e) => {
                        effectInput("Payload", e.target.value, "Payload");
                      }}
                      type="number"
                      data-msg="Required"
                      className="form-control required"
                      id="Payload"
                      name="Payload"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-2" id="Tog7a">
                <div className="form-group">
                  <label className="form-label" htmlFor="fw-first-name">
                    Unit
                  </label>
                  <div className="form-control-wrap">
                    <Select
                      id="PayloadMeasurementUnit"
                      name="PayloadMeasurementUnit"
                      onChange={(item) => {
                        handleChange(item, "Payload");
                      }}
                      options={massMeasurementUnits}
                      ref={payloadRef}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-4" id="Tog8">
                <div className="form-group">
                  <label className="form-label" htmlFor="fw-last-name">
                    Height
                  </label>
                  <div className="form-control-wrap">
                    <input
                      onChange={(e) => {
                        effectInput("Height", e.target.value, "Height");
                      }}
                      type="number"
                      data-msg="Required"
                      className="form-control required"
                      id="Height"
                      name="Height"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-2" id="Tog8a">
                <div className="form-group">
                  <label className="form-label" htmlFor="fw-first-name">
                    Unit
                  </label>
                  <div className="form-control-wrap">
                    <Select
                      id="HeightMeasurementUnit"
                      name="HeightMeasurementUnit"
                      onChange={(item) => {
                        handleChange(item, "Height");
                      }}
                      options={lengthMeasurementUnits}
                      ref={heightRef}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6" id="Tog9">
                <div className="form-group">
                  <label className="form-label" htmlFor="fw-last-name">
                    Category
                  </label>
                  <div className="form-control-wrap">
                    <Select
                      id="TubularCategoryId"
                      name="TubularCategoryId"
                      onChange={(item) => {
                        setSelectedCategory(item);
                        handleChange(item, "TubularCategoryId");
                      }}
                      options={parentCategories}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6" id="Tog9a">
                <div className="form-group">
                  <label className="form-label" htmlFor="fw-last-name">
                    Subcategory
                  </label>
                  <div className="form-control-wrap">
                    <Select
                      id="TubularSubCategoryId"
                      name="TubularSubCategoryId"
                      onChange={(item) => {
                        setSelectedSubCategory(item);
                        handleChange(item, "TubularSubCategoryId");
                      }}
                      options={subCategoryOptions}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-4" id="Tog11">
                <div className="form-group">
                  <label className="form-label" htmlFor="fw-last-name">
                    Diameter
                  </label>
                  <div className="form-control-wrap">
                    <input
                      onChange={(e) => {
                        effectInput("Diameter", e.target.value, "Diameter");
                      }}
                      type="number"
                      data-msg="Required"
                      className="form-control required"
                      id="Diameter"
                      name="Diameter"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-2" id="Tog11a">
                <div className="form-group">
                  <label className="form-label" htmlFor="fw-first-name">
                    Unit
                  </label>
                  <div className="form-control-wrap">
                    <Select
                      id="DiameterMeasurementUnit"
                      name="DiameterMeasurementUnit"
                      onChange={(item) => {
                        handleChange(item, "Diameter");
                      }}
                      options={lengthMeasurementUnits}
                      ref={diameterRef}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-4" id="Tog12">
                <div className="form-group">
                  <label className="form-label" htmlFor="fw-last-name">
                    Length
                  </label>
                  <div className="form-control-wrap">
                    <input
                      onChange={(e) => {
                        effectInput("Length", e.target.value, "Length");
                      }}
                      type="number"
                      data-msg="Required"
                      className="form-control required"
                      id="Length"
                      name="Length"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-2" id="Tog12a">
                <div className="form-group">
                  <label className="form-label" htmlFor="fw-first-name">
                    Unit
                  </label>
                  <div className="form-control-wrap">
                    <Select
                      id="LengthUnits"
                      name="LengthUnits"
                      onChange={(item) => {
                        handleChange(item, "Length");
                      }}
                      options={lengthMeasurementUnits}
                      ref={lengthRef}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-4" id="Tog13">
                <div className="form-group">
                  <label className="form-label" htmlFor="fw-last-name">
                    Width
                  </label>
                  <div className="form-control-wrap">
                    <input
                      onChange={(e) => {
                        effectInput("Width", e.target.value, "Width");
                      }}
                      type="number"
                      data-msg="Required"
                      className="form-control required"
                      id="Width"
                      name="Width"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-2" id="Tog13a">
                <div className="form-group">
                  <label className="form-label" htmlFor="fw-first-name">
                    Unit
                  </label>
                  <div className="form-control-wrap">
                    <Select
                      id="WidthUnits"
                      name="WidthUnits"
                      onChange={(item) => {
                        handleChange(item, "Width");
                      }}
                      options={lengthMeasurementUnits}
                      ref={widthRef}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6" id="Tog14">
                <div className="form-group">
                  <label className="form-label" htmlFor="fw-last-name">
                    Bigbag Description
                  </label>
                  <div className="form-control-wrap">
                    <input
                      placeholder="Fill in description"
                      className="form-control no-resize"
                      id="EquipmentDescription"
                      disabled
                      // value={() =>
                      //   describeEquipment(
                      //     newEquipmentData.CargoCarryingEquipmentTypeId
                      //   )
                      // }
                      value={
                        newEquipmentData.VolumeCapacity == undefined
                          ? null
                          : `BigBag - ${newEquipmentData.VolumeCapacity}${volumeUnit ||
                          " "
                          }`
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6" id="Tog15">
                <div className="form-group">
                  <label className="form-label" htmlFor="fw-last-name">
                    Tubular Description
                  </label>
                  <div className="form-control-wrap">
                    <input
                      placeholder="Fill in description"
                      className="form-control no-resize"
                      id="EquipmentDescription"
                      disabled
                      // value={() =>
                      //   describeEquipment(
                      //     newEquipmentData.CargoCarryingEquipmentTypeId
                      //   )
                      // }
                      value={
                        categories?.find(
                          (category) =>
                            category.TubularCategoryId ==
                            newEquipmentData.TubularCategoryId
                        )?.label == undefined ||
                          subCategories?.find(
                            (category) =>
                              category.TubularCategoryId ==
                              newEquipmentData.TubularSubCategoryId
                          )?.label == undefined ||
                          newEquipmentData.Diameter == undefined ||
                          newEquipmentData.Length == undefined
                          ? null
                          : `${categories?.find(
                            (category) =>
                              category.TubularCategoryId ==
                              newEquipmentData.TubularCategoryId
                          )?.label
                          } - ${subCategories?.find(
                            (category) =>
                              category.TubularCategoryId ==
                              newEquipmentData.TubularSubCategoryId
                          )?.TubularCategory
                          } - ${newEquipmentData.Diameter}${diameterUnit || " "
                          // } - ${newEquipmentData.Diameter}${diameterRef.current?.state?.value?.label || " "
                          } - ${newEquipmentData.Length}${lengthUnit || " "
                          }`
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="nk-tb-col">
            <span>&nbsp;</span>
          </div>
          <div className="form-group">
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={() => postNewEquipmentData()}
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-sm btn-danger m-1"
              onClick={() => window.history.back()}
            >
              Cancel
            </button>
          </div>
        </form>
        <hr className="preview-hr" />
      </div>
    </MainLayout>
  );
}
