import apiCall from "../utils/apiCall";

export default class ZoneService {
  static fetchZones = async () => {
    let response =  await apiCall(`/api/Location/Get/v2?page=1&pageSize=50&locationType=Zone`, "GET", null, null, null, true);
    if (response?.Data?.Data){
      response = response?.Data?.Data
    }
    return response;
  };
}
