import React, { useEffect, useState } from "react";
import Spinner from "../../utils/spinner";
import SailingVisitsListComponent from "../sailingSchedule/sailingVisitsList";
import { toReadableDate } from "../../utils/utilityFunctions";

export default function VoyageSailingVisitsComponent({
  voyage,
  visits,
  loading,
}) {
  useEffect(async () => {}, []);

  const dataItem = (label, value) => {
    if (value == "null") value = "";
    return (
      <li className="col-6 col-lg-3">
        <p>
          <span className="text-soft small">{label}</span> {value || "-"}
        </p>
      </li>
    );
  };

  return (
    <>
      {loading && (
        <div className="row my-2">
          {" "}
          <div className="col-12 text-center">
            <Spinner />
          </div>{" "}
        </div>
      )}
      {!loading && (
        <div className="px-4">
          <div className="row mt-4">
            <div className="col-12">
              <p>
                <span className="preview-title-lg overline-title">
                  Sailing Visits
                </span>{" "}
              </p>
            </div>
            {voyage && (
              <div className="col-12">
                <div className="card sp-plan">
                  <div className="row no-gutters">
                    <div className="col-md-8">
                      <div className="sp-plan-desc card-inner">
                        <ul className="row gx-1">
                          {dataItem(
                            "Sailing Number",
                            voyage?.SailingDetails?.SailingNo
                          )}
                          {dataItem(
                            "Submission Deadline",
                            toReadableDate(
                              voyage?.SailingDetails?.SubmissionDeadline
                            ) || "-"
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <SailingVisitsListComponent dataList={visits} />
        </div>
      )}
    </>
  );
}
