import apiCall from "../utils/apiCall";
import { clearAPICache, dynamicSort } from "../utils/utilityFunctions";

export default class VesselService {
  static searchByImoOrMmsi = async (imo_mmsi) => {
    return await apiCall(
      `/api/allvesseldetails?mmsi=${imo_mmsi}&type=mmssiorimo`, "GET", null, null, null, true
    );
  };
  static singleVesselPosition = async (vesselSpecificationId) => {
    let response = await apiCall(
      `/api/VesselPosition/GetSingleVesselPositionsByVesselSpecificationId/${vesselSpecificationId}`, "GET", null, null, null, true
    );
    return response;
  };
  static searchByName = async (imo_mmsi) => {
    return await apiCall(`/api/allvesseldetails?mmsi=${imo_mmsi}`, "GET", null, null, null, true);
  };

  static postVessel = async (payload) => {
    clearAPICache("vessel");
    return await apiCall(
      `/api/VesselSpecifications/PostVesselWithDocs`,
      "POST",
      payload
    );
  };

  static fetchVessel = async (vesselId) => {
    return await apiCall(`/api/VesselSpecifications/Get/${vesselId}`, "GET", null, null, null, true);
  };
  static fetchVesselExtended = async (vesselId) => {
    let response = await apiCall(`/api/Vessel/ExtendedGet/${vesselId}`, "GET", null, null, null, true);
    if (response) {
      response = { ...response.specification, ...response };
    }
    return response;
  };

  static fetchVesselTypes = async () => {
    return await apiCall(`/api/VesselType/Get`, "GET", null, null, null, true);
  };
  static fetchVesselList = async () => {
    return await apiCall(`/api/Vessel/ExtendedList`, "GET", null, null, null, true);
  };

  static postVesselType = async (vesselType) => {
    clearAPICache("vessel");
    return await apiCall(`/api/VesselType/Post`, "POST", { vesselType });
  };

  static updateVesselImage = async (vesselId, base64) => {
    const payload = {
      VesselSpecificationId: vesselId,
      Base64Image: base64,
    };
    clearAPICache("vessel");
    return await apiCall(
      `/api/VesselSpecifications/ChangeVesselImage`,
      "POST",
      payload
    );
  };

  static fetchVoyageHistory = async (
    vesselId,
    currentPage = 1,
    PageSize = 8
  ) => {
    return await apiCall(
      `/api/VoyageHistory/GetVoyageHistoryByVesselId?VesselId=${vesselId}&pageCount=${currentPage}&noPerPage=${PageSize}`
    );
  };
  static listAllVessels = async () => {
    let response = await apiCall(`/api/VesselSpecifications/Get`, "GET", null, null, null, true);
    if (response?.Data) {
      response = response?.Data.sort(dynamicSort("VesselName"));
    }
    return response;
  };

  static extendedListing = async (
    page = 1,
    pageSize = 10,
    search,
    imo_or_mmsi
  ) => {
    return await apiCall(
      `/api/Vessel/ExtendedList?imoOrMmsi=${imo_or_mmsi || 0
      }&searchParam=${search}&pageCount=${page}&noPerPage=${pageSize}`, "GET", null, null, null, true
    );
  };

  static fetchVoyageHistory2 = async (vesselId) => {
    return await apiCall(
      `/api/VoyageHistory/GetVoyageHistoryByVesselId?VesselId=${vesselId}`
    );
  };
  static fetchVoyageHistory = async (vesselId) => {
    return await apiCall(
      `/api/VoyageHistory/GetVoyageHistoryByVesselId?VesselId=${vesselId}`
    );
  };
  static countVoyageHistory = async (vesselId) => {
    return await apiCall(
      `/api/VoyageHistory/CountVoyageHistory?VesselId=${vesselId}`
    );
  };
  static updateVoyageHistory = async (vesselId) => {
    return await apiCall(
      `/api/VoyageHistory/UpdateVoyageHistory?vesselId=` + vesselId
    );
  };
  static searchVessels = async (pageCount, pageSize, searchParam, imo) => {
    pageCount = pageCount || 1;
    pageSize = pageSize || 20;
    searchParam = searchParam || "";
    return await apiCall(
      `/api/VesselSpecifications/SearchVessels?imo=${imo}&searchParam=${searchParam}&pageCount=${pageCount}&noPerPage=${pageSize}`, "GET", null, null, null, true
    );
  };

  static listOfVesselTypes = async () => {
    return await apiCall(`/api/VesselType/Get`, "GET", null, null, null, true);
  };
  static postEditVessel = async (id, payload) => {
    clearAPICache("vessel");
    return await apiCall(`/api/VesselSpecifications/Put/${id}`, "PUT", payload);
  };

  static listByLocation = async (locationId) => {
    return await apiCall(`/api/Vessel/ByLocation?locationId=${locationId}`);
  };

  static validateVessel = async (body) => {
    return await apiCall(`/api/Vessel/ValidateCapabilities`, "POST", body);
  };

  static validateCargoHoldingCapabilities = async (body) => {
    return await apiCall(
      `/api/Vessel/ValidateCapabilitiesByCargos`,
      "POST",
      body
    );
  };

  static listTanks = async (vesselId) => {
    return await apiCall(`/api/Vessel/ListTanks?vesselId=${vesselId}`);
  };

  // hire status
  static setVesselOnHire = async (payload) => {
    return await apiCall(`/api/OffHireCommercial/GoOnHire`, "POST", payload);
  };

  static setVesselOffHire = async (payload) => {
    return await apiCall(`/api/OffHireCommercial/GoOffHire`, "POST", payload);
  };

  static getActiveVesselContract = async (vesselId) => {
    return await apiCall(
      `/api/Commercials/getactive?vesselSpecificationId=${vesselId}`
    );
  };

  static getVesselUtilizationData = async (query) => {
    return await apiCall(
      `/api/VesselReport/GetVesselUtilizationData?${query}`,
      "GET",
      null,
      null,
      true
    );
  };

  static getSailingHistoryData = async (query) => {
    const res = await apiCall(
      `/api/VesselReport/GetSailingRoutesFromHistory?${query}`,
      "GET",
      null,
      null,
      true
    );

    return res?.Data ? res.Data : null;
  };
  static getHireHistoryData = async (query) => {
    const res = await apiCall(
      `/api/VesselReport/GetVesselHistoricalHireCost?${query}`,
      "GET",
      null,
      null,
      true
    );

    return res?.Data ? res.Data : null;
  };

  static updateVesselMasterDetails = async (id, payload) => {
    clearAPICache("vessel");
    return await apiCall(
      `/api/VesselSpecifications/UpdateVesselMasterDetails/${id}`,
      "PUT",
      payload
    );
  };

  static getLatestVoyageForVessel = async (vesselSpecificationId) => {
    return await apiCall(
      `/api/vessel/GetCurrentOrLastVoyage?vesselSpecId=${vesselSpecificationId}`
    );
  };

  static getVesselByVesselMaster = async (vesselMasterUserId) => {
    return await apiCall(
      `/api/VesselSpecifications/GetVesselByMaster/get-vessel-by-master?vesselMasterUserId=${vesselMasterUserId}`
    );
  };

  static utilizationDashboard = async (page, pageSize, query = "") => {
    page = page || 1;
    pageSize = pageSize || 10;
    return await apiCall(
      `/api/Vessel/Get/vessel-utilization-dashboard?pageCount=${page}&noPerPage=${pageSize}&${query}`
    );
  };
  static utilizationReport = async (query = "") => {
    return await apiCall(
      `/api/vesselreport/utilization_report?${query}`
    );
  };

  static getActiveVessels = async (page, pageSize, commercialStatus = "") => {
    page = page || 1;
    pageSize = pageSize || 1000;
    commercialStatus = commercialStatus || "Active";
    let response = await apiCall(
      `/api/Vessel/ListByCommercialStatus?pageCount=${page}&noPerPage=${pageSize}&commercialStatus=${commercialStatus}`
    );
    if (response?.Data) {
      response = response?.Data.sort(dynamicSort("VesselName"));
    }
    return response;
  };
}
