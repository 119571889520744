import React, { useEffect, useState } from 'react';
import "../styles/linearActivity.scss";

export default function LinearActivity() {

    useEffect(() => {

    }, []);



    return (
        null && <div className="linear-activity">
            <div className="indeterminate"></div>
        </div>
    );
}


