import React, { useEffect, useState, useRef } from "react";
import { Navigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import Spinner from "../../utils/spinner";
import toastr from "../../utils/toastr";
import TankListComponent from "../../components/voyage/tankList";
import VesselService from "../../services/vesselService";
import VoyageService from "../../services/voyageService";
import AssignTanksComponent from "../../components/voyage/assignTanksList";
import ReactTooltip from "react-tooltip";


export default function AssignCargoTanks() {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [voyageInformation, setVoyageInformation] = useState({});
  const [availableTanks, setAvailableTanks] = useState([]);
  const [voyageCargo, setVoyageCargo] = useState([]);
  const [tankProductId, setTankProductId] = useState();
  const [checked, setChecked] = useState(false);
  const [userSelectedProductId, setUserSelectedProductId] = useState();
  const [availableTankMatch, setAvailableTankMatch] = useState();
  const [redirectUrl, setRedirectUrl] = useState(null);

  let vesselId;
  let tanks;
  const tanksOnVessel = useRef()

  useEffect(async () => {
    await getVoyageEntireDetails();

    if (vesselId) {
      await getVesselTanks();
    }
  }, []);

  const getVoyageEntireDetails = async () => {
    const voyageDetails = await VoyageService.get(params.voyagePlanningId);
    setVoyageInformation(voyageDetails);
    vesselId = voyageDetails?.VesselSpecificationId;
    setVoyageCargo(voyageDetails?.BulkCargos);
    if (voyageDetails?.BulkCargos) {
      let bCargo = voyageDetails.BulkCargos?.map(x => ({
        ...x,
        assignIcon: assignTanksIcon(x),
        assignedFieldObj: (x.ShipSideEquipmentId) ? overflowMenu(x) : "unassigned"
      }))
      tanksOnVessel.current = bCargo;
      setVoyageCargo(bCargo || []);
    };
    setLoading(false);

  }
  const overflowMenu = () => {
    return "tank assigned"
  }

  const getVesselTanks = async () => {
    setLoading(true);
    tanks = await VesselService.listTanks(vesselId);
    let tankList = tanks?.map((x) =>
    ({
      ...x,
      literMeasure: `${x.CapacityMeasure}${x.unit}`,
    }));
    let tankListProducts = tankList?.map((obj) => ({
      productId: obj.ProductId,
      ShipSideEquipmentId: obj.ShipSideEquipmentId,
      VesselSpecificationId: obj.VesselSpecificationId,
      tankType: obj.tankType
    }))
    setTankProductId(tankListProducts);
    setAvailableTanks(tankList || []);
    setLoading(false);
  }

  const assignBulkCargoTanks = async (e) => {
    const tankListProducts = tanks?.map((tank) => ({
      productId: tank.ProductId,
      ShipSideEquipmentId: tank.ShipSideEquipmentId,
      VesselSpecificationId: tank.VesselSpecificationId,
      tankType: tank.tankType
    }))
    const retrievedSelection = e.target.value;
    setUserSelectedProductId(retrievedSelection)

    const matchingTank = tankListProducts?.find(tank => tank?.productId === retrievedSelection)?.productId;

    const isTankAssigned = tanksOnVessel?.current?.find(product => product?.ProductId === retrievedSelection)?.ShipSideEquipmentId

    if ((e.target.checked === true) && matchingTank && !isTankAssigned) {
      toastr("info", `tank available for selected bulk cargo`);
      setAvailableTankMatch(matchingTank)
      e.target.checked = true
    }
    else if ((e.target.checked === true) && matchingTank && isTankAssigned) {
      toastr("info", `tank available for selected bulk cargo and has already been assigned, would you like to re-assign it ?`);
      setAvailableTankMatch(matchingTank)
      e.target.checked = true
    }
    else if (e.target.checked === true && !matchingTank) {
      setAvailableTankMatch("");
      toastr("info", `No available tank for selected bulk cargo`);
      e.target.checked = false;
    }
    else if (e.target.checked === false) {
      setAvailableTankMatch("");
    }
  }

  const assignTanksIcon = (selectedCargo) => {

    return (
      <>
        <ReactTooltip id="uid">Select bulk cargo</ReactTooltip>
        <a
          href="#"
          className="btn btn-sm btn-icon btn-trigger"
          data-toggle="tooltip"
          data-placement="top"
          data-original-title="Select cargo"
          data-tip
          data-for="uid"
        >
          <div>
            <input type="checkbox"
              onChange={(e) => assignBulkCargoTanks(e)}
              value={selectedCargo?.ProductId}
              style={{ boxShadow: 0 }}

            />
            <label ></label>
          </div>

        </a>
      </>
    );
  };

  const saveEntireForm = async () => {
    setLoading(true);
    let retrieveBulkCargoId = voyageCargo?.find(cargo => cargo?.ProductId === userSelectedProductId)?.BulkCargoDetailId;
    let payload = {
      bulkCargoDetailId: retrieveBulkCargoId,
      tankId: availableTankMatch?.ShipSideEquipmentId
    }
    const response = await VoyageService.allocateTank(payload);
    if (response) {
      payload = {};
      toastr("success", "Tank has been successfully assigned");
      setTimeout(() => setRedirectUrl("/voyages"), 1200);
      setLoading(false);
    } else if (!response) {
      toastr("error", "Tank could not be asigned, please review available tank(s) / selected bulk Cargo");
      setLoading(false);
      return;
    }
  }

  return (
    redirectUrl ? <Navigate to={redirectUrl} replace={true} /> :
      <MainLayout title="Assign Cargo Tank(s)">
        <div className="card-inner p-5">
          <form>
            <div className="row">
              <div className="col-12">
                <p>
                  <span className="preview-title-lg overline-title">
                    Available Tank(s)
                  </span>{" "}
                </p>
              </div>
            </div>
            {loading ? (
              <div className="mt-5 pt-5 mx-auto">
                <Spinner />
              </div>
            ) : (<TankListComponent dataList={availableTanks} />
            )}

            <br />
            <br />
            <div className="row">
              <div className="col-12">
                <p>
                  <span className="preview-title-lg overline-title">
                    Bulk Cargo Details
                  </span>{" "}
                </p>
              </div>
            </div>
            {loading ? (
              <div className="mt-5 pt-5 mx-auto">
                <Spinner />
              </div>
            ) : (
              <AssignTanksComponent dataList={voyageCargo} />
            )}
            {<div className="form-group p-2 m-3">
              <button type="button" onClick={() => saveEntireForm()} className="btn btn-md btn-dark btn-wide px-5 mr-3">Assign Tank</button>
              <button type="button" className="btn btn-sm btn-link m-1" onClick={() => window.history.back()}>Cancel</button>
            </div>}
          </form>
          <hr className="preview-hr" />

        </div>
      </MainLayout>
  )
}