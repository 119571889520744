import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../../components/fileInput";
import Spinner from "../../utils/spinner";
import {
  Validator,
  ValidateGroup,
  ClearValidator,
} from "../../components/validator";
import toastr from "../../utils/toastr";
import ReactTooltip from "react-tooltip";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import CustomModal from "../../utils/customModal";
import {
  toReadableDate,
  dateToYYYY_MM_DD,
  camelizeKeys,
} from "../../utils/utilityFunctions";
import { forEach } from "lodash";
import RoleService from "../../services/roleService";
import UserService from "../../services/userService";
import VesselService from "../../services/vesselService";
import LocationService from "../../services/locationService";

export default function AddNewUserPage() {
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({});
  const [roles, setRoles] = useState([]);
  const [locations, setLocations] = useState([]);
  const [vessels, setVessels] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [saving, setSaving] = useState(false);
  const localizer = momentLocalizer(moment);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [tab, setTab] = useState(1);
  const [reloading, setReloading] = useState(false);

  const addPayload = new PayloadUpdater({
    payload: payload,
    setPayload: setPayload,
  }).update;
  const validationGroup = "addnewuser";

  useEffect(async () => {
    setLoading(true);

    await retrieveAllRoles();
    await retrieveAllVessels();
    await retrieveAllLocations();

    setLoading(false);

    return () => {
      ClearValidator(validationGroup);
    };
  }, []);

  const retrieveAllRoles = async () => {
    let _roles = await RoleService.getAllRolesPaginated(1, 1000);
    _roles = camelizeKeys(_roles?.Data || [])?.map((role) => ({
      ...role,
      label: role.name,
      value: role.roleId,
    }));
    setRoles(_roles || []);
  };

  const retrieveAllVessels = async () => {
    let _vessels = await VesselService.listAllVessels();
    _vessels = camelizeKeys(_vessels || [])?.map((vessel) => ({
      ...vessel,
      label: vessel.vesselName,
      value: vessel.vesselSpecificationId,
    }));
    setVessels(_vessels || []);
  };

  const retrieveAllLocations = async () => {
    let _locations = await LocationService.list(1, 1000);
    _locations = camelizeKeys(_locations || [])?.map((location) => ({
      ...location,
      label: location.locationName + " - " + location.locationTypeName,
      value: location.locationId,
    }));
    setLocations(_locations || []);
  };

  const submitForm = async () => {
    const _payload = { ...payload };

    if (!_payload.firstName) {
      toastr("error", "Enter user's firstname");
      return;
    }

    if (!_payload.lastName) {
      toastr("error", "Enter user's lastname");
      return;
    }

    if (!_payload.email) {
      toastr("error", "Enter user's email address");
      return;
    }

    if (!_payload.phoneNumber) {
      toastr("error", "Enter user's phone number");
      return;
    }

    if (!_payload.password) {
      toastr("error", "Enter password");
      return;
    }

    setSaving(true);

    const response = await UserService.addNewUser(_payload);
    if (response) {
      setPayload({});
      toastr("success", "User added successfully");
      setRedirectUrl(`/edit-user-details/${response.Id}`);
    } else {
      toastr("error", "User could not be added");
    }
    setSaving(false);
  };

  const reloadPage = () => {
    setReloading(true);
    setTimeout(() => {
      setReloading(false);
    }, 200);
  };

  // const handleRoleSelect = (option) => {
  //   let _roles = option?.map(function (role) {
  //     return role.value;
  //   });
  //   setSelectedRoles(_roles || []);
  // };

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout title="Add New User" loading={loading} showFullLoader={loading}>
      <form>
        {
          <div className="card-inner">
            <div>
              <div className="row">
                {/* <div className="col-12">
                  <p>
                    <span className="preview-title-lg overline-title">
                      User Information
                    </span>{" "}
                  </p>
                </div> */}

                <div className="col-md-3">
                  <div className="form-group ">
                    <label className="form-label small">First Name</label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control required"
                        required
                        // value={payload.firstName}
                        onChange={(e) =>
                          addPayload("firstName", e.target.value)
                        }
                      />
                      <Validator
                        data={payload.firstName}
                        errorMessage="Enter first name"
                        groupName={validationGroup}
                        dataKey={"firstName"}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group ">
                    <label className="form-label small">Last Name</label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control required"
                        required
                        // value={payload.lastName}
                        onChange={(e) => addPayload("lastName", e.target.value)}
                      />
                      <Validator
                        data={payload.lastName}
                        errorMessage="Enter last name"
                        groupName={validationGroup}
                        dataKey={"lastName"}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="gap gap-12px" />

              <div className="row">
                <div className="col-md-3">
                  <div className="form-group ">
                    <label className="form-label small required">E-mail</label>
                    <div className="form-control-wrap">
                      <input
                        type="email"
                        className="form-control required"
                        required
                        // value={payload.email}
                        onChange={(e) => addPayload("email", e.target.value)}
                      />
                      <Validator
                        data={payload.email}
                        errorMessage="Enter e-mail"
                        groupName={validationGroup}
                        dataKey={"email"}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group ">
                    <label className="form-label small">Phone Number</label>
                    <div className="form-control-wrap">
                      <input
                        type="number"
                        className="form-control required"
                        required
                        // value={payload.phoneNumber}
                        onChange={(e) =>
                          addPayload("phoneNumber", e.target.value)
                        }
                      />
                      <Validator
                        data={payload.phoneNumber}
                        errorMessage="Enter phone number"
                        groupName={validationGroup}
                        dataKey={"phoneNumber"}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="gap gap-12px" />

              <div className="row">
                <div className="col-md-3">
                  <div className="form-group ">
                    <label className="form-label small">Password</label>
                    <div className="form-control-wrap">
                      <input
                        type="password"
                        className="form-control required"
                        required
                        onChange={(e) => addPayload("password", e.target.value)}
                      />
                      <Validator
                        data={payload.password}
                        errorMessage="Enter password"
                        groupName={validationGroup}
                        dataKey={"password"}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group ">
                    <label className="form-label small">Upload Image</label>
                    <div className="form-control-wrap">
                      <FileInput
                        accept=".png, .jpg, .jpeg"
                        onChange={(file) => {
                          addPayload("profileImageBase64String", file.base64);
                        }}
                        fileValue={{
                          fileContent: payload.profileImageBase64String,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="gap gap-12px" /> */}

              {/* <div className="row">
                <div className="col-md-3">
                  <div className="form-group ">
                    <label className="form-label small required">Vessel</label>
                    <div className="form-control-wrap">
                      <Select
                        onChange={(item) =>
                          addPayload(["vesselId"], [item.value])
                        }
                        value={vessels.find(
                          (a) => a.value == payload?.vesselId
                        )}
                        options={vessels}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group ">
                    <label className="form-label small">Location</label>
                    <div className="form-control-wrap">
                      <Select
                        onChange={(item) =>
                          addPayload(["locationId"], [item.value])
                        }
                        value={locations.find(
                          (a) => a.value == payload?.locationId
                        )}
                        options={locations}
                      />
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        }

        {saving && (
          <div className="form-group p-2 m-3">
            <button type="button" className="btn btn-md btn-link m-1">
              <Spinner size="1.5" />
            </button>
          </div>
        )}
        {!saving && (
          <div className="form-group p-2 m-3">
            <button
              type="button"
              className="btn btn-md btn-dark btn-wide px-5 mr-3"
              onClick={() => submitForm()}
            >
              Add User
            </button>
            <button
              type="button"
              className="btn btn-sm btn-link m-1"
              onClick={() => window.history.back()}
            >
              Cancel
            </button>
          </div>
        )}
      </form>
      <hr className="preview-hr" style={{ visibility: "hidden" }} />
    </MainLayout>
  );
}
