import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/mainLayout";
import MyTable from "../../components/dataTable";
import { toReadableDate } from "../../utils/utilityFunctions";
import SailingService from "../../services/sailingService";
import Spinner from "../../utils/spinner";
import toastr from "../../utils/toastr";
import { Link } from "react-router-dom";
import Select from "react-select";
import ReactModal from "react-modal";
import LocationService from "../../services/locationService";
import EquipmentService from "../../services/equipmentService";
import moment from "moment";

export default function VisitList({ sailingId, selectedSailing }) {
  const [tableData, setTableData] = useState(null);
  const [visits, setVisits] = useState([]);
  const [currentVisit, setCurrentVisit] = useState([{}]);
  const [visitsCount, setVisitsCount] = useState(0);
  const [initializing, setInit] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [pageSize, setPageSize] = useState(5);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [measurementUnits, setMeasurementUnits] = useState([]);
  const [locations, setLocations] = useState([]);
  const [finalSaveloading, setFinalSaveLoading] = useState(false);

  useEffect(async () => {
    await retrieveVisits(1, 8);
    await getTotalVisitCount();
  }, []);

  const headerList = [
    {
      Header: "Sequence",
      accessor: "Sequence",
    },
    {
      Header: "Location",
      accessor: "Location",
    },
    {
      Header: "Location Type",
      accessor: "LocationType",
    },
    {
      Header: "Day On Location",
      accessor: "DayOnLocationFormatted",
    },
    {
      Header: "Time On Location (In Hrs)",
      accessor: "ReqTimeOnLocationInHrs",
    },
    {
      Header: "Deck Area",
      accessor: "ReqDeckArea",
    },
    {
      Header: "Deck Area Measurement Unit",
      accessor: "MeasurementUnit",
    },
    {
      Header: "Bulk Requirement?",
      accessor: "IsBulkRequirement",
    },
    {
      Header: "",
      accessor: "menu",
    },
  ];

  const retrieveVisits = async (
    currentPage,
    PageSize,
    query = "",
    orderByAsc = true
  ) => {
    setInit(true);

    const units = await EquipmentService.listMeasurementUnits();
    if (units == null) setMeasurementUnits([]);
    else
      setMeasurementUnits(
        units.map((e) => ({
          ...e,
          label: e.MeasurementUnitSymbol,
          value: e.MeasurementUnitId,
          id: "MeasurementUnitId",
        }))
      );

    let _locations = await LocationService.list(1, 1000);
      _locations = _locations?.filter((locale) => locale?.LocationTypeName?.toLowerCase() !== "field")
    setLocations(
      _locations.map((e) => ({
        ...e,
        label: `${e.LocationName} - ${e.LocationTypeName}`,
        value: e.LocationId,
        id: "LocationId",
      })) || []
    );
    setCurrentPage(currentPage);
    setSearchParam(query);
    setPageSize(PageSize);
    var visitList = await SailingService.getSailingLocationBySailing(sailingId);
    visitList = (visitList || []).map((e) => ({
      ...e,
      IsBulkRequirement: e.IsBulkRequirement ? "Yes" : "No",
      MeasurementUnit:
        units.find((m) => m.MeasurementUnitId == e.MeasurementUnitId)
          ?.MeasurementUnit || "",
      Location:
        _locations.find((m) => m.LocationId == e.LocationId)?.LocationName ||
        "",
      DayOnLocationFormatted: toReadableDate(e.DayOnLocation),
      menu: overflowMenu(e),
    }));
    setVisits(visitList || []);
    setTableData(visitList || []);
    setInit(false);
  };

  const getTotalVisitCount = async () => {
    const count = await SailingService.countSailingLocations(sailingId);
    if (count != null) {
      setVisitsCount(count);
    }
  };

  const deleteAction = () => { };

  const formatDate = (date) => {
    if (!date) return "";
    return date.split("T")[0];
  };
  const tableTopActions = (
    <div className="card-tools mr-n1" data-select2-id={28}></div>
  );

  const handleChange = (e, type) => {
    setCurrentVisit({
      ...currentVisit,
      [e.id]: e.value,
    });
  };

  const effectInput = (id, value) => {
    setCurrentVisit({
      ...currentVisit,
      [id]: value,
    });
  };

  const AddVisitToList2 = () => {
    var visitList = visits;
    visitList.push(currentVisit);
    setVisits(visitList);
  };

  const AddNewVisit = async () => {
    // check for empty select fields
    if (!currentVisit.LocationId) {
      return toastr("error", "Please select a location");
    }

    if (currentVisit.SailingLocationId) {
      UpdateVisit();
      return;
    }

    var hasFieldErrs = false;

    if (hasFieldErrs) return;
    setFinalSaveLoading(true);
    const vesselAdded = await SailingService.addSailingLocation(currentVisit);
    if (!vesselAdded) {
      toastr("error", "Sailing Visit could not be added");
      setFinalSaveLoading(false);
      return;
    } else {
      await retrieveVisits(1, 8);
      await getTotalVisitCount();
      toastr("success", "Sailing Visit successfully added");
      setFinalSaveLoading(false);
    }
    setModalIsOpen(false);
  };
  const UpdateVisit = async () => {
    var hasFieldErrs = false;

    if (hasFieldErrs) return;
    setFinalSaveLoading(true);
    const vesselAdded = await SailingService.updateSailingLocation(
      currentVisit
    );
    if (!vesselAdded) {
      toastr("error", "Sailing Visit could not be updated");
      setFinalSaveLoading(false);
      return;
    } else {
      await retrieveVisits(1, 8);
      await getTotalVisitCount();
      toastr("success", "Sailing Visit successfully updated");
      setFinalSaveLoading(false);
    }
    setModalIsOpen(false);
  };

  const showEditVisitLocation = () => {
    setCurrentVisit({
      SailingId: sailingId,
    });
    setModalIsOpen(true);
  };

  const overflowMenu = (visit) => {
    return (
      <ul className="nk-tb-actions gx-2">
        {null && (
          <li className="nk-tb-action-hidden">
            <a
              href="#"
              className="btn btn-sm btn-icon btn-trigger"
              data-toggle="tooltip"
              data-placement="top"
              title="Suspend"
            >
              <em className="icon ni ni-user-cross-fill" />
            </a>
          </li>
        )}
        <li>
          <div className="drodown">
            <a
              href="#"
              className="btn btn-sm btn-icon btn-trigger dropdown-toggle"
              data-toggle="dropdown"
            >
              <em className="icon ni ni-more-h" />
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <ul className="link-list-opt no-bdr">
                <li
                  className="clickable"
                  onClick={() => {
                    showEditVisitLocation();
                    setCurrentVisit(visit);
                  }}
                >
                  <a>
                    <em className="icon ni ni-eye" />
                    <span>Edit</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    );
  };
  const addVisitModal = () => {
    return (
      <ReactModal
        isOpen={modalIsOpen}
        contentLabel="Add Sailing Visit"
        className="hide-scrollbar overflow-auto"
        style={{ overlay: { zIndex: "1300" } }}
      >
        <MainLayout
          preTitle={
            <span className="ml-2">
              <em
                className="icon ni ni-arrow-left pointer"
                onClick={() => setModalIsOpen(false)}
              />
              <span className="pointer" onClick={() => setModalIsOpen(false)}>
                Return
              </span>
              <h4 className="ml-2">Sailing Location</h4>
            </span>
          }
          loading={finalSaveloading}
          showFullLoader={finalSaveloading}
        >
          <div className="card  col">
            <form
              action="#"
              id="editEventForm"
              className="form-validate is-alter"
              onSubmit={(e) => {
                e.preventDefault();
                AddNewVisit();
              }}
            >
              <div className="row gx-4 gy-3">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="edit-event-title">
                      Sequence
                    </label>
                    <div className="form-control-wrap">
                      <input
                        onChange={(e) => {
                          effectInput("Sequence", e.target.value);
                        }}
                        value={currentVisit.Sequence || ""}
                        type="text"
                        className="form-control"
                        id="edit-event-Sequence"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="edit-event-title">
                      Location
                    </label>
                    <div className="form-control-wrap">
                      <Select
                        options={locations}
                        value={locations?.filter(function (option) {
                          return option.value === currentVisit.LocationId;
                        })}
                        id="LocationId"
                        name="LocationId"
                        onChange={(item) => {
                          handleChange(item, "LocationId");
                        }}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">Day On Location</label>
                    <div className="form-control-wrap">
                      <div className="form-icon form-icon-left">
                        <em className="icon ni ni-calendar" />
                      </div>
                      <input
                        onChange={(e) => {
                          effectInput("DayOnLocation", e.target.value);
                        }}
                        value={formatDate(currentVisit.DayOnLocation) || ""}
                        type="date"
                        min={
                          selectedSailing?.DayOfSailing &&
                          moment(selectedSailing?.DayOfSailing).format(
                            "YYYY-MM-DD"
                          )
                        }
                        max={
                          selectedSailing?.DayOfReturnInPort &&
                          moment(selectedSailing?.DayOfReturnInPort).format(
                            "YYYY-MM-DD"
                          )
                        }
                        id="DayOnLocation"
                        className="form-control date-picker"
                        data-date-format="yyyy-mm-dd"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">
                      Req Time On Location In Hrs
                    </label>
                    <div className="form-control-wrap">
                      <div className="form-icon form-icon-left">
                        <em className="icon ni ni-clock" />
                      </div>
                      <input
                        onChange={(e) => {
                          effectInput("ReqTimeOnLocationInHrs", e.target.value);
                        }}
                        value={currentVisit.ReqTimeOnLocationInHrs || ""}
                        type="text"
                        id="ReqTimeOnLocationInHrs"
                        data-time-format="HH:mm:ss"
                        className="form-control time-picker"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12"></div>

                <div className="col-sm-4 col-md-3">
                  <div className="form-group">
                    <label className="form-label">Req Deck Area</label>
                    <div className="form-control-wrap">
                      <input
                        onChange={(e) => {
                          effectInput("ReqDeckArea", e.target.value);
                        }}
                        value={currentVisit.ReqDeckArea || ""}
                        type="text"
                        id="edit-ReqDeckArea"
                        data-time-format="HH:mm:ss"
                        className="form-control time-picker"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 col-md-3">
                  <div className="form-group">
                    <label className="form-label">Measurement Unit</label>
                    <div className="form-control-wrap">
                      <Select
                        id="MeasurementUnitId"
                        name="MeasurementUnitId"
                        onChange={(item) => {
                          handleChange(item, "MeasurementUnitId");
                        }}
                        value={measurementUnits?.filter(function (option) {
                          return (
                            option.value === currentVisit.MeasurementUnitId
                          );
                        })}
                        options={measurementUnits.filter(
                          (a) => a.UnitType == "area"
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="form-group d-flex align-center">
                    <input
                      onChange={(e) => {
                        effectInput("IsBulkRequirement", e.target.checked);
                      }}
                      value={currentVisit.IsBulkRequirement || ""}
                      type="checkbox"
                      id="IsBulkRequirement"
                      className=" mr-2"
                    />
                    <label
                      className="form-label mb-0"
                      htmlFor="IsBulkRequirement"
                    >
                      Is Bulk Requirement?
                    </label>
                  </div>
                </div>

                <div className="col-12">
                  <ul className="d-flex justify-content-between gx-4 mt-1">
                    <li>
                      <button
                        type="submit"
                        id="updateEvent"
                        className="btn btn-primary"
                        disabled={finalSaveloading}
                      >
                        Save
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </form>
          </div>
        </MainLayout>
      </ReactModal>
    );
  };

  return (
    <>
      <div
        style={{ marginTop: "100px" }}
        className="nk-block-head nk-block-head-sm"
      >
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title col">Sailing Visits</h3>
          </div>
          <div className="nk-block-head-content">
            <div className="toggle-wrap nk-block-tools-toggle">
              <div className="toggle-expand-content" data-content="pageMenu">
                <ul className="nk-block-tools g-3">
                  <li className="nk-block-tools-opt">
                    <div className="drodown">
                      <button
                        onClick={() => showEditVisitLocation()}
                        id="setModalIsOpen"
                        type="button"
                        className="btn btn-primary"
                      >
                        Add Visit
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {initializing && (
          <div className="w-100 text-center center my-5 py-5">
            <Spinner />
          </div>
        )}
        {!modalIsOpen && !initializing && (
          <MyTable
            columns={headerList}
            cssArray={["font-weight-500", "", "", "text-right"]}
            data={tableData}
            reloadData={(page, pageSize, query) =>
              retrieveVisits(page, pageSize, query)
            }
            emptyMessage="Visit List is Empty"
            pageSize={pageSize}
            page={currentPage}
            searchQuery={searchParam}
            totalCount={visitsCount}
            paginationStyle={2}
            tableTopActions={tableTopActions}
          />
        )}
        {addVisitModal()}
      </div>
    </>
  );
}
