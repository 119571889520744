import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/mainLayout";
import payloadUpdater from "../../utils/payloadUpdater";
import { Validator, ValidateGroup } from "../../components/validator";
import Select from "react-select";
import toastr from "../../utils/toastr";
import FleetService from "../../services/fleetService";
import SailingService from "../../services/sailingService";
import VesselService from "../../services/vesselService";
import ZoneService from "../../services/zoneService";
import Spinner from "../../utils/spinner";
import EquipmentService from "../../services/equipmentService";
import { checkUserPermission } from "../../utils/utilityFunctions";

export default function AddSailingSchedule() {
  const [zones, setZones] = useState([]);
  const [vessels, setVessels] = useState([]);
  const [vesselName, setVesselName] = useState(null);
  const [document, setDocument] = useState(null);
  const [measurementUnits, setMeasurementUnits] = useState([]);
  const [redirectUrl, setRedirectUrl] = useState(null);

  const [postVesselToFleetData, setPostVesselToFleetData] = useState({
    VesselSpecificationId: "",
    FleetId: "",
    IsActive: true,
  });
  const [finalSaveloading, setFinalSaveLoading] = useState(false);

  useEffect(async () => {
    await retrieveZones();
    await retrieveVessels();
    await retrieveMeasurementUnits();
  }, []);

  const retrieveZones = async () => {
    const zoneList = await ZoneService.fetchZones();
    if (!zoneList || zoneList == null) setZones([]);
    else setZones(zoneList);
  };
  const retrieveVessels = async () => {
    const VesselList = (await VesselService.listAllVessels()) || [];
    setVessels(
      VesselList.map((e) => ({
        ...e,
        id: "VesselSpecificationId",
        label: e.VesselName,
        value: e.VesselSpecificationId,
      }))
    );
  };
  const retrieveMeasurementUnits = async () => {
    let units = await EquipmentService.listMeasurementUnits();
    units = units || []; //.filter(a=> a.UnitType == "area");

    if (units == null) setMeasurementUnits([]);
    else
      setMeasurementUnits(
        units.map((e) => ({
          ...e,
          label: e.MeasurementUnitSymbol,
          value: e.MeasurementUnitId,
          id: "MeasurementUnitId",
        }))
      );
  };

  const handleChange = (e) => {
    setPostVesselToFleetData({
      ...postVesselToFleetData,
      [e.id]: e.value,
    });
  };

  const UploadFile = async () => {
    var hasFieldErrs = false;
    //if(postVesselToFleetData.VesselSpecificationId == ""){
    //  toastr("error", "Please select vessel");
    //hasFieldErrs = true;
    // }
    if (document == null) {
      toastr("error", "Select file to upload");
      return;
    }

    if (!postVesselToFleetData.MeasurementUnitId) {
      toastr("error", "Select a measurement unit");
      return;
    }

    if (hasFieldErrs) return;
    setFinalSaveLoading(true);
    const formData = new FormData();
    formData.append("file", document);
    //formData.append('VesselSpecificationId', postVesselToFleetData.VesselSpecificationId);
    formData.append(
      "MeasurementUnitId",
      postVesselToFleetData.MeasurementUnitId
    );
    const vesselAdded = await SailingService.uploadSchedule(formData);
    if (!vesselAdded) {
      toastr("error", "Sailing Schedule could not be uploaded");
    } else {
      setPostVesselToFleetData({
        VesselSpecificationId: "",
        FleetId: "",
        IsActive: true,
      }); //reset payload
      toastr("success", "Sailing Schedule successfully added");
      setRedirectUrl("/sailingschedule");
    }

    setFinalSaveLoading(false);
  };
  const setFile = (e) => {
    setDocument(e.target.files[0]);
  };
  return (
    <MainLayout
      title="Upload Sailing Schedule"
      loading={finalSaveloading}
      redirectUrl={redirectUrl}
    >
      <div className="">
        <div className="card-inner">
          <form>
            <div className="row align-items-center">
              <div className="row gy-4 p-3">
                {null && (
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label">Name of Vessel</label>
                      <div className="form-control-wrap">
                        <Select
                          onChange={(item) => {
                            handleChange(item);
                            setVesselName(item.label);
                          }}
                          value={vessels.filter(function (option) {
                            return (
                              option.value ===
                              postVesselToFleetData.VesselSpecificationId
                            );
                          })}
                          id="VesselSpecificationId"
                          name="VesselSpecificationId"
                          options={vessels}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {checkUserPermission(
                  "sailing-schedule_download_upload_template"
                ) && (
                  <div className="col-sm-12">
                    <p>
                      <a
                        href={`${process.env.REACT_APP_SUBFOLDER}/sailing_schedule_template.xlsx`}
                      >
                        Download upload template
                      </a>
                    </p>
                  </div>
                )}
                <div className="col-sm-6">
                  <div className="form-group ">
                    <label className="form-label" htmlFor="fw-last-name">
                      File
                    </label>
                    <div className="form-control-wrap">
                      <input type="file" onChange={(e) => setFile(e)} />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <label className="form-label">
                      Measurement Unit <small>(for Deck Area)</small>
                    </label>
                    <div className="form-control-wrap">
                      <Select
                        id="MeasurementUnitId"
                        name="MeasurementUnitId"
                        onChange={(item) => {
                          handleChange(item);
                        }}
                        value={measurementUnits.filter(function (option) {
                          return (
                            option.value ===
                            postVesselToFleetData.MeasurementUnitId
                          );
                        })}
                        options={measurementUnits.filter(
                          (a) => a.UnitType == "area"
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="nk-tb-col">
              <span>&nbsp;</span>
            </div>
            {!finalSaveloading &&
              checkUserPermission(
                "sailing-schedule_upload_sailing_schedule"
              ) && (
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-primary mb-4"
                    onClick={() => UploadFile()}
                  >
                    {" "}
                    Upload
                  </button>
                </div>
              )}
            {finalSaveloading && (
              <div className="form-group">
                <button type="button" className="btn btn-primary mb-4" disabled>
                  <Spinner size="1.5" />
                </button>
              </div>
            )}
            <hr className="preview-hr" />
          </form>
        </div>
      </div>
    </MainLayout>
  );
}
