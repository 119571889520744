import React from "react";
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	Tooltip,
	LabelList,
	Legend,
	ResponsiveContainer,
} from "recharts";

export default function testChart() {
	const labels = {
		messages: "Messages",
		views: "Views",
		shares: "Shares",
		favorites: "Favorites",
	};

	const legendFormatter = (value, entry, index) => labels[value.split(".")[0]];
	const tooltipFormatter = (value, name, props) => [
		value,
		legendFormatter(name),
	];

	const renderCustomizedLabel = (fill) => {
		return (props) => {
			const { x, y, width, value } = props;

			

			return (
				<g>
					<rect width={width} height={30} x={x} y={y - 30} fill="#fff" />
					<text
						x={x + width / 2}
						y={y - 12}
						fill={fill}
						textAnchor="middle"
						dominantBaseline="middle">
						{value > 0 ? "+" + value : value}
					</text>
				</g>
			);
		};
	};

	const data = [
		{
			Month: "August",

			Lagos: {
				NumberOfVoyages: 7,
				OffshoreInstallationId: "33e6bd2f-4c21-4b35-906f-3a7e2969c48a",
				Positioning: 1,
				TotalCost: 4299.87,
			},
			Agbami: {
				NumberOfVoyages: 4,
				OffshoreInstallationId: "33e6bd2f-4c21-4b35-906f-3a7e2969c48a",
				Positioning: 1,
				TotalCost: 4299.87,
			},
		},

		{
			Month: "September",

			Lagos: {
				NumberOfVoyages: 5,
				OffshoreInstallationId: "33e6bd2f-4c21-4b35-906f-3a7e2969c48a",
				Positioning: 1,
				TotalCost: 4299.87,
			},
			//   Apapa: {
			//     NumberOfVoyages: 9,
			//     OffshoreInstallationId: "33e6bd2f-4c21-4b35-906f-3a7e2969c48a",
			//     Positioning: 1,
			//     TotalCost: 4299.87,
			//   },
			Tincan: {
				NumberOfVoyages: 10,
				OffshoreInstallationId: "33e6bd2f-4c21-4b35-906f-3a7e2969c48a",
				Positioning: 1,
				TotalCost: 4299.87,
			},
		},
		{
			Month: "November",

			Lagos: {
				NumberOfVoyages: 5,
				OffshoreInstallationId: "33e6bd2f-4c21-4b35-906f-3a7e2969c48a",
				Positioning: 1,
				TotalCost: 4299.87,
			},
			Apapa: {
				NumberOfVoyages: 9,
				OffshoreInstallationId: "33e6bd2f-4c21-4b35-906f-3a7e2969c48a",
				Positioning: 1,
				TotalCost: 4299.87,
			},
			Tincan: {
				NumberOfVoyages: 10,
				OffshoreInstallationId: "33e6bd2f-4c21-4b35-906f-3a7e2969c48a",
				Positioning: 1,
				TotalCost: 4299.87,
			},
		},

		{
			LocationName: "AGBAMI",
			Month: "December",
			NumberOfVoyages: 3,
			OffshoreInstallationId: "742b5116-da7b-11ec-b918-85abc6df0ea5",
			Positioning: 2,
			TotalCost: 4234.76,
		},

	
	];

	

	const breakdown = {
		
	};

	const modified = [
		{
			LocationName: "AGBAMI",
			Month: "September",
			NumberOfVoyages: 3,
			OffshoreInstallationId: "742b5116-da7b-11ec-b918-85abc6df0ea5",
			Positioning: 2,
			TotalCost: 4234.76,
		},
		{
			LocationName: "AGBAMI",
			Month: "October",
			NumberOfVoyages: 3,
			OffshoreInstallationId: "742b5116-da7b-11ec-b918-85abc6df0ea5",
			Positioning: 2,
			TotalCost: 4234.76,
		},

		{
			LocationName: "Lagos",
			Month: "October",
			NumberOfVoyages: 3,
			OffshoreInstallationId: "33e6bd2f-4c21-4b35-906f-3a7e2969c48a",
			Positioning: 1,
			TotalCost: 4299.87,
		},

		{
			LocationName: "Lagos",
			Month: "November",
			NumberOfVoyages: 3,
			OffshoreInstallationId: "33e6bd2f-4c21-4b35-906f-3a7e2969c48a",
			Positioning: 1,
			TotalCost: 4299.87,
		},
	];

	const uniqueLocations = {};

	modified.map((item) => {
		// handles month breakdown
		breakdown[item.Month] = {
			...(breakdown[item.Month] || {}),
			[item.LocationName]: { ...item },
		};

		// handles unique locations
		uniqueLocations[item.LocationName] = true;
	});

	

	const RenderChart = (data) => (
		<ResponsiveContainer height={400}>
			<BarChart
				data={data}
				style={{ backgroundColor: "#F4F4F4" }}
				margin={{ top: 45, right: 20, left: -10, bottom: 15 }}>
				<XAxis dataKey="Month" />
				<YAxis />
				<Tooltip />
				<Legend />
				{/* <Tooltip formatter={tooltipFormatter} />
        <Legend formatter={legendFormatter} /> */}
				{/* <Bar dataKey="NumberOfVoyages" fill="#4A8A95" /> */}

				{/* {Object.keys(uniqueLocations).map((item, key) => (
          <Bar dataKey={item["NumberOfVoyages"]} fill="blue" key={key} />
        ))} */}

				<Bar dataKey="Agbami.NumberOfVoyages" fill="#003f5c" />
				<Bar dataKey="Apapa.NumberOfVoyages" fill="#2f4b7c" />
				<Bar dataKey="Lagos.NumberOfVoyages" fill="#665191" />
				<Bar dataKey="Tincan.NumberOfVoyages" fill="#a05195" />
				{/* <Bar dataKey="shares.value" fill="#4A8A95" /> */}
				{/* <Bar dataKey="shares.favorites" fill="#4A8A95" /> */}
				{/* <Bar dataKey="views.value" fill="#FBBE52" minPointSize={5}>
            <LabelList dataKey="views.growth" content={renderCustomizedLabel('#FBBE52')} />
        </Bar>
        <Bar dataKey="shares.value" fill="#EA4646" minPointSize={5}>
            <LabelList dataKey="shares.growth" content={renderCustomizedLabel('#EA4646')} />
        </Bar>
        <Bar dataKey="favorites.value" fill="#0467BA" minPointSize={5}>
            <LabelList dataKey="favorites.growth" content={renderCustomizedLabel('#0467BA')} />
        </Bar> */}
			</BarChart>
		</ResponsiveContainer>
	);
	return <div>{RenderChart(breakdown)}</div>;
}
