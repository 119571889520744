import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import Spinner from "../../utils/spinner";
import { Validator, ValidateGroup, ClearValidator } from "../validator";
import toastr from "../../utils/toastr";
import VoyageService from "../../services/voyageService";
import ReactTooltip from "react-tooltip";
import ConfigurationService from "../../services/configurationService";
import CustomModal from "../../utils/customModal";
import { toReadableDate, camelizeKeys } from "../../utils/utilityFunctions";
import { forEach } from "lodash";
import CommercialService from "../../services/commercialService";

export default function AddLocationCostComponent({
  voyagePlanningId,
  voyageUnderView,
}) {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState([]);
  const [saving, setSaving] = useState(false);
  const [currency, setCurrency] = useState([]);
  const [
    currencyConfigurationFromRequiredCommercial,
    setCurrencyConfigurationFromRequiredCommercial,
  ] = useState("");

  const addPayload = new PayloadUpdater({
    payload: payload,
    setPayload: setPayload,
  }).update;
  const validationGroup = "addLocationCost";

  useEffect(async () => {
    await init();
  }, []);

  const init = async () => {
    setLoading(true);
    let _voyageLocations = await VoyageService.getAllVoyageLocations(
      voyagePlanningId
    );

    let modifiedDataList = camelizeKeys(_voyageLocations || []);

    setPayload(
      modifiedDataList.filter(
        (location) =>
          location.locationTypeName.toLowerCase() === "fso" ||
          location.locationTypeName.toLowerCase() === "fpso" ||
          location.locationTypeName.toLowerCase() === "offshore installation" ||
          location.locationTypeName.toLowerCase() === "offshore terminal"
      ) || []
    );

    if (voyageUnderView && voyageUnderView.VesselSpecificationId) {
      const _vesselCommercials = await CommercialService.searchCommercial(
        1,
        1000,
        null,
        null,
        voyageUnderView.VesselSpecificationId,
        null
      );

      let _commercialWithRequiredCurrency = _vesselCommercials?.Data?.find(
        (commercial) =>
          commercial.CommercialStatus === "active" ||
          new Date(commercial.ContractStartDate) <=
          new Date(voyageUnderView.ExpectedDateOfArrival) <
          new Date(commercial.ContractEndDate)
      );
      setCurrencyConfigurationFromRequiredCommercial(
        _commercialWithRequiredCurrency?.CurrencyConfigurationId || ""
      );
    }

    await configurations();

    setLoading(false);
  };

  const configurations = async () => {
    let _configList = await ConfigurationService.getConfigList();

    if (_configList) {
      let _currency = _configList?.filter(
        (l) =>
          l.CorrespondingData.toLowerCase() == "usd" ||
          l.CorrespondingData.toLowerCase() == "naira" ||
          l.CorrespondingData.toLowerCase() == "gbp"
      );
      setCurrency(_currency);
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    const _payload = [...payload];

    setSaving(true);
    const request = await VoyageService.allocateLocationCost(
      params.voyagePlanningId,
      _payload
    );
    if (request) {
      // setPayload({});
      toastr("success", "Cost allocated to location");
      await init();
    } else {
      toastr("error", "Cost not allocated to location");
      setSaving(false);
      return;
    }
    setSaving(false);
  };

  const pageActions = (
    <div className="toggle-wrap nk-block-tools-toggle">
      <a
        href="#"
        className="btn btn-icon btn-trigger toggle-expand mr-n1"
        data-target="pageMenu"
      >
        <em className="icon ni ni-menu-alt-r" />
      </a>
      <div className="toggle-expand-content" data-content="pageMenu">
        <ul className="nk-block-tools g-3">
          <li>
            <button
              onClick={() => window.history.back()}
              className="btn btn-white btn-outline-light"
            >
              <em className="icon ni ni-arrow-left" />
              <span>Return to Previous Page</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );

  return (
    <>
      {loading ? (
        <div className="w-100 text-center center my-5 py-5">
          <Spinner />
        </div>
      ) : (
        <>
          <div
            className="alert alert-info alert-dismissible fade show"
            role="alert" >
            Enter direct costs in each Offshore Installation.
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
          <form onSubmit={submitForm}>
            <div className="card-inner">
              {payload?.map((location, index) => (
                <div key={index}>
                  <>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label small">Location</label>
                          <div className="form-control-wrap">
                            <input
                              type="text"
                              className="form-control"
                              value={
                                location?.locationName +
                                " - " +
                                location?.locationTypeName
                              }
                              required
                              disabled
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-2">
                        <div className="form-group ">
                          <label className="form-label small">
                            Cost Incurred
                          </label>
                          <div className="form-control-wrap">
                            <input
                              type="number"
                              className="form-control"
                              defaultValue={location?.costIncurred}
                              required
                              step="any"
                              onChange={(e) => {
                                setPayload((prev) => {
                                  prev[index].costIncurred = e.target.value;
                                  prev[index].currencyConfigurationId =
                                    currencyConfigurationFromRequiredCommercial;
                                  return prev;
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-2">
                        <div
                          className="form-group"
                          style={{ zIndex: 100 - index }}
                        >
                          <label className="form-label small">Currency</label>
                          <div className="form-control-wrap">
                            <Select
                              defaultValue={currency
                                .map((c) => ({
                                  ...c,
                                  label: c.CorrespondingData,
                                  value: c.ConfigurationId,
                                }))
                                .find(
                                  (a) =>
                                    a.value ==
                                    // payload[index]?.currencyConfigurationId
                                    currencyConfigurationFromRequiredCommercial
                                )}
                              options={currency.map((c) => ({
                                ...c,
                                label: c.CorrespondingData,
                                value: c.ConfigurationId,
                              }))}
                              // onChange={(e) => {
                              //   setPayload((prev) => {
                              //     prev[index].currencyConfigurationId = e.value;
                              //     return prev;
                              //   });
                              // }}
                              isDisabled={true}
                            // onChange={(e) => {
                            //   addPayload(
                            //     "currencyConfigurationId",
                            //     e.value
                            //   );
                            // }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="gap" />
                    </div>
                  </>
                </div>
              ))}
            </div>

            {saving && (
              <div className="float-right">
                <div className="form-group p-2 m-3">
                  <button type="button" className="btn btn-md btn-link m-1">
                    <Spinner size="1.5" />
                  </button>
                </div>
              </div>
            )}
            {!saving && (
              <div className="float-right">
                <div className="form-group p-2 m-3">
                  <button
                    type="submit"
                    className="btn btn-md btn-dark btn-wide px-5 mr-3"
                  // onClick={() => submitForm()}
                  >
                    Save
                  </button>
                  {/* <button
                        type="button"
                        className="btn btn-sm btn-link m-2"
                        onClick={() => window.history.back()}
                      >
                        Cancel
                      </button> */}
                </div>
              </div>
            )}
          </form>
        </>
      )}
    </>
  );
}
