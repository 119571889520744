import React, { useRef, useEffect, useState } from "react";
import jspreadsheet from "jspreadsheet-ce";
import "jsuites/dist/jsuites.css";
import "../../node_modules/jspreadsheet-ce/dist/jspreadsheet.css";


export default function BookingEquipmentSheet({ editedBookingData,
    measurementUnitArray, allCargoCarryingEquipments,
    bookingEquipmentContent, ownersList,
    bookingDataToEdit, cargoEquipmentForBooking,
    setClickedVal = () => null,
    setBookingData = () => null,
    setBookingDataFirstColumn = () => null,
    callback = () => null,
}) {
    const [bookingEquipmentData, setBookingEquipmentData] = useState([]);
    const [addRowClicked, setAddRowClicked] = useState(false);
    const [uneditedFirstColumn, setUneditedFirstColumn] = useState([]);

    let sourceOptions = ['full', 'half-empty', 'empty'];
    //these arrays were created so that the carogEquipment on addBooking.j won't be altered from data manipulation on this page
    let cargoEquipmentList = [];
    let allCargoCarryingEquipmentList = [];
    allCargoCarryingEquipmentList.push(...allCargoCarryingEquipments)
    cargoEquipmentList.push(...cargoEquipmentForBooking);

    const retrieveInputData = () => {
        //The line below retrieves data from the Excel sheet.
        let inputRowData = jRef.current?.jexcel?.getColumnData([0]);
        let filteredRowData = inputRowData.filter(Boolean);//this removes all empty strings, null values, undefined, NaN and false => All falsy values
        setBookingData(filteredRowData);

    }
    let changed = function (instance, cell, x, y, value) {
        let cellName = jRef.current.jexcel.getColumnData([x, y]);
        retrieveInputData();
        retrieveLiveInput()//This 
    }
    let deleteExcessColumns = () => {
        try {
            jRef?.current?.jexcel.deleteColumn([13], [12]);
        } catch (err) {
            console.log("Error with function: ", err.stack)
        }
        retrieveLiveInput()//This 
    }
    const retrieveLiveInput = () => {
        let firstColumnRecords = jRef?.current?.jexcel?.getColumnData([0]);
        setBookingDataFirstColumn(firstColumnRecords)
        setUneditedFirstColumn(firstColumnRecords)
        let userInput = jRef?.current?.jexcel?.getData();
        setBookingEquipmentData(userInput);
        let filteredStuff = firstColumnRecords?.filter(Boolean)//removes falsey values from filteredStuff


    }
    //The tableEntryTracker variable helps in tracking when real changes are made to the table
    //By using this, it deletes all empty strings, null values, undefined, NaN and false => All falsy values
    //from the array
    let tableEntryTracker = bookingEquipmentContent?.flat()?.filter(Boolean)

    if (bookingEquipmentContent?.length > 1) {
        bookingDataToEdit = bookingEquipmentContent;
    }
    else if (bookingDataToEdit?.length === 0) {
        bookingDataToEdit = [
            ["", "", "", "", "", "", "", "", ""],
        ];

        if (tableEntryTracker?.length > 0) {
            bookingDataToEdit = bookingEquipmentContent;
        }
    }
    else if ((bookingDataToEdit?.length > 0) && (tableEntryTracker?.length > 0)) {
        bookingDataToEdit = bookingEquipmentContent;
    }

    useEffect(() => {
        const options = {
            maxDimensions: [9, 1],
            data: bookingDataToEdit,
            tableOverflow: true,
            columnSorting: false,
            tableWidth: "100%",
            // tableHeight: "100%",
            // allowInsertRow: false,
            // allowInsertColumn: false,
            // allowManualInsertColumn:false,
            // // Allow new columns
            // allowInsertColumn:false,
            ondeleterow: retrieveLiveInput,
            onchange: changed,
            onload: deleteExcessColumns,

            columns: [

                {
                    type: 'dropdown',
                    title: 'Cargo-Carrying Equipment *',
                    width: 240,
                    source: cargoEquipmentList,
                    options: 'picker',
                    lazyLoading: true,
                    name: "specificCargoEquipmentId",
                    // filter: dropdownFilter,
                },
                {
                    type: 'dropdown',
                    title: 'Owner',
                    name: "PartyId",
                    width: 250,
                    source: ownersList,
                    options: 'picker',
                    lazyLoading: true,
                },
                {
                    type: 'text',
                    title: 'Quantity *',
                    width: 80,
                    name: 'QuantityOfEquipment',
                },
                {
                    type: 'text',
                    title: 'Seal No.',
                    width: 120,
                    name: "SealNo",
                },
                {
                    type: 'numeric',
                    title: 'Est. Gross Weight *',
                    width: 140,
                    name: 'EstimatedGrossWeight',
                },
                {
                    type: 'dropdown',
                    title: 'Measurement Unit *',
                    name: 'MeasurementUnitId',
                    source: measurementUnitArray,
                    width: 200,
                    options: 'picker',
                    lazyLoading: true,

                },
                {
                    type: 'text',
                    title: 'Container No',
                    width: 160,
                    name: "CCUNumber"
                },
                {
                    type: 'text',
                    title: 'Dimension',
                    width: 400,
                    name: "Description",
                },
                {
                    type: 'dropdown',
                    title: 'Full / Empty Indication',
                    source: sourceOptions,
                    width: 120,
                    options: 'picker',
                    lazyLoading: true,
                    name: 'FullEmptyIndication',
                },
                {
                    type: 'hidden',
                    title: 'BookingRequestId',
                    width: 120,
                    options: 'picker',
                    lazyLoading: true,
                    name: 'BookingRequestId',
                },
                {
                    type: 'hidden',
                    title: 'BookingEquipmentDetailId',
                    width: 120,
                    options: 'picker',
                    lazyLoading: true,
                    name: 'BookingEquipmentDetailId',
                },
                {
                    type: 'hidden',
                    title: 'CargoCarryingEquipmentTypeId',
                    width: 120,
                    options: 'picker',
                    lazyLoading: true,
                    name: 'CargoCarryingEquipmentTypeId',
                },
                {
                    type: 'hidden',
                    title: 'TubularCategoryId',
                    width: 120,
                    options: 'picker',
                    lazyLoading: true,
                    name: 'TubularCategoryId',
                },
            ],

        };
        if (!jRef?.current?.jspreadsheet) {
            jspreadsheet(jRef?.current, options);
        }

        retrieveLiveInput();

    }, []);

    const jRef = useRef(null);

    const handleAddRow = () => {
        jRef.current.jexcel.insertRow();
        setAddRowClicked(true);
        setTimeout(() => setAddRowClicked(false), 380)
    }
    setClickedVal(addRowClicked);

    if (addRowClicked === "" || undefined) {
        setAddRowClicked(false)
        setClickedVal(addRowClicked);
    }
    useEffect(() => {
        callback(bookingEquipmentData);
    }, [bookingEquipmentData]);

    return (
        <div >
            <p className="text-danger mb-0 small"><b className="mr-2">-</b>(Please <b>double-click</b> to see drop-down options) </p>
            <p className="text-danger mb-0 small"><b className="mr-2">-</b> Container number field is required for Offshore Containers. </p>
            <p className="text-danger mb-0 small"><b className="mr-2">-</b>The 'Quantity' field for Offshore Containers can only be one per container entry. </p>
            <p className="text-danger mb-0 small"><b className="mr-2">-</b>The 'Owner' field is not required for Bigbags and Tubulars. </p>
            <p className="text-danger small"><b className="mr-2">-</b>The 'Dimension' field is ONLY required for Bigbags and Tubulars.</p>
            <div ref={jRef} />
            <br /> <br />
            <input type="button" className="btn-btn-dim" onClick={handleAddRow} value="Add more" />
        </div>
    );
}

