import { getRoles } from "@testing-library/react";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { DataContext } from "../contexts/dataContexts";

export default class TopNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileDropdownVisible: false,
      displayExtraTopLinks: false,
      user: {},
    };
  }

  static contextType = DataContext;

  componentDidMount() {
    this.setState({ user: this.context.globalState.user });
  }

  toggleProfileDropdown() {
    let { profileDropdownVisible } = this.state;
    this.setState({ profileDropdownVisible: !profileDropdownVisible }, () => {
    });
  }

  logout() {
    localStorage.clear();
    document.location.href = `${process.env.REACT_APP_AUTH_LOGIN_URL}?redirect_url=${document.location.href}`;
  }

  userRole() {
    const roles = this.state?.user?.roles;
    if (
      roles &&
      roles?.filter((role) => role.Name == "Super Admin").length !== 0
    ) {
      return roles?.filter((role) => role.Name == "Super Admin")[0]?.Name;
    } else if (
      roles &&
      roles?.filter((role) => role.Name == "Super Admin").length == 0
    ) {
      return roles[0]?.Name;
    }
  }

  render() {
    const role = this.userRole();
    return (
      <>
        <div className="nk-header nk-header-fixed is-light">
          <div className="container-fluid">
            <div className="nk-header-wrap">
              <div className="nk-menu-trigger d-xl-none ml-n1">
                <Link
                  to={"#"}
                  className="nk-nav-toggle nk-quick-nav-icon toggle-active"
                  data-target="sidebarMenu"
                >
                  <em className="icon ni ni-menu" />
                </Link>
              </div>
              <div className="nk-header-brand d-xl-none">
                <Link to={"/"} className="logo-link">
                  <img
                    className="logo-light logo-img"
                    src="/images/Wavis_Logo_Dark.png"
                    srcSet="/images/Wavis_Logo_Dark.png 2x"
                    alt="WAVIS"
                    
                  />
                  <img
                    className="logo-dark logo-img"
                    src="/images/Wavis_Logo_Dark.png"
                    srcSet="/images/Wavis_Logo_Dark.png 2x"
                    alt="WAVIS" />
                </Link>
              </div>
              {/* .nk-header-brand */}
              {/* <div class="nk-header-news d-none d-xl-block">
                                        <div class="nk-news-list">
                                            <a class="nk-news-item" href="#">
                                                
                                            
                                            </a>
                                        </div>
                                    </div>.nk-header-news */}
              <div className="nk-header-tools">
                <ul className="nk-quick-nav">
                  <li className="dropdown user-dropdown">
                    <Link
                      to={"#"}
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      <div className="user-toggle">
                        <div className="user-avatar sm">
                          <em className="icon ni ni-user-alt" />
                        </div>
                        <div className="user-info d-none d-md-block">
                          <div className="user-status">
                            {/* {this.state.user?.roles &&
                              this.state.user?.roles[0]?.Name} */}
                            {/* {role && role[0]?.Name} */}
                            {role}
                          </div>
                          <div className="user-name dropdown-indicator">
                            {this.state.user?.user?.FirstName}{" "}
                            {this.state.user?.user?.LastName}
                          </div>
                        </div>
                      </div>
                    </Link>
                    <div className="dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1">
                      <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                        <div className="user-card">
                          <div className="user-avatar">
                            <span>
                              {this.state.user?.user?.FirstName?.charAt(
                                0
                              ).toUpperCase()}
                              {this.state.user?.user?.LastName?.charAt(
                                0
                              ).toUpperCase()}
                            </span>
                          </div>
                          <div className="user-info">
                            <span className="lead-text">
                              {this.state.user?.user?.FirstName}{" "}
                              {this.state.user?.user?.LastName}
                            </span>
                            <span className="sub-text">
                              {this.state.user?.user?.Email}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="dropdown-inner">
                        <ul className="link-list">
                          <li>
                            <Link to="/profile">
                              <em className="icon ni ni-user-alt" />
                              <span>View Profile</span>
                            </Link>
                          </li>
                          {/* <li>
                            <Link to="html/user-profile-setting.html">
                              <em className="icon ni ni-setting-alt" />
                              <span>Account Setting</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="html/user-profile-activity.html">
                              <em className="icon ni ni-activity-alt" />
                              <span>Login Activity</span>
                            </Link>
                          </li>
                          <li>
                            <a className="dark-switch" href="#">
                              <em className="icon ni ni-moon" />
                              <span>Dark Mode</span>
                            </a>
                          </li> */}
                        </ul>
                      </div>
                      <div className="dropdown-inner">
                        <ul className="link-list">
                          <li>
                            <a href="javascript:void(0)" onClick={this.logout}>
                              <em className="icon ni ni-signout" />
                              <span>Sign out</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  {/* .dropdown */}
                  <li className="dropdown notification-dropdown mr-n1">
                    {/* <a
                      href="#"
                      className="dropdown-toggle nk-quick-nav-icon"
                      data-toggle="dropdown"
                    >
                      <div className="icon-status icon-status-info">
                        <em className="icon ni ni-bell" />
                      </div>
                    </a> */}
                    <div className="dropdown-menu dropdown-menu-xl dropdown-menu-right dropdown-menu-s1">
                      <div className="dropdown-head">
                        <span className="sub-title nk-dropdown-title">
                          Notifications
                        </span>
                        <Link to="#">Mark All as Read</Link>
                      </div>
                      <div className="dropdown-body">
                        <div className="nk-notification">
                          <div className="nk-notification-item dropdown-inner">
                            <div className="nk-notification-icon">
                              <em className="icon icon-circle bg-warning-dim ni ni-curve-down-right" />
                            </div>
                            <div className="nk-notification-content">
                              <div className="nk-notification-text">
                                You have requested to
                                <span>Widthdrawl</span>
                              </div>
                              <div className="nk-notification-time">
                                2 hrs ago
                              </div>
                            </div>
                          </div>
                          <div className="nk-notification-item dropdown-inner">
                            <div className="nk-notification-icon">
                              <em className="icon icon-circle bg-success-dim ni ni-curve-down-left" />
                            </div>
                            <div className="nk-notification-content">
                              <div className="nk-notification-text">
                                Your <span>Deposit Order</span> is placed
                              </div>
                              <div className="nk-notification-time">
                                2 hrs ago
                              </div>
                            </div>
                          </div>
                          <div className="nk-notification-item dropdown-inner">
                            <div className="nk-notification-icon">
                              <em className="icon icon-circle bg-warning-dim ni ni-curve-down-right" />
                            </div>
                            <div className="nk-notification-content">
                              <div className="nk-notification-text">
                                You have requested to
                                <span>Widthdrawl</span>
                              </div>
                              <div className="nk-notification-time">
                                2 hrs ago
                              </div>
                            </div>
                          </div>
                          <div className="nk-notification-item dropdown-inner">
                            <div className="nk-notification-icon">
                              <em className="icon icon-circle bg-success-dim ni ni-curve-down-left" />
                            </div>
                            <div className="nk-notification-content">
                              <div className="nk-notification-text">
                                Your <span>Deposit Order</span> is placed
                              </div>
                              <div className="nk-notification-time">
                                2 hrs ago
                              </div>
                            </div>
                          </div>
                          <div className="nk-notification-item dropdown-inner">
                            <div className="nk-notification-icon">
                              <em className="icon icon-circle bg-warning-dim ni ni-curve-down-right" />
                            </div>
                            <div className="nk-notification-content">
                              <div className="nk-notification-text">
                                You have requested to
                                <span>Widthdrawl</span>
                              </div>
                              <div className="nk-notification-time">
                                2 hrs ago
                              </div>
                            </div>
                          </div>
                          <div className="nk-notification-item dropdown-inner">
                            <div className="nk-notification-icon">
                              <em className="icon icon-circle bg-success-dim ni ni-curve-down-left" />
                            </div>
                            <div className="nk-notification-content">
                              <div className="nk-notification-text">
                                Your <span>Deposit Order</span> is placed
                              </div>
                              <div className="nk-notification-time">
                                2 hrs ago
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* .nk-notification */}
                      </div>
                      {/* .nk-dropdown-body */}
                      <div className="dropdown-foot center">
                        <Link to="#">View All</Link>
                      </div>
                    </div>
                  </li>
                  {/* .dropdown */}
                </ul>
                {/* .nk-quick-nav */}
              </div>
              {/* .nk-header-tools */}
            </div>
            {/* .nk-header-wrap */}
          </div>
          {/* .container-fliud */}
        </div>
      </>
    );
  }
}
