import React, { useCallback, useEffect, useState } from "react";
import { Navigate, useParams, useLocation } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import LocationService from "../../services/locationService";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../../components/fileInput";
import Spinner from "../../utils/spinner";
import {
  Validator,
  ValidateGroup,
  ClearValidator,
} from "../../components/validator";
import toastr from "../../utils/toastr";
import CountryService from "../../services/countriesService";
import ProvinceService from "../../services/provinceService";
import { validationLatitudeLongitude } from "validation-latitude-longitude";
import ReactTooltip from "react-tooltip";
import PartyModal from "../Modals/partyModal";
import PartyService from "../../services/partyService";
import CustomModal from "../../utils/customModal";
import MapView2 from "../../components/mapView2";
import {
  camelizeKeys,
  dynamicSort,
  OffshoreInstallationLocationTypes,
  toReadableDate,
} from "../../utils/utilityFunctions";
import AddBerthOrWarehouseInput from "../../components/location/AddBerthOrWarehouseInput";
import BerthOrWarehouseItem from "../../components/location/BerthOrWarehouseItem";
import ContactPersonService from "../../services/contactPersonService";
import { Popup } from "react-leaflet";
import { checkUserPermission } from "../../utils/utilityFunctions";
import LocationHistoryListComponent from "../../components/location/LocationHistoryList";

export default function AddLocationPage() {
  const params = useParams();
  const isEdit = useLocation().pathname.startsWith("/edit-location");
  const [editData, setEditData] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [locationTypes, setLocationTypes] = useState([]);
  const [allLocationTypes, setAllLocationTypes] = useState([]);
  const [selectedLocationTypeParentId, setSelectedLocationTypeParentId] =
    useState(null);
  const [locations, setLocations] = useState([]);
  const [offshoreInstallationsCoords, setOffshoreInstallationsCoords] =
    useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);

  const [payload, setPayload] = useState({});
  const [saving, setSaving] = useState(false);
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [partyList, setParty] = useState([{}]);
  const [filteredPartyList, setFilteredParty] = useState([{}]); //for the dropdown
  const [partyTypes, setPartyTypes] = useState([]);
  const [selectedPartyId, setSelectedPartyId] = useState(null);
  const [modalIsClosed, setModalIsClosed] = useState(true);
  const [mapData, setMapData] = useState(null);
  const [mapArea, setMapArea] = useState(null);
  const [newZoneCoordinates, setNewZoneCoordinates] = useState([]);
  const [mapCreating, setMapCreating] = useState(true);

  // berths and warehouses
  const [berths, setBerths] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedSubPortEntry, setSelectedSubPortEntry] = useState({});
  const [editBerthOrWarehouse, setEditBerthOrWarehouse] = useState(false);
  const [addBerthOrWarehouse, setAddBerthOrWarehouse] = useState(false);
  const [contactPersonRoles, setContactPersonRoles] = useState([]);
  // the location contact personel has the hard coded role of main contact... the id belonging to the main contact role type is added to the payload
  const [mainContactID, setMMainContactID] = useState(null);

  // when editing and deleting berths or warehouses, the ids to be deleted are to be passed into a separate array in the payload
  const [berthsOrWarehousesToBeDeleted, setBerthsOrWarehousesToBeDeleted] =
    useState([]);

  // filter out these location types from the dropdown because the user should not be able to create these from the frontend
  const [locationTypesToBeFilteredOut, setLocationTypesToBeFilteredOut] =
    useState([
      "field",
      "oml",
      "port terminal-berth",
      "port terminal-warehouse location",
      "logistics base-berth",
      "logistics base-warehouse location",
    ]);

  // Location type detector for relocation of a location
  const [moveableLocationType, setMoveableLocationType] = useState(false);
  const [tab, setTab] = useState(1);
  const [relocationModalIsClosed, setRelocationModalIsClosed] = useState(true);
  const [relocationHistory, setRelocationHistory] = useState([]);
  const [relocationPayload, setRelocationPayload] = useState({});
  const [parentLocationTypeId, setParentLocationTypeId] = useState("");

  const locationTypesToDetect = [
    "offshore installation",
    "fpso",
    "fso",
    "offshore terminal",
  ];

  const addPayload = new PayloadUpdater({
    payload: payload,
    setPayload: setPayload,
  }).update;

  const addRelocationPayload = new PayloadUpdater({
    payload: relocationPayload,
    setPayload: setRelocationPayload,
  }).update;
  const validationGroup = "addlocation";

  useEffect(async () => {
    setLoading(true);

    const locationData = await init();

    const parties = await retrieveParty();

    if (isEdit) {
      // filterPartyDropdown(locationData.locationType || "", parties);
      filterPartyDropdown(
        locationData.locationTypeName || locationData.locationType,
        parties
      );
      loadEditBerthAndWarehouseData(locationData.children || []);
    }

    const _partyTypes = await PartyService.listPartyTypes();
    setPartyTypes(_partyTypes || []);

    const _contactPersonRoles = await ContactPersonService.listAllRoles();
    setContactPersonRoles(_contactPersonRoles);
    setMMainContactID(
      _contactPersonRoles.find(
        (contact) => contact.ContactPersonRole === "Main Contact"
      )?.ContactPersonRoleId
    );
    setLoading(false);

    return () => {
      ClearValidator(validationGroup);
    };
  }, []);

  const init = async () => {
    let _locationTypes = await LocationService.listLocationTypes();
    setAllLocationTypes(_locationTypes);

    // filter out these location types from the dropdown because the user should not be able to create these from the frontend
    _locationTypes = _locationTypes?.filter(
      (_type) =>
        !locationTypesToBeFilteredOut.includes(
          _type.LocationType?.toLowerCase()
        )
    );
    setLocationTypes(_locationTypes || []);

    const _locations = await LocationService.allLocations();
    setLocations(_locations || []);
    // get the coordinates of offshore installations to display on the map
    const offshoreInstallationsList = OffshoreInstallationLocationTypes.map(e=> e.toLocaleLowerCase());

    const offshoreInstallations = _locations.filter(
      (loc) =>
        offshoreInstallationsList.includes(
          loc.LocationTypeName?.toLowerCase()
        ) &&
        loc.Latitude != null &&
        loc.Longitude != null
    );

    if (offshoreInstallations?.length) {
      setOffshoreInstallationsCoords(
        offshoreInstallations?.map((loc) => [loc.Latitude, loc.Longitude, loc])
      );
    } else setOffshoreInstallationsCoords(null);

    // get countries
    let _countries = await CountryService.listCountry();
    if (_countries) {
      _countries = _countries.map((c) => ({
        ...c,
        label: c.CountryName,
        value: c.CountryId,
      }));
      setCountries(_countries);
    }

    if (isEdit) {
      let locationData = await loadEditData();

      return locationData;
    }
  };

  // Location type detector to identify the location type for a location loaded on edit
  const locationTypeDetector = (type) => {
    if (
      locationTypesToDetect.find((element) => element == type.toLowerCase())
    ) {
      return true;
    } else return false;
  };

  const loadEditData = async () => {
    let location = await LocationService.get(params.locationId);
    location = camelizeKeys(location || {}); //change object keys to camel case
    await loadRegions(location.countryId);
    const _moveableLocationCheck = locationTypeDetector(
      location.locationTypeName.toLowerCase()
    );
    setMoveableLocationType(_moveableLocationCheck);

    let _parentLocation = await LocationService.get(location?.parentLocationId);
    setParentLocationTypeId(_parentLocation?.LocationTypeId);

    if (_moveableLocationCheck == true) {
      const _allLocations = await LocationService.list(1, 1000);
      const _countries = await CountryService.listCountry();

      // load the relocation history for a particular location
      const _relocationHistory = await LocationService.getLocationHistory(
        params.locationId
      );
      setRelocationHistory(
        _relocationHistory.map((prevLocation) => ({
          ...prevLocation,
          RelocationDate:
            prevLocation.DateMoved !== null
              ? toReadableDate(prevLocation.DateMoved)
              : " Current Location",
          ParentLocationName: _allLocations?.find(
            (location) => location?.LocationId == prevLocation?.ParentLocationId
          )?.LocationName,
          CountryName: _countries.find(
            (country) => country.CountryId == prevLocation.CountryId
          ).CountryName,
          Coordinates: `Lat: ${prevLocation.Latitude}°, Long: ${prevLocation.Longitude}°`,
        })) || []
      );
    }

    if (location?.locationTypeName?.toLowerCase() == "zone") {
      if (location.coordinates.length > 0) {
        let _coordinates = [...location.coordinates];
        _coordinates.sort(dynamicSort("drawOrder"));
        const _mapArea = location.coordinates.map((e) => [e.lat, e.lng]);
        setMapArea(_mapArea);
      }
    }

    location.locationType = location.locationTypeName;
    setEditData({
      ...location,
      newContactPerson: location.contactPerson || {},
    });
    setPayload({ ...location, newContactPerson: location.contactPerson || {} });
    if (location.partyId) setSelectedPartyId(location.partyId);
    setSelectedLocationTypeParentId(location.parentLocationId);
    return location;
  };

  const loadRegions = async (countryId) => {
    let _regions = await ProvinceService.listProvincesByCountry(countryId);
    if (_regions) {
      _regions = _regions.map((c) => ({
        ...c,
        label: c.RegionName,
        value: c.RegionId,
      }));
      setRegions(_regions);
    } else {
      setRegions([]);
    }
  };

  const validateParentLocationIsRequired = () => {
    return locations.filter(
      (location) => location.LocationTypeId === selectedLocationTypeParentId
    ).length;
  };

  // Warehouse & berths logic start   =====================>

  const discardWarehouseOrBerthChanges = () => {
    setSelectedSubPortEntry({});
    setAddBerthOrWarehouse(false);
  };

  const addNewarehouseOrBerth = (type) => {
    if (selectedSubPortEntry.type || addBerthOrWarehouse) {
      toastr(
        "error",
        `Save or discard your ${selectedSubPortEntry.type} changes.`
      );
      return;
    }

    setAddBerthOrWarehouse(true);

    const getBerthOrWarehouseID = (_type = type) => {
      // this function extracts the appropriate location type ID for berths and warehouses
      let result;
      if (payload?.locationType.toLowerCase() === "port terminal") {
        result = allLocationTypes.find((_locationType) =>
          _locationType.LocationType?.toLowerCase().includes(
            `terminal-${_type}`
          )
        );
      } else {
        result = allLocationTypes.find((_locationType) =>
          _locationType.LocationType?.toLowerCase().includes(`base-${_type}`)
        );
      }

      return result ? result.LocationTypeId : null;
    };

    if (type === "berth") {
      setSelectedSubPortEntry({
        type: "berth",
        data: { locationTypeId: getBerthOrWarehouseID(type) },
      });
    } else {
      setSelectedSubPortEntry({
        type: "warehouse",
        data: { locationTypeId: getBerthOrWarehouseID(type) },
      });
    }
  };

  const editWarehouseOrBerth = ({ type, index, data }) => {
    if (selectedSubPortEntry.type || addBerthOrWarehouse) {
      toastr(
        "error",
        `Save or discard your ${selectedSubPortEntry.type} changes.`
      );
      return;
    }
    setSelectedSubPortEntry({ type, index, data });
  };

  const deleteWarehouseOrBerth = (type, index) => {
    if (selectedSubPortEntry.type || addBerthOrWarehouse) {
      toastr(
        "error",
        `Save or discard your ${selectedSubPortEntry.type} changes.`
      );
      return;
    }

    if (type === "berth") {
      setBerths(
        berths.filter((berth, berthIndex) => {
          if (berthIndex === index && berth.locationId) {
            setBerthsOrWarehousesToBeDeleted((prev) => [
              ...prev,
              berth.locationId,
            ]);
          }
          return berthIndex !== index;
        })
      );
    } else {
      setWarehouses(
        warehouses.filter((warehouse, warehouseIndex) => {
          if (warehouseIndex === index && warehouse.locationId) {
            setBerthsOrWarehousesToBeDeleted((prev) => [
              ...prev,
              warehouse.locationId,
            ]);
          }
          return warehouseIndex !== index;
        })
      );
    }
  };

  const saveWarehouseOrBerthChanges = (type, item) => {
    //  check for empty fields. Berths don't have short names
    if (
      !item?.data?.locationName ||
      (type !== "berth" &&
        !(item?.data?.shortName && item?.data?.shortName.length === 3))
    ) {
      return toastr("error", `Please provide complete ${type} details.`);
    }
    if (type === "berth") {
      if (item.index === undefined) {
        setBerths((prev) => [...prev, item.data]);
      } else {
        let tempBerths = berths;
        tempBerths[item.index] = item.data;
        setBerths(tempBerths);
      }
    } else {
      if (item.index === undefined) {
        setWarehouses((prev) => [...prev, item.data]);
      } else {
        let tempWarehouses = warehouses;
        tempWarehouses[item.index] = item.data;
        setWarehouses(tempWarehouses);
      }
    }

    setEditBerthOrWarehouse(false);
    setAddBerthOrWarehouse(false);
    setSelectedSubPortEntry({});
  };

  const loadEditBerthAndWarehouseData = (data) => {
    data.map((_location) => {
      if (_location.locationTypeName.toLowerCase().includes("berth")) {
        setBerths((prev) => [...prev, _location]);
      } else if (
        _location.locationTypeName.toLowerCase().includes("warehouse")
      ) {
        setWarehouses((prev) => [...prev, _location]);
      }
    });
  };

  // Warehouse & berths end   =====================>

  const submitForm = async (tempPayload) => {
    let _payload = tempPayload
      ? { ...tempPayload, coordinates: tempPayload.coordinates }
      : { ...payload };
    if (!_payload.locationTypeId) {
      toastr("error", "Select location type");
      return;
    }

    if (validateParentLocationIsRequired() && !_payload.parentLocationId) {
      toastr("error", "Select parent location");
      return;
    }

    if (!_payload.locationName) {
      toastr("error", "Enter location name");
      return;
    }

    if (!_payload.shortName) {
      toastr("error", "Enter a 3 letter short name");
      return;
    }

    if (!_payload.countryId) {
      toastr("error", "Select country");
      return;
    }

    if (filteredPartyList.length && !_payload.partyId) {
      toastr("error", "Select location manager");
      return;
    }

    if (!_payload.isOpen24Hours && !_payload.openingTime) {
      toastr("error", "Enter Opening Time");
      return;
    }

    if (!_payload.isOpen24Hours && !_payload.closingTime) {
      toastr("error", "Enter Closing Time");
      return;
    }

    if (selectedSubPortEntry.type || addBerthOrWarehouse) {
      toastr(
        "error",
        `Save or discard your ${selectedSubPortEntry.type} changes.`
      );
      return;
    }

    const __type = _payload.locationType?.toLowerCase();

    if (
      __type != "zone" &&
      !validationLatitudeLongitude.latLong(
        _payload.latitude,
        _payload.longitude
      )
    ) {
      toastr(
        "error",
        "Invalid location coordinates, Hover on the help icon for the correct format"
      );
      return;
    } else {
      if (
        __type == "zone" &&
        !tempPayload &&
        (!mapArea || mapArea.length <= 0)
      ) {
        toastr("error", "Coordinate area must be provided for Zones");
        return;
      }
    }

    if (!_payload.isActive) _payload.isActive = false;

    if (!_payload.isOpen24Hours) _payload.isOpen24Hours = false;
    if (__type == "zone" && _payload.partyId) {
      // zone location type should not have a party
      _payload.partyId = null;
      _payload.partyName = null;
      setSelectedPartyId(null);
    }

    // merge berths and warehouses in the payload
    _payload.berthsAndWarehouses = [...berths, ...warehouses];
    _payload.berthsAndWarehouses = _payload.berthsAndWarehouses.map((item) => {
      return {
        ...item,
        // locationTypeId: _payload.locationTypeId,
        longitude: _payload.longitude,
        latitude: _payload.latitude,
        partyId: _payload.partyId ? _payload.partyId : null,
        isOpen24Hours: _payload.isOpen24Hours,
        openingTime: _payload.openingTime ? _payload.openingTime : null,
        closingTime: _payload.closingTime ? _payload.closingTime : null,
        photoReferenceId: _payload.photoReferenceId
          ? _payload.photoReferenceId
          : null,
        photoReference: _payload.photoReference
          ? _payload.photoReference
          : null,
        isActive: _payload.isActive,
      };
    });
    delete _payload.locationType;

    // manually add the contact person role of "main contact" to the payload if the contact personnel payload is filled
    if (
      _payload.partyId &&
      _payload.newContactPerson?.firstName &&
      _payload.newContactPerson?.emailAddress
    ) {
      _payload.newContactPerson.roleId = mainContactID;
      _payload.newContactPerson.partyId = _payload.partyId;
    }

    _payload = {
      location: _payload,
      berthsAndWarehouses: _payload.berthsAndWarehouses || [],
      newContactPerson: _payload.newContactPerson || {},
    };

    let contactInfo;
    if (isEdit) {
      contactInfo = _payload.location.newContactPerson || {};
    }

    delete _payload.location.berthsAndWarehouses;
    delete _payload.location.newContactPerson;
    if (!_payload.newContactPerson?.firstName || isEdit) {
      delete _payload.newContactPerson;
    }
    if (!_payload.berthsAndWarehouses?.length) {
      delete _payload.berthsAndWarehouses;
    }

    setSaving(true);

    let response;
    if (!isEdit) {
      response =
        __type.toLowerCase() == "zone"
          ? await LocationService.postZone({
              location: _payload.location,
              coordinates: mapArea.map((e, index) => ({
                Latitude: e[0],
                Longitude: e[1],
                DrawOrder: index,
              })),
            })
          : await LocationService.postZone(_payload);
    } else {
      const apiPayload = {
        ..._payload,
        childrenToRemove: berthsOrWarehousesToBeDeleted,
        base64Photo: _payload.base64Photo,
      };
      // const apiPayload = {
      //   location: { ..._payload },
      //   base64Photo: _payload.base64Photo,
      // };

      // old comment =======>
      // response = await LocationService.update(_payload.locationId, {
      //   location: { ..._payload },
      //   base64Photo: _payload.base64Photo,
      // });
      // old comment =======>

      delete apiPayload?.location?.base64Photo;
      // response = await LocationService.update(_payload.locationId, apiPayload);
      response = await LocationService.update(
        _payload.location.locationId,
        apiPayload
      );

      if (!response?.error && response !== null) {
        await updateContactPersonnel(_payload.location.locationId, contactInfo);
      }

      // old comment =======>
      // __type.toLowerCase() == "zone"
      //   ? await LocationService.update(_payload.locationId, {
      //       // location: _payload,
      //       ..._payload,
      //       coordinates: newZoneCoordinates,
      //     })
      // : await LocationService.update(_payload.locationId, _payload);
      // old comment =======>
    }
    if (!response?.error && response !== null) {
      setPayload({});
      isEdit ? await init() : setRedirectUrl("/locations");
      setLoading(false);
      if (!tempPayload) {
        toastr(
          "success",
          isEdit
            ? "Location updated successfully"
            : "Location added successfully"
        );
      }
    } else {
      setLoading(true);
      if (
        response?.status === 500 &&
        response?.data?.toLowerCase()?.includes("duplicate entry")
      ) {
        toastr("error", "Duplicate entry. This location already exists.");
      } else {
        toastr(
          "error",
          isEdit
            ? "Location couldn't be updated. Please try again."
            : "Location  couldn't be added. Please try again."
        );
      }
      await init();
      setLoading(false);
    }
    setSaving(false);
  };

  const addPartyModalCallback = async (partyAndContactPerson) => {
    if (partyAndContactPerson?.party) {
      const _partyWithContactList =
        await PartyService.getPartyWithContactPerson(
          partyAndContactPerson.party.partyId ||
            partyAndContactPerson.party.PartyId
        );
      const _selectedContactPerson = _partyWithContactList &&
        _partyWithContactList.ContactPersons && {
          ..._partyWithContactList.ContactPersons[0],
        };
    }
    await retrieveParty();
  };

  const retrieveParty = async () => {
    const party_List = await PartyService.listParty();
    setParty(party_List);
    return party_List;
  };

  const filterPartyDropdown = (locationType, fullPartyList) => {
    // party mapping of what party can manage what installation
    const partyMapping = {
      // operators
      operator: {
        fpso: "04107827-d77d-11ec-b918-85abc6df0ea5",
        fso: "04355db2-d77d-11ec-b918-85abc6df0ea5",
        "onshore terminal": "047e93b3-d77d-11ec-b918-85abc6df0ea5",
        "offshore terminal": "04a34193-d77d-11ec-b918-85abc6df0ea5",
        "offshore installation": "0efe7186-17ca-4fb9-b1a6-a43f58101c73",
      },

      // Port authority
      "port authority": {
        port: "96ac9c6a-38a3-4366-a1ba-7f58eef26f33",
      },

      // terminal managers
      "terminal manager": {
        "port terminal": "8d048204-c716-4ae0-8bfd-2fba41205533",
        "logistics base": "045a592f-d77d-11ec-b918-85abc6df0ea5",
      },
    };

    const _partyList = fullPartyList || partyList;

    // this filters the location managers dropdown to show only the managers belonging to the selected location type (derived from the mapping above)
    let list = _partyList.filter((party) => {
      return (
        !!partyMapping[party.PartyType?.toLowerCase()] &&
        !!partyMapping[party.PartyType.toLowerCase()][
          locationType?.toLowerCase()
        ]
      );
    });
    //sorting party name by alphabetical order irrespective of the casing
    list.sort((a, b) => {
      if (a.PartyName.toLowerCase() < b.PartyName.toLowerCase()) return -1;
      if (a.PartyName.toLowerCase() > b.PartyName.toLowerCase()) return 1;
      return 0;
    });
    setFilteredParty(list || []);
  };

  const mapChanged = async (data) => {
    if (data && data.length > 0) {
      setMapData(data);
      const _mapArea = [];
      data.forEach((c) => {
        _mapArea.push([c.lat, c.lng]);
      });
      setMapArea(_mapArea);

      const newCoordinates = _mapArea.map((e, index) => ({
        Latitude: e[0],
        Longitude: e[1],
        DrawOrder: index,
      }));

      setNewZoneCoordinates(newCoordinates);
      setLoading(true);

      // update zone location only if editing
      if (isEdit) {
        try {
          await LocationService.updateZoneCoordinates(
            params.locationId,
            newCoordinates
          );
          toastr("success", "Coordinates updated");
        } catch (e) {
          toastr("error", "Coordinates couldn't be updated. Please try again");
        }
      }
      setLoading(false);
    } else {
      setMapData(null);
      setMapArea(null);
    }
  };

  const mapDone = () => {
    if (mapData) {
      setMapCreating(false);
    }
  };

  const updateContactPersonnel = async (id, payload) => {
    const contactInfo = editData?.contactPerson;
    if (
      contactInfo &&
      (contactInfo.firstName !== payload.firstName ||
        contactInfo.lastName !== payload.lastName ||
        contactInfo.emailAddress !== payload.emailAddress ||
        contactInfo.phoneNumber !== payload.phoneNumber)
    ) {
      return await ContactPersonService.postEditedContactPerson(id, payload);
    }
  };

  const onChangeLocationType = (event) => {
    // delete every berth and warehouse in this location from the backend (the ones with IDs mean that they have been stored on the backend)
    let entriesToBeDeleted = [];
    berths.map((berth) =>
      berth.locationId ? entriesToBeDeleted.push(berth.locationId) : ""
    );
    warehouses.map((warehouse) =>
      warehouse.locationId ? entriesToBeDeleted.push(warehouse.locationId) : ""
    );

    setBerthsOrWarehousesToBeDeleted((prev) => [
      ...prev,
      ...entriesToBeDeleted,
    ]);

    setSelectedPartyId(null);
    setFilteredParty([]);
    setSelectedSubPortEntry({});
    setAddBerthOrWarehouse(false);
    setBerths([]);
    setWarehouses([]);
    const _payload = addPayload(
      [
        "locationTypeId",
        "locationType",
        "parentLocationId",
        "partyId",
        "partyName",
        "regionId",
        "city",
        "newContactPerson",
      ],
      [event.value, event.label, null, null, null, null, null, {}]
    );
    setSelectedLocationTypeParentId(event.ParentLocationTypeId);
    if (
      isEdit &&
      event.label == "Zone" &&
      editData?.locationTypeName?.toLowerCase() !== "zone"
    ) {
      setLoading(true);
      submitForm(_payload);
    }

    filterPartyDropdown(event.label);
  };

  const disableParentLocation = useCallback(() => {
    return (
      !payload.locationType ||
      payload.locationType?.toLowerCase() == "zone" ||
      payload.locationType?.toLowerCase() == "oml"
    );
  }, [payload.locationType]);

  const pageActions = (
    <div className="toggle-wrap nk-block-tools-toggle">
      <a
        href="#"
        className="btn btn-icon btn-trigger toggle-expand mr-n1"
        data-target="pageMenu"
      >
        <em className="icon ni ni-menu-alt-r" />
      </a>
      <div className="toggle-expand-content" data-content="pageMenu">
        <ul className="nk-block-tools g-3">
          {checkUserPermission("location_add_new_location") && !loading && (
            <li>
              <a
                role="button"
                className="btn btn-dim btn-secondary"
                onClick={() => setRelocationModalIsClosed(false)}
              >
                <em className="icon ni ni-file-docs" />
                <span>Relocate</span>
              </a>
            </li>
          )}
        </ul>
      </div>
    </div>
  );

  const saveRelocationInfo = async () => {
    const _payload = {
      locationId: payload.locationId,
      longitude: payload.longitude,
      latitude: payload.latitude,
      parentLocationId: payload.parentLocationId,
      countryId: payload.countryId,
      regionId: payload.regionId,
      city: payload.city,
      relocationDate: payload.dateMoved,
    };

    const _relocationPayload = {
      longitude: relocationPayload.longitude,
      latitude: relocationPayload.latitude,
      parentLocationId: relocationPayload.parentLocationId,
      countryId: relocationPayload.countryId,
      regionId: relocationPayload.regionId,
      city: relocationPayload.city,
      relocationDate: relocationPayload.dateMoved,
    };

    if (!_relocationPayload.longitude) {
      toastr("error", "Enter a new longitude");
      return;
    }

    if (!_relocationPayload.latitude) {
      toastr("error", "Enter a new latitude");
      return;
    }

    if (!_relocationPayload.relocationDate) {
      toastr("error", "Select a date for relocation");
      return;
    }

    setSaving(true);

    const response = await LocationService.changeLocationPoint(_payload);
    if (response) {
      setPayload({});
      setRelocationPayload({});
      toastr("success", "Location moved successfully");
      setRelocationModalIsClosed(true);
      setRedirectUrl("/locations");
      // setRedirectUrl(`/edit-location/${response.newLocation.LocationId}`);
      // await init();
    } else {
      toastr("error", "Location was not moved");
    }
    setSaving(false);
  };

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout
      title={`${isEdit ? "Edit" : "Add"} Location`}
      loading={loading}
      showFullLoader={loading}
      pageActions={isEdit && moveableLocationType == true ? pageActions : null}
    >
      <ul className="nav nav-tabs mt-n3 ml-3">
        {isEdit && moveableLocationType == true ? (
          <li className="nav-item">
            <a
              onClick={() => setTab(1)}
              className={`nav-link ${tab == 1 && "active"}`}
            >
              <em className="icon ni ni-file-docs" />
              <span> Edit</span>
            </a>
          </li>
        ) : null}

        {isEdit && moveableLocationType == true && (
          <li className="nav-item">
            <a
              onClick={() => setTab(2)}
              className={`nav-link ${tab == 2 && "active"}`}
            >
              <em className="icon ni ni-view-grid-wd" />
              <span> Location History</span>
            </a>
          </li>
        )}
      </ul>
      {!loading && tab === 1 && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm();
          }}
        >
          <div className="card-inner">
            <div>
              <div className="row">
                <div className="col-12">
                  <p>
                    <span className="preview-title-lg overline-title">
                      Location Details
                    </span>{" "}
                  </p>
                </div>

                <div className="col-sm-4 col-lg-3 mb-3">
                  <div className="form-group" style={{ zIndex: 12 }}>
                    <label className="form-label small">Location Type</label>
                    <div className="form-control-wrap">
                      <Select
                        onChange={onChangeLocationType}
                        isDisabled={isEdit}
                        value={locationTypes
                          .map((f) => ({
                            ...f,
                            label: f.LocationType,
                            value: f.LocationTypeId,
                          }))
                          .find((a) => a.value == payload?.locationTypeId)}
                        options={locationTypes.map((f) => ({
                          ...f,
                          label: f.LocationType,
                          value: f.LocationTypeId,
                        }))}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 col-lg-3 mb-3">
                  <div className="form-group" style={{ zIndex: 12 }}>
                    <label className="form-label small">Parent location</label>
                    <div className="form-control-wrap">
                      <Select
                        onChange={(item) =>
                          addPayload("parentLocationId", item.value)
                        }
                        value={locations
                          .map((f) => ({
                            ...f,
                            label: f.LocationName,
                            value: f.LocationId,
                          }))
                          .find((a) => a.value == payload?.parentLocationId)}
                        options={locations
                          .filter(
                            (location) =>
                              location.LocationTypeId ===
                              selectedLocationTypeParentId
                          )
                          .map((filteredLocation) => ({
                            ...filteredLocation,
                            label:
                              filteredLocation.LocationName ||
                              filteredLocation.locationName,
                            value:
                              filteredLocation.LocationId ||
                              filteredLocation.locationId,
                          }))}
                        isDisabled={
                          disableParentLocation() ||
                          (isEdit && moveableLocationType == true)
                        }
                        key={`parent-location-${payload?.parentLocationId}`}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="form-group ">
                    <label className="form-label small">Location Name</label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control required"
                        required
                        value={payload.locationName}
                        onChange={(e) =>
                          addPayload("locationName", e.target.value)
                        }
                      />
                      <Validator
                        data={payload.locationName}
                        errorMessage="Enter location name"
                        groupName={validationGroup}
                        dataKey={"locationName"}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="form-group mb-3">
                    <label className="form-label small">
                      Location Short name (3 letter shortcode)
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control required"
                        maxLength="3"
                        minLength="3"
                        required
                        value={payload.shortName}
                        onChange={(e) =>
                          addPayload("shortName", e.target.value)
                        }
                      />
                      <Validator
                        data={payload.shortName}
                        errorMessage="Enter 3 letter short name"
                        groupName={validationGroup}
                        dataKey={"shortName"}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4 mb-3">
                  <div className="form-group" style={{ zIndex: 11 }}>
                    <label className="form-label small">Country</label>
                    <div className="form-control-wrap">
                      <Select
                        onChange={(item) => {
                          addPayload("countryId", item.value);
                          loadRegions(item.value);
                        }}
                        value={countries.find(
                          (a) => a.value == payload?.countryId
                        )}
                        options={countries}
                        isDisabled={isEdit && moveableLocationType == true}
                      />
                    </div>
                  </div>
                </div>
                {payload.locationType?.toLowerCase() === "port" && (
                  <div className="col-sm-4 col-lg-3 mb-3">
                    <div className="form-group" style={{ zIndex: 11 }}>
                      <label className="form-label small">State / region</label>
                      <div className="form-control-wrap">
                        <Select
                          onChange={(item) =>
                            addPayload("regionId", item.value)
                          }
                          value={regions.find(
                            (a) => a.value == payload?.regionId
                          )}
                          options={regions}
                          isDisabled={
                            (isEdit && moveableLocationType == true) ||
                            payload.locationType === "Zone" ||
                            payload.locationType === "Offshore Installation"
                              ? true
                              : false
                          }
                          key={`region-${payload?.parentLocationId}`}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {payload.locationType?.toLowerCase() === "port" && (
                  <div className="col-sm-4 col-lg-3">
                    <div className="form-group ">
                      <label className="form-label small">City</label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className="form-control required"
                          required
                          value={payload.city}
                          onChange={(e) => addPayload("city", e.target.value)}
                          disabled={
                            (isEdit && moveableLocationType == true) ||
                            payload.locationType === "Zone" ||
                            payload.locationType === "Offshore Installation"
                              ? true
                              : false
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {(filteredPartyList.length || payload.partyId) && (
                <div className="row">
                  <div className="col-12">
                    <p>
                      <span className="preview-title-lg overline-title">
                        Location Management
                      </span>{" "}
                    </p>
                  </div>
                  <div className="col-md-4 mb-4">
                    <div className="form-group" style={{ zIndex: 10 }}>
                      <label className="form-label small">
                        Location manager
                      </label>
                      <div className="form-control-wrap">
                        <Select
                          options={filteredPartyList.map((e) => ({
                            ...e,
                            label: e.PartyName,
                            value: e.PartyId,
                          }))}
                          value={filteredPartyList
                            .map((e) => ({
                              ...e,
                              label: e.PartyName,
                              value: e.PartyId,
                            }))
                            .find((a) => a.value == selectedPartyId)}
                          onChange={(p) => addPayload("partyId", p.PartyId)}
                          key={`location-manager-${payload?.locationTypeId}`}
                          id="location-manager-select"
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-sm-4 col-lg-3 mb-3"
                    style={{ paddingTop: "8px" }}
                  >
                    <div className="form-control-wrap">
                      <PartyModal
                        callback={async (_party_contact) =>
                          await addPartyModalCallback(_party_contact)
                        }
                      />
                    </div>
                  </div>

                  <div className="row col-12">
                    <div className="col-12">
                      <p>
                        <span className="preview-title-lg overline-title">
                          Contact Personnel
                        </span>{" "}
                      </p>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group ">
                        <label className="form-label small">First Name</label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control required"
                            required
                            value={payload?.newContactPerson?.firstName}
                            onChange={(e) =>
                              addPayload("newContactPerson", {
                                ...payload.newContactPerson,
                                firstName: e.target.value,
                              })
                            }
                          />
                          <Validator
                            data={payload?.newContactPerson?.firstName}
                            errorMessage="Enter person's name"
                            groupName={validationGroup}
                            dataKey={"firstName"}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group ">
                        <label className="form-label small">Last Name</label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control required"
                            required
                            value={payload?.newContactPerson?.lastName}
                            onChange={(e) =>
                              addPayload("newContactPerson", {
                                ...payload.newContactPerson,
                                lastName: e.target.value,
                              })
                            }
                          />
                          <Validator
                            data={payload?.newContactPerson?.lastName}
                            errorMessage="Enter person's Last name"
                            groupName={validationGroup}
                            dataKey={"lastName"}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group ">
                        <label className="form-label small">Email</label>
                        <div className="form-control-wrap">
                          <input
                            type="email"
                            className="form-control required"
                            required
                            value={payload?.newContactPerson?.emailAddress}
                            onChange={(e) =>
                              addPayload("newContactPerson", {
                                ...payload.newContactPerson,
                                emailAddress: e.target.value,
                              })
                            }
                          />
                          <Validator
                            data={payload?.newContactPerson?.emailAddress}
                            errorMessage="Enter person's email"
                            groupName={validationGroup}
                            dataKey={"emailAddress"}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group ">
                        <label className="form-label small">Phone</label>
                        <div className="form-control-wrap">
                          <input
                            type="number"
                            className="form-control required"
                            required
                            value={payload?.newContactPerson?.phoneNumber}
                            onChange={(e) =>
                              addPayload("newContactPerson", {
                                ...payload.newContactPerson,
                                phoneNumber: e.target.value,
                              })
                            }
                          />
                          <Validator
                            data={payload?.newContactPerson?.phoneNumber}
                            errorMessage="Enter person's phone number"
                            groupName={validationGroup}
                            dataKey={"phoneNumber"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="row mt-4 align-items-center">
                <div className="col-12">
                  <p>
                    <span className="preview-title-lg overline-title">
                      Availability
                    </span>{" "}
                  </p>
                </div>
                <div className="col-sm-4">
                  <div className="form-group row align-items-center">
                    <label className="form-label col-auto">
                      Open 24 hours?
                    </label>
                    <div className="form-control-wrap col">
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customSwitch24Hours"
                          checked={!!payload.isOpen24Hours}
                          onChange={() =>
                            addPayload(
                              "isOpen24Hours",
                              payload.isOpen24Hours ? false : true
                            )
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customSwitch24Hours"
                        >
                          {payload.isOpen24Hours ? "Yes" : "No"}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {payload.isOpen24Hours || (
                  <div className="col-sm-4 col-md-4 col-lg-4">
                    <div className="form-group row">
                      <div className="col">
                        <label className="form-label small">Opening time</label>
                        <div className="form-control-wrap">
                          <input
                            type="time"
                            className="form-control required"
                            required
                            value={payload.openingTime}
                            onChange={(e) =>
                              addPayload("openingTime", e.target.value)
                            }
                          />
                          <Validator
                            data={payload.openingTime}
                            errorMessage="Required"
                            groupName={validationGroup}
                            dataKey={"openingTime"}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <label className="form-label small">Closing time</label>
                        <div className="form-control-wrap">
                          <input
                            type="time"
                            className="form-control required"
                            required
                            value={payload.closingTime}
                            onChange={(e) =>
                              addPayload("closingTime", e.target.value)
                            }
                          />
                          <Validator
                            data={payload.closingTime}
                            errorMessage="Required"
                            groupName={validationGroup}
                            dataKey={"closingTime"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="row mt-4 align-items-center d-none">
                <div className="col-12">
                  <p>
                    <span className="preview-title-lg overline-title">
                      Coordinates{" "}
                      { payload?.locationType == "Zone" && <em
                        data-tip
                        data-for="mapInstructions"
                        class="icon ni ni-help-fill text-primary fs-16px"
                      ></em>}
                    </span>{" "}
                   {payload?.locationType == "Zone" &&  <span style={{ zIndex: 9999, position: "relative" }}>
                      <ReactTooltip id="mapInstructions">
                        ~ Press the "Draw a polygon" in the upper right corner
                        to start creating the zone.
                        <br />
                        ~ Click on the various points on the map that mark the
                        outer boundaries of the zone.
                        <br />
                        ~ Press the "Edit layers" to update an existing zone.
                        <br />
                        ~ Press "Delete layers" to delete an existing zone.
                        <br />~ Click the fullscreen icon below the minus icon
                        to maximize the map.
                      </ReactTooltip>
                    </span>}
                  </p>
                </div>
                <div className="col-sm-4 col-md-4 col-lg-4">
                  {/* {`${payload.locationType}`.toLowerCase() == "zone" && (
                    <div className="col-sm-12 col-md-8 col-lg-8">
                      <div className="form-group row">
                        <div className="col">
                          <MapView2
                            onChange={(d) => mapChanged(d)}
                            height="300px"
                            mapArea={mapArea}
                            isCreateMode={false}
                          />
                        </div>
                      </div>
                    </div>
                  )} */}

                  <div className="form-group row">
                    {payload?.locationType == "Zone" ? (
                      <div
                        className="col"
                        style={{ zIndex: 9999, position: "relative" }}
                      >
                        {
                          <div className="col-12">
                            <div className="form-group row">
                              <div className="col">
                                <MapView2
                                  onChange={(d) => mapChanged(d)}
                                  height="300px"
                                  mapArea={mapArea}
                                  isCreateMode={false}
                                  markerOnClick={(loc) =>
                                    setSelectedMarker(loc[2])
                                  }
                                  useChildren={true}
                                  markerPositions={offshoreInstallationsCoords}
                                >
                                  {selectedMarker && (
                                    <Popup
                                      position={[
                                        selectedMarker.Latitude,
                                        selectedMarker.Longitude,
                                      ]}
                                      onClose={() => {
                                        setSelectedMarker(null);
                                      }}
                                    >
                                      <div>
                                        <h5>
                                          Location Name:{" "}
                                          {selectedMarker.LocationName}
                                        </h5>
                                        <p>
                                          Type :{" "}
                                          {selectedMarker?.LocationTypeName}
                                        </p>
                                        <p>
                                          Party :{" "}
                                          {selectedMarker?.PartyName || "N/A"}
                                        </p>
                                        <p>
                                          Status :{" "}
                                          {selectedMarker?.IsActive
                                            ? "Active"
                                            : "Inactive" || "N/A"}
                                        </p>
                                      </div>
                                    </Popup>
                                  )}
                                </MapView2>
                              </div>
                            </div>

                            {mapArea && (
                              <span className="text-muted small">
                                Zone area coordinates have been set
                              </span>
                            )}
                          </div>
                        }
                        {/* <button
                          style={{ border: "0" }}
                          type="button"
                          className={`btn btn-sm btn-outline-${
                            mapData ? "danger" : "primary"
                          } mt-1`}
                          onClick={() => setModalIsClosed(false)}
                        >
                          <em className="icon ni ni-map-pin-fill mr-2"></em>{" "}
                          {mapData ? "Change" : "Define"} zone coordinates
                        </button> */}
                      </div>
                    ) : (
                      <>
                        <div className="col">
                          <label className="form-label small">
                            Longitude{" "}
                            <em
                              data-tip
                              data-for="longitudeTip"
                              class="icon ni ni-help-fill text-primary fs-16px"
                            ></em>
                          </label>
                          <div className="form-control-wrap">
                            <ReactTooltip id="longitudeTip">
                              The Correct Longitude format should be <br />a
                              maximum of three whole number digits
                              <br />
                              (between -180 and 180) and not more than six (6)
                              <br />
                              decimal places, i.e -179.999999, 179.999999.
                            </ReactTooltip>
                            {/* <ReactTooltip id="longitudeTip">The Correct Longitude format should be 13 characters long<br /> i.e -189.00101010, 120.010110101.
                                                </ReactTooltip> */}
                            <input
                              type="number" step="any"
                              data-tip
                              data-for="longitudeTip"
                              className="form-control required"
                              required
                              value={payload.longitude}
                              onChange={(e) =>
                                addPayload("longitude", e.target.value)
                              }
                              disabled={isEdit && moveableLocationType == true}
                            />
                          </div>
                        </div>
                        <div className="col">
                          <label className="form-label small">
                            Latitude{" "}
                            <em
                              data-tip
                              data-for="longitudeTip"
                              class="icon ni ni-help-fill text-primary fs-16px"
                            ></em>
                          </label>
                          <div className="form-control-wrap">
                            <ReactTooltip id="latitudeTip">
                              The Correct Latitude format should be <br />a
                              maximum of two whole number digits
                              <br />
                              (between -90 and 90) and not more than six (6)
                              <br />
                              decimal places, i.e -89.999999, 89.999999.
                            </ReactTooltip>
                            {/* <ReactTooltip id="latitudeTip">The Correct Latitude format should be 12 characters long<br/> i.e -89.00101010, 90.010110101.
                                            </ReactTooltip> */}
                            <input
                              type="number"  step="any"
                              data-tip
                              data-for="latitudeTip"
                              className="form-control required"
                              required
                              value={payload.latitude}
                              onChange={(e) =>
                                addPayload("latitude", e.target.value)
                              }
                              disabled={isEdit && moveableLocationType == true}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="col-sm-12 mt-2">
                  <div className="form-group col-md-4 pl-0">
                    <>
                      <label className="form-label small">Upload Photo</label>
                      <FileInput
                        onChange={(file) => {
                          // isEdit ? "base64Photo" : "photoReferenceId",
                          addPayload(
                            [
                              isEdit ? "base64Photo" : "photoReference",
                              "fileName",
                            ],
                            [file.base64, `${file.name}`]
                          );
                        }}
                        fileValue={{
                          name: payload.fileName,
                          size: payload.fileContent?.length || 0,
                          fileContent: payload.fileContent,
                          fileRef: payload.photoReferenceId,
                          // fileRef: payload.photoReference,
                          // fileRef: payload.PhotoUrl,
                        }}
                      />
                    </>
                  </div>
                </div>
              </div>

              <div className="row mt-4 align-items-center">
                <div className="col-12">
                  <p>
                    <span className="preview-title-lg overline-title">
                      Location Status
                    </span>{" "}
                  </p>
                </div>
                <div className="col-sm-4">
                  <div className="form-group row align-items-center">
                    <label className="form-label col-auto">
                      Is location currently active?
                    </label>
                    <div className="form-control-wrap col">
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customSwitchActive"
                          checked={!!payload.isActive}
                          onChange={() =>
                            addPayload(
                              "isActive",
                              payload.isActive ? false : true
                            )
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customSwitchActive"
                        >
                          {payload.isActive ? "Yes" : "No"}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-4 align-items-center">
                <div className="col-sm-12 mb-5">
                  <div className="form-group col-md-4 pl-0 ">
                    <>
                      <label className="form-label small">Upload Photo</label>
                      <FileInput
                        onChange={(file) => {
                          // isEdit ? "base64Photo" : "photoReferenceId",
                          addPayload(
                            [
                              isEdit ? "base64Photo" : "photoReference",
                              "fileName",
                            ],
                            [file.base64, `${file.name}`]
                          );
                        }}
                        fileValue={{
                          name: payload.fileName,
                          size: payload.fileContent?.length || 0,
                          fileContent: payload.fileContent,
                          fileRef: payload.photoReferenceId,
                          // fileRef: payload.photoReference,
                          // fileRef: payload.PhotoUrl,
                        }}
                      />
                    </>
                  </div>
                </div>
                <div className="col-12">
                  <p>
                    <span className="preview-title-lg overline-title">
                      Coordinates{" "}
                      { payload?.locationType == "Zone" && <em
                        data-tip
                        data-for="mapInstructions"
                        class="icon ni ni-help-fill text-primary fs-16px"
                      ></em>}
                    </span>{" "}
                    { payload?.locationType == "Zone" && <span style={{ zIndex: 9999, position: "relative" }}>
                      <ReactTooltip id="mapInstructions">
                        ~ Press the "Draw a polygon" in the upper right corner
                        to start creating the zone.
                        <br />
                        ~ Click on the various points on the map that mark the
                        outer boundaries of the zone.
                        <br />
                        ~ Press the "Edit layers" to update an existing zone.
                        <br />
                        ~ Press "Delete layers" to delete an existing zone.
                        <br />~ Click the fullscreen icon below the minus icon
                        to maximize the map.
                      </ReactTooltip>
                    </span>}
                  </p>
                </div>
                <div className="col-sm-4 col-md-4 col-lg-4">
                  {/* {`${payload.locationType}`.toLowerCase() == "zone" && (
                    <div className="col-sm-12 col-md-8 col-lg-8">
                      <div className="form-group row">
                        <div className="col">
                          <MapView2
                            onChange={(d) => mapChanged(d)}
                            height="300px"
                            mapArea={mapArea}
                            isCreateMode={false}
                          />
                        </div>
                      </div>
                    </div>
                  )} */}

                  <div className="form-group row">
                    {payload?.locationType == "Zone" ? (
                      <div
                        className="col"
                        style={{ zIndex: 9999, position: "relative" }}
                      >
                        {
                          <div className="col-12">
                            <div className="form-group row">
                              <div className="col">
                                <MapView2
                                  onChange={(d) => mapChanged(d)}
                                  height="300px"
                                  mapArea={mapArea}
                                  isCreateMode={false}
                                  markerOnClick={(loc) =>
                                    setSelectedMarker(loc[2])
                                  }
                                  useChildren={true}
                                  markerPositions={offshoreInstallationsCoords}
                                >
                                  {selectedMarker && (
                                    <Popup
                                      position={[
                                        selectedMarker.Latitude,
                                        selectedMarker.Longitude,
                                      ]}
                                      onClose={() => {
                                        setSelectedMarker(null);
                                      }}
                                    >
                                      <div>
                                        <h5>
                                          Location Name:{" "}
                                          {selectedMarker.LocationName}
                                        </h5>
                                        <p>
                                          Type :{" "}
                                          {selectedMarker?.LocationTypeName}
                                        </p>
                                        <p>
                                          Party :{" "}
                                          {selectedMarker?.PartyName || "N/A"}
                                        </p>
                                        <p>
                                          Status :{" "}
                                          {selectedMarker?.IsActive
                                            ? "Active"
                                            : "Inactive" || "N/A"}
                                        </p>
                                      </div>
                                    </Popup>
                                  )}
                                </MapView2>
                              </div>
                            </div>

                            {mapArea && (
                              <span className="text-muted small">
                                Zone area coordinates have been set
                              </span>
                            )}
                          </div>
                        }
                        {/* <button
                          style={{ border: "0" }}
                          type="button"
                          className={`btn btn-sm btn-outline-${
                            mapData ? "danger" : "primary"
                          } mt-1`}
                          onClick={() => setModalIsClosed(false)}
                        >
                          <em className="icon ni ni-map-pin-fill mr-2"></em>{" "}
                          {mapData ? "Change" : "Define"} zone coordinates
                        </button> */}
                      </div>
                    ) : (
                      <>
                        <div className="col">
                          <label className="form-label small">
                            Longitude{" "}
                            <em
                              data-tip
                              data-for="longitudeTip"
                              class="icon ni ni-help-fill text-primary fs-16px"
                            ></em>
                          </label>
                          <div className="form-control-wrap">
                            <ReactTooltip id="longitudeTip">
                              The Correct Longitude format should be <br />a
                              maximum of three whole number digits
                              <br />
                              (between -180 and 180) and not more than six (6)
                              <br />
                              decimal places, i.e -179.999999, 179.999999.
                            </ReactTooltip>
                            {/* <ReactTooltip id="longitudeTip">The Correct Longitude format should be 13 characters long<br /> i.e -189.00101010, 120.010110101.
                                                </ReactTooltip> */}
                            <input
                              type="number"  step="any"
                              data-tip
                              data-for="longitudeTip"
                              className="form-control required"
                              required
                              value={payload.longitude}
                              onChange={(e) =>
                                addPayload("longitude", e.target.value)
                              }
                              disabled={isEdit && moveableLocationType == true}
                            />
                          </div>
                        </div>
                        <div className="col">
                          <label className="form-label small">
                            Latitude{" "}
                            <em
                              data-tip
                              data-for="longitudeTip"
                              class="icon ni ni-help-fill text-primary fs-16px"
                            ></em>
                          </label>
                          <div className="form-control-wrap">
                            <ReactTooltip id="latitudeTip">
                              The Correct Latitude format should be <br />a
                              maximum of two whole number digits
                              <br />
                              (between -90 and 90) and not more than six (6)
                              <br />
                              decimal places, i.e -89.999999, 89.999999.
                            </ReactTooltip>
                            {/* <ReactTooltip id="latitudeTip">The Correct Latitude format should be 12 characters long<br/> i.e -89.00101010, 90.010110101.
                                            </ReactTooltip> */}
                            <input
                              type="number"  step="any"
                              data-tip
                              data-for="latitudeTip"
                              className="form-control required"
                              required
                              value={payload.latitude}
                              onChange={(e) =>
                                addPayload("latitude", e.target.value)
                              }
                              disabled={isEdit && moveableLocationType == true}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>

              {/* berths and warehouses for ports  */}
              {(payload?.locationType?.toLowerCase() === "port terminal" ||
                payload?.locationType?.toLowerCase() === "logistics base") && (
                <div className="row mt-4 align-items-center">
                  {/* berths */}
                  <div className="col-sm-5">
                    <div className="">
                      <p>
                        <span className="preview-title-lg overline-title">
                          Berths
                        </span>{" "}
                      </p>
                    </div>

                    {!berths.length && !addBerthOrWarehouse ? (
                      <div>
                        <p>No berth added</p>
                      </div>
                    ) : (
                      berths.map((data, index) => (
                        <div className="">
                          <div className="form-group row align-items-center">
                            <div className="form-group col">
                              {/* view berths */}

                              {/* {editBerthOrWarehouse && */}
                              {!addBerthOrWarehouse &&
                              selectedSubPortEntry.type === "berth" &&
                              selectedSubPortEntry.index === index ? (
                                <AddBerthOrWarehouseInput
                                  selectedEntry={selectedSubPortEntry}
                                  setSelectedEntry={setSelectedSubPortEntry}
                                  saveEntry={saveWarehouseOrBerthChanges}
                                  discardChanges={
                                    discardWarehouseOrBerthChanges
                                  }
                                />
                              ) : (
                                <BerthOrWarehouseItem
                                  type="berth"
                                  index={index}
                                  entry={data}
                                  // setSelectedEntry={setSelectedSubPortEntry}
                                  deleteEntry={deleteWarehouseOrBerth}
                                  editEntry={editWarehouseOrBerth}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      ))
                    )}

                    {/* add new berth */}
                    {addBerthOrWarehouse &&
                    selectedSubPortEntry.type === "berth" ? (
                      <AddBerthOrWarehouseInput
                        selectedEntry={selectedSubPortEntry}
                        setSelectedEntry={setSelectedSubPortEntry}
                        saveEntry={saveWarehouseOrBerthChanges}
                        discardChanges={discardWarehouseOrBerthChanges}
                      />
                    ) : (
                      <div
                        className="d-flex  align-center mt-2"
                        onClick={() => addNewarehouseOrBerth("berth")}
                      >
                        <em
                          class="icon ni ni-plus-sm text-primary fs-20px pointer mr-1"
                          title="Add berth"
                        ></em>
                        <a
                          href="javascript:void(0)"
                          className="mb-0 text-primary"
                        >
                          Add a berth
                        </a>
                      </div>
                    )}
                  </div>

                  {/* warehouses */}
                  <div className="col-sm-5">
                    <div className="">
                      <p>
                        <span className="preview-title-lg overline-title">
                          Warehouses
                        </span>{" "}
                      </p>
                    </div>

                    {!warehouses.length && !addBerthOrWarehouse ? (
                      <div>
                        <p>No warehouse added</p>
                      </div>
                    ) : (
                      warehouses.map((data, index) => (
                        <div className="">
                          <div className="form-group row align-items-center">
                            <div className="form-group col">
                              {/* view warehouses */}

                              {!addBerthOrWarehouse &&
                              selectedSubPortEntry.type === "warehouse" &&
                              selectedSubPortEntry.index === index ? (
                                <AddBerthOrWarehouseInput
                                  selectedEntry={selectedSubPortEntry}
                                  setSelectedEntry={setSelectedSubPortEntry}
                                  saveEntry={saveWarehouseOrBerthChanges}
                                  discardChanges={
                                    discardWarehouseOrBerthChanges
                                  }
                                />
                              ) : (
                                <BerthOrWarehouseItem
                                  type="warehouse"
                                  index={index}
                                  entry={data}
                                  deleteEntry={deleteWarehouseOrBerth}
                                  editEntry={editWarehouseOrBerth}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      ))
                    )}

                    {/* add new warehouse */}
                    {addBerthOrWarehouse &&
                    selectedSubPortEntry.type === "warehouse" ? (
                      <AddBerthOrWarehouseInput
                        selectedEntry={selectedSubPortEntry}
                        setSelectedEntry={setSelectedSubPortEntry}
                        saveEntry={saveWarehouseOrBerthChanges}
                        discardChanges={discardWarehouseOrBerthChanges}
                      />
                    ) : (
                      <div
                        className="d-flex  align-center mt-2"
                        onClick={() => addNewarehouseOrBerth("warehouse")}
                      >
                        <em
                          class="icon ni ni-plus-sm text-primary fs-20px pointer mr-1"
                          title="Add warehouse"
                        ></em>
                        <a
                          href="javascript:void(0)"
                          className="mb-0 text-primary"
                        >
                          Add a warehouse
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          {saving && (
            <div className="form-group p-2 m-3">
              <button type="button" className="btn btn-md btn-link m-1">
                <Spinner size="1.5" />
              </button>
            </div>
          )}
          {!saving && (
            <div className="form-group p-2 m-3">
              <button
                type="submit"
                // type="button"
                // onClick={() => submitForm()}
                className="btn btn-md btn-dark btn-wide px-5 mr-3"
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-sm btn-link m-1"
                onClick={() => window.history.back()}
              >
                Cancel
              </button>
            </div>
          )}
        </form>
      )}

      {!loading && isEdit && moveableLocationType == true && tab === 2 && (
        <div className="card-inner p-5">
          <div className="gap" />
          <p>
            <span className="preview-title-lg overline-title">
              Location History
            </span>{" "}
          </p>
          <LocationHistoryListComponent dataList={relocationHistory} />
        </div>
      )}
      <CustomModal
        content={
          <div style={{ background: "#ffffff", textAlign: "center" }}>
            <MapView2
              onChange={(d) => mapChanged(d)}
              height="300px"
              mapArea={mapArea}
              // isCreateMode={mapCreating}
              isCreateMode={true}
            />
            <button
              type="button"
              onClick={() => {
                setModalIsClosed(true);
                mapDone();
              }}
              className="btn btn-sm btn-dark btn-wide px-4 my-4"
            >
              Continue
            </button>
          </div>
        }
        isClosed={modalIsClosed}
        title="Map zone area"
        onClose={() => {
          setModalIsClosed(true);
          mapDone();
        }}
        noContentPadding
      />
      <CustomModal
        content={
          <div className="card-inner p-4">
            <form>
              <div className="row">
                <div className="col-6">
                  <div
                    className="form-group mb-3"
                    style={{ zIndex: 12 }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <label className="form-label small">Parent location</label>
                    <small className="text-danger ml-1">*</small>
                    <div className="form-control-wrap">
                      <Select
                        onChange={(item) => {
                          addPayload("parentLocationId", item.value);
                          addRelocationPayload("parentLocationId", item.value);
                        }}
                        value={locations
                          .map((f) => ({
                            ...f,
                            label: f.LocationName,
                            value: f.LocationId,
                          }))
                          .find((a) => a.value == payload?.parentLocationId)}
                        options={locations
                          .filter(
                            (location) =>
                              location.LocationTypeId === parentLocationTypeId
                          )
                          .map((filteredLocation) => ({
                            ...filteredLocation,
                            label:
                              filteredLocation.LocationName ||
                              filteredLocation.locationName,
                            value:
                              filteredLocation.LocationId ||
                              filteredLocation.locationId,
                          }))}
                        key={`parent-location-${payload?.parentLocationId}`}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div
                    className="form-group mb-3"
                    style={{ zIndex: 11 }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <label className="form-label small">Country</label>
                    <small className="text-danger ml-1">*</small>
                    <div className="form-control-wrap">
                      <Select
                        onChange={(item) => {
                          addPayload("countryId", item.value);
                          addRelocationPayload("countryId", item.value);
                          loadRegions(item.value);
                        }}
                        value={countries.find(
                          (a) => a.value == payload?.countryId
                        )}
                        options={countries}
                      />
                    </div>
                  </div>
                </div>

                {payload.locationType?.toLowerCase() === "port" && (
                  <>
                    <div className="col-6">
                      <div
                        className="form-group mb-3"
                        style={{ zIndex: 10 }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <label className="form-label small">
                          State / region
                        </label>
                        <div className="form-control-wrap">
                          <Select
                            onChange={(item) => {
                              addPayload("regionId", item.value);
                              addRelocationPayload("regionId", item.value);
                            }}
                            value={regions.find(
                              (a) => a.value == payload?.regionId
                            )}
                            options={regions}
                            isDisabled={
                              payload.locationType === "Zone" ||
                              payload.locationType === "Offshore Installation"
                                ? true
                                : false
                            }
                            key={`region-${payload?.parentLocationId}`}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="form-group mb-3">
                        <label className="form-label small">City</label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control required"
                            required
                            value={payload.city}
                            onChange={(e) => {
                              addPayload("city", e.target.value);
                              addRelocationPayload("city", e.target.value);
                            }}
                            disabled={
                              payload.locationType === "Zone" ||
                              payload.locationType === "Offshore Installation"
                                ? true
                                : false
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className="col-6 mb-3">
                  <label className="form-label small">
                    Longitude{" "}
                    <em
                      data-tip
                      data-for="longitudeTip"
                      class="icon ni ni-help-fill text-primary fs-16px"
                    ></em>
                  </label>
                  <small className="text-danger ml-1">*</small>
                  <div className="form-control-wrap">
                    <ReactTooltip id="longitudeTip">
                      The Correct Longitude format should be <br />a maximum of
                      three whole number digits
                      <br />
                      (between -180 and 180) and not more than six (6)
                      <br />
                      decimal places, i.e -179.999999, 179.999999.
                    </ReactTooltip>
                    <input
                      type="number"  step="any"
                      data-tip
                      data-for="longitudeTip"
                      className="form-control required"
                      required
                      value={payload.longitude}
                      onChange={(e) => {
                        addPayload("longitude", e.target.value);
                        addRelocationPayload("longitude", e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="col-6 mb-3">
                  <label className="form-label small">
                    Latitude{" "}
                    <em
                      data-tip
                      data-for="longitudeTip"
                      class="icon ni ni-help-fill text-primary fs-16px"
                    ></em>
                  </label>
                  <small className="text-danger ml-1">*</small>
                  <div className="form-control-wrap">
                    <ReactTooltip id="latitudeTip">
                      The Correct Latitude format should be <br />a maximum of
                      two whole number digits
                      <br />
                      (between -90 and 90) and not more than six (6)
                      <br />
                      decimal places, i.e -89.999999, 89.999999.
                    </ReactTooltip>
                    <input
                      type="number"  step="any"
                      data-tip
                      data-for="latitudeTip"
                      className="form-control required"
                      required
                      value={payload.latitude}
                      onChange={(e) => {
                        addPayload("latitude", e.target.value);
                        addRelocationPayload("latitude", e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="col-6">
                  <label className="form-label small">Relocation Date </label>
                  <small className="text-danger ml-1">*</small>
                  <div className="form-control-wrap">
                    <input
                      type="date"
                      className="form-control required"
                      required
                      // value={toReadableDate(payload.dateMoved)}
                      onChange={(e) => {
                        addPayload("dateMoved", e.target.value);
                        addRelocationPayload("dateMoved", e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>
            <div className="gap gap-40px" />
            {saving ? (
              <div className="form-group p-2 m-3">
                <Spinner size="1.5" />
              </div>
            ) : (
              <div className="form-group">
                <button
                  type="button"
                  onClick={() => saveRelocationInfo()}
                  className="btn btn-md btn-dark btn-wide px-5 mr-3"
                >
                  Save
                </button>
              </div>
            )}
            <div className="gap gap-10px" />
          </div>
        }
        isClosed={relocationModalIsClosed}
        title="Relocate Location"
        onClose={() => {
          setRelocationModalIsClosed(true);
        }}
        noContentPadding
      />
    </MainLayout>
  );
}
