import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../../components/fileInput";
import Spinner from "../../utils/spinner";
import {
  Validator,
  ValidateGroup,
  ClearValidator,
} from "../../components/validator";
import toastr from "../../utils/toastr";
import LocationService from "../../services/locationService";
import VoyageService from "../../services/voyageService";
import ReactTooltip from "react-tooltip";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import VesselService from "../../services/vesselService";
import CommercialService from "../../services/commercialService";
import PartyService from "../../services/partyService";
import PartyModal from "../Modals/partyModal";
import CustomModal from "../../utils/customModal";
import { toReadableDate } from "../../utils/utilityFunctions";
import { forEach } from "lodash";
import {
  dateToYYYY_MM_DD,
  dateToYYYY_MM_DD_hh_mm,
} from "../../utils/utilityFunctions";
import { camelizeKeys, dynamicSort } from "../../utils/utilityFunctions";

export default function EditCommercialPage() {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [payload, setPayload] = useState({});
  const [partyTypes, setPartyTypes] = useState({});
  const [partyList, setPartyList] = useState([{}]);
  const [selectedPartyId, setSelectedPartyId] = useState(null);
  const [saving, setSaving] = useState(false);
  const [vessels, setVessels] = useState([]);
  const [tab, setTab] = useState(1);
  const [currency, setCurrency] = useState([]);
  const [contractType, setContractType] = useState([]);
  const [vesselParty, setVesselParty] = useState([]);
  const [partyNameDb, setPartyNameDb] = useState([]);
  const [vesselOwner, setVesselOwmer] = useState({
    partyName: "",
    partyId: "",
  });

  const addPayload = new PayloadUpdater({
    payload: payload,
    setPayload: setPayload,
  }).update;
  const validationGroup = "editcommercial";

  useEffect(async () => {
    setLoading(true);

    await init();

    await retrieveVessels();

    await configurations();

    await retrieveParty();

    const _partyTypes = await PartyService.listPartyTypes();
    setPartyTypes(_partyTypes || []);
    setLoading(false);
  }, []);

  const init = async () => {
    let commercial = await CommercialService.getCommercial(
      params.vesselCommercialId
    );

    commercial = camelizeKeys(commercial || {});
    setPayload(commercial || {});
    // setPartyNameDb( commercial?.partyName);
  };
  const retrieveVessels = async () => {
    let _vessels = await VesselService.listAllVessels();
    setVessels(_vessels || []);
  };

  const vesselOwnership = async (option) => {
    let _vesselPartiesList = await PartyService.listByVesselId(option);

    if (_vesselPartiesList) {
      let _vesselParty = _vesselPartiesList?.find(
        (l) => l.PartyType.toLowerCase() == "vessel owner"
      );
      // setVesselParty(_vesselParty);
      let selectedParty = _vesselParty?.Party || [];
      addPayload(
        ["ownerPartyId", "ownerPartyName", "vesselSpecificationId"],
        [selectedParty.PartyId || "", selectedParty.PartyName || "", option]
      );
    }
  };

  const configurations = async () => {
    let _configList = await CommercialService.getConfigList();

    if (_configList) {
      let _currency = _configList?.filter(
        (l) =>
          l.CorrespondingData.toLowerCase() == "usd" ||
          l.CorrespondingData.toLowerCase() == "naira" ||
          l.CorrespondingData.toLowerCase() == "gbp"
      );
      setCurrency(_currency);
    }

    if (_configList) {
      let _contractType = _configList?.filter(
        (l) =>
          l.CorrespondingData.toLowerCase() == "long term" ||
          l.CorrespondingData.toLowerCase() == "spot charter"
      );
      setContractType(_contractType);
    }
  };

  const addPartyModalCallback = async (partyAndContactPerson) => {
    if (partyAndContactPerson?.party) {
      const _partyWithContactList =
        await PartyService.getPartyWithContactPerson(
          partyAndContactPerson.party.partyId ||
          partyAndContactPerson.party.PartyId
        );
      const _selectedContactPerson = _partyWithContactList &&
        _partyWithContactList.ContactPersons && {
        ..._partyWithContactList.ContactPersons[0],
      };
    }
    await retrieveParty();
  };

  const retrieveParty = async () => {
    const party_List = await PartyService.listParty();
    setPartyList(party_List);
  };

  const renewalOptions = [
    { label: "One-off deal", value: "one_off" },
    { label: "Recurring", value: "recurring" },
    { label: "Subject to approval", value: "subject_to_approval" },
  ];

  const commercialStatusOptions = [
    { label: "Active", value: "active" },
    { label: "Future", value: "future" },
    { label: "Extended", value: "extended" },
    { label: "Cancelled", value: "cancelled" },
    { label: "Terminated", value: "terminated" },
  ];

  const submitForm = async () => {
    const _payload = { ...payload };

    if (!_payload.vesselSpecificationId) {
      toastr("error", "Vessel required");
      return;
    }

    // if (!_payload.ownerPartyId) {
    //   toastr("error", "Vessel owner required");
    //   return;
    // }

    if (!_payload.chartererPartyId) {
      toastr("error", "Select a charterer");
      return;
    }

    if (!_payload.dailyRate) {
      toastr("error", "Enter daily rate");
      return;
    }

    // if (!_payload.currencyConfigurationId) {
    //   toastr("error", "Select currency");
    //   return;
    // }

    // if (!_payload.commercialStatus) {
    //     toastr("error", "Select commercial status");
    //     return;
    // }

    if (!_payload.contractTypeConfigurationId) {
      toastr("error", "Select contract type");
      return;
    }

    if (!_payload.contractStartDate) {
      toastr("error", "Select contract start date");
      return;
    }

    if (!_payload.contractEndDate) {
      toastr("error", "Select contract end date");
      return;
    }

    if (!_payload.contractOptions) {
      toastr("error", "Select a contract option");
      return;
    }
    setSaving(true);

    delete _payload.chartererPartyName;
    delete _payload.ownerPartyName;
    delete _payload.partyName;
    delete _payload.currency;
    delete _payload.vesselName;

    const response = await CommercialService.updateCommercial(_payload);
    if (response) {
      setPayload({});
      setRedirectUrl("/commercials");
      toastr("success", "Commercial updated successfully");
    }
    setSaving(false);
  };

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout
      title="Edit Commercial"
      loading={loading}
      showFullLoader={loading}
    >
      {!loading && (
        <form>
          {tab === 1 && (
            <div className="card-inner">
              <div>
                <div className="row">
                  <div className="col-12">
                    <p>
                      <span className="preview-title-lg overline-title">
                        Vessel Details
                      </span>{" "}
                    </p>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group" style={{ zIndex: "50" }}>
                      <label className="form-label small">Vessel Name</label>
                      <div className="form-control-wrap">
                        <Select
                          value={vessels
                            .map((c) => ({
                              ...c,
                              label: c.VesselName,
                              value: c.VesselSpecificationId,
                            }))
                            .find(
                              (a) => a.value == payload?.vesselSpecificationId
                            )}
                          options={vessels.map((c) => ({
                            ...c,
                            label: c.VesselName,
                            value: c.VesselSpecificationId,
                          }))}
                          onChange={async (e) => {
                            // addPayload("vesselSpecificationId", e.value);
                            await vesselOwnership(e.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div
                      className="form-group"
                    // style={{ zIndex: "1000" }}
                    >
                      <label className="form-label small">Vessel Owner</label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className="form-control"
                          // defaultValue={payload.ownerPartyName}
                          value={payload.ownerPartyName}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <br />

                <div className="row">
                  <div className="col-12">
                    <p>
                      <span className="preview-title-lg overline-title">
                        Charterer's Information
                      </span>{" "}
                    </p>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group" style={{ zIndex: "40" }}>
                      <label className="form-label small">
                        Charterer's Name
                      </label>
                      <div className="form-control-wrap">
                        <Select
                          options={partyList.map((e) => ({
                            ...e,
                            label: e.PartyName,
                            value: e.PartyId,
                          }))}
                          value={partyList
                            .map((e) => ({
                              ...e,
                              label: e.PartyName,
                              value: e.PartyId,
                            }))
                            .find((a) => a.value == payload.chartererPartyId)}
                          onChange={(p) =>
                            addPayload("chartererPartyId", p.PartyId)
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-sm-4 col-lg-3 mb-3"
                    style={{ paddingTop: "8px" }}
                  >
                    <div className="form-control-wrap">
                      <PartyModal
                        addNewLabel="+ Add Charterer"
                        callback={async (_party_contact) =>
                          await addPartyModalCallback(_party_contact)
                        }
                      />
                    </div>
                  </div>
                </div>
                <br />

                <div className="row">
                  <div className="col-12">
                    <p>
                      <span className="preview-title-lg overline-title">
                        Contract Timeline
                      </span>{" "}
                    </p>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group" style={{ zIndex: "10" }}>
                      <label className="form-label small">
                        (Expected) Start Date
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="datetime-local"
                          value={dateToYYYY_MM_DD_hh_mm(
                            payload.contractStartDate
                          )}
                          // value={payload.contractStartDate}
                          className="form-control required"
                          onChange={(e) =>
                            addPayload("contractStartDate", e.target.value)
                          }
                        // value={dateToYYYY_MM_DD(
                        //   payload.issueDate || payload.IssueDate
                        // )}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group" style={{ zIndex: "10" }}>
                      <label className="form-label small">
                        Comments on starting conditions
                      </label>
                      <div className="form-control-wrap">
                        <textarea
                          // type="text"
                          value={payload.contractStartComments}
                          className="form-control"
                          style={{ height: "100px" }}
                          onChange={(e) =>
                            addPayload("contractStartComments", e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <br />

                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group" style={{ zIndex: "10" }}>
                      <label className="form-label small">
                        (Expected) End Date
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="datetime-local"
                          value={dateToYYYY_MM_DD_hh_mm(
                            payload.contractEndDate
                          )}
                          className="form-control required"
                          onChange={(e) =>
                            addPayload("contractEndDate", e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group" style={{ zIndex: "10" }}>
                      <label className="form-label small">
                        Comments on ending conditions
                      </label>
                      <div className="form-control-wrap">
                        <textarea
                          // type="text"
                          value={payload.contractEndComments}
                          className="form-control"
                          style={{ height: "100px" }}
                          onChange={(e) =>
                            addPayload("contractEndComments", e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <br />

                <div className="row">
                  <div className="col-12">
                    <p>
                      <span className="preview-title-lg overline-title">
                        Charter Value
                      </span>{" "}
                    </p>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group ">
                      <label className="form-label small">Daily Rate</label>
                      <div className="form-control-wrap">
                        <input
                          type="number"
                          value={payload.dailyRate}
                          className="form-control required"
                          required
                          onChange={(e) =>
                            addPayload("dailyRate", e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group" style={{ zIndex: "60" }}>
                      <label className="form-label small">Currency</label>
                      <div className="form-control-wrap">
                        <Select
                          value={currency
                            .map((c) => ({
                              ...c,
                              label: c.CorrespondingData,
                              value: c.ConfigurationId,
                            }))
                            .find(
                              (a) =>
                                a.value === payload?.currencyConfigurationId
                            )}
                          options={currency.map((c) => ({
                            ...c,
                            label: c.CorrespondingData,
                            value: c.ConfigurationId,
                          }))}
                          isDisabled={true}
                        // onChange={(e) => {
                        //   addPayload("currencyConfigurationId", e.value);
                        // }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <br />

                <div className="row">
                  <div className="col-12">
                    <p>
                      <span className="preview-title-lg overline-title">
                        Contract Options
                      </span>{" "}
                    </p>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group" style={{ zIndex: "30" }}>
                      <label className="form-label small">Contract Type</label>
                      <div className="form-control-wrap">
                        <Select
                          value={contractType
                            .map((c) => ({
                              ...c,
                              label: c.CorrespondingData,
                              value: c.ConfigurationId,
                            }))
                            .find(
                              (a) =>
                                a.value == payload?.contractTypeConfigurationId
                            )}
                          options={contractType.map((c) => ({
                            ...c,
                            label: c.CorrespondingData,
                            value: c.ConfigurationId,
                          }))}
                          onChange={(e) => {
                            addPayload("contractTypeConfigurationId", e.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group" style={{ zIndex: "40" }}>
                      <label className="form-label small">
                        Options for renewal/extension
                      </label>
                      <div className="form-control-wrap">
                        <Select
                          options={renewalOptions}
                          onChange={(e) =>
                            addPayload("contractOptions", e.value)
                          }
                          value={renewalOptions.find(
                            (a) => a.value == payload?.contractOptions
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <br />

                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group ">
                      <label className="form-label small">
                        Contract Document
                      </label>
                      <div className="form-control-wrap">
                        <FileInput
                          onChange={(file) => {
                            addPayload(
                              [
                                "contractDocumentBase64String",
                                "contractDocumentFilename",
                                "contractDocumentMime",
                              ],
                              [file.base64, `${file.name}`, `${file.type}`]
                            );
                          }}
                          fileValue={{
                            name: payload.contractDocumentFilename,
                            size:
                              payload.contractDocumentBase64String?.length || 0,
                            fileContent: payload.contractDocumentBase64String,
                            type: payload.contractDocumentMime,
                            fileRef: payload.contractDocumentReference,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {saving && (
            <div className="form-group p-2 m-3">
              <button type="button" className="btn btn-md btn-link m-1">
                <Spinner size="1.5" />
              </button>
            </div>
          )}
          {!saving && (
            <div className="form-group p-2 m-3">
              <button
                type="button"
                className="btn btn-md btn-dark btn-wide px-5 mr-3"
                onClick={() => submitForm()}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-sm btn-link m-1"
                onClick={() => window.history.back()}
              >
                Cancel
              </button>
            </div>
          )}
        </form>
      )}
      <hr className="preview-hr" style={{ visibility: "hidden" }} />
    </MainLayout>
  );
}
