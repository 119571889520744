import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";

export default function Profile() {
  const [loading, setLoading] = useState(true);
  const user = JSON.parse(localStorage.resu)?.user;
  const roles = JSON.parse(localStorage.resu)?.roles;

  useEffect(async () => {
    setLoading(true);

    setLoading(false);
  }, []);

  const init = async () => {};

  const pageActions = (
    <div className="toggle-wrap nk-block-tools-toggle">
      <a
        href="#"
        className="btn btn-icon btn-trigger toggle-expand mr-n1"
        data-target="pageMenu"
      >
        <em className="icon ni ni-menu-alt-r" />
      </a>
      <div className="toggle-expand-content" data-content="pageMenu">
        <ul className="nk-block-tools g-3">
          <li>
            <Link to="/change-password" className="btn btn-dim btn-secondary">
              <em className="icon ni ni-switch" />
              <span>Change Password</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );

  return (
    <MainLayout
      title="Profile"
      loading={loading}
      showFullLoader={loading}
      pageActions={pageActions}
    >
      <div className="card-inner">
        <div>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group ">
                <label className="form-label small">Profile Picture</label>
                <div className="form-control-wrap">
                  {user.ImageUrl == "00000000-0000-0000-0000-000000000000" ||
                  user.ImageUrl == null ? (
                    <p> No profile picture uploaded</p>
                  ) : (
                    <img
                      src={`${user.profileImageUrl}?authorization=${localStorage.token}`}
                      alt="Profile Picture"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="gap gap-12px" />

          <div className="row">
            <div className="col-md-3">
              <div className="form-group ">
                <label className="form-label small">First Name</label>
                <div className="form-control-wrap">
                  <p>{user.FirstName}</p>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group ">
                <label className="form-label small">Last Name</label>
                <div className="form-control-wrap">
                  <p>{user.LastName}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="gap gap-12px" />

          <div className="row">
            <div className="col-md-3">
              <div className="form-group ">
                <label className="form-label small required">
                  Username / E-mail
                </label>
                <div className="form-control-wrap">
                  <p>{user.Email}</p>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group ">
                <label className="form-label small">Phone Number</label>
                <div className="form-control-wrap">
                  <p>{user.PhoneNumber || "N/A"}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="gap gap-12px" />

          <div className="row">
            <div className="col-md-3">
              <div className="form-group ">
                <label className="form-label small required">Role(s)</label>
                <div className="form-control-wrap">
                  {roles.map((role) => (
                    <p className="mb-1">{role.Name}</p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
