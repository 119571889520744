import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/mainLayout";
import { Navigate, useParams } from "react-router-dom";
import Spinner from "../../utils/spinner";
import toastr from "../../utils/toastr";
import PayloadUpdater from "../../utils/payloadUpdater";
import UserService from "../../services/userService";
import ReactTooltip from "react-tooltip";

export default function ChangePassword() {
  const [loading, setLoading] = useState(true);
  const user = JSON.parse(localStorage.resu)?.user;
  const roles = JSON.parse(localStorage.resu)?.roles;
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [payload, setPayload] = useState({});
  const [saving, setSaving] = useState(false);

  const addPayload = new PayloadUpdater({
    payload: payload,
    setPayload: setPayload,
  }).update;

  useEffect(async () => {
    setLoading(true);

    setLoading(false);
  }, []);

  const init = async () => {};

  const submitForm = async () => {
    const _payload = { ...payload };

    if (!_payload.password) {
      toastr("error", "Enter new password");
      return;
    }

    if (!_payload.confirmPassword) {
      toastr("error", "Confirm new password");
      return;
    }

    if (_payload.password !== _payload.confirmPassword) {
      toastr("error", "New password and confirmation do not match");
      return;
    }

    setSaving(true);

    delete _payload.confirmPassword;

    const response = await UserService.changePassword(_payload);
    if (response) {
      toastr("success", "Password changed");
      setRedirectUrl("/profile");
    } else {
      toastr("error", "Password could not be changed");
    }
    setSaving(false);
  };

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout
      title="Change Password"
      loading={loading}
      showFullLoader={loading}
    >
      <form>
        <div className="card-inner">
          <div className="row">
            <div className="col-sm-4 col-lg-3 mt-2">
              <div className="form-group">
                <label className="form-label small">New Password</label>
                <div className="form-control-wrap">
                  <input
                    type="password"
                    className="form-control"
                    value={payload.password}
                    onChange={(e) => addPayload("password", e.target.value)}
                  />
                  <span>
                    <small className="text-danger">
                      Password should have a minimum length of eight characters
                      with a numeric, block-letter & special case included
                    </small>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-lg-3 mt-2">
              <div className="form-group">
                <label className="form-label small">Confirm New Password</label>
                <div className="form-control-wrap">
                  <input
                    type="password"
                    className="form-control"
                    value={payload.confirmPassword}
                    onChange={(e) =>
                      addPayload("confirmPassword", e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {saving && (
          <div className="form-group p-2 m-3">
            <button type="button" className="btn btn-md btn-link m-1">
              <Spinner size="1.5" />
            </button>
          </div>
        )}
        {!saving && (
          <div className="form-group p-2 m-3">
            <button
              type="button"
              className="btn btn-md btn-dark btn-wide px-5 mr-3"
              onClick={() => submitForm()}
            >
              Change
            </button>
            <button
              type="button"
              className="btn btn-sm btn-link m-1"
              onClick={() => window.history.back()}
            >
              Cancel
            </button>
          </div>
        )}
      </form>
    </MainLayout>
  );
}
