import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/mainLayout";
import { getSystemCurrencies } from "../../utils/utilityFunctions";
import PartyService from "../../services/partyService";
import { useParams } from "react-router-dom";
import InvoiceDetailsComponent from "../../components/Invoice/InvoiceDetailsComponent";
import InvoiceService from "../../services/invoiceService";
import OrganizationService from "../../services/organizationService";

export default function InvoiceDetail() {
  const [initializing, setInit] = useState(true);
  const [details, setDetails] = useState({});
  const [currencies, setCurrencies] = useState([]);
  const [organizationAccounts, setOrganizationAccounts] = useState([]);

  const params = useParams();

  useEffect(async () => {
    await Promise.all([
      getInvoiceDetails(),
      getCurrencies(),
      getOrganizationAccount(),
    ]);
  }, []);

  const getInvoiceDetails = async () => {
    setInit(true);

    let _res = await InvoiceService.getSingleInvoice(params.id);

    // setDetails(_res?.res[0] || {});
    setDetails(
      _res?.Data || {
        personaId: "bf257f20-0f50-4b6d-a16a-c2908a8e7c1c",
        InvoiceRefNo: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        Discount: 0,
        Vat: 1,
        GrossAmount: 3000,
        NetAmount: 3000,
        CurrencyConfigurationId: "e087029f-b00c-11ec-89b1-addc49d55320",
        AccountDetailConfigurationId: "f4cd26dd-9481-11ec-89b1-addc49d55320",
        PartyId: "5a21850b-317e-4627-8a25-ee7366c795d7",
        DueDate: "2022-06-06T14:41:22.749Z",
        Detail: [
          {
            Description: "Water Consumption",
            Quantity: 2000,
            Rate: 1.5,
            UnitAmount: 2000,
            TotalAmount: 3000,
          },
          {
            Description: "Water Consumption",
            Quantity: 2000,
            Rate: 1.5,
            UnitAmount: 2000,
            TotalAmount: 3000,
          },
          {
            Description: "Water Consumption",
            Quantity: 2000,
            Rate: 1.5,
            UnitAmount: 2000,
            TotalAmount: 3000,
          },
        ],
      }
    );
    setInit(false);
  };

  const getCurrencies = async () => {
    const _res = await getSystemCurrencies();
    setCurrencies(_res);
  };

  const getOrganizationAccount = async () => {
    const _res = await OrganizationService.fullList();
    setOrganizationAccounts(_res?.Data || []);
  };

  return (
    <MainLayout
      title={`Invoice #${details?.InvoiceRefNo || ""}`}
      // subTitle={`Generated at ${details?.DateCreated || ""}`}
      loading={initializing}
      showFullLoader={initializing}
      details={details}
    >
      <div>
        {!initializing && (
          <InvoiceDetailsComponent
            details={details}
            currencies={currencies}
            organizationAccounts={organizationAccounts}
            callback={getInvoiceDetails}
          />
        )}
      </div>
    </MainLayout>
  );
}
