import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from "react-router-dom";
import VoyageActivitiesList from '../../components/voyage/voyageActivitiesList';
import MainLayout from '../../layouts/mainLayout';
import Tabs from '../../utils/tabs';

export default function VoyageActivityListPage() {
    const params = useParams(); //we are expecting params.voyageId as voyagePlanningId

    const [redirectUrl, setRedirectUrl] = useState(null);


    useEffect(async () => {


    }, []);
/*
    const tabHeaders = [
        { icon: "icon ni ni-view-grid-wd", text: "Details" },
        { icon: "icon ni ni-user-list", text: "Managers" },
        { icon: "icon ni ni-files", text: "Compliance" },
        { icon: "icon ni ni-files", text: "Equipment" },
    ];
    const tabContent = [
        <VesselDetailsComponent vesselSpecId={params.vesselId} />,
        <VesselPartiesComponent vesselSpecId={params.vesselId} />,
        <ComplianceComponent vesselSpecId={params.vesselId} />,
        <EquipmentListComponent vesselSpecId={params.vesselId} />
    ];

    const _tabView = (
        <Tabs
            headers={tabHeaders}
            contents={tabContent} contentPadding="3"
        />
    );
*/

    return (
        redirectUrl ? <Navigate to={redirectUrl} replace={true} /> :
            <MainLayout title="Voyage Activity">
                <div className="">
                    <VoyageActivitiesList voyagePlanningId={params.voyageId} />
                </div>
            </MainLayout >
    );
}
