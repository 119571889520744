import React from "react";
import { useCallback } from "react";
import { useState } from "react";

export default function DateFilter({ from, to, callback }) {
  // state
  const [fromDate, setFromDate] = useState(from);
  const [toDate, setToDate] = useState(to);

  // methods
  const filterUtilizationData = async (fromValue, toValue) => {
    if (!fromValue || !toValue) return;
    const payload = { startDate: fromValue, endDate: toValue };
    callback && callback(payload);
  };

  const clearFilter = () => {
    setFromDate("");
    setToDate("");
    callback && callback();
  };

  const isFilterFilled = useCallback(() => {
    return !!fromDate && !!toDate;
  });

  return (
    <div className="d-flex align-center">
      <p className="text-soft mr-3 mb-0">Filter Date</p>

      <div className="form-group row align-center">
        <div className="col">
          <div className="form-control-wrap">
            <input
              type="date"
              className="form-control "
              placeholder=""
              id="from-date-filter"
              value={fromDate}
              onChange={(e) => {
                setFromDate(e.target.value);
                filterUtilizationData(e.target.value, toDate);
              }}
            />
          </div>
        </div>
        -
        <div className="col">
          <div className="form-control-wrap">
            <input
              type="date"
              className="form-control "
              placeholder=""
              id="to-date-filter"
              value={toDate}
              onChange={(e) => {
                setToDate(e.target.value);
                filterUtilizationData(fromDate, e.target.value);
              }}
            />
          </div>
        </div>
        <div className="col">
          <div className="d-flex">
            <em
              className={`icon ni ni-trash text-danger fs-22px pointer mr-3 ${
                isFilterFilled() ? "text-danger" : "text-muted"
              }`}
              title="Clear filter"
              onClick={() => isFilterFilled() && clearFilter()}
            ></em>
          </div>
        </div>
      </div>
    </div>
  );
}

DateFilter.defaultProps = {
  from: "",
  to: "",
};
