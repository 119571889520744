import "../../src/styles/accordion.scss";

const Accordion = ({
  title,
  content,
  index,
  activeAccordionIndex,
  setActiveAccordionIndex,
}) => {
  return (
    <div className="accordion-item">
      <div
        className="accordion-title border-bottom px-4"
        onClick={() =>
          setActiveAccordionIndex(activeAccordionIndex ? null : index)
        }
      >
        <div>{title}</div>
        <div style={{ fontSize: "20px" }}>
          {activeAccordionIndex ? "-" : "+"}
        </div>
      </div>
      {activeAccordionIndex && (
        <div
          className="accordion-content disappearing-scrollbar px-2"
          style={{ zoom: "90%", overflowX: "scroll", maxHeight: "500px" }}
        >
          {content}
        </div>
      )}
    </div>
  );
};

export { Accordion };
