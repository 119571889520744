import apiCall from "../utils/apiCall";
import { clearAPICache } from "../utils/utilityFunctions";

export default class VoyageActivityService {
  static getVoyageActivitiesList = async (
    page = 1,
    pageSize = 50,
    voyagePlanningId
  ) => {
    return await apiCall(
      `/api/VoyageActivities/GetListByVoyageId/${voyagePlanningId}/${page}/${pageSize}`
    );
  };
  static getPortLoadingBulk = async (
    voyageActivityId
  ) => {
    return await apiCall(
      `/api/cargos/port-loading-bulk-list?voyageActivityId=${voyageActivityId}`
    );
  };
  static getPortDischargingBulk = async (
    voyageActivityId
  ) => {
    return await apiCall(
      `/api/cargos/port-discharging-bulk-list?voyageActivityId=${voyageActivityId}`
    );
  };

  static getVoyageTerminalActivitiesList = async (
    page,
    pageSize,
    voyagePlanningId,
    locationId
  ) => {
    page = page || 1;
    pageSize = pageSize || 150;
    locationId = locationId || "";
    let response = await apiCall(
      `/api/VoyageActivities/GetTerminalByVoyagePlanningId/${voyagePlanningId}/${page}/${pageSize}?locationId=${locationId}`
    );
    return response;
  };

  static getVoyageActivity = async (voyageActivityId) => {
    return await apiCall(
      `/api/VoyageActivities/GetByVoyageActivityId/${voyageActivityId}`
    );
  };

  static startVoyageActivity = async (payload) => {
    return await apiCall(
      `/api/VoyageActivities/StartActivity`,
      "PUT",
      payload,
      null,
      null,
      null,
      true
    );
  };

  static updateVoyageActivity = async (voyageActivityId, payload) => {
    return await apiCall(
      `/api/VoyageActivities/Put/${voyageActivityId}`,
      "PUT",
      payload
    );
  };

  static postVoyageActivityByBookingId = async (bookingRequestId, payload) => {
    return await apiCall(
      `/api/VoyageActivities/LogDurationofActivity/${bookingRequestId}`,
      "POST",
      payload
    );
  };

  static recordWeightOfLoadedCargo = async (payload) => {
    return await apiCall(
      `/api/VoyageActivities/Recordweightofloadeditems`,
      "POST",
      payload
    );
  };

  static recordNumberOfLifts = async (payload) => {
    return await apiCall(
      `/api/VoyageActivities/Recordnumberoflifts`,
      "POST",
      payload
    );
  };

  static getWeatherInfo = async (longitude, latitude, timePeriod) => {
    latitude = latitude;
    longitude = longitude;
    timePeriod = timePeriod;
    let response = await apiCall(
      `/api/AllVesselDetails/GetWeatherInformation?lat=${latitude}&lon=${longitude}&mockData=false&part=${timePeriod}`
    );
    return response;
  };

  static recordROB = async (payload) => {
    return await apiCall(`/api/VoyageActivities/RecordRob`, "POST", payload);
  };

  static getCargosForVoyageActivity = async (
    page,
    pageSize,
    voyagePlanningId
  ) => {
    page = page || 1;
    pageSize = pageSize || 150;
    voyagePlanningId = voyagePlanningId || "";
    let response = await apiCall(
      `/api/VoyageActivities/GetActivityBookingList/${page}/${pageSize}?voyagePlanningId=${voyagePlanningId}
      `
    );
    return response?.Data?.data;
  };

  static endVoyageActivity = async (payload) => {
    return await apiCall(
      `/api/VoyageActivities/EndVoyageActivity`,
      "PUT",
      payload,
      null,
      null,
      null,
      true
    );
  };

  static getVoyageActivitiesAndSailingList = async (
    page = 1,
    pageSize = 50,
    voyagePlanningId
  ) => {
    let response = await apiCall(
      `/api/VoyageActivities/GetSailingDetails/${voyagePlanningId}/${page}/${pageSize}`
    );
    return response?.Data;
  };

  static deleteVoyageActivity = async (voyageActivityId) => {
    return await apiCall(
      `/api/VoyageActivities/DeleteByVoyageActivityId/${voyageActivityId}`,
      "DELETE"
    );
  };

  static startSailing = async (payload) => {
    clearAPICache("voyage");
    return await apiCall(`/api/VoyageActivities/StartSailing`, "POST", payload);
  };

  static getVoyageActivitiesWithSailing = async (voyagePlanningId) => {
    return await apiCall(
      `/api/sailing/getactivities?voyagePlanningId=${voyagePlanningId}`
    );
  };

  static editVoyageActivityAndETA = async (payload) => {
    return await apiCall(
      `/api/VoyageActivities/EditActivityUpdateETA`,
      "PUT",
      payload
    );
  };

  static getCompletionLog = async (voyageActivityId) => {
    return await apiCall(
      `/api/VoyageActivities/CompletionLog?voyageActivityId=${voyageActivityId}`
    );
  };
}
