import React from "react";
import CustomModal from "../../utils/customModal";
import Spinner from "../../utils/spinner";

function ConfirmationModal({
  text,
  title,
  onClose,
  onSubmit,
  loading,
  btnText,
}) {
  const content = (
    <div className="text-center">
      <p className="mb-3 fs-17px">{text}</p>
      <div className="form-group">
        <button disabled={loading} onClick={onSubmit} className="btn btn-dark">
          {loading ? <Spinner size="1" color="white" /> : btnText || "Submit"}
        </button>
      </div>
    </div>
  );

  return (
    <>
      <CustomModal
        title={
          <span>
            <em className="icon ni ni-info-i text-primary"></em> {title}
          </span>
        }
        content={content}
        onClose={() => onClose()}
      />
    </>
  );
}

export default ConfirmationModal;
