import apiCall from "../utils/apiCall";
import { dynamicSort } from "../utils/utilityFunctions";

export default class ProductService {
  static listAll = async () => {
    let response = await apiCall(
      `/api/Product/search?page=1&pageSize=50`,
      "GET",
      null,
      false,
      false,
      true
    );

    if (response && response.Data) {
      response = response.Data.sort(dynamicSort("ProductName"));
    }

    return response;
  };

  static newRequest = async (payload) => {
    return await apiCall(`/api/request`, "POST", payload);
  };

  static fuelConsumptionLog = async (payload) => {
    let response = await apiCall(
      `/api/request/fuelConsumptionLog`,
      "POST",
      payload
    );
    return response;
  };

  static waterConsumptionLog = async (payload) => {
    let response = await apiCall(
      `/api/request/waterConsumptionLog`,
      "POST",
      payload
    );
    return response;
  };
}
