import React, { useEffect, useState } from 'react';
import { Link, Navigate, useParams } from "react-router-dom";
import MainLayout from '../../layouts/mainLayout';
import Tabs from '../../utils/tabs';
import { dynamicSort } from '../../utils/utilityFunctions';
import VesselDetailsComponent from '../../components/vessel/vesselDetailsComponent';
import VesselPartiesComponent from '../../components/vessel/vesselPartiesComponent';
import ComplianceComponent from '../../components/vessel/complianceComponent';
import CustomModal from '../../utils/customModal';
import LocationDetailsComponent from '../../components/location/locationDetails';
import VesselService from '../../services/vesselService';
import VesselListComponent from '../../components/vessel/vesselListComponent';
import LocationService from '../../services/locationService';
import MapView2 from '../../components/mapView2';
import MapView from '../../components/mapView';
import FleetService from '../../services/fleetService';
import { checkUserPermission } from '../../utils/utilityFunctions';

export default function FullLocationViewPage() {
    const params = useParams(); //we are expecting params.locationId, fleets_vessels

    const [redirectUrl, setRedirectUrl] = useState(null);
    const [locationVessels, setLocationVessels] = useState({});
    const [_tabView, setTabView] = useState(null);
    const [location, setLocation] = useState({});
    const [loading, setLoading] = useState(false);
    const [selectedFleet, setSelectedFleet] = useState(null);
    const [mapArea, setMapArea] = useState(null);


    useEffect(async () => {
        await init();
    }, [selectedFleet]);

    //I had to introduce the second useEffect due to a lag in the population of a required variabl which is 
    //been supplied in the dependency array
    useEffect(async () => {
        renderMap()
    }, [location.LocationTypeName === "Zone"])

    let zoneCoordinatesArray = [];
    const init = async () => {
        setLoading(true);
        const _locationVessels = await VesselService.listByLocation(params.locationId);
        setLocationVessels(_locationVessels || {});

        const _location = await LocationService.get(params.locationId);
        setLocation(_location || {});

        if (_location?.LocationTypeName?.toLowerCase() == "zone") {
            if (_location.coordinates.length > 0) {
                let _coordinates = [..._location.coordinates];
                _coordinates.sort(dynamicSort("drawOrder"));
                const _mapArea = _location.coordinates.map((e) => [e.Lat, e.Lng]);
                zoneCoordinatesArray?.splice(0, zoneCoordinatesArray.length, ..._mapArea)
                setMapArea(_mapArea);
            }

        }
        setLoading(false);

    }
    const renderMap = () => {

        const tabContent = [
            <>
                <div className="row no-gutters">
                    <div className="col p-0 my-4">
                        <LocationDetailsComponent locationId={params.locationId} hideImage={true} />

                    </div>
                    {(location?.LocationTypeName == "Zone") ?
                        (
                            <div className="col">
                                {

                                    (<MapView2
                                        height="300px"
                                        mapArea={mapArea}
                                        isCreateMode={false} readOnly>
                                    </MapView2>)

                                }
                            </div>
                        ) : ""}

                </div>
            </>,
        ];
        const __tabView = (
            <Tabs
                headers={tabHeaders}
                contents={tabContent} contentPadding="4" _firstIndex={params.fleets_vessels ? 1 : 0}
            />
        );
        setTabView(__tabView);
    }

    const tabHeaders = [
        { icon: "icon ni ni-view-grid-wd", text: "Details" },
        // { icon: "icon ni ni-files", text: "Fleets and Vessels" },
    ];


    const pageActions =
        <div className="toggle-wrap nk-block-tools-toggle">
            <Link to={"#"} className="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em className="icon ni ni-menu-alt-r" /></Link>
            <div className="toggle-expand-content" data-content="pageMenu">
                <ul className="nk-block-tools g-3">
                    {checkUserPermission("location_view_module") && (<li><Link to="/locations" className="btn btn-dim btn-secondary"> <span>{"<"} Back to locations</span></Link>
                    </li>)}
                    {checkUserPermission("location_edit_location_details") && (<li><Link to={`/edit-location/${params.locationId}`} className="btn btn-white btn-outline-light"><em className="icon ni ni-edit" /><span>Edit location</span></Link>
                    </li>)}

                </ul>
            </div>
        </div>

    return (
        redirectUrl ? <Navigate to={redirectUrl} replace={true} showFullLoader={loading} /> :
            <MainLayout title={location?.LocationName ? `Location Details - ${location?.LocationName}` : "Location Details "} loading={loading} pageActions={pageActions}>
                <div className="">{_tabView}</div>
            </MainLayout >
    );
}
