import apiCall from "../utils/apiCall";
import { clearAPICache, dynamicSort } from "../utils/utilityFunctions";

export default class ActivityService {
  static activitiesList = async () => {
    let response = await apiCall(
      `/api/Activities/Get`,
      "GET",
      null,
      null,
      false,
      true
    );
    if (response?.Data) {
      response?.Data.sort(dynamicSort("ActivityName"));
    }

    return response.Data;
  };
  static activitiesPaginatedList = async (currentPage, PageSize, query) => {
    currentPage = currentPage || 1;
    PageSize = PageSize || 50;
    query = query || "";
    return await apiCall(
      `/api/Activities/SearchList?pageCount=${currentPage}&noPerPage=${PageSize}&searchParam=${query}`, "GET", null, null, null, true
    );
  };
  static locationGroupList = async (currentPage, PageSize, query) => {
    currentPage = currentPage || 1;
    PageSize = PageSize || 50;
    query = query || "";
  };
  // api/Activities/Get/
  static singleActivity = async (activityId) => {
    return await apiCall(`/api/Activities/GetWithParallels/${activityId}`);
  };
  static postActivity = async (payload) => {
    return await apiCall(`/api/Activities/Post`, "POST", payload);
  };
  static postParallelActivities = async (firstActivityId, payload) => {
    return await apiCall(
      `/api/Activities/addparallelactivity?firstActivityId=${firstActivityId}`,
      "POST",
      payload
    );
  };
  static postVoyageActivity = async (payload) => {
    return await apiCall(`/api/voyageactivities/addactivity`, "POST", payload);
  };

  static listByVoyageId = async (voyagePlanningId) => {
    return await apiCall(
      `/api/voyageactivities/GetListByVoyageId/${voyagePlanningId}/${1}/${10000}`,
      "GET"
    );
  };

  static singleVoyageActivity = async (id) => {
    return await apiCall(`/api/voyageactivities/Get/${id}`, "GET");
  };
  static getTimeSpent = async (page, pageSize, query = "") => {
    page = page || 1;
    pageSize = pageSize || 50;
    return await apiCall(
      `/api/VesselReport/GetActivityTotalTime?page=${page}&pageSize=${pageSize}&${query}`
    );
  };
  static updateCargoLogs = async (payload) => {
    clearAPICache("voyage");
    return await apiCall(`/api/Activities/UpdateCargoLogs`, "POST", payload);
  };

  static autoStartAndEndMainActivity = async (payload) => {
    return await apiCall(
      `/api/Activities/AutoUpdateMainActivityStartEnd`,
      "POST",
      payload
    );
  };
}
