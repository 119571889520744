import { camelCase } from "lodash";
import CommercialService from "../services/commercialService";
import moment from "moment";

const OffshoreInstallationLocationTypes = [
  "FPSO",
  "FSO",
  "Offshore Terminal",
  "Offshore Installation",
  "Onshore Terminal",
];

const toReadableDate = (date) => {
  if (!date) return "";
  try {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    const response = [day, month, year].join("/");
    if (response.includes("NaN")) return "";
    return [day, month, year].join("/");
  } catch (e) {
    return "";
  }

};

const dateToYYYY_MM_DD = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

const isPhoneValid = (phoneNumber) => {
  if (phoneNumber.startsWith("0")) {
    phoneNumber = phoneNumber.substr(1);
  }

  var phoneRe = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/;
  var digits = phoneNumber.replace(/\D/g, "");
  return phoneRe.test(digits);
};

const currencyFormat = (amount, decimalPoints) => {
  if (!decimalPoints && (decimalPoints !== 0)) {
    decimalPoints = 2;
  }
  return Number(parseFloat(amount).toFixed(decimalPoints)).toLocaleString("en", {
    minimumFractionDigits: decimalPoints,
  });
};

const removeElementFromArray = (element, array) => {
  const _array = [...array];
  let index = array.indexOf(element);
  let removed = array.splice(index, 1);
};

const camelizeKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelizeKeys(v));
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeKeys(obj[key]),
      }),
      {}
    );
  }
  return obj;
};

const randomString = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const dynamicSort = (property) => {
  let sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    let result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
};

const generateQueryString = (queryObject) => {
  var result = "";
  for (const key in queryObject) {
    if (queryObject[key]) result += `${key}=${queryObject[key]}&`;
  }
  return result;
};

const currencySymbol = (currencyCode) => {
  let result = currencyCode;
  switch (currencyCode) {
    case "NGN":
      result = "₦";
      break;
    case "USD":
      result = "$";
      break;

    default:
      break;
  }
  return result;
};

const formatAmount = (amount, currency = "NGN") => {
  return currencySymbol(currency) + (amount || "0");
};

const getSystemCurrencies = async (getAll) => {
  // this is for currency dropdowns
  let _configList = await CommercialService.getConfigList();
  let _currency;
  if (_configList && !getAll) {
    _currency = _configList?.filter(
      (l) =>
        l.CorrespondingData.toLowerCase() == "usd" ||
        l.CorrespondingData.toLowerCase() == "naira" ||
        l.CorrespondingData.toLowerCase() == "gbp"
    );
  }
  return _currency;
};

const oldToReadableDateTime = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    hour = "" + d.getHours(),
    minute = "" + d.getMinutes();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if (hour.length < 2) hour = "0" + hour;
  if (minute.length < 2) minute = "0" + minute;
  const response =
    [day, month, year].join("/") + " " + [hour, minute].join(":");
  if (response.includes("NaN")) return "";
  return [day, month, year].join("/") + " " + [hour, minute].join(":");
};

const toReadableDateTime = (date) => {
  if (moment(date).format("DD/MM/YYYY HH:mm") !== "Invalid date") {
    return moment(date).format("DD/MM/YYYY HH:mm");
  } else return "-";
};
const convertToUTC = (date) => {
  if (date) {
    return moment.utc(moment(date).format()).format();
  } else return "-";
};

const oldDateToYYYY_MM_DD_hh_mm = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    hour = "" + d.getHours(),
    minute = "" + d.getMinutes();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if (hour.length < 2) hour = "0" + hour;
  if (minute.length < 2) minute = "0" + minute;

  return [year, month, day].join("-") + "T" + [hour, minute].join(":");
};

const dateToYYYY_MM_DD_hh_mm = (date) => {
  return moment(date).format("YYYY-MM-DDTHH:mm");
};

const localTimeInYMD = (date) => {
  if (date) {
    return moment(date).format("DD/MM/YYYY HH:mm");
  } else return "-";
};

const durationByHours = (date, useDays) => {
  let days = moment.duration(date).days();
  let hours = Math.floor(moment.duration(date).asHours());
  let minutes = moment.duration(date).minutes();
  if (useDays) {
    hours = moment.duration(date).hours();
    return `${days}D ${hours}H ${minutes ? minutes + "M" : ""}`;
  }
  return `${hours}H ${minutes && minutes ? minutes + "M" : ""}`;
};

const durationInHoursFromMilliSeconds = (startTime, endTime) => {
  let start = new moment(startTime);
  let end = new moment(endTime);

  let duration = moment.duration(end.diff(start));

  let hours = moment.duration(duration).hours();
  let minutes = Math.abs(moment.duration(duration).minutes());
  const days = moment.duration(duration).days();
  if (days) {
    hours = hours + (days * 24);
  }
  if (hours && minutes) {
    return `${hours}hrs ${minutes && minutes ? minutes + "mins" : ""}`;
  } else if (hours && !minutes) {
    return `${hours}hrs`;
  } else if (!hours && minutes) {
    return `${minutes && minutes ? minutes + "mins" : ""}`;
  }
};

const checkUserPermission = (permission) => {
  const bypassAuth = process.env.REACT_APP_BYPASSAUTH == "true";
  if (bypassAuth) return true;

  const userPermissions =
    localStorage.getItem("resu") &&
    JSON.parse(localStorage.getItem("resu"))?.permissions; //user backwards is the user storage
  if (!userPermissions) return false;

  return !!userPermissions.find(
    (userPermission) => userPermission.Permission === permission
  );
};

const checkUserRole = (role) => {
  const bypassAuth = process.env.REACT_APP_BYPASSAUTH == "true";
  if (bypassAuth) return true;

  const userRoles =
    localStorage.getItem("resu") &&
    JSON.parse(localStorage.getItem("resu"))?.roles; //user backwards is the user storage
  if (!userRoles) return false;
  return !!userRoles.find((userRole) => userRole.Name === role);
};

const getQueryObjectFromString = (url = "") => {
  const index = url.indexOf("?");
  if (index === -1) return {};
  const query = url.substr(index);
  const urlSearchParams = new URLSearchParams(query);
  const params = Object.fromEntries(urlSearchParams.entries());
  return params;
};

const randomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const colorArray = ["#1ee0ac", " #f4bd0e", "#e85347", "#854fff", "#09c2de"];
const randomColor = () => {
  return colorArray[randomNumber(0, 13)];
};

const cycleColor = (index = 0) => {
  return colorArray[index % colorArray.length];
};

const formatValue = (value) => {
  if (value < 1e3) return value;
  if (value >= 1e3 && value < 1e6) return +(value / 1e3).toFixed(1) + "k";
  if (value >= 1e6 && value < 1e9) return +(value / 1e6).toFixed(1) + "m";
  if (value >= 1e9 && value < 1e12) return +(value / 1e9).toFixed(1) + "b";
  if (value >= 1e12) return +(value / 1e12).toFixed(1) + "t";
};

const returnAsIntegerOrTwoDecimalPlaceValue = (value) => {
  if (Number.isInteger(value) === true) {
    return value;
  } else {
    const v = parseFloat(value);
    return v.toFixed(2);
  }
};

const convertFromKilometresToNauticalMiles = (distanceInKilometres) => {
  if (!distanceInKilometres) {
    return 0;
  }
  const distanceInNauticalMiles = 0.53996 * distanceInKilometres;
  return distanceInNauticalMiles;
};

const clearAPICache = (endpoint) => {
  const cacheKey = endpoint.toLowerCase();
  sessionStorage.removeItem(cacheKey);
  const otherMatchingKeys = Object.keys(sessionStorage).filter(a => a.includes(endpoint));
  if (otherMatchingKeys && otherMatchingKeys.length > 0) {
    otherMatchingKeys.forEach(k => {
      sessionStorage.removeItem(k);
    });
  }
}

const numberOrNA = (n) => {
  return n || (n === 0 ? 0 : "N/A");
}

const extractDetailsFromBulkCargo = (cargo, _sailingLocations, voyageActivityId) => {
  return {
    ...(cargo?.ActivityEndLog || {}),
    cargoName: cargo.BookingReference + " - " + cargo.ProductName,
    rob: numberOrNA(cargo?.ActivityEndLog?.Rob),
    quantityUnit: cargo?.ActivityEndLog?.RobUnitName || "N/A",
    bulkCargoDetailId: cargo.BulkCargoDetailId,
    bulkQuantityInLitres: numberOrNA(cargo?.ActivityEndLog?.BulkQuantity),
    direction: cargo?.Direction,
    quantityLoaded: numberOrNA(cargo?.ActivityEndLog?.BulkQuantity),
    installationName: cargo?.OffshoreInstallation?.LocationName,
    deliveryDestination: (_sailingLocations || []).find((site) => site.LocationId === cargo?.ActivityEndLog?.deliveryDestination)?.LocationName,
    voyageActivityId: voyageActivityId,
    pricePerLitre: numberOrNA((cargo?.ActivityEndLog?.BulkTotalCost) / (cargo?.ActivityEndLog?.BulkQuantity)),
  }
}

const toDurationString = (hour) => {
  if (!hour || isNaN(hour)) return '';
  hour = hour * 60;
  let response = '';
  const hours = Math.floor(hour / 60);
  const minutes = hour % 60;
  if (hours) response += hours + "hrs ";
  if (minutes) response += minutes + "mins";
  return response;
}

export {
  isPhoneValid,
  currencyFormat,
  toReadableDate,
  dateToYYYY_MM_DD,
  removeElementFromArray,
  camelizeKeys,
  randomString,
  dynamicSort,
  generateQueryString,
  currencySymbol,
  formatAmount,
  getSystemCurrencies,
  toReadableDateTime,
  oldToReadableDateTime,
  dateToYYYY_MM_DD_hh_mm,
  oldDateToYYYY_MM_DD_hh_mm,
  convertToUTC,
  localTimeInYMD,
  durationByHours,
  durationInHoursFromMilliSeconds,
  checkUserPermission,
  checkUserRole,
  getQueryObjectFromString,
  randomNumber,
  colorArray,
  randomColor,
  cycleColor,
  formatValue,
  returnAsIntegerOrTwoDecimalPlaceValue,
  convertFromKilometresToNauticalMiles,
  clearAPICache, OffshoreInstallationLocationTypes, extractDetailsFromBulkCargo, numberOrNA,
  toDurationString
};
