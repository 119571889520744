import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthService from "../../services/authServices";
import Spinner from "../../utils/spinner";
import {
  checkUserPermission,
  getQueryObjectFromString,
} from "../../utils/utilityFunctions";
import VesselService from "../../services/vesselService";
import VoyageActivityService from "../../services/voyageActivityService";

// the requiredPermission prop serves as a route guard to ensure that the user has the required permission to be in this module
function WatchRouteWrapper({ children, setLoggedInUser, requiredPermission }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [bypassAuth, setBypassAuth] = useState(
    process.env.REACT_APP_BYPASSAUTH == "true"
  );

  const postLoginRedirect = async () => {
    const _user = JSON.parse(localStorage.resu)?.user;

    const _userRoles = JSON.parse(localStorage.resu)?.roles;

    // For vessel master redirect
    if (
      _user &&
      _userRoles?.find((role) => role.Name == "Vessel Master") &&
      !_userRoles?.find((role) => role.Name.toLowerCase() == "super admin")
    ) {
      const _vesselForVesselMaster =
        await VesselService.getVesselByVesselMaster(_user?.Id);

      const _latestVoyage = await VesselService.getLatestVoyageForVessel(
        _vesselForVesselMaster?.VesselSpecificationId
      );

      if (
        _vesselForVesselMaster &&
        _latestVoyage &&
        _latestVoyage.VoyagePlanningId !== undefined
      ) {
        document.location.href = `/voyage-activities/${_latestVoyage.VoyagePlanningId}`;
      } else {
        document.location.href = "/list-vessels";
      }
    } else document.location.href = "/";
  };

  // watch for route changes and check auth status. this useEffect handles auth checking
  useEffect(async () => {
    if (bypassAuth) return setLoading(false);

    let user = localStorage.getItem("resu");

    // this is to redirect to the single sign on platform  if no user object is present on the browser
    if (user) {
      user = JSON.parse(user);
      setLoggedInUser(user);
      setLoading(false);
    } else if (getQueryObjectFromString(document.location.href).token) {
      const res = await AuthService.verifyAccessToken({
        token: getQueryObjectFromString(document.location.href).token,
      });

      if (res) {
        localStorage.resu = JSON.stringify(res);
        localStorage.token = res.token;
      }
      //   check if a from url is set and that it's the same user that is logging back in, if it is resume their session else redirect the new person to the vessel page
      // if (
      //   localStorage.fromUrl &&
      //   localStorage.lastLoggedInEmail &&
      //   localStorage.lastLoggedInEmail === res?.user?.Email
      // ) {
      //   const url = localStorage.fromUrl;
      //   delete localStorage.fromUrl;
      //   delete localStorage.lastLoggedInEmail;
      //   document.location.href = url;
      //   return;
      // } else {
      //   delete localStorage.fromUrl;
      //   delete localStorage.lastLoggedInEmail;
      //   document.location.href = "/list-vessels";
      //   return;
      // }
      await postLoginRedirect();
    } else {
      localStorage.fromUrl = document.location.href;
      document.location.href = `${process.env.REACT_APP_AUTH_LOGIN_URL}?redirect_url=${window.location.origin}`;
      setLoading(false);
    }
  }, [location]);

  useEffect(() => {
    if (bypassAuth) return setLoading(false);

    // this useEffect acts as a route guard to be sure that the current user has the permission to be here
    if (localStorage.resu && requiredPermission) {
      !checkUserPermission(requiredPermission) && navigate("/");
    }
  }, [location]);
  return (
    <>
      {loading ? (
        <div
          className="center flex-column"
          style={{ transform: "translateY(47vh)" }}
        >
          <Spinner text={"Authenticating, Please wait."} />
        </div>
      ) : (
        children
      )}
    </>
  );
}

export default WatchRouteWrapper;
