import React, { useEffect, useState } from "react";
import MyTable from "../dataTable";
import { toReadableDate } from "../../utils/utilityFunctions";
import Spinner from "../../utils/spinner";
import ConsumptionBulkService from "../../services/consumptionBulkService";
import CustomModal from "../../utils/customModal";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import ProductService from "../../services/productService";
import toastr from "../../utils/toastr";

export default function VoyageBulkCargoForPortConsumptionBulk({ dataList, voyageActivityId }) {
    const [tableData, setTableData] = useState([{}]);
    const [initializing, setInit] = useState(true);
    const [completionLog, setCompletionLog] = useState(null);
    const [payload, setPayload] = useState({});
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);


    const addPayload = new PayloadUpdater({
        payload: payload,
        setPayload: setPayload,
    }).update;

    const headerList = [
        {
            Header: "Cargo",
            accessor: "ProductName",
        },
        {
            Header: "Quantity Loaded",
            accessor: "BulkQuantityInLitres",
        },
        {
            Header: "Price Per Unit",
            accessor: "CostPerLitre",
        },

        {
            Header: "ROB",
            accessor: "RobInLitres",
        },
        {
            Header: "ROB Unit",
            accessor: "quantityUnit",
        },
        {
            Header: "",
            accessor: "updateButtonComponent",
        },
    ];

    const unavailableLog = {
        BulkQuantityInLitres: 'NA',
        ProductName: 'NA',
        RobInLitres: 'NA', CostPerLitre: 'NA'
    }

    const init = async () => {
        setInit(true);

        const _apiResponse = await ConsumptionBulkService.getLog(voyageActivityId);

        const _completionLog = _apiResponse || { ...unavailableLog };
        setCompletionLog(_completionLog);
        setPayload(_apiResponse || {});

        const _tableData = [_completionLog].map(e => ({ ...e, quantityUnit: 'litres', updateButtonComponent: <span className="pointer text-primary" onClick={() => setModalIsOpen(true)}>Update</span> }))
        setTableData(_tableData);

        setInit(false);
    }

    useEffect(async () => {
        await init();
        const _products = await ProductService.listAll();
        const _formattedProducts = (_products || []).filter(a => a.ProductName.includes("Water") || a.ProductName.includes("Fuel")).map(e => ({ ...e, value: e.ProductId, label: e.ProductName }));
        setProducts(_formattedProducts);
    }, [dataList]);

    const submit = async () => {
        if (!payload.ProductId) {
            toastr("error", "Select product");
            return;
        }
        if (!payload.BulkQuantityInLitres) {
            toastr("error", "Enter quantity of bulk loaded");
            return;
        } else {
            payload.BulkQuantityInLitres = parseFloat(payload.BulkQuantityInLitres.toString());
        }
        if (!payload.RobInLitres) {
            payload.RobInLitres = 0;
        }
        payload.VoyageActivityId = voyageActivityId;

        setLoading(true);
        const response = payload.ConsumptionLoadedLogId ? await ConsumptionBulkService.UpdateLog(payload) : await ConsumptionBulkService.CreateLog(payload);
        if (response && response.ConsumptionLoadedLogId) {
            toastr("success", "Successfully updated");
            setModalIsOpen(false);
            await init();
        } else {
            toastr("error", "Your request failed");
        }

        setLoading(false);
    }

    return (<>
        <div
            style={{
                overflow: "auto",
                boxShadow: "rgb(221, 221, 221) 2px 2px 5px 0.1px",
                // maxHeight: 400,
                // width: 1024,
            }}
        >
            {initializing && <Spinner />}
            {!initializing && (
                <MyTable
                    columns={headerList}
                    cssArray={["font-weight-500", "", "", "text-center"]}
                    data={tableData || []}
                    disablePageSize={true}
                    enableSearch={false}
                    emptyMessage="Bulk cargo unavailable"
                />
            )}
        </div>

        {modalIsOpen && <CustomModal
            title="Update Loaded Consumption Bulk"
            content={
                <>
                    <form>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-3">
                                    <label
                                        className="form-label small"
                                    >
                                        Product Loaded
                                    </label>
                                    <div className="form-control-wrap">

                                        <Select onChange={(item) => { addPayload("ProductId", item.ProductId) }}
                                            value={products.find(a => a.ProductId == payload?.ProductId)}
                                            defaultValue={products.find(a => a.ProductId == payload?.ProductId)}
                                            options={products}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label
                                        className="form-label small"
                                    >
                                        Loaded Quantity (in litres)
                                    </label>
                                    <div className="form-control-wrap">
                                        <input
                                            defaultValue={payload?.BulkQuantityInLitres}
                                            type="number"
                                            className="form-control required"
                                            onChange={(e) => {
                                                addPayload("BulkQuantityInLitres", e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label
                                        className="form-label small"
                                    >
                                        Remainder On Board Quantity (in litres)
                                    </label>
                                    <div className="form-control-wrap">
                                        <input
                                            defaultValue={payload?.RobInLitres}
                                            type="number"
                                            className="form-control required"
                                            onChange={(e) => {
                                                addPayload("RobInLitres", e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 mt-4 text-right">
                                {loading && <Spinner />}
                                {!loading && <button className="btn btn-outline-dark" onClick={() => submit()}>{payload.ConsumptionLoadedLogId ? "Update" : "Submit"}</button>}
                            </div>
                        </div>
                    </form>
                </>
            }
            onClose={() => setModalIsOpen(false)}
            isClosed={!modalIsOpen}
        />}
    </>
    );
}
