import apiCall from "../utils/apiCall";
import { dynamicSort, clearAPICache } from "../utils/utilityFunctions";

export default class FleetService {
  static listFleet = async () => {
    let response = await apiCall(`/api/Fleets`, "GET", null, null, null, true);

    if (response?.Data) {
      response = response.Data.sort(dynamicSort("FleetName"));
    }

    return response;
  };
  static addVesselToFleet = async (payload) => {
    return await apiCall(`/api/VesselFleet/Post`, "POST", payload);
  };
  static RemoveVesselFromPool = async (vesselFleetId) => {
    return await apiCall(`/api/VesselFleet/Delete/${vesselFleetId}`, "DELETE");
  };
  static fleetByVessel = async () => {
    let response = await apiCall(`/api/VesselFleet/Get`, "GET", null, null, null, true);
    return response?.Data;
  };
  static listByLocation = async (locationId) => {
    let response = await apiCall(`/api/Fleets/bylocation/${locationId}`);
    return response?.Data;
  };

  static listFleetExtended = async (page, pageSize) => {
    let response = await apiCall(
      `/api/Fleets/GetExtended?page=${page}&pageSize=${pageSize}`
    );
    return response?.Data;
  };
  static postPool = async (payload) => {
    clearAPICache(`/api/VesselFleet/Get`);
    return await apiCall(`/api/VesselFleet/Post`, "POST", payload);
  };
  static getAllVesselsByFleetId = async (page, pageSize, fleetId) => {
    let response = await apiCall(
      `/api/VesselFleet/GetVessels?fleetId=${fleetId}&page=${page}&pageSize=${pageSize}&activeVessels=false&vesselActivities=false&mapPositions=false&byOnhire=false`
    );
    return response?.Data?.Data;
  };
  static getDeletedVesselsFromPool = async (page, pageSize) => {
    let response = await apiCall(
      `/api/VesselFleet/GetDeletedVessels?page=${page}&pageSize=${pageSize}`
    );
    return response?.Data?.Data;
  }

  static addPool = async (payload) => {
    
    return await apiCall(`/api/Fleets/create`, "POST", payload);
  };

  static editPool = async (fleetId, payload) => {
    clearAPICache(`/api/Fleets`);
    return await apiCall(`/api/Fleets/${fleetId}`, "PUT", payload);
  };

  static listFleetExtended1 = async (
    page,
    pageSize,
    locationId,
    fleetName,
    fleetId
  ) => {
    let response = await apiCall(
      `/api/Fleets/GetExtended?fleetId=${fleetId}&locationId=${locationId}&fleetName=${fleetName}&page=${page}&pageSize=${pageSize}`
      , "GET", null, null, null, true
    );
    return response?.Data;
  };

  static listFleetDetailed = async (
    page,
    pageSize,
    locationId,
    fleetName,
    fleetId,
    vesselSpecificationId,
    activeVessels,
    vesselActivities,
    mapPositions,
    byOnhire
  ) => {
    let response = await apiCall(
      // `/api/Fleets/GetExtended?page=${page}&pageSize=${pageSize}`
      `/api/VesselFleet/GetVessels?fleetId=${fleetId}&locationId=${locationId}&vesselSpecificationId=${vesselSpecificationId}&fleetName=${fleetName}&page=${page}&pageSize=${pageSize}&activeVessels=false&vesselActivities=false&mapPositions=false&byOnhire=false`
    );
    return response?.Data;
  };

  static getFleetByVesselIdList = async (
    page,
    pageSize,
    vesselSpecificationId
  ) => {
    let response = await apiCall(
      `/api/VesselFleet/GetVessels?vesselSpecificationId=${vesselSpecificationId}&page=${page}&pageSize=${pageSize}&activeVessels=false&vesselActivities=false&mapPositions=false&byOnhire=false`
    );
    return response?.Data?.Data;
  };

  static switchVesselToAnotherPool = async (vesselFleetId, payload) => {
    clearAPICache(`/api/VesselFleet/Get`);
    return await apiCall(
      `/api/VesselFleet/Put/${vesselFleetId}`,
      "PUT",
      payload
    );
  };

  static RemoveVesselFromPool = async (vesselFleetId) => {
    clearAPICache(`/api/VesselFleet/Get`);
    return await apiCall(`/api/VesselFleet/Delete/${vesselFleetId}`, "DELETE");
  };

  static getPoolById = async (fleetId) => {
    return await apiCall(`/api/Fleets/${fleetId}`, "GET");
  };

  static getAllVesselsByFleetId = async (page, pageSize, fleetId) => {
    let response = await apiCall(
      `/api/VesselFleet/GetVessels?fleetId=${fleetId}&page=${page}&pageSize=${pageSize}&activeVessels=false&vesselActivities=false&mapPositions=false&byOnhire=false`
    );
    return response?.Data?.Data;
  };

  static postPool = async (payload) => {
    clearAPICache(`/api/VesselFleet/Get`);
    return await apiCall(`/api/VesselFleet/Post`, "POST", payload);
  };
}
