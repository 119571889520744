import React, { useEffect, useState } from "react";
import MyTable from "../dataTable";
import { toReadableDate } from "../../utils/utilityFunctions";
import CertificateService from "../../services/certificateService";
import Spinner from "../../utils/spinner";

export default function VesselCreationCertificateListComponent({
  dataList,
  reload,
  deleteAction,
  viewAction,
}) {
  const [tableData, setTableData] = useState(dataList);
  const [initializing, setInit] = useState(true);

  const headerList = [
    {
      Header: "Certificate",
      accessor: viewAction ? "certificateNameComponent" : "certificateName",
    },
    {
      Header: "Issuing Authority",
      accessor: "issuerName",
    },
    {
      Header: "Place of Issue",
      accessor: "placeOfIssueName",
    },
    {
      Header: "Validity Period",
      accessor: "dateComponent",
    },
    {
      Header: "Status",
      accessor: "statusComponent",
    },
  ];

  useEffect(async () => {
    setInit(true);
    const _issuerList = await CertificateService.listIssuers(); //cached shouldnt cause performance hit
    // const _validityPeriod = await CertificateService.listValidityPeriod(); //cached shouldnt cause performance hit

    (dataList || []).forEach((element) => {
      if (viewAction) {
        element.certificateNameComponent = (
          <span
            className="clickable text-primary"
            onClick={() => viewAction({ ...element })}
          >
            {element.certificateName}
          </span>
        );
      }

      // if(!element.validityPeriodName){
      //   const _validityForThis = _validityPeriod.find(a=> a.ValidityPeriodId == element.ValidityPeriodId);
      //   element.validityPeriodName = _validityForThis && `${_validityForThis?.ValidityPeriod}`;
      // }

      // element.dateComponent = `${element.validityPeriodName || element.ValidityPeriodName} : ${toReadableDate(element.issueDate || element.IssueDate)} - ${toReadableDate(element.expiryDate || element.ExpiryDate)}`;
      element.dateComponent =
        !element.expiryDate && !element.ExpiryDate
          ? "Indefinite"
          : `${toReadableDate(
              element.issueDate || element.IssueDate
            )} - ${toReadableDate(element.expiryDate || element.ExpiryDate)}`;

      element.statusComponent = (
        <span className="badge badge-pill badge-dim badge-outline-secondary">
          {element.status}
        </span>
      );
      if (!element.issuerName) {
        const _issuerForThis = _issuerList.find(
          (a) => a.IssuerId == element.IssuerId
        );
        element.issuerName =
          _issuerForThis &&
          `${_issuerForThis?.IssuerName} (${_issuerForThis?.IssuerAbbreviation})`;
      }
    });

    setTableData(dataList);
    setInit(false);
  }, [dataList, tableData]);

  return (
    <div>
      {initializing && (
        <div className="center">
          <Spinner />
        </div>
      )}
      {!initializing && (
        <MyTable
          columns={headerList}
          cssArray={["font-weight-500", "", "", "text-right"]}
          data={dataList || []}
          disablePageSize={true}
          enableSearch={false}
          deleteAction={deleteAction}
          emptyMessage="You have not added any compliance records"
        />
      )}
    </div>
  );
}
