import React, { useEffect, useState } from "react";
import MyTable from "../dataTable";
import { toReadableDate } from "../../utils/utilityFunctions";
import Spinner from "../../utils/spinner";

export default function SailingVisitsListComponent({ dataList }) {
  const [tableData, setTableData] = useState(null);
  const [initializing, setInit] = useState(true);

  const headerList = [
    {
      Header: "Sequence",
      accessor: "Sequence",
    },
    {
      Header: "Location",
      accessor: "Location",
    },
    {
      Header: "Location Type",
      accessor: "LocationType",
    },
    {
      Header: "Day On Location",
      accessor: "DayOnLocationFormatted",
    },
    {
      Header: "Time On Location (In Hrs)",
      accessor: "ReqTimeOnLocationInHrs",
    },
    {
      Header: "Deck Area",
      accessor: "ReqDeckArea",
    },
    {
      Header: "Deck Area Measurement Unit",
      accessor: "MeasurementUnit",
    },
    {
      Header: "Bulk Requirement?",
      accessor: "IsBulkRequirement",
    },
  ];

  useEffect(async () => {
    setInit(true);

    setTableData(dataList);
    setInit(false);
  }, [dataList, tableData]);

  return (
    <div
      style={{
        boxShadow: "rgb(221, 221, 221) 2px 2px 5px 0.1px",
        width: "100%",
      }}
    >
      {initializing && <Spinner />}
      {!initializing && (
        <MyTable
          columns={headerList}
          cssArray={["font-weight-500", "", "", "text-center"]}
          data={dataList || []}
          disablePageSize={true}
          enableSearch={false}
          emptyMessage="Visit List is Empty"
        />
      )}
    </div>
  );
}
