import React, { useEffect, useState } from "react";
import MyTable from "../dataTable";
import { toReadableDate } from "../../utils/utilityFunctions";
import BookingRequestService from "../../services/bookingRequestService";
import Spinner from "../../utils/spinner";


export default function VesselsInPoolsViewComponent({ callback, dataList, currentPoolName, pools }) {
  const [tableData, setTableData] = useState(null);
  const [initializing, setInit] = useState(true);

  const headerList = [
    {
      Header: "Name of Vessel",
      accessor: "vesselName",
    },
    {
      Header: "Date Added",
      accessor: "dateCreated",
    },
    // {
    //   Header: "Date of creation",
    //   accessor: "dateCreated",
    // },
    // {
    //   Header: "Is vessel Active?",
    //   accessor: "isActive",
    // },
    {
      Header: "",
      accessor: "removeIcon",
    },
  ];

  useEffect(async () => {
    setInit(true);
    setTableData(dataList);
    setInit(false);
  }, [dataList, tableData]);

  return (
    <div className="small-scrollbar"
      style={{
        overflow: "auto",
        boxShadow: "rgb(221, 221, 221) 2px 2px 5px 0.1px",
        maxHeight: 400,
        width: "100%",
      }}
    >
        <br/>
         <span className="preview-title-lg overline-title">
              List of Vessels in {currentPoolName}
            </span>
      {initializing && <Spinner />}
      {!initializing && (
        <MyTable
          columns={headerList}
          cssArray={["font-weight-500", "text-center",  "text-right"]}
          data={dataList || []}
          disablePageSize={true}
          enableSearch={false}
          emptyMessage="No vessels in this pool"
        />
      )}
    </div>
  );
}
