import apiCall from "../utils/apiCall";
import { dynamicSort, clearAPICache } from "../utils/utilityFunctions";

export default class PoolService {
  // /api/VesselFleet/Get`
  static fetchPool = async () => {
    let response = await apiCall(`/api/VesselFleet/Get`, "GET", null, null, null, true);
    if (response?.Data) {
      response = response?.Data
    }
    return response;
  };
  static postPool = async (payload) => {
    clearAPICache("/api/VesselFleet");
    return await apiCall(`/api/VesselFleet/Post`, "POST", payload);
  };
  static getVesselsInPool = async (fleetId) => {
    let response = await apiCall(`/api/Fleets/FleetVesselPosition?fleetId=${fleetId}`, "GET", null, null, null, true);
    if (response?.Data) {
      response = response?.Data
    }
    return response;
  };

}