import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/mainLayout";
import MyTable from "../../components/dataTable";
import PartyService from "../../services/partyService";
import Spinner from "../../utils/spinner";
import { Link, Navigate, useParams } from "react-router-dom";
import Tabs from "../../utils/tabs";
import LocationService from "../../services/locationService";
import BookingTable from "../../components/bookingTable";
import { checkUserPermission } from "../../utils/utilityFunctions";

export default function ViewParty() {
  const params = useParams();
  const [partyId, setPartyId] = useState(params.partId);
  const [partyDetails, setPartyDetails] = useState({});
  const [contactPersonDetails, setContactPersonDetails] = useState({});
  const [partyCountry, setPartyCountry] = useState({});
  const [partyTypeList, setPartyType] = useState([{}]);
  const [vesselList, setVesselList] = useState([{}]);
  const [partyLocations, setPartyLocations] = useState([{}]);
  const [initializing, setInit] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParam, setSearchParam] = useState("");

  useEffect(async () => {
    setInit(true);
    const party_Types = await PartyService.listPartyTypes();
    if (party_Types) {
      setPartyType(party_Types);
    }
    await retrieveParty();
    await retrieveVessels();
    await retrieveLocations();
    setInit(false);
  }, []);

  const retrieveParty = async () => {
    const partDetails = await PartyService.getPartyWithContactPerson(partyId);
    if (partDetails) {
      setPartyDetails({
        ...partDetails.Party,
        ...partDetails.Country,
        ...partDetails.Region,
      });
      if (partDetails.ContactPersons)
        setContactPersonDetails({
          ...partDetails.ContactPersons[0],
          ...partDetails.ContactPersonRole,
        });
    }
  };
  const retrieveVessels = async () => {
    setInit(true);
    const partDetails = await PartyService.getPartyVessels(partyId);
    if (partDetails) {
      setVesselList(partDetails);
      setInit(false);
    }
  };

  const retrieveLocations = async () => {
    setInit(true);
    let _partyLocations = await LocationService.searchPartyLocations(
      1,
      1000,
      partyId
    );

    _partyLocations = _partyLocations.Data?.map((location) => ({
      ...location,
      IsActiveLocation: location.IsActive ? "Yes" : "No",
      IsAvailable_24_7: location.IsOpen24Hours ? "Yes" : "No",
    }));

    if (_partyLocations) {
      setPartyLocations(_partyLocations || []);
      setInit(false);
    }
  };

  const vesselHeaderList = [
    {
      Header: "VesselName",
      accessor: "VesselName",
      link: "Name",
    },
    {
      Header: "Vessel Type",
      accessor: "vesselType",
    },
    {
      Header: "Vessel IMO",
      accessor: "Imo",
    },
    {
      Header: "Fleet",
      accessor: "fleet",
    },
    {
      Header: "Year Built",
      accessor: "YearOfBuild",
    },
    {
      Header: "Status",
      accessor: "status",
    },
  ];

  const locationsHeaderList = [
    {
      Header: "Location Name",
      accessor: "LocationName",
    },
    {
      Header: "Location Type",
      accessor: "LocationTypeName",
    },
    {
      Header: "Parent Location",
      accessor: "ParentLocationName",
    },
    {
      Header: "Active Location",
      accessor: "IsActiveLocation",
    },
    {
      Header: "Always Open Location",
      accessor: "IsAvailable_24_7",
    },
  ];

  const tabHeaders = [
    { icon: "icon ni ni-view-grid-wd", text: "Party Details" },
    { icon: "icon ni ni-user-list", text: "Vessels" },
    { icon: "icon ni ni-map-pin-fill", text: "Locations" },
  ];

  const tabContent = [
    <div className="card">
      <div className="row">
        <div className="col-sm-6">
          <div className="card-inner">
            <p className="card-text">
              <b>Party Name: </b> <span> {partyDetails.PartyName}</span>
            </p>
            {/* <p className="card-text">
              <b>Phone number :</b> {partyDetails.PhoneNumber}
            </p>
            <p className="card-text">
              <b>Email address :</b> {partyDetails.EmailAddress}
            </p> */}
            <p className="card-text">
              <b>Street: </b>
              <span> {partyDetails.AddressLine1}</span>
            </p>
            <p className="card-text">
              <b>City: </b> {partyDetails.City}
            </p>
            <p className="card-text">
              <b>State :</b> {partyDetails.RegionName}
            </p>
            <p className="card-text">
              <b>Country :</b> {partyDetails.CountryName}
            </p>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="card-inner">
            <p className="card-text">
              <b>Contact Person Name: </b>{" "}
              <span>{contactPersonDetails.FirstName}</span>
            </p>
            <p className="card-text">
              <b>Contact Person Role: </b>
              <span> {contactPersonDetails.ContactPersonRole} </span>
            </p>
            <p className="card-text">
              <b>Email Address (Contact Person): </b>{" "}
              {contactPersonDetails.EmailAddress}
            </p>
            <p className="card-text">
              <b>Phone number :</b> {contactPersonDetails.PhoneNumber}
            </p>
          </div>
        </div>
      </div>
    </div>,
    <>
      {initializing && <Spinner />}
      {!initializing && (
        <MyTable
          columns={vesselHeaderList}
          cssArray={["font-weight-500", "", "", "text-right"]}
          data={vesselList}
          reloadData={(page, pageSize, query) =>
            retrieveVessels(page, pageSize, query)
          }
          emptyMessage="No attached vessel"
          pageSize={5}
          page={currentPage}
          searchQuery={searchParam}
          totalCount={10}
          paginationStyle={2}
        />
      )}
    </>,
    <>
      {initializing && <Spinner />}
      {!initializing && (
        <>
          <div className="gap" />
          <BookingTable
            columns={locationsHeaderList}
            cssArray={["font-weight-500", "", "", "text-right"]}
            data={partyLocations}
            emptyMessage="No locations for this party"
          />
        </>
      )}
    </>,
  ];

  const _tabView = (
    <Tabs headers={tabHeaders} contents={tabContent} contentPadding="0" />
  );

  return (
    <MainLayout title="View Party Details" showFullLoader={initializing}>
      <div className="pl-3">
        {_tabView}

        <div className="mt-5">
          <Link to={"/party-list"}>
            {" "}
            <button className="btn btn-md btn-light btn-dim">
              <em className="icon ni ni-arrow-left pointer mr-2" /> Go back
            </button>
          </Link>

        {checkUserPermission("parties_edit_party") && (  
        <Link to={"/edit-party/" + partyId}>
            <button type="button" className="btn btn-md btn-dark float-right">
              Edit Party
            </button>
          </Link>)}
        </div>
      </div>
    </MainLayout>
  );
}
