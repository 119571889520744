import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CountryService from "../../services/countriesService";
import LocationService from "../../services/locationService";
import ProvinceService from "../../services/provinceService";
import Spinner from "../../utils/spinner";
import BerthOrWarehouseItem from "./BerthOrWarehouseItem";

export default function LocationDetailsComponent({ locationId, location, hideImage }) {
    const [loading, setLoading] = useState(false);
    const [locationData, setLocation] = useState(location || {});
    const [country, setCountry] = useState(null);
    const [countryList, setCountryList] = useState(null);
    const [region, setRegion] = useState(null);
    const [berths, setBerths] = useState([]);
    const [warehouses, setWarehouses] = useState([]);


    const init = async () => {
        if (!location) {
            setLoading(true);
            const _location = await LocationService.get(locationId);
            setLocation(_location ||  {});
            const countries = await CountryService.listCountry();
            setCountryList(countries)
            const regions = await ProvinceService.listProvincesByCountry(_location?.CountryId);
            setRegion(regions);
            if (_location) {
                loadBerthAndWarehouseData(_location.children)
            }

        }
        setLoading(false);
    }
    const countryOfLocation = (countryList || []).find(c => c?.CountryId == locationData?.CountryId);
    const regionOfLocation = (region || []).find(r => r?.RegionId == locationData?.RegionId);

    useEffect(async () => {
        await init();
        if (locationData?.length > 0) {
            loadBerthAndWarehouseData(locationData?.children)
        }

    }, [])

    const loadBerthAndWarehouseData = (data) => {
        data && data?.map((_location) => {
            if (_location.LocationTypeName?.toLowerCase().includes("berth")) {
                setBerths((prev) => [...prev, _location]);
            } else if (
                _location.LocationTypeName.toLowerCase().includes("warehouse")
            ) {
                setWarehouses((prev) => [...prev, _location]);
            }
        });
    };
    const dataItem = (label, value) => {
        if (value == "null") value = "";
        return <li className="col-6 col-lg-3"><p><span className="text-soft small">{label}</span> {value || "-"}</p></li>
    }

    return (
        <>
            {loading && <Spinner />}
            {loading || <div className="card ">
                {locationData?.PhotoUrl && !hideImage && <img src={locationData?.PhotoUrl} className="card-img-top p-2" alt="" />}
                {!locationData?.PhotoUrl && <p className="text-muted">No photo available</p>}
                <div className="sp-plan-desc card-inner">
                    <ul className="row gx-1">
                        {dataItem("Location type", locationData?.LocationTypeName)}
                        {locationData?.ParentLocationName && dataItem("Parent location", locationData?.ParentLocationName)}
                        {dataItem("Country", countryOfLocation?.CountryName)}
                        {locationData?.City && dataItem("City", locationData?.City)}
                        {!locationData?.City && dataItem("Region", regionOfLocation?.RegionName)}
                        {dataItem("Short Name", locationData?.ShortName)}
                        {dataItem("Is location currently active?", locationData?.IsActive ? "Yes" : "No")}
                        {locationData?.Longitude && dataItem("Co-ordinates", (!locationData?.Longitude || !locationData?.Latitude) ? null : `Long: ${locationData.Longitude} x Lat: ${locationData.Latitude}`)}
                        {locationData?.PartyName && dataItem("Location manager", locationData?.PartyName)}
                        {locationData?.ContactPerson && dataItem("Contact Person", `${locationData?.ContactPerson?.FirstName} - ${locationData?.ContactPerson?.LastName}` )}
                        {dataItem("Operating hours", locationData?.IsOpen24Hours ? "Open 24 hours" : `${locationData?.OpeningTime?.substring(0, 5)} - ${locationData?.ClosingTime?.substring(0, 5)}`)}
                        {berths && berths?.map((item, index) => {
                            return <li className="col-6 col-lg-3"><p><span className="text-soft small pl-0">{`Berth (${index + 1})`}</span>{item?.LocationName}</p></li>
                        })
                        }
                        {warehouses && warehouses?.map((item, index) => {
                            return <li className="col-6 col-lg-3"><p><span className="text-soft small pl-0">{`Wharehouse (${index + 1})`}</span>{item?.LocationName}</p></li>
                        })
                        }
                    </ul>
                </div>


            </div>}
        </>
    );
}

