import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import Spinner from "../../utils/spinner";
import { Validator, ValidateGroup, ClearValidator } from "../validator";
import toastr from "../../utils/toastr";
import VoyageService from "../../services/voyageService";
import ReactTooltip from "react-tooltip";
import ConfigurationService from "../../services/configurationService";
import CustomModal from "../../utils/customModal";
import { toReadableDate, camelizeKeys } from "../../utils/utilityFunctions";
import { forEach } from "lodash";

export default function FinalLocationCostComponent({
  voyagePlanningId,
  payload,
  currency,
}) {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(async () => {
    await init();
  }, []);


  const init = async () => {
    setLoading(true);

    setLoading(false);
  };

  const pageActions = (
    <div className="toggle-wrap nk-block-tools-toggle">
      <a
        href="#"
        className="btn btn-icon btn-trigger toggle-expand mr-n1"
        data-target="pageMenu"
      >
        <em className="icon ni ni-menu-alt-r" />
      </a>
      <div className="toggle-expand-content" data-content="pageMenu">
        <ul className="nk-block-tools g-3">
          <li>
            <button
              onClick={() => window.history.back()}
              className="btn btn-white btn-outline-light"
            >
              <em className="icon ni ni-arrow-left" />
              <span>Return to Previous Page</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );

  return (
    <>
      {loading ? (
        <div className="w-100 text-center center my-5 py-5">
          <Spinner />
        </div>
      ) : (
        <>
          <form>
            <div className="card-inner">
              {payload?.map((location, index) => (
                <div key={index}>
                  <>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label small">Location</label>
                          <div className="form-control-wrap">
                            <input
                              type="text"
                              className="form-control"
                              value={
                                location?.locationName +
                                " - " +
                                location?.locationTypeName
                              }
                              disabled
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-2">
                        <div className="form-group ">
                          <label className="form-label small">
                            Cost Incurred
                          </label>
                          <div className="form-control-wrap">
                            <input
                              type="number"
                              className="form-control"
                              value={location?.costIncurred}
                              disabled
                            // step="any"
                            // onChange={(e) => {
                            //   setPayload((prev) => {
                            //     prev[index].costIncurred = e.target.value;
                            //     return prev;
                            //   });
                            // }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-2">
                        <div
                          className="form-group"
                          style={{ zIndex: 100 - index }}
                        >
                          <label className="form-label small">Currency</label>
                          <div className="form-control-wrap">
                            <Select
                              value={currency
                                .map((c) => ({
                                  ...c,
                                  label: c.CorrespondingData,
                                  value: c.ConfigurationId,
                                }))
                                // .find(
                                //   (a) =>
                                //     a.value ==
                                //     payload[index]?.currencyConfigurationId
                                // )
                              }
                              isDisabled={true}
                            // options={currency.map((c) => ({
                            //   ...c,
                            //   label: c.CorrespondingData,
                            //   value: c.ConfigurationId,
                            // }))}
                            // onChange={(e) => {
                            //   setPayload((prev) => {
                            //     prev[index].currencyConfigurationId = e.value;
                            //     return prev;
                            //   });
                            // }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="gap" />
                    </div>
                  </>
                </div>
              ))}
            </div>
          </form>
        </>
      )}
    </>
  );
}
