import React, { useEffect, useState,useMemo } from 'react';
import { Link } from 'react-router-dom';


export default function VoyageTableComponent({ columns, rowData, overflowActions,voyageHistoryCount, callback }) {
    const [rowData2, setRowData2] = useState(rowData);
    const [currentPage, setCurrentPage] = useState(1);
    const [PageSize, setPageSize] = useState(10);

    const testT = function(){
        
        // setRowData2(null);
     }
     useEffect(async () => {
        changePage(2);
        changePage(1);
    }, []);

     const changePage= async (p) => {
         if(p < 1) p =1;
         if(p > parseInt(voyageHistoryCount/PageSize) && voyageHistoryCount>0 ) p = parseInt(voyageHistoryCount/PageSize);
          rowData = await callback(p,PageSize);
         if(rowData != null){
            setCurrentPage(p);
            setRowData2(rowData);
         } 
     }
     const currentTableData =  useMemo( () => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
       return rowData2;
      }, [currentPage]);
    
    const rowTemplate = (row_data) => {
        if(row_data == null) return <div> </div>;
        const rowKeys = Object.keys(row_data);
        return <div className="nk-tb-item"> {rowKeys.map((property, index) => <div className={`nk-tb-col ${columns[index].size && `tb-col-${columns[index].size}`}`}>
            {typeof row_data[property] === "object"? row_data[property]: <span>{row_data[property]}</span>} 
        </div>)}
        </div>
    }
    const paginationTemplate = () => {
        const pages  =  [...Array(parseInt(voyageHistoryCount/PageSize)).keys()];
        return  <ul className="pagination justify-content-center justify-content-md-start">
                    <li  onClick={() =>  changePage(currentPage-1)} className="page-item"><a className="page-link">Prev</a></li>
                    {pages.map((property, index) => 
                    <li onClick={ () => changePage(index+1)} className={`page-item ${currentPage== index+1? "active": ""}`}><a className="page-link" >{index+1}</a></li>
                )}
                <li onClick={() => changePage(currentPage+1)} className="page-item"><a className="page-link" >Next</a></li>
            </ul>
    }

    return (
        <div className="">
            <div className="card-inner-group">
                <div className="card-inner position-relative card-tools-toggle">
                    <div className="card-title-group">
                        <div className="card-tools">
                            <div className="form-inline flex-nowrap gx-3">
                            </div>{/* .form-inline */}
                        </div>{/* .card-tools */}
                        <div className="card-tools mr-n1">
                            <ul className="btn-toolbar gx-1">
                                <li>
                                    <Link to={"#"} className="btn btn-icon search-toggle toggle-search" data-target="search"><em className="icon ni ni-search" /></Link>
                                </li>{/* li */}
                                <li className="btn-toolbar-sep" />{/* li */}
                                <li>
                                    <div className="toggle-wrap">
                                        <Link to={"#"} className="btn btn-icon btn-trigger toggle" data-target="cardTools"><em className="icon ni ni-menu-right" /></Link>
                                        <div className="toggle-content" data-content="cardTools">
                                            <ul className="btn-toolbar gx-1">
                                                <li className="toggle-close">
                                                    <Link to={"#"} className="btn btn-icon btn-trigger toggle" data-target="cardTools"><em className="icon ni ni-arrow-left" /></Link>
                                                </li>{/* li */}
                                                <li>
                                                    <div className="dropdown">
                                                        <Link to={"#"} className="btn btn-trigger btn-icon dropdown-toggle" data-toggle="dropdown">
                                                            <div className="dot dot-primary">
                                                            </div>
                                                            <em className="icon ni ni-filter-alt" />
                                                        </Link>
                                                        <div className="filter-wg dropdown-menu dropdown-menu-xl dropdown-menu-right">
                                                            <div className="dropdown-head">
                                                                <span className="sub-title dropdown-title">Filter
                                                                    Vessels</span>
                                                                <div className="dropdown">
                                                                    <Link to={"#"} className="btn btn-sm btn-icon">
                                                                        <em className="icon ni ni-more-h" />
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                            <div className="dropdown-body dropdown-body-rg">
                                                                <div className="row gx-6 gy-3">
                                                                    {/* <div class="col-6">
                                                                                    <div class="custom-control custom-control-sm custom-checkbox">
                                                                                        <input type="checkbox" class="custom-control-input" id="hasBalance">
                                                                                        <label class="custom-control-label" for="hasBalance"> Have Balance</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-6">
                                                                                    <div class="custom-control custom-control-sm custom-checkbox">
                                                                                        <input type="checkbox" class="custom-control-input" id="hasKYC">
                                                                                        <label class="custom-control-label" for="hasKYC"> KYC Verified</label>
                                                                                    </div>
                                                                                </div> */}
                                                                    <div className="col-6">
                                                                        <div className="form-group">
                                                                            <label className="overline-title overline-title-alt">Voyage Type</label>
                                                                            <select className="form-select form-select-sm">
                                                                                <option value="any">
                                                                                    In motion
                                                                                </option>
                                                                                <option value="investor">
                                                                                    In port
                                                                                </option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <div className="form-group">
                                                                            <button type="button" className="btn btn-secondary">Filter</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="dropdown-foot between">
                                                                <a className="clickable" href="#">Reset
                                                                    Filter</a>
                                                                <Link to={"#"}>Save Filter</Link>
                                                            </div>
                                                        </div>{/* .filter-wg */}
                                                    </div>{/* .dropdown */}
                                                </li>{/* li */}
                                                <li>
                                                    <div className="dropdown">
                                                        <Link to={"#"} className="btn btn-trigger btn-icon dropdown-toggle" data-toggle="dropdown">
                                                            <em className="icon ni ni-setting" />
                                                        </Link>
                                                        <div className="dropdown-menu dropdown-menu-xs dropdown-menu-right">
                                                            <ul className="link-check">
                                                                <li><span>Show</span></li>
                                                                <li className="active"><Link to={"#"}>10</Link></li>
                                                                <li><Link to={"#"}>20</Link></li>
                                                                <li><Link to={"#"}>50</Link></li>
                                                            </ul>
                                                            <ul className="link-check">
                                                                <li><span>Order</span></li>
                                                                <li className="active"><Link to={"#"}>DESC</Link>
                                                                </li>
                                                                <li><Link to={"#"}>ASC</Link></li>
                                                            </ul>
                                                        </div>
                                                    </div>{/* .dropdown */}
                                                </li>{/* li */}
                                            </ul>{/* .btn-toolbar */}
                                        </div>{/* .toggle-content */}
                                    </div>{/* .toggle-wrap */}
                                </li>{/* li */}
                            </ul>{/* .btn-toolbar */}
                        </div>{/* .card-tools */}
                    </div>{/* .card-title-group */}
                    <div className="card-search search-wrap" data-search="search">
                        <div className="card-body">
                            <div className="search-content">
                                <Link to={"#"} className="search-back btn btn-icon toggle-search" data-target="search"><em className="icon ni ni-arrow-left" /></Link>
                                <input type="text" className="form-control border-transparent form-focus-none" placeholder="Search by owner name or vessel name" />
                                <button className="search-submit btn btn-icon"><em className="icon ni ni-search" /></button>
                            </div>
                        </div>
                    </div>{/* .card-search */}
                </div>{/* .card-inner */}
                <div className="card-inner p-0">
                    <div className="nk-tb-list nk-tb-ulist is-compact">
                        <div className="nk-tb-item nk-tb-head">
                            {
                                columns.map((column, index) => <div key={index} className={`nk-tb-col ${column.size && `tb-col-${column.size}`}`}><span className="fw-bold fs-15px text-dark">{column.label}</span></div>)
                            }

                            {overflowActions && <div className="nk-tb-col nk-tb-col-tools text-right">

                            </div>}
                        </div>{/* .nk-tb-item */}
                    
                      { rowData2?
                          currentTableData.map( (row_data, index)=> rowTemplate(row_data)) :''
                      }
                    </div>{/* .nk-tb-list */}
                </div>{/* .card-inner */}
                <div className="card-inner">
                      {
                         paginationTemplate()
                      }
                    {/* .pagination */}
                </div>{/* .card-inner */}
            </div>{/* .card-inner-group */}
        </div>


    );
}
