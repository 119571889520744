import React, { useEffect, useState } from "react";
import PartyModal from "../Modals/partyModal";
import MainLayout from "../../layouts/mainLayout";
import CountryService from "../../services/countriesService";
import DPService from "../../services/dynamicPositioning";
import FleetService from "../../services/fleetService";
import PartyService from "../../services/partyService";
import VesselService from "../../services/vesselService";
import Spinner from "../../utils/spinner";
import ProvinceService from "../../services/provinceService";
import RolesService from "../../services/rolesService";
import payloadUpdater from "../../utils/payloadUpdater";
import ComplianceService from "../../services/complianceService";
import Select from "react-select";
import FileInput from "../../components/fileInput";

import CertificateService from "../../services/certificateService";
import ContactPersonService from "../../services/contactPersonService";

export default function EditVessel() {
  const [payload, setPayload] = useState({
    VesselSpecificationId: "",
    Mmsi: "",
    Imo: "",
    ShipId: "",
    VesselName: "",
    VesselTypeId: "",
    YearOfBuild: "",
    PlaceOfBuild: "",
    BreadthExtreme: "",
    SummerDwt: "",
    DisplacementSummer: "",
    CallSign: "",
    Draught: "",
    DraughtMax: "",
    LengthOverall: "",
    FuelConsumption: "",
    SpeedMax: "",
    SpeedService: "",
    LiquidOil: "",
    GrossRegisteredTonnage: "",
    RateOfTurn: "",
    Width: "",
    CountryId: "",
    PhotoUrl: "",
    VesselUrl: "",
    Beam: "",
    Bhp: "",
    LengthBetweenPerpendicular: "",
    NoOfCrewCabins: "",
    NoOfPassengerCabins: "",
    DeckArea: "",
    DeckCargoCapacity: "",
    DynamicPositioningId: "",
  });
  const [vesselOwnerPayload, setVesselOwnerPayload] = useState({
    PartyId: "",
    PartyName: "",
    PartyTypeId: "",
    EmailAddress: "",
    PhoneNumber: "",
    AddressLine1: "",
    AddressLine2: "",
    RegionId: "",
    CountryId: "",
  });
  const [contactPersonPayload, setContactPersonPayload] = useState({
    ContactPersonId: "",
    EmailAddress: "",
    FirstName: "",
    LastName: "",
    PartyId: "",
    PhoneNumber: "",
    RoleId: "",
  });
  const [technicalMgrPayload, setTechnicalMgrPayload] = useState({
    PartyId: "",
    PartyName: "",
    PartyTypeId: "",
    EmailAddress: "",
    PhoneNumber: "",
    AddressLine1: "",
    AddressLine2: "",
    RegionId: "",
    CountryId: "",
  });
  const [commercialMgrPayload, setCommercialMgrPayload] = useState({
    PartyId: "",
    PartyName: "",
    PartyTypeId: "",
    EmailAddress: "",
    PhoneNumber: "",
    AddressLine1: "",
    AddressLine2: "",
    RegionId: "",
    CountryId: "",
  });
  const [ismMgrPayload, setIsmMgrPayload] = useState({
    PartyId: "",
    PartyName: "",
    PartyTypeId: "",
    EmailAddress: "",
    PhoneNumber: "",
    AddressLine1: "",
    AddressLine2: "",
    RegionId: "",
    CountryId: "",
  });
  const [compliancePayload, setCompliancePayload] = useState({
    EntryId: "",
    VesselSpecificationId: "",
    CertificateNameId: "",
    IssuerId: "",
    PlaceOfIssue: "",
    IssueDate: "",
    ExpiryDate: "",
    StatusId: "",
    ValidityPeriodId: "",
    Remarks: null,
    CertificateDocumentId: null,
  });
  const [vesselDetail, setVesselDetail] = useState(null);
  const [tab, setTab] = useState(1);
  const [storageTanks, setStorageTanks] = useState([{}]);
  const [fleetList, setFleetList] = useState([{}]);
  const [vesselFleetList, setVesselFleetList] = useState([{}]);
  const [dypValue, setDp] = useState([{}]);
  const [vesselDp, setVesselDp] = useState();
  const [vesselOwnerOptions, setVesselOwnerOptions] = useState();
  const [fleetForVessel, setFleetForVessel] = useState("");
  const [vesselParty, setVesselParty] = useState([{}]);
  const [allParties, setAllParties] = useState([{}]);
  const [partyTypes, setPartyTypes] = useState([]);
  const [provinces, setProvinces] = useState({});
  const [countryList, setCountry] = useState([{}]);
  const [partyList, setParty] = useState([{}]);
  const [roles, setRoles] = useState([{}]);
  const [selectedCpRole, setSelectedCpRole] = useState();
  const [vesselOwner, setVesselOwner] = useState();
  const [technicalManager, setTechnicalManager] = useState();
  const [commercialManager, setCommercialManager] = useState();
  const [ismManager, setIsmManager] = useState();
  const [vesselOwnerCountry, setVesselOwnerCountry] = useState();
  const [techManagerCountry, setTechManagerCountry] = useState();
  const [techMgrProvince, setTechMgrProvince] = useState();
  const [commManagerCountry, setCommManagerCountry] = useState();
  const [commMgrProvince, setCommMgrProvince] = useState();
  const [ismManagerCountry, setIsmManagerCountry] = useState();
  const [ismMgrProvince, setIsmMgrProvince] = useState();
  const [selectedVesselCertificate, setSelectedVesselCertificate] = useState();
  const [selectedVesselIssuer, setSelectedVesselIssuer] = useState();
  const [selectedVesselStatus, setSelectedVesselStatus] = useState();
  const [selectedValidtyPeriod, setSelectedValidityPeriod] = useState();
  const [provinceList, setProvinceList] = useState();
  const [vesselOwnerProvince, setVesselOwnerProvince] = useState();
  const [selectedVesselFleetType, setSelectedVesselFleetType] = useState([{}]);
  const [allVesselType, setAllVesselType] = useState([{}]);
  const [selectedCertificateCountry, setSelectedCertificateCountry] = useState(
    []
  );
  const [selectedFlag, setSelectedFlag] = useState({});
  const [certificateName, setCertificateName] = useState([]);
  const [certValidityPeriod, setCertValidtyPeriod] = useState([]);
  const [issuingAuthority, setIssuingAuthority] = useState([]);
  const [vesselList, setVesselList] = useState([]);
  const [certStatus, setCertStatus] = useState([]);
  const [contactPersonList, setContactPersonList] = useState();
  const [selectedContactPerson, setSelectedContactPerson] = useState();
  const [selectedVessel, setSelectedVessel] = useState([]);
  const [savedVesselCertificates, setSavedCertificates] = useState([]);
  const [selectedExpiryDate, setSelectedExpiryDate] = useState(new Date());
  const [selectedIssueDate, setSelectedIssueDate] = useState(new Date());
  const [postVesselData, setPostVesselData] = useState({});
  const [postSampleData, setPostSampleData] = useState({});
  const [postId, setPostId] = useState("");
  const [specificProvince, setSpecificProvince] = useState("");
  const [vesselOwnerEntryId, setVesselOwnerEntryId] = useState("");
  const [techMgrEntryId, setTechMgrEntryId] = useState("");
  const [commMgrEntryId, setCommMgrEntryId] = useState("");
  const [isMgrEntryId, setIsMgrEntryId] = useState("");
  const [contactPersonEntryId, setContactPersonEntryId] = useState("");
  const [complianceEntryId, setComplianceEntryId] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await Promise.all([
      await CertificateService.listAll(),
      await ComplianceService.fetchValidityPeriod(),
      await ComplianceService.certificateIssuer(),
      await VesselService.listAllVessels(),
      await CountryService.listCountry(),
      await ComplianceService.certificateValidityList(),
      await ComplianceService.certificateStatus(),
      await FleetService.fleetByVessel(),
      await FleetService.listFleet(),
      await VesselService.listOfVesselTypes(),
      await DPService.dpList(),
      await PartyService.getVesselParties(),
      await PartyService.listParty(),
      await PartyService.listPartyTypes(),
      await ProvinceService.listOfProvinces(),
      await PartyService.contactPersonList(),
      await PartyService.partyRoles(),
      //TO DO: import Roles
    ])
      .then((response) => {
        setCertificateName(response[0]);
        setCertValidtyPeriod(response[1]);
        setIssuingAuthority(response[2]);
        setVesselList(response[3]);
        setCountry(response[4]);
        setSavedCertificates(response[5]);
        const certStatusList = response[6].filter(
          (stat) =>
            stat.ParentStatusId === "0e35c9ba-e5d1-41f7-a4b9-78c3a9ceef44"
        ); //save this in the .env file.
        setCertStatus(certStatusList);
        setVesselFleetList(response[7]);
        setFleetList(response[8]);
        setAllVesselType(response[9]);
        setDp(response[10]);
        setVesselParty(response[11]);
        setAllParties(response[12]);
        setPartyTypes(response[13]);
        setProvinceList(response[14]);
        setContactPersonList(response[15]);
        setRoles(response[16]);
      })
      .catch((err) => {});
  };

  const toNextTab = () => {
    let nextTab = tab + 1;
    if (nextTab > 4) nextTab = 4;
    if (nextTab === 2) {
    }
    if (nextTab === 3) {
      //add validations for tab 2.
    }
    if (nextTab === 4) {
    }
    setTab(nextTab);
    /*  setTimeout(() => {
             formHelper();
         }, 100); */
  };
  const submit = async (e) => {
    e.preventDefault();
    try {
      const saveEditedVesselSpec = await VesselService?.postEditVessel(
        postId,
        payload
      );
      const saveEditedVesselOwner = await PartyService?.postEditedParty(
        vesselOwnerEntryId,
        vesselOwnerPayload
      );
      const saveEditedTechnicalMgr = await PartyService?.postEditedParty(
        techMgrEntryId,
        technicalMgrPayload
      );
      const saveEditedCommercialMgr = await PartyService?.postEditedParty(
        commMgrEntryId,
        commercialMgrPayload
      );
      const saveEditedIsMgrr = await PartyService?.postEditedParty(
        isMgrEntryId,
        ismMgrPayload
      );
      const saveEditedContact =
        await ContactPersonService?.postEditedContactPerson(
          contactPersonEntryId,
          contactPersonPayload
        );
      const saveEditedCompliance = await ComplianceService?.postEditVessel(
        complianceEntryId,
        compliancePayload
      );
    } catch (e) {
      console.error(e);
    }
  };
  const toPreviousTab = () => {
    let prevTab = tab - 1;
    if (prevTab < 1) prevTab = 1;
    setTab(prevTab);
  };
  const onVesselNameChange = (vessel) => {
    //#region  for VesselSpec -- Tab 1 and Tab2

    setSelectedVessel(vessel);
    const vesselDetails = vessel;

    let selectedVesselSpecId = vessel.VesselSpecificationId;
    setPostId(selectedVesselSpecId);
    let fleetForSelectedVessel = vesselFleetList.find(
      (vesselFleet) =>
        vesselFleet.VesselSpecificationId === vessel.VesselSpecificationId
    );

    let fleetDetails = fleetList.find(
      (exactFleet) => exactFleet?.FleetId === fleetForSelectedVessel?.FleetId
    );
    setFleetForVessel(fleetDetails); //fleetForVessel

    let chosenVesseltype = allVesselType.find(
      (type) => type?.VesselTypeId === vessel?.VesselTypeId
    );
    setSelectedVesselFleetType(chosenVesseltype);

    let vesselCountry = countryList.find(
      (country) => country?.CountryId === vessel?.CountryId
    );
    setSelectedFlag(vesselCountry);

    let selectedVesselDp = dypValue.find(
      (dp) => dp?.DynamicPositioningId === vessel?.DynamicPositioningId
    );
    setVesselDp({
      DynamicPositioningId: selectedVesselDp?.DynamicPositioningId,
      DynamicPositioning: selectedVesselDp?.DynamicPositioning,
    });

    //#endregion for Vessel Spec

    //#region for vessel Owner
    let relatedParty = vesselParty.filter(
      (party) => party?.VesselSpecificationId === vessel?.VesselSpecificationId
    );
    sessionStorage.setItem("relatedVesselParty", JSON.stringify(relatedParty)); //This an inverted intention to make vesselRelatedParty Global
    let vesselRelatedParty = sessionStorage.getItem("relatedVesselParty");
    vesselRelatedParty = JSON.parse(vesselRelatedParty);
    let vesselOwner = partyTypes.find(
      (p) => p.PartyType?.toLowerCase() === "vessel owner"
    );

    let selectVesselOwner =
      vesselOwner?.PartyTypeId !== undefined &&
      vesselRelatedParty.find(
        (a) => a?.PartyTypeId === vesselOwner?.PartyTypeId
      );
    let selectedVesselOwnerInfo =
      selectVesselOwner?.PartyId !== undefined &&
      allParties.find((x) => x?.PartyId === selectVesselOwner?.PartyId);
    let vesselOwnerId = selectedVesselOwnerInfo?.PartyId;
    setVesselOwnerEntryId(vesselOwnerId);
    // let vesselOwnerCountry =
    setVesselOwner(selectedVesselOwnerInfo);
    setVesselOwnerOptions({
      PartyName: selectedVesselOwnerInfo?.PartyName,
      PartyId: selectedVesselOwnerInfo?.PartyId,
    });

    let ownerCountry = countryList.find(
      (country) => country?.CountryId === selectedVesselOwnerInfo?.CountryId
    );
    setVesselOwnerCountry(ownerCountry);

    let ownerProvinceByCountry = provinceList.filter(
      (regions) => regions?.CountryId === vesselOwnerPayload.CountryId
    );
    setSpecificProvince(ownerProvinceByCountry);

    let ownerProvince =
      selectedVesselOwnerInfo?.RegionId !== undefined &&
      provinceList.find(
        (region) => region?.RegionId === selectedVesselOwnerInfo?.RegionId
      );
    setVesselOwnerProvince(ownerProvince); //vesselOwnerProvince

    let contactPersonInfo = contactPersonList.find(
      (cc) => cc?.PartyId === selectVesselOwner?.PartyId
    );
    setSelectedContactPerson(contactPersonInfo);
    setContactPersonEntryId(contactPersonInfo?.ContactPersonId);

    let roleOfContactPerson = roles.find(
      (r) => r?.RoleId === contactPersonInfo?.RoleId
    );
    setSelectedCpRole(roleOfContactPerson);
    //#endregion for vessel owner

    //#region for Technical Manager
    let vesselTechnicalMgr = partyTypes.find(
      (p) => p.PartyType?.toLowerCase() === "technical manager"
    );
    let selectTechnicalMgr =
      vesselTechnicalMgr?.PartyTypeId !== undefined &&
      vesselRelatedParty.find(
        (a) => a?.PartyTypeId === vesselTechnicalMgr?.PartyTypeId
      ); //use filter if there are more than 1
    let selectedTechnicalMgrInfo =
      selectTechnicalMgr?.PartyId !== undefined &&
      allParties.find((x) => x?.PartyId === selectTechnicalMgr?.PartyId);
    setTechMgrEntryId(selectedTechnicalMgrInfo?.PartyId);
    setTechnicalManager(selectedTechnicalMgrInfo);
    let tMCountry = countryList.find(
      (country) => country?.CountryId === selectedTechnicalMgrInfo?.CountryId
    );
    setTechManagerCountry({
      CountryId: tMCountry?.CountryId,
      CountryName: tMCountry?.CountryName,
    });
    let tmProvince = provinceList.find(
      (region) => region?.RegionId === selectedTechnicalMgrInfo?.RegionId
    );
    setTechMgrProvince(tmProvince);
    //#endregion

    //#region for Commercial Manager
    let vesselCommercialMgr = partyTypes.find(
      (p) => p.PartyType?.toLowerCase() === "commercial manager"
    );
    let selectCommercialMgr =
      vesselCommercialMgr?.PartyTypeId !== undefined &&
      vesselRelatedParty.find(
        (a) => a?.PartyTypeId === vesselCommercialMgr?.PartyTypeId
      ); //use filter if there are more than 1
    let selectedCommercialMgr =
      selectCommercialMgr?.PartyId !== undefined &&
      allParties.find((x) => x?.PartyId === selectCommercialMgr?.PartyId);
    setCommercialManager(selectedCommercialMgr);
    setCommMgrEntryId(selectedCommercialMgr?.PartyId);
    let commMgrCountry = countryList.find(
      (country) => country?.CountryId === selectedCommercialMgr?.CountryId
    );
    setCommManagerCountry(commMgrCountry);
    let commMgrProvince = provinceList.find(
      (region) => region?.RegionId === selectedCommercialMgr?.RegionId
    );
    setCommMgrProvince(commMgrProvince);
    //#endregion

    //#region for International Safety Manager
    let vesselIsMgr = partyTypes.find(
      (p) => p.PartyType?.toLowerCase() === "international safety manager"
    );
    let selectIsMgr =
      vesselIsMgr?.PartyTypeId !== undefined &&
      vesselRelatedParty.find(
        (a) => a?.PartyTypeId === vesselIsMgr?.PartyTypeId
      ); //use filter if there are more than 1
    let selectedIsmMgr =
      selectCommercialMgr?.PartyId !== undefined &&
      allParties.find((x) => x?.PartyId === selectIsMgr?.PartyId);
    setIsmManager(selectedIsmMgr);
    setIsMgrEntryId(selectedIsmMgr?.PartyId);
    let ismMgrCountry = countryList.find(
      (country) => country?.CountryId === selectedIsmMgr?.CountryId
    );
    setIsmManagerCountry(ismMgrCountry);
    let ismMgrProvince = provinceList.find(
      (region) => region?.RegionId === selectedIsmMgr?.RegionId
    );
    setIsmMgrProvince(ismMgrProvince);
    //#endregion

    //#region for Compliance
    //TODO: certificate would help to retrieve EntryId
    const certificate = savedVesselCertificates.find(
      (cert) => cert?.VesselSpecificationId === vessel?.VesselSpecificationId
    );
    setComplianceEntryId(certificate?.EntryId);

    let selectVesselCert = certificateName.find(
      (certName) =>
        certName?.CertificateNameId === certificate?.CertificateNameId
    );
    setSelectedVesselCertificate(selectVesselCert);

    let selectIssuer = issuingAuthority.find(
      (issueBody) => issueBody?.IssuerId === certificate?.IssuerId
    );
    setSelectedVesselIssuer(selectIssuer);

    let certificateCountry = countryList.find(
      (country) => country?.CountryId === certificate?.PlaceOfIssue
    );
    setSelectedCertificateCountry({
      CountryId: certificateCountry?.CountryId,
      CountryName: certificateCountry?.CountryName,
    });

    let exactValidityPeriod = certValidityPeriod.find(
      (certValidty) =>
        certValidty?.ValidityPeriodId === certificate?.ValidityPeriodId
    );
    setSelectedValidityPeriod({
      ValidityPeriod: exactValidityPeriod?.ValidityPeriod,
      ValidityPeriodId: exactValidityPeriod?.ValidityPeriodId,
    });

    let extractedStatus =
      certificate?.StatusId !== undefined &&
      certStatus?.find(
        (certValidity) => certValidity?.StatusId === certificate?.StatusId
      );
    setSelectedVesselStatus({
      StatusId: extractedStatus?.StatusId,
      Status: extractedStatus?.Status,
    });

    // let preExpiryDate = certificate.ExpiryDate;
    let d = new Date(certificate?.ExpiryDate).toISOString();
    let b = d.slice(0, d.indexOf("T"));
    setSelectedExpiryDate(b);

    let dd = new Date(certificate?.IssueDate).toISOString();
    let bb = dd.slice(0, dd.indexOf("T"));
    setSelectedIssueDate(bb);

    //#endregion

    setPayload({
      VesselSpecificationId: vessel?.VesselSpecificationId,
      Mmsi: vesselDetails?.Mmsi,
      Imo: vesselDetails?.Imo,
      ShipId: vesselDetails?.ShipId,
      VesselName: vesselDetails?.VesselName,
      VesselTypeId: vesselDetails?.VesselTypeId,
      YearOfBuild: vesselDetails?.YearOfBuild,
      PlaceOfBuild: vesselDetails?.PlaceOfBuild,
      BreadthExtreme: vesselDetails?.BreadthExtreme,
      SummerDwt: vesselDetails?.SummerDwt,
      DisplacementSummer: vesselDetails?.DisplacementSummer,
      CallSign: vesselDetails?.CallSign,
      Draught: vesselDetails?.Draught,
      DraughtMax: vesselDetails?.DraughtMax,
      LengthOverall: vesselDetails?.LengthOverall,
      FuelConsumption: vesselDetails?.FuelConsumption,
      SpeedMax: vesselDetails?.SpeedMax,
      SpeedService: vesselDetails?.SpeedService,
      LiquidOil: vesselDetails?.LiquidOil,
      GrossRegisteredTonnage:
        vesselDetails?.GrossRegisteredTonnage || vesselDetails?.Grt,
      Tot: vesselDetails?.Rot,
      Width: vesselDetails?.Width,
      CountryId: vesselDetails?.CountryId,
      PhotoUrl: vesselDetails?.PhotoUrl,
      VesselUrl: vesselDetails?.VesselUrl,
      Beam: vesselDetails?.Beam,
      Bhp: vesselDetails?.Bhp,
      LengthBetweenPerpendicular: vesselDetails?.LengthBetweenPerpendicular,
      NoOfCrewCabins: vesselDetails?.NoOfCrewCabins,
      NoOfPassengerCabins: vesselDetails?.NoOfPassengerCabins,
      DeckArea: vesselDetails?.DeckArea,
      DeckCargoCapacity: vesselDetails?.DeckCargoCapacity,
      DynamicPositioningId: selectedVesselDp?.DynamicPositioningId,
    });

    setVesselOwnerPayload({
      PartyId: selectedVesselOwnerInfo?.PartyId,
      PartyName: selectedVesselOwnerInfo?.PartyName,
      PartyTypeId: selectedVesselOwnerInfo?.PartyTypeId,
      EmailAddress: selectedVesselOwnerInfo?.EmailAddress,
      PhoneNumber: selectedVesselOwnerInfo?.PhoneNumber,
      AddressLine1: selectedVesselOwnerInfo?.AddressLine1,
      AddressLine2: selectedVesselOwnerInfo?.AddressLine2,
      RegionId: selectedVesselOwnerInfo?.RegionId,
      CountryId: selectedVesselOwnerInfo?.CountryId,
    });
    setContactPersonPayload({
      ContactPersonId: contactPersonInfo?.ContactPersonId,
      EmailAddress: contactPersonInfo?.EmailAddress,
      FirstName: contactPersonInfo?.FirstName,
      LastName: contactPersonInfo?.LastName,
      PartyId: contactPersonInfo?.PartyId,
      PhoneNumber: contactPersonInfo?.PhoneNumber,
      RoleId: contactPersonInfo?.RoleId,
    });
    setTechnicalMgrPayload({
      PartyId: selectedTechnicalMgrInfo?.PartyId,
      PartyName: selectedTechnicalMgrInfo?.PartyName,
      PartyTypeId: selectedTechnicalMgrInfo?.PartyTypeId,
      EmailAddress: selectedTechnicalMgrInfo?.EmailAddress,
      PhoneNumber: selectedTechnicalMgrInfo?.PhoneNumber,
      AddressLine1: selectedTechnicalMgrInfo?.AddressLine1,
      AddressLine2: selectedTechnicalMgrInfo?.AddressLine2,
      RegionId: selectedTechnicalMgrInfo?.RegionId,
      CountryId: selectedTechnicalMgrInfo?.CountryId,
    });
    setCommercialMgrPayload({
      PartyId: selectedCommercialMgr?.PartyId,
      PartyName: selectedCommercialMgr?.PartyName,
      PartyTypeId: selectedCommercialMgr?.PartyTypeId,
      EmailAddress: selectedCommercialMgr?.EmailAddress,
      PhoneNumber: selectedCommercialMgr?.PhoneNumber,
      AddressLine1: selectedCommercialMgr?.AddressLine1,
      AddressLine2: selectedCommercialMgr?.AddressLine2,
      RegionId: selectedCommercialMgr?.RegionId,
      CountryId: selectedCommercialMgr?.CountryId,
    });
    setIsmMgrPayload({
      PartyId: selectedIsmMgr?.PartyId,
      PartyName: selectedIsmMgr?.PartyName,
      PartyTypeId: selectedIsmMgr?.PartyTypeId,
      EmailAddress: selectedIsmMgr?.EmailAddress,
      PhoneNumber: selectedIsmMgr?.PhoneNumber,
      AddressLine1: selectedIsmMgr?.AddressLine1,
      AddressLine2: selectedIsmMgr?.AddressLine2,
      RegionId: selectedIsmMgr?.RegionId,
      CountryId: selectedIsmMgr?.CountryId,
    });
    //setCompliancePayload
    setCompliancePayload({
      EntryId: certificate?.EntryId,
      VesselSpecificationId: certificate.VesselSpecificationId,
      CertificateNameId: selectVesselCert?.CertificateNameId,
      IssuerId: selectIssuer?.IssuerId,
      PlaceOfIssue: certificateCountry?.CountryId,
      IssueDate: certificate?.IssueDate,
      ExpiryDate: certificate?.ExpiryDate,
      StatusId: extractedStatus?.StatusId,
      ValidityPeriodId: exactValidityPeriod?.ValidityPeriodId,
      Remarks: certificate.Remarks,
      CertificateDocumentId: certificate.CertificateDocumentId,
    });
  };
  //#region for setting countries with their matching provinces
  //Picking VesselOwner Region By Country and making it a global variable.
  let changedProvince =
    vesselOwnerCountry?.CountryId !== undefined &&
    provinceList.filter(
      (newRegion) => newRegion?.CountryId === vesselOwnerCountry?.CountryId
    );
  sessionStorage.setItem("changedProvince", JSON.stringify(changedProvince));
  let changedProv = sessionStorage.getItem("changedProvince");
  changedProv = JSON.parse(changedProv);

  if (!changedProv) {
    changedProv = provinceList;
  }

  //Picking TechnicalManager Region By Country and making it a global variable while storing to the session
  let changedTmProvince =
    techManagerCountry?.CountryId !== undefined &&
    provinceList.filter(
      (newRegion) => newRegion?.CountryId === techManagerCountry?.CountryId
    );
  sessionStorage.setItem(
    "changedTmProvince",
    JSON.stringify(changedTmProvince)
  );
  let changedTmProv = sessionStorage.getItem("changedTmProvince");
  changedTmProv = JSON.parse(changedTmProv);
  if (!changedTmProv) {
    changedTmProv = provinceList;
  }

  //Picking CommercialManager Region By Country and making it a global variable while storing to the session
  let changedCmProvince =
    commManagerCountry?.CountryId !== undefined &&
    provinceList.filter(
      (newRegion) => newRegion?.CountryId === commManagerCountry?.CountryId
    );
  sessionStorage.setItem(
    "changedCmProvince",
    JSON.stringify(changedCmProvince)
  );
  let changedCmProv = sessionStorage.getItem("changedCmProvince");
  changedCmProv = JSON.parse(changedCmProv);
  if (!changedCmProv) {
    changedCmProv = provinceList;
  }

  //Picking ISManager Region By Country and making it a global variable while storing to the session
  let changedIsmProvince =
    ismManagerCountry?.CountryId !== undefined &&
    provinceList.filter(
      (newRegion) => newRegion?.CountryId === ismManagerCountry?.CountryId
    );
  sessionStorage.setItem(
    "changedCmProvince",
    JSON.stringify(changedIsmProvince)
  );
  let changedIsmProv = sessionStorage.getItem("changedCmProvince");
  changedIsmProv = JSON.parse(changedIsmProv);
  if (!changedIsmProv) {
    changedIsmProv = provinceList;
  }

  //#endregion
  const onTmCountryChange = (option) => {
    let exactCountry = countryList.find(
      (country) => country.CountryId === option.value
    );
    // certificate["PlaceOfIssue"] = exactCountry.CountryId;
    setTechManagerCountry({
      CountryId: exactCountry.CountryId,
      CountryName: exactCountry.CountryName,
    });
    formHelperForTechnicalMgr("CountryId", exactCountry.CountryId);
  };
  const onIssueDateChange = (e) => {
    let dateObject = e.target.value;
    let convertedIssueDate = new Date(dateObject).toISOString();
    let newDateObject = convertedIssueDate.slice(
      0,
      convertedIssueDate.indexOf("T")
    );
    setSelectedIssueDate(newDateObject);
    formHelperForCompliance("IssueDate", e.target.value);
  };

  const onExpiryDateChange = (e) => {
    let dateObject2 = e.target.value;
    let convertedIssueDate2 = new Date(dateObject2).toISOString();
    let newDateObject2 = convertedIssueDate2.slice(
      0,
      convertedIssueDate2.indexOf("T")
    );
    setSelectedExpiryDate(newDateObject2);
    formHelperForCompliance("ExpiryDate", e.target.value);
  };

  const onStatusChange = (stat) => {
    setSelectedVesselStatus(stat);
    formHelperForCompliance("StatusId", stat?.StatusId);
  };

  const updateVesselDetails = async () => {
    await VesselService.postVessel(postVesselData);
  };

  const renderStorageTank = (index) => {
    return (
      <div className="row align-items-center" id="TP1">
        <div className="col-md-4">
          <div className="form-group ">
            <label className="form-label" htmlFor="fw-last-name">
              Storage Tank Name (Tank {index + 1})
            </label>
            <div className="form-control-wrap ">
              <div className="form-control-select">
                <select
                  className="form-control "
                  id="fw-nationality"
                  name="fw-nationality"
                >
                  <option value>-- Select --</option>
                  <option value="us">Brine</option>
                  <option value="uk">Potable Water ( Cargo)</option>
                  <option value> Potable Water (Tug)</option>
                  <option value="us">Dilling and Ballast Water</option>
                  <option value="uk">Liquid Mud</option>
                  <option value="uk">Fuel</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label className="form-label" htmlFor="fw-nationality">
              Bulk Product Type
            </label>
            <div className="form-control-wrap ">
              <div className="form-control-select">
                <select
                  className="form-control "
                  id="fw-nationality"
                  name="fw-nationality"
                >
                  <option value>-- Select --</option>
                  <option value="us">Wet</option>
                  <option value="uk">Dry</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="form-group ">
            <label className="form-label" htmlFor="fw-last-name">
              Tank Capacity
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                data-msg="Required"
                className="form-control required"
                id="fw-last-name"
                name="fw-last-name"
                required
              />
            </div>
          </div>
        </div>
        <div className="gap" />
        <div className="col-md-4">
          <div className="form-group ">
            <label className="form-label" htmlFor="fw-last-name">
              Discharge Rate
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                data-msg="Required"
                className="form-control required"
                id="fw-last-name"
                name="fw-last-name"
                required
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group ">
            <label className="form-label" htmlFor="fw-last-name">
              Cargo Volume
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                data-msg="Required"
                className="form-control required"
                id="fw-last-name"
                name="fw-last-name"
                required
              />
            </div>
          </div>
        </div>
        <div className="col-12 mt-5"></div>
      </div>
    );
  };

  const updateStorageTankCount = (e) => {
    let count = parseInt(e.target.value);
    let _storageTanks = [...storageTanks];

    if (count > storageTanks.length) {
      for (let i = storageTanks.length; i < count; i++) {
        _storageTanks.push({});
      }
    } else if (count < storageTanks.length) {
      _storageTanks = [];
      for (let i = 0; i < count; i++) {
        _storageTanks.push(storageTanks[i]);
      }
    }
    setStorageTanks(_storageTanks);
  };

  const formHelper = (name, value) => {
    setPayload((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const formHelperForVesselOwner = (name, value) => {
    setVesselOwnerPayload((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const formHelperForContactPerson = (name, value) => {
    setContactPersonPayload((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const formHelperForTechnicalMgr = (name, value) => {
    setTechnicalMgrPayload((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const formHelperForCommercialMgr = (name, value) => {
    setCommercialMgrPayload((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const formHelperForIsmMgr = (name, value) => {
    setIsmMgrPayload((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const formHelperForCompliance = (name, value) => {
    setCompliancePayload((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <MainLayout title="Edit Vessel Details">
      <div className="card shadow-lg col">
        <form className=" nk-wizard-simple is-alter p-3" onSubmit={submit}>
          {tab === 1 && (
            <>
              <div className="nk-wizard-head">
                {/*beginning of step 1*/}
                <h5>Vessel Description </h5>
              </div>
              <div className="nk-wizard-content">
                <div className="row align-items-center">
                  <div className="col-md-4">
                    <div className="form-group ">
                      <label className="form-label" htmlFor="vessel-name-label">
                        Vessel Name
                      </label>
                      <div className="form-control-wrap">
                        <Select
                          options={vesselList.map((a) => ({
                            ...a,
                            label: a.VesselName,
                            value: a.VesselSpecificationId,
                          }))}
                          value={vesselList
                            .map((a) => ({
                              ...a,
                              label: a.VesselName,
                              value: a.VesselSpecificationId,
                            }))
                            .find(
                              (a) =>
                                a.value === postSampleData.VesselSpecificationId
                            )}
                          onChange={(option) =>
                            formHelper(
                              "VesselName",
                              option.value,
                              onVesselNameChange(option)
                            )
                          }
                          // onChange={(e) => editVesselPayload("VesselName", e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label" htmlFor="imo-label">
                        IMO
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          name="Imo"
                          onChange={(e) => formHelper("Imo", e.target.value)}
                          value={payload.Imo || ""}
                          placeholder="....Find vessel by Name"
                          className="form-control required"
                          // eslint-disable-next-line react/jsx-no-duplicate-props
                          required
                        />
                      </div>
                    </div>
                  </div>
                  {vesselDetail && vesselDetail.VesselPhoto && (
                    <div className="col-md-4">
                      <div className="form-group">
                        {/* onChange={(e) => editVesselPayload("PhotoUrl", e.target.value)} */}
                        {vesselDetail.VesselPhoto.URL ? (
                          <div className="p-3">
                            <img
                              className="rounded"
                              src={vesselDetail.VesselPhoto.URL}
                            />
                          </div>
                        ) : (
                          <>
                            <label htmlFor="InputFile">Vessel Photo</label>
                            <input type="file" id="InputFile" />
                            <p className="help-block">Upload Photo.</p>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <span>&nbsp;</span>
                {/* {loading && <div className="mt-2"><Spinner size="2" /> </div>}
                            {vesselDetail && vesselDetail?.Vessel?.SHIPNAME &&  */}
                <div className="row gy-3">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="mmsi-label">
                        MMSI
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          value={payload?.Mmsi || ""}
                          data-msg="Required"
                          onChange={(e) => formHelper("Mmsi", e.target.value)}
                          className="form-control required"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fleet-label">
                        Add to Fleet
                      </label>
                      <div className="form-control-wrap ">
                        <Select
                          options={fleetList.map((a) => ({
                            ...a,
                            label: a?.FleetName,
                            value: a?.FleetId,
                          }))}
                          value={fleetForVessel?.FleetName || ""}
                          // onChange={(e) => formHelper("Imo", e.target.value)}
                          id="fleet-field"
                          name="fleet-field"
                          aria-current="FleetType"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-first-name">
                        Year of Build
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          value={payload?.YearOfBuild || ""}
                          data-msg="Required"
                          onChange={(e) =>
                            formHelper("YearOfBuild", e.target.value)
                          }
                          className="form-control required"
                          name="YearOfBuild"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="vessel-type-label">
                        Vessel Type
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          value={selectedVesselFleetType?.VesselType || ""}
                          data-msg="Required"
                          onChange={(e) =>
                            formHelper("VesselTypeId", e.target.value)
                          }
                          className="form-control required"
                          id="vessel-type-field"
                          name="vessel-type-field"
                          aria-current="VesselTypeId"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-last-name">
                        Length Overall
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          value={payload?.LengthOverall || ""}
                          onChange={(e) =>
                            formHelper("LengthOverall", e.target.value)
                          }
                          data-msg="Required"
                          className="form-control required"
                          name="LengthOverall"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-last-name">
                        Maximum draught
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          data-msg="Required"
                          value={payload?.DraughtMax || ""}
                          onChange={(e) =>
                            formHelper("DraughtMax", e.target.value)
                          }
                          className="form-control required"
                          name="DraughtMax"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-first-name">
                        Net Registered Tonnage
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          data-msg="Required"
                          className="form-control required"
                          name="RateOfTurn"
                          required
                          value={payload?.Rot || ""}
                          onChange={(e) =>
                            formHelper("RateOfTurn", e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-last-name">
                        Gross Registered Tonnage -
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          data-msg="Required"
                          className="form-control required"
                          value={
                            payload?.GrossRegisteredTonnage ||
                            payload?.Grt ||
                            ""
                          }
                          onChange={(e) =>
                            formHelper("GrossRegisteredTonnage", e.target.value)
                          }
                          name="GrossRegisteredTonnage"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-last-name">
                        Dead weight
                      </label>
                      <div className="form-control-wrap">
                        {/*  <input type="text" defaultValue={payload?.SummerDwt || ""} 
                                            data-msg="Required" className="form-control required" id="fw-last-name"
                                             name="fw-last-name" required /> */}
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-sm-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fw-first-name">Depth</label>
                                        <div className="form-control-wrap">
                                            <input type="text" data-msg="Required" className="form-control required"
                                                // onChange={(e) => formHelper("Depth", e.target.value)} 
                                                id="fw-first-name" name="fw-first-name" required
                                            />
                                        </div>
                                    </div>
                                </div> */}
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-last-name">
                        Speed
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          value={payload?.SpeedMax || ""}
                          data-msg="Required"
                          className="form-control required"
                          name="SpeedMax"
                          required
                          onChange={(e) =>
                            formHelper("SpeedMax", e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-first-name">
                        BHP
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          data-msg="Required"
                          className="form-control required"
                          name="Bhp"
                          value={payload?.Bhp || ""}
                          onChange={(e) => formHelper("Bhp", e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-last-name">
                        Flag
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          value={payload?.Flag || ""}
                          data-msg="Required"
                          className="form-control required"
                          onChange={(e) => formHelper("Flag", e.target.value)}
                          name="Flag"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
                {/*  } */}
              </div>
            </>
          )}
          {tab === 2 && (
            <>
              <div className="nk-wizard-head">
                <h5>Vessel Attributes</h5>
              </div>
              <div className="nk-wizard-content">
                <div className="row gy-4 p-4">
                  <div>
                    <p>
                      <span className="preview-title-lg overline-title">
                        Vessel Attributes
                      </span>{" "}
                    </p>
                  </div>
                  <br />
                  <div className="row align-items-center">
                    <div className="col-sm-6">
                      <div className="form-group ">
                        <label
                          className="form-label"
                          htmlFor="Lenght-between-perpendicular"
                        >
                          Length between perpendicular
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            data-msg="Required"
                            className="form-control required"
                            value={payload?.LengthBetweenPerpendicular || ""}
                            onChange={(e) =>
                              formHelper(
                                "LengthBetweenPerpendicular",
                                e.target.value
                              )
                            }
                            name="LengthBetweenPerpendicular"
                            aria-current="LengthBetweenPerpendicular"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group ">
                        <label className="form-label" htmlFor="beam-label">
                          Beam
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            data-msg="Required"
                            className="form-control required"
                            value={payload?.Beam || ""}
                            onChange={(e) => formHelper("Beam", e.target.value)}
                            name="Beam"
                            aria-current="Beam"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="gap" />
                    <div className="col-sm-6">
                      <div className="form-group ">
                        <label
                          className="form-label"
                          htmlFor="crew-cabin-label"
                        >
                          Number of Crew Cabins
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            data-msg="Required"
                            className="form-control required"
                            value={payload?.NoOfCrewCabins || ""}
                            onChange={(e) =>
                              formHelper("NoOfCrewCabins", e.target.value)
                            }
                            name="NoOfCrewCabins"
                            aria-current="NoOfCrewCabins"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group ">
                        <label
                          className="form-label"
                          htmlFor="passenger-cabin-label"
                        >
                          Number of Passenger Cabins
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            data-msg="Required"
                            className="form-control required"
                            value={payload?.NoOfPassengerCabins || ""}
                            onChange={(e) =>
                              formHelper("NoOfPassengerCabins", e.target.value)
                            }
                            name="NoOfPassengerCabins"
                            aria-current="NoOfPassengerCabins"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="gap" />
                    <div className="col-sm-6">
                      <div className="form-group ">
                        <label className="form-label" htmlFor="deck-area-label">
                          Deck Area
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            data-msg="Required"
                            className="form-control required"
                            value={payload?.DeckArea || ""}
                            onChange={(e) =>
                              formHelper("DeckArea", e.target.value)
                            }
                            name="DeckArea"
                            aria-current="DeckArea"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group ">
                        <label
                          className="form-label"
                          htmlFor="deck-cargo-label"
                        >
                          Deck Cargo Capacity
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            data-msg="Required"
                            className="form-control required"
                            value={payload?.DeckCargoCapacity || ""}
                            onChange={(e) =>
                              formHelper("DeckCargoCapacity", e.target.value)
                            }
                            name="DeckCargoCapacity"
                            aria-current="DeckCargoCapacity"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="gap" />
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label
                          className="form-label"
                          htmlFor="customMultipleFilesLabel"
                        >
                          Upload vessel documents
                        </label>
                        <div className="form-control-wrap">
                          <div className="custom-file">
                            <FileInput
                              onChange={(file) => {
                                formHelper(
                                  ["fileContent", "fileName", "mime"],
                                  [
                                    file.base64,
                                    `Vessel-document-${file.name}`,
                                    file.type,
                                  ]
                                );
                              }}
                              fileValue={{
                                name: postVesselData.fileName,
                                size: postVesselData.fileContent?.length || 0,
                                fileContent: postVesselData.fileContent,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group ">
                        <label
                          className="form-label"
                          htmlFor="dynamic-positioning-label"
                        >
                          Dynamic Positioning
                        </label>
                        <div className="form-control-wrap ">
                          <Select
                            options={dypValue.map((a) => ({
                              ...a,
                              label: a?.DynamicPositioning,
                              value: a?.DynamicPositioningId,
                            }))}
                            value={{
                              label: vesselDp?.DynamicPositioning,
                              value: vesselDp?.DynamicPositioningId,
                            }}
                            onChange={(e) => {
                              setVesselDp(e);
                              formHelper("DynamicPositioningId", e.value);
                            }}
                            name="DynamicPositioningId"
                            aria-current="DynamicPositioningId"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="preview-hr" />
                <div>
                  <p>
                    <span className="preview-title-lg overline-title">
                      Storage Tanks
                    </span>{" "}
                  </p>
                </div>
                <br />
                {
                  <div>
                    <label className="nk-block-des text-soft bg-warning-dim">
                      <i>Select Number of tanks</i>
                    </label>
                    <select
                      id="TP0"
                      onChange={(e) => updateStorageTankCount(e)}
                    >
                      <option defaultValue={1}>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                      <option>11</option>
                      <option>12</option>
                    </select>
                  </div>
                }
                {storageTanks.map((item, index) => renderStorageTank(index))}
              </div>
            </>
          )}
          {tab === 3 && (
            <>
              {" "}
              <div className="nk-wizard-head">
                <h5>Vessel Managers</h5>
              </div>
              <div className="nk-wizard-content">
                <div className="row gy-3 p-4">
                  <div>
                    <p>
                      <span className="preview-title-lg overline-title">
                        Vessel Owner
                      </span>
                    </p>
                  </div>
                  <br />
                  <div className="row align-items-center">
                    <div className="col-md-4">
                      <div className="form-group ">
                        <label className="form-label" htmlFor="fw-last-name">
                          Select existing party
                        </label>
                        <div className="form-control-wrap">
                          <Select
                            options={allParties.map((a) => ({
                              ...a,
                              label: a?.PartyName,
                              value: a?.PartyId,
                            }))}
                            value={{
                              label: vesselOwnerPayload?.PartyName,
                              value: vesselOwnerPayload?.PartyId,
                            }}
                            // onChange={e => onVesselNameChange(e)    vesselOwnerPayload}
                            onChange={(e) => {
                              setVesselOwnerPayload(e);
                              formHelperForVesselOwner("PartyId", e.value);
                            }}
                            name="PartyId"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group ">
                        <label className="form-label" htmlFor="EmailAddress" >
                          Email Address
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="email"
                            data-msg="Required"
                            className="form-control required"
                            name="EmailAddress"
                            value={vesselOwnerPayload?.EmailAddress || ""}
                            onChange={(e) =>
                              formHelperForVesselOwner(
                                "EmailAddress",
                                e.target.value
                              )
                            }
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group ">
                        <label className="form-label" htmlFor="fw-last-name">
                          Phone number
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="number"
                            data-msg="Required"
                            className="form-control required"
                            value={vesselOwnerPayload.PhoneNumber || ""}
                            name="PhoneNumber"
                            onChange={(e) =>
                              formHelperForVesselOwner(
                                "PhoneNumber",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="gap gap-20px" />
                    <div className="col-sm-6">
                      <div className="form-group ">
                        <label className="form-label" htmlFor="fw-last-name">
                          Address Line 1
                        </label>
                        <div className="form-control-wrap">
                          <textarea
                            placeholder="Fill in address"
                            className="form-control no-resize"
                            value={vesselOwnerPayload?.AddressLine1 || ""}
                            onChange={(e) =>
                              formHelperForVesselOwner(
                                "AddressLine1",
                                e.target.value
                              )
                            }
                            name="AddressLine1"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group ">
                        <label className="form-label" htmlFor="fw-last-name">
                          Address Line 2
                        </label>
                        <div className="form-control-wrap">
                          <textarea
                            placeholder="Fill in address"
                            className="form-control no-resize"
                            id="default-textarea"
                            value={vesselOwnerPayload?.AddressLine2 || ""}
                            onChange={(e) =>
                              formHelperForVesselOwner(
                                "AddressLine2",
                                e.target.value
                              )
                            }
                            name="AddressLine2"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="gap" />
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="fw-nationality">
                          Country
                        </label>
                        <div className="form-control-wrap ">
                          <Select
                            options={countryList.map((a) => ({
                              ...a,
                              label: a?.CountryName,
                              value: a?.CountryId,
                            }))}
                            value={{
                              label: vesselOwnerCountry?.CountryName,
                              value: vesselOwnerCountry?.CountryId,
                            }}
                            // onChange={(option) => retrieveProvince(option.value)}
                            onChange={(e) => {
                              setVesselOwnerCountry(e);
                              formHelperForVesselOwner(
                                "CountryId",
                                vesselOwnerCountry?.CountryId
                              );
                            }}
                            name="CountryId"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="fw-nationality">
                          Region / Province
                        </label>
                        <div className="form-control-wrap ">
                          <Select
                            options={
                              changedProv?.map((a) => ({
                                ...a,
                                label: a?.RegionName,
                                value: a?.RegionId,
                              })) || ""
                            }
                            value={
                              {
                                label: vesselOwnerProvince?.RegionName,
                                value: vesselOwnerProvince?.RegionId,
                              } || ""
                            }
                            onChange={(e) => {
                              setVesselOwnerProvince(e);
                              formHelperForVesselOwner("RegionId", e.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="gap gap-20px" />
                  <div className="gap gap-20px" />
                  <span className="preview-title-lg overline-title">
                    Contact Person Information{" "}
                  </span>
                  <div className="gap gap-20px" />
                  <div className="gap gap-20px" />
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-last-name">
                        First Name
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          data-msg="Required"
                          className="form-control required"
                          id="fw-last-name"
                          value={contactPersonPayload?.FirstName || ""}
                          onChange={(e) =>
                            formHelperForContactPerson(
                              "FirstName",
                              e.target.value
                            )
                          }
                          name="FirstName"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-last-name">
                        Last Name
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          data-msg="Required"
                          className="form-control required"
                          value={contactPersonPayload?.LastName || ""}
                          onChange={(e) =>
                            formHelperForContactPerson(
                              "LastName",
                              e.target.value
                            )
                          }
                          id="fw-last-name"
                          name="FirstName"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="role">
                        Designation
                      </label>
                      <div className="form-control-wrap">
                        <Select
                          options={roles.map((a) => ({
                            ...a,
                            label: a?.Name,
                            value: a?.RoleId,
                          }))}
                          value={{
                            label: selectedCpRole?.Name,
                            value: selectedCpRole?.RoleId,
                          }}
                          onChange={(e) => {
                            setSelectedCpRole(e);
                            formHelperForContactPerson("RoleId", e.value);
                          }}
                          name="Designation"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-last-name">
                        Email Address(Contact Person)
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="email"
                          data-msg="Required"
                          className="form-control required"
                          value={contactPersonPayload?.EmailAddress || ""}
                          onChange={(e) =>
                            formHelperForContactPerson(
                              "EmailAddress",
                              e.target.value
                            )
                          }
                          id="fw-last-name"
                          name="EmailAddress"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-last-name">
                        Phone number
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="email"
                          data-msg="Required"
                          className="form-control required"
                          value={contactPersonPayload?.PhoneNumber || ""}
                          onChange={(e) =>
                            formHelperForContactPerson(
                              "PhoneNumber",
                              e.target.value
                            )
                          }
                          id="fw-last-name"
                          name="PhoneNumber"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">{/* <PartyModal /> */}</div>
                  </div>
                </div>
                <div className="row gy-3 p-4">
                  <div>
                    <p>
                      <span></span>
                    </p>
                    <h4> Technical Manager</h4>
                    <p />
                  </div>
                  <br />
                  <div className="row align-items-center">
                    <div className="col-sm-6">
                      <div className="form-group ">
                        <label className="form-label" htmlFor="fw-last-name">
                          First Name
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            data-msg="Required"
                            className="form-control required"
                            id="fw-last-name"
                            value={technicalMgrPayload?.PartyName || ""}
                            onChange={(e) =>
                              formHelperForTechnicalMgr(
                                "PartyName",
                                e.target.value
                              )
                            }
                            name="fw-last-name"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group ">
                        <label className="form-label" htmlFor="fw-last-name">
                          Last Name
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            data-msg="Required"
                            className="form-control required"
                            id="fw-last-name"
                            name="fw-last-name"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="gap gap-20px" />
                    <div className="col-sm-6">
                      <div className="form-group ">
                        <label className="form-label" htmlFor="fw-last-name">
                          Email Address
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="email"
                            data-msg="Required"
                            className="form-control required"
                            id="fw-last-name"
                            value={technicalMgrPayload?.EmailAddress || ""}
                            onChange={(e) =>
                              formHelperForTechnicalMgr(
                                "EmailAddress",
                                e.target.value
                              )
                            }
                            name="EmailAddress"
                            required
                          />
                        </div>
                      </div>

                      <div className="form-group ">
                        <label className="form-label" htmlFor="fw-last-name">
                          Phone number
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="number"
                            data-msg="Required"
                            className="form-control required"
                            id="fw-last-name"
                            value={technicalMgrPayload?.PhoneNumber || ""}
                            onChange={(e) =>
                              formHelperForTechnicalMgr(
                                "PhoneNumber",
                                e.target.value
                              )
                            }
                            name="PhoneNumber"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group ">
                        <label className="form-label" htmlFor="fw-last-name">
                          Address
                        </label>
                        <div className="form-control-wrap">
                          <textarea
                            placeholder="Fill in address"
                            className="form-control no-resize"
                            id="default-textarea"
                            value={technicalMgrPayload?.AddressLine1 || ""}
                            onChange={(e) =>
                              formHelperForTechnicalMgr(
                                "AddressLine1",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="gap" />
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="fw-nationality">
                          Country
                        </label>
                        <div className="form-control-wrap ">
                          <Select
                            options={countryList.map((a) => ({
                              ...a,
                              label: a?.CountryName,
                              value: a?.CountryId,
                            }))}
                            value={{
                              label: techManagerCountry?.CountryName,
                              value: techManagerCountry?.CountryId,
                            }}
                            // value={({ label: techManagerCountry?.CountryName, value: techManagerCountry?.CountryId })}
                            onChange={(e) => {
                              onTmCountryChange(e);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="fw-nationality">
                          Region / Province
                        </label>
                        <div className="form-control-wrap ">
                          <Select
                            options={changedTmProv.map((a) => ({
                              ...a,
                              label: a?.RegionName,
                              value: a?.RegionId,
                            }))}
                            value={{
                              label: techMgrProvince?.RegionName,
                              value: techMgrProvince?.RegionId,
                            }}
                            onChange={(e) => {
                              setTechMgrProvince(e);
                              formHelperForTechnicalMgr("RegionId", e.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row gy-3 p-4">
                  <div>
                    <p>
                      <span></span>
                    </p>
                    <h4> Commercial Manager</h4>
                    <p />
                  </div>
                  <br />
                  <div className="row align-items-center">
                    <div className="col-sm-6">
                      <div className="form-group ">
                        <label className="form-label" htmlFor="fw-last-name">
                          First Name
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            data-msg="Required"
                            className="form-control required"
                            id="fw-last-name"
                            value={commercialMgrPayload?.PartyName || ""}
                            onChange={(e) =>
                              formHelperForCommercialMgr(
                                "PartyName",
                                e.target.value
                              )
                            }
                            name="FirstName"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group ">
                        <label className="form-label" htmlFor="fw-last-name">
                          Last Name
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            data-msg="Required"
                            className="form-control required"
                            id="fw-last-name"
                            name="fw-last-name"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="gap gap-20px" />
                    <div className="col-sm-6">
                      <div className="form-group ">
                        <label className="form-label" htmlFor="fw-last-name">
                          Email Address
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="email"
                            data-msg="Required"
                            className="form-control required"
                            id="fw-last-name"
                            value={commercialMgrPayload?.EmailAddress || ""}
                            onChange={(e) =>
                              formHelperForCommercialMgr(
                                "EmailAddress",
                                e.target.value
                              )
                            }
                            name="EmailAddress"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group ">
                        <label className="form-label" htmlFor="fw-last-name">
                          Phone number
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="number"
                            data-msg="Required"
                            className="form-control required"
                            id="fw-last-name"
                            value={commercialMgrPayload?.PhoneNumber || ""}
                            onChange={(e) =>
                              formHelperForCommercialMgr(
                                "PhoneNumber",
                                e.target.value
                              )
                            }
                            name="PhoneNumber"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group ">
                        <label className="form-label" htmlFor="fw-last-name">
                          Address
                        </label>
                        <div className="form-control-wrap">
                          <textarea
                            placeholder="Fill in address"
                            className="form-control no-resize"
                            id="default-textarea"
                            value={commercialMgrPayload?.AddressLine1 || ""}
                            onChange={(e) =>
                              formHelperForCommercialMgr(
                                "AddressLine1",
                                e.target.value
                              )
                            }
                            name="AddressLine1"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="gap" />
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="fw-nationality">
                          Country
                        </label>
                        <div className="form-control-wrap ">
                          <Select
                            options={countryList.map((a) => ({
                              ...a,
                              label: a?.CountryName,
                              value: a?.CountryId,
                            }))}
                            value={{
                              label: commManagerCountry?.CountryName,
                              value: commManagerCountry?.CountryId,
                            }}
                            onChange={(e) => {
                              setCommManagerCountry(e);
                              formHelperForCommercialMgr("CountryId", e.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="fw-nationality">
                          Region / Province
                        </label>
                        <div className="form-control-wrap ">
                          <Select
                            options={changedCmProv.map((a) => ({
                              ...a,
                              label: a?.RegionName,
                              value: a?.RegionId,
                            }))}
                            value={{
                              label: commMgrProvince?.RegionName,
                              value: commMgrProvince?.RegionId,
                            }}
                            onChange={(e) => {
                              setCommMgrProvince(e);
                              formHelperForCommercialMgr("RegionId", e.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row gy-3 p-4">
                  <div>
                    <p>
                      <span></span>
                    </p>
                    <h4> ISM Manager</h4>
                    <p />
                  </div>
                  <br />
                  <div className="row align-items-center">
                    <div className="col-sm-6">
                      <div className="form-group ">
                        <label className="form-label" htmlFor="fw-last-name">
                          First Name
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            data-msg="Required"
                            className="form-control required"
                            id="fw-last-name"
                            value={ismMgrPayload?.PartyName || ""}
                            onChange={(option) =>
                              formHelperForIsmMgr(
                                "PartyName",
                                option.target.value
                              )
                            }
                            name="FirstName"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group ">
                        <label className="form-label" htmlFor="fw-last-name">
                          Last Name
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            data-msg="Required"
                            className="form-control required"
                            id="fw-last-name"
                            name="PartyName"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="gap gap-20px" />
                    <div className="col-sm-6">
                      <div className="form-group ">
                        <label className="form-label" htmlFor="fw-last-name">
                          Email Address
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="email"
                            data-msg="Required"
                            className="form-control required"
                            id="fw-last-name"
                            value={ismMgrPayload?.EmailAddress || ""}
                            onChange={(e) =>
                              formHelperForIsmMgr(
                                "EmailAddress",
                                e.target.value
                              )
                            }
                            name="EmailAddress"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group ">
                        <label className="form-label" htmlFor="fw-last-name">
                          Phone number
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="number"
                            data-msg="Required"
                            className="form-control required"
                            id="fw-last-name"
                            value={ismMgrPayload?.PhoneNumber || ""}
                            onChange={(e) =>
                              formHelperForIsmMgr("PhoneNumber", e.target.value)
                            }
                            name="PhoneNumber"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group ">
                        <label className="form-label" htmlFor="fw-last-name">
                          Address
                        </label>
                        <div className="form-control-wrap">
                          <textarea
                            placeholder="Fill in address"
                            className="form-control no-resize"
                            id="default-textarea"
                            value={ismMgrPayload?.AddressLine1 || ""}
                            onChange={(e) =>
                              formHelperForIsmMgr(
                                "AddressLine1",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="gap" />
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="fw-nationality">
                          Country
                        </label>
                        <div className="form-control-wrap ">
                          <Select
                            options={countryList.map((a) => ({
                              ...a,
                              label: a?.CountryName,
                              value: a?.CountryId,
                            }))}
                            value={{
                              label: ismManagerCountry?.CountryName,
                              value: ismManagerCountry?.CountryId,
                            }}
                            onChange={(option) => {
                              setIsmManagerCountry(option);
                              formHelperForIsmMgr("CountryId", option.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="fw-nationality">
                          Region / Province
                        </label>
                        <div className="form-control-wrap ">
                          <Select
                            options={changedIsmProv.map((a) => ({
                              ...a,
                              label: a?.RegionName,
                              value: a?.RegionId,
                            }))}
                            value={{
                              label: ismMgrProvince?.RegionName,
                              value: ismMgrProvince?.RegionId,
                            }}
                            onChange={(option) => {
                              setIsmMgrProvince(option);
                              formHelperForIsmMgr("RegionId", option.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {tab === 4 && (
            <>
              <div className="nk-wizard-head">
                <h5>Compliance</h5>
              </div>
              <div className="nk-wizard-content">
                <div className="row align-items-center">
                  <div className="row gy-4 p-3">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="form-label">Certificate </label>
                        <div className="form-control-wrap">
                          <Select
                            options={certificateName.map((a) => ({
                              ...a,
                              label: a?.CertificateName,
                              value: a?.CertificateNameId,
                            }))}
                            value={{
                              label: selectedVesselCertificate?.CertificateName,
                              value:
                                selectedVesselCertificate?.CertificateNameId,
                            }}
                            onChange={(e) => {
                              setSelectedVesselCertificate(e);
                              formHelperForCompliance(
                                "CertificateNameId",
                                e.value
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group ">
                        <label className="form-label" htmlFor="fw-last-name">
                          Issuing Authrority
                        </label>
                        <div className="form-control-wrap">
                          <Select
                            options={issuingAuthority.map((e) => ({
                              ...e,
                              label: e.IssuerName,
                              value: e.IssuerId,
                            }))}
                            value={{
                              label: selectedVesselIssuer?.IssuerName,
                              value: selectedVesselIssuer?.IssuerId,
                            }}
                            onChange={(e) => {
                              setSelectedVesselIssuer(e);
                              formHelperForCompliance("IssuerId", e.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="fw-last-name">
                          Place of Issue{" "}
                        </label>
                        <div className="form-control-wrap">
                          <Select
                            options={countryList.map((e) => ({
                              ...e,
                              label: e.CountryName,
                              value: e.CountryId,
                            }))}
                            value={{
                              label: selectedCertificateCountry?.CountryName,
                              value: selectedCertificateCountry?.CountryId,
                            }}
                            onChange={(option) => {
                              setSelectedCertificateCountry(option);
                              formHelperForCompliance(
                                "PlaceOfIssue",
                                option.value
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="form-label" htmlFor="fw-last-name">
                          Commencement date
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="date"
                            className="form-control required"
                            defaultValue={selectedIssueDate || ""}
                            onChange={(e) => onIssueDateChange(e)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="form-label" htmlFor="fw-last-name">
                          Expiry date
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="date"
                            className="form-control required"
                            defaultValue={selectedExpiryDate || ""}
                            onChange={(e) => onExpiryDateChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="fw-last-name">
                          Validity period
                        </label>
                        <div className="form-control-wrap">
                          <Select
                            options={certValidityPeriod.map((e) => ({
                              ...e,
                              label: e.ValidityPeriod,
                              value: e.ValidityPeriodId,
                            }))}
                            value={{
                              label: selectedValidtyPeriod?.ValidityPeriod,
                              value: selectedValidtyPeriod?.ValidityPeriodId,
                            }}
                            onChange={(option) => {
                              setSelectedValidityPeriod(option);
                              formHelperForCompliance(
                                "ValidityPeriodId",
                                option.value
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group ">
                        <label className="form-label" htmlFor="fw-last-name">
                          Status
                        </label>
                        <div className="form-control-wrap">
                          <Select
                            options={certStatus.map((e) => ({
                              ...e,
                              label: e?.Status,
                              value: e?.StatusId,
                            }))}
                            value={{
                              label: selectedVesselStatus?.Status,
                              value: selectedVesselStatus?.StatusId,
                            }}
                            onChange={(stat) => onStatusChange(stat)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="form-label"
                          htmlFor="customMultipleFilesLabel"
                        >
                          Upload vessel documents
                        </label>{" "}
                        <small className="text-danger ml-1">*</small>
                        <FileInput
                          // onChange={(file)=> {addVesselPayload(["fileContent", "fileName", "mime"], [file.base64,  `Vessel-document-${file.name}`, file.type])}}
                          fileValue={{
                            name: postVesselData.fileName,
                            size: postVesselData.fileContent?.length || 0,
                            fileContent: postVesselData.fileContent,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <div></div>
          <div></div>
          <div className="row">
            <div className="col mb-4 pl-5 mt-5">
              {tab > 1 && (
                <button
                  type="button"
                  onClick={() => toPreviousTab()}
                  className="btn btn-md btn-primary"
                >
                  {"<"} Previous
                </button>
              )}
            </div>
            <div className="col text-right mb-4 pr-5 mt-5">
              {tab < 4 && (
                <button
                  type="button"
                  id="saveVessel"
                  onClick={() => toNextTab()}
                  className="btn btn-md btn-primary"
                >
                  Next {">"}
                </button>
              )}
              {tab == 4 && (
                <>
                  {
                    <button type="submit" className="btn btn-lg btn-dark">
                      <em className="icon ni ni-save mr-2" />
                      Save Vessel Details
                    </button>
                  }
                </>
              )}
            </div>
          </div>
        </form>

        <hr className="preview-hr" />
      </div>
    </MainLayout>
  );
}
