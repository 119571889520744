import React, { useEffect, useState } from "react";
import MyTable from "../dataTable";
import { toReadableDate } from "../../utils/utilityFunctions";
import Spinner from "../../utils/spinner";

export default function VoyageActivitiesListComponent({ dataList }) {
  const [tableData, setTableData] = useState([]);
  const [initializing, setInit] = useState(true);


  const headerList = [
    {
      Header: "Start Date",
      accessor: "intendedStartDate",
    },
    {
      Header: "End Date",
      accessor: "intendedEndDate",
    },
    {
      Header: "Location",
      accessor: "locationNameWithType",
    },
    {
      Header: "Activity Name",
      accessor: "activityComponent",
    },
    {
      Header: "Allows Parallel",
      accessor: "isParallel",
    },
    {
      Header: "Activity Duration",
      accessor: "durationOfActivityExpression",
    },
    {
      Header: <span style={{ display: "inline-block", width: "80px" }} title="Sailing Distance (in nautical miles)">Sailing Distance (nm)</span>,
      accessor: "approximateSailingDistance",
    },
    {
      Header: "",
      accessor: "startEndComponent",
      cell: "startEndComponent",
    },
    {
      Header: "",
      accessor: "overflowComponent",
    },
  ];


  const activityDisplayFormat = (activity) => {
    return <><span title={activity.activityName === "Port Discharging Bulk cargo" ? `bulk being loaded from ${activity?.offshoreLocation}`
      : activity.activityName === "Port Loading Bulk cargo" ? `bulk to be delivered at ${activity?.offshoreLocation}` : activity.activityName}
      style={{ color: '#000' }}>{activity.activityName}</span><br />
      <span style={{ marginRight: '10px' }}>
        <span title="Activity type" className="text-primary pointer" >{activity.activityType}</span>
      </span>
      <span title="Activity status" className="text-primary pointer" >{activity.activityStatus}</span>
    </>;
  }

  const reFormatData = () => {
    const formattedData = (dataList || []).map(e => ({
      ...e,
      activityComponent: activityDisplayFormat(e)
    }));
    setTableData(formattedData);
  }

  useEffect(async () => {
    setInit(true);

    reFormatData();
    setInit(false);
  }, [dataList]);



  return (
    <div>
      {initializing && <Spinner />}
      {!initializing && (
        <MyTable
          columns={headerList}
          cssArray={["font-weight-500", "", "", "text-left"]}
          data={tableData || []}
          disablePageSize={true}
          enableSearch={false}
          emptyMessage="No voyage activities to view"
        />
      )}
    </div>
  );
}
