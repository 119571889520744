import { object } from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import {
  Circle,
  FeatureGroup,
  LayerGroup,
  LayersControl,
  MapContainer,
  Marker,
  Popup,
  Rectangle,
  TileLayer,
  Polygon,
} from "react-leaflet";
import L from "leaflet";
import { EditControl } from "react-leaflet-draw";
import osm from "../utils/osm.providers";
import Spinner from "../utils/spinner";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import marker from "../images/marker.svg";
import Fullscreen from "react-leaflet-fullscreen-plugin";

export default function MapView2({
  height,
  onChange,
  mapArea,
  isCreateMode,
  markerPositions,
  readOnly,
  children,
  markerOnClick,
}) {
  // markerPositions = markerPositions || [];
  const [center, setCenter] = useState([0, 0]);
  const [loading, setLoading] = useState(true);
  const [mapResized, setMapResized] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const defaultMarker = new L.Icon({
    iconUrl: marker,
    iconRetinaUrl: marker,
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(60, 75),
    className: "leaflet-div-icon-custom",
    // className: "leaflet-div-icon",
  });

  useEffect(() => {
    if (!mapArea) {
      if (!markerPositions) {
        getLocation();
      } else {
        const _center = [markerPositions[0][0], markerPositions[0][1]];
        setCenter(_center);
        setLoading(false);
      }
    } else {
      const secondIndex = Math.ceil((mapArea.length - 1) / 2);
      const _centerLat = (mapArea[0][0] + mapArea[secondIndex][0]) / 2;
      const _centerLng = (mapArea[0][1] + mapArea[secondIndex][1]) / 2;
      const _center = [_centerLat, _centerLng];
      setCenter(_center);
      setLoading(false);
    }
  }, [height, mapArea]);

  const getLocation = () => {
    setLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(setPosition);
    } else {
      setPosition();
    }
    setLoading(false);
  };

  const setPosition = (p) => {
    if (p) {
      setCenter([p.coords.latitude, p.coords.longitude]);
    } else {
      setCenter([6.5095, 3.3711]);
    }
    setLoading(false);
  };

  const mapChanged = (eventType, data) => {
    let callbackData = [];
    if (eventType == "created") {
      callbackData = data?.layer?.editing?.latlngs[0][0];
    } else if (eventType == "edited") {
      let keys = Object.keys(data?.layers?._layers);
      callbackData = data?.layers?._layers[keys[0]]?._latlngs[0];
    } else if (eventType == "deleted") {
      let keys = Object.keys(data?.layers?._layers);
      callbackData = [];
    } else {
      callbackData = [];
    }

    if (onChange) {
      onChange(callbackData);
    }
  };
  const purpleOptions = { color: "purple" };

  const resizeMap = useCallback(() => {
    // this function is for the glitch in leaflet which prevents the map from being fully rendered especially if the map is initialized outside the DOM. It needs to run just once
    window.dispatchEvent(new Event("resize"));
    setMapResized(true);
  });

  const fullscreenOptions = {
    position: "topleft", // change the position of the button can be topleft, topright, bottomright or bottomleft, default topleft
    title: "Fullscreen", // change the title of the button, default Full Screen
    titleCancel: "Exit fullscreen mode", // change the title of the button when fullscreen is on, default Exit Full Screen
    content: null, // change the content of the button, can be HTML, default null
    forceSeparateButton: true, // force separate button to detach from zoom buttons, default false
    forcePseudoFullscreen: true, // force use of pseudo full screen even if full screen API is available, default false
    fullscreenElement: false, // Dom element to render in full screen, false by default, fallback to map._container
  };

  return loading ? (
    <Spinner />
  ) : (
    <div
      style={{ height: height || "300px" }}
      onMouseOver={() => {
        !mapResized && resizeMap();
      }}
    >
      <MapContainer
        center={center}
        zoom={13}
        style={{ height: height || "300px" }}
      >
        <TileLayer
          attribution={osm.openstreetmap.attribution}
          url={osm.openstreetmap.url}
        />
        <FeatureGroup>
          {!readOnly && (
            <EditControl
              position="topright"
              onCreated={(d) => mapChanged("created", d)}
              onEdited={(d) => mapChanged("edited", d)}
              onDeleted={(d) => mapChanged("deleted", d)}
              draw={{
                rectangle: false,
                polyline: false,
                circle: false,
                circlemarker: false,
                marker: false,
              }}
            />
          )}
          {mapArea && !isCreateMode && (
            <Polygon pathOptions={purpleOptions} positions={mapArea} />
          )}
        </FeatureGroup>
        {markerPositions?.map((p, i) => (
          <Marker
            position={p}
            icon={defaultMarker}
            key={i}
            eventHandlers={{
              click: () => {
                markerOnClick && markerOnClick(p);
              },
            }}
          ></Marker>
        ))}

        {children}
        <Fullscreen
          eventHandlers={{
            enterFullscreen: (event) => {
              setIsFullscreen(true);
            },
            exitFullscreen: (event) => {
              setIsFullscreen(false);
            },
          }}
          {...fullscreenOptions}
        />
      </MapContainer>
    </div>
  );
}
