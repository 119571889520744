const selectStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "#495057",
      fontSize: "0.8125rem",
      fontWeight: "600",
      backgroundColor: state.isSelected ? "#83B739" : provided.backgroundColor,
    }),
    control: (provided, state) => {
      // none of react-select's styles are passed to <Control />
      return {
        ...provided,
        borderColor:
          state.isFocused || state.isSelected
            ? "#83B739"
            : provided.borderColor,
        "&:hover": {
          borderColor:
            state.isFocused || state.isSelected
              ? "#83B739"
              : provided.borderColor,
        },
      };
    },
    singleValue: (provided, state) => {
      return {
        ...provided,
        color: "#495057",
        fontSize: "0.8125rem",
        fontWeight: "600",
        borderColor:
          state.isFocused || state.isSelected
            ? "#83B739"
            : provided.borderColor,
      };
    },
  };

  const  defaultModalStyle = {
    content: {
     width: "100vw",
     left: "0px",
     bottom: "0px", padding: "0px"
    },
  };

  export {selectStyles, defaultModalStyle}