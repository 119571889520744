import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import LocationService from "../../services/locationService";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import Spinner from "../../utils/spinner";
import { Validator, ValidateGroup, ClearValidator } from "../validator";
import toastr from "../../utils/toastr";
import ProductService from "../../services/productService";
import EquipmentService from "../../services/equipmentService";
import UsageReportComponent from "../product/usageReport";
import VoyageService from "../../services/voyageService";
import { Link } from "react-router-dom";
import WaterUsageTableComponent from "../voyageReporting/waterUsageTableComponent";

export default function WaterUsageReportComponent({
	voyagePlanningId,
	voyageUnderView,
	waterConsumptionHistory,
}) {
	const params = useParams();
	const [redirectUrl, setRedirectUrl] = useState(null);
	const [loading, setLoading] = useState(false);
	const [products, setProducts] = useState([]);
	const [units, setUnits] = useState([]);
	const [litresUnit, setLitresUnit] = useState([]);
	const [payload, setPayload] = useState({});
	const [saving, setSaving] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [voyage, setVoyage] = useState({});
	const [refreshCounter, setRefreshCounter] = useState(0)

	let totalConsumptionFigure = waterConsumptionHistory?.find(
		(item) => item.totalConsumption
	)?.totalConsumption;

	const addPayload = new PayloadUpdater({
		payload: payload,
		setPayload: setPayload,
	}).update;
	const validationGroup = "fuel_consumption_log";
	useEffect(async () => {
		await init();

		return () => {
			ClearValidator(validationGroup);
		};
	}, []);

	const init = async () => {
		setLoading(true);

		let _units = await EquipmentService.listMeasurementUnits();
		_units = (_units || [])
			.map((a) => ({
				...a,
				value: a.MeasurementUnitId,
				label: `${a.MeasurementUnit} (${a.MeasurementUnitSymbol})`,
			}))
			.filter((a) => a.UnitType == "volume");
		let litreUnit = _units.find((unit) => unit.MeasurementUnitSymbol === "L");
		setLitresUnit(litreUnit);
		setUnits(_units);

		if (voyageUnderView) {
			setVoyage(voyageUnderView);
		} else {
			let _voyage = await VoyageService.get(voyagePlanningId);
			setVoyage(_voyage || {});
		}

		setLoading(false);
	};

	const submitForm = async () => {
		const _payload = {
			...payload,
			voyagePlanningId: voyagePlanningId,
			WaterMeasurementUnitId: litresUnit?.MeasurementUnitId,
		};

		if (
			voyage.WaterMeasurementUnitId !== "00000000-0000-0000-0000-000000000000"
		) {
			_payload.WaterMeasurementUnitId =
				voyage.WaterMeasurementUnitId || litresUnit?.MeasurementUnitId;
		}
		setSaving(true);
		const response = await ProductService.waterConsumptionLog(_payload);
		if (response) {
			setPayload({});
			toastr("success", "Consumption log saved successfully");
			window.location.reload();

		}
		setSaving(false);
	};

	return redirectUrl ? (
		<Navigate to={redirectUrl} replace={true} />
	) : (
		<>
			{loading && (
				<div className="w-100 text-center center my-5 py-5">
					<Spinner />
				</div>
			)}

			{!loading && (
				<div
					className="alert alert-info alert-dismissible fade show"
					role="alert">
					This displays all the logs of water consumed
					by the vessel during the voyage.
					<button
						type="button"
						className="close"
						data-dismiss="alert"
						aria-label="Close"></button>
				</div>
			)}

			{!loading && (
				<div className="row">
					<div className="col-6">
						<h6>
							<b>Total Consumption: {totalConsumptionFigure}</b> {totalConsumptionFigure ? "liters" : "N/A"}</h6>

						<form className="p-1">
							<div className="card-inner pl-0">
								<div className="col">
									<div className="form-group row">
										<div className="form-control-wrap col-4">
											<label className="control-label">Quantity</label>
											<input
												type="number"
												className="form-control required"
												placeholder="Enter value"
												defaultValue={voyage?.QuantityofWaterOnboard}
												onChange={(e) =>
													addPayload("QuantityofWaterRequested", e.target.value)
												}
											/>
										</div>
										<div className="form-control-wrap col">
											<label className="control-label">
												Unit of measurement
											</label>
											<Select
												

												placeholder="unit of measurement"
												value={units.find(
													(a) => a.value == litresUnit?.MeasurementUnitId
												)}
												options={litresUnit}
												isDisabled={true}
												// isDisabled={voyage?.FuelMeasurementUnitSymbol !== null || litresUnit?.MeasurementUnitId}
											/>
										</div>
									</div>
								</div>
								<div>
									{
										// products.map((product, index)=> <div style={{zIndex: (products.length - index)}} key={index} className="my-2"><UsageReportComponent productId={product.ProductId}  /></div>)
									}
								</div>
							</div>
							{saving && (
								<div className="form-group p-2 m-3">
									<button type="button" className="btn btn-md btn-link m-1">
										<Spinner size="1.5" />
									</button>
								</div>
							)}
							{!saving && (
								<div className="form-group p-2 mt-3 ml-0">
									<button
										type="button"
										onClick={() => submitForm()}
										className="btn btn-md btn-dark btn-wide  mr-3">
										Save Water Consumption
									</button>
									<Link to={`/voyages`} className="btn btn-sm btn-link m-1">
										Cancel
									</Link>
								</div>
							)}
						</form>
					</div>
					<div className="col-6">
						<WaterUsageTableComponent
							waterConsumptionHistory={waterConsumptionHistory}
						/>
					</div>
				</div>
			)}
		</>
	);
}
