import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CategoryService from "../../services/countriesService";
import LocationService from "../../services/locationService";
import ProvinceService from "../../services/provinceService";
import Spinner from "../../utils/spinner";
import EquipmentService from "../../services/equipmentService";
import CertificateService from "../../services/certificateService";
import PartyService from "../../services/partyService";
import { checkUserPermission } from "../../utils/utilityFunctions";

export default function ViewEquipmentModal({
  measurementTypes,
  equipment,
  equipmentMeasurements,
}) {
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState(null);
  const [subCategory, setSubCategory] = useState(null);
  const [equipmentType, setEquipmentType] = useState(null);
  const [equipmentSize, setEquipmentSize] = useState(null);
  const [certificate, setCertificate] = useState(null);
  const [ownerName, setOwnerName] = useState(null);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [refinedEquipmentMeasurements, setRefinedEquipmentMeasurements] =
    useState([]);
  const [chosenEquipment, setChosenEquipment] = useState("");

  const init = async () => {
    setLoading(true);
    const categories = await EquipmentService.getEquipmentCategories();
    const _category = (categories || []).find(
      (c) =>
        c.TubularCategoryId ==
        equipment.CargoCarryingEquipment.TubularCategoryId
    );
    if (_category != null) setCategory(_category.TubularCategory);
    const _subCategory = (categories || []).find(
      (c) =>
        c.TubularCategoryId ==
        equipment.CargoCarryingEquipment.TubularSubCategoryId
    );
    if (_subCategory != null) setSubCategory(_subCategory.TubularCategory);

    const _containerSizes = await EquipmentService.getOffShoreContainerSizes();
    const _containerTypes = await EquipmentService.getOffShoreContainerTypes();
    const _containerSize = (_containerSizes || []).find(
      (c) =>
        c.ContainerSizeId == equipment.CargoCarryingEquipment.ContainerSizeId
    );
    const _containerType = (_containerTypes || []).find(
      (c) =>
        c.EquipmentTypeId ==
        equipment.CargoCarryingEquipment.OffshoreContainerEquipmentTypeId
    );
    
    if (_containerType != null) setEquipmentType(_containerType.EquipmentType);
    if (_containerSize != null) setEquipmentSize(_containerSize.Size);

    const _certificates = await CertificateService.listAll();
    const _certificate = (_certificates || []).find(
      (c) =>
        c.CertificateNameId == equipment.CargoCarryingEquipment.CertificateId
    );
    if (_certificate != null) setCertificate(_certificate.CertificateName);

    const _parties = await PartyService.listParty();
    const _owner = (_parties || []).find(
      (c) => c.PartyId == equipment.Party.PartyId
    );
    if (_owner != null) setOwnerName(_owner.PartyName);

    const EquipmentTypes = await EquipmentService.listCargoEquipmentTypes();
    if (EquipmentTypes == null) setEquipmentTypes([]);
    else
      setEquipmentTypes(
        EquipmentTypes.map((e) => ({
          ...e,
          label: e.EquipmentType,
          value: e.EquipmentTypeId,
          id: "CargoCarryingEquipmentTypeId",
        }))
      );
    setChosenEquipment(
      EquipmentTypes.find(
        (e) =>
          e.EquipmentTypeId ==
          equipment?.CargoCarryingEquipment?.CargoCarryingEquipmentTypeId
      )?.EquipmentType
    );

    const _equipmentMeasurementUnits =
      await EquipmentService.listMeasurementUnits();
    const _equipmentMeasurements = equipmentMeasurements.map((measurement) => ({
      ...measurement,
      MeasurementUnit: _equipmentMeasurementUnits?.find(
        (unit) => unit?.MeasurementUnitId == measurement?.MeasurementUnitId
      )?.MeasurementUnitSymbol,
    }));
    if (_equipmentMeasurements != null)
      setRefinedEquipmentMeasurements(_equipmentMeasurements);

    setLoading(false);
  };

  useEffect(async () => {
    await init();

    return () => {};
  }, []);

  const getMeasurementValue = (type, toReturn = "value") => {
    const measurementType = measurementTypes.find(
      (a) => a.MeasurementType == type
    );
    if (!measurementType) return "";
    const measurement = refinedEquipmentMeasurements.find(
      (m) => m.MeasurementTypeId == measurementType.MeasurementTypeId
    );
    if (measurement) {
      if (toReturn == "unitId") return measurement.MeasurementUnitId;
      return `${measurement.MeasurementValue}${measurement.MeasurementUnit}`;
    }
  };

  const dataItem = (label, value) => {
    if (value == "null") value = "";
    return (
      <li className="col-6 ">
        <p>
          <span className="text-soft small">{label}</span> {value || "-"}
        </p>
      </li>
    );
  };

  return (
    <>
      {loading && <Spinner />}
      {loading || (
        <div className="card ">
          <div className="sp-plan-desc card-inner">
            {chosenEquipment == "Offshore Container" ? (
              <ul className="row gx-1">
                {dataItem("Equipment Type", chosenEquipment)}
                {dataItem(
                  "Equipment Description",
                  equipment.CargoCarryingEquipment.EquipmentDescription
                )}
                {dataItem(
                  "CCU Number",
                  equipment.CargoCarryingEquipment.Ccunumber
                )}
                {dataItem("Owner", ownerName)}
                {dataItem("Certificate", certificate)}
                {dataItem("Type", equipmentType)}
                {dataItem("Size", equipmentSize)}
                {dataItem("MaxGrossMass", getMeasurementValue("MaxGrossMass"))}
                {dataItem("TareMass", getMeasurementValue("TareMass"))}
                {dataItem("Payload", getMeasurementValue("Payload"))}
                {dataItem("Height", getMeasurementValue("Height"))}
                {/* {dataItem("Length", getMeasurementValue("Length"))} */}
              </ul>
            ) : null}
            {chosenEquipment == "BigBag" ? (
              <ul className="row gx-1">
                {dataItem("Equipment Type", chosenEquipment)}
                {dataItem(
                  "Equipment Description",
                  equipment.CargoCarryingEquipment.EquipmentDescription
                )}
                {dataItem(
                  "Volume Capacity",
                  getMeasurementValue("VolumeCapacity")
                )}
                {dataItem("Length", getMeasurementValue("Length"))}
                {dataItem("Width", getMeasurementValue("Width"))}
              </ul>
            ) : null}
            {chosenEquipment == "Tubular" ? (
              <ul className="row gx-1">
                {dataItem("Equipment Type", chosenEquipment)}
                {dataItem(
                  "Equipment Description",
                  equipment.CargoCarryingEquipment.EquipmentDescription
                )}
                {dataItem("Category", category)}
                {dataItem("Sub-Category", subCategory)}
                {dataItem("Diameter", getMeasurementValue("Diameter"))}
                {dataItem("Length", getMeasurementValue("Length"))}
              </ul>
            ) : null}
            {/* <ul className="row gx-1">
              {dataItem(
                "Equipment Description",
                equipment.CargoCarryingEquipment.EquipmentDescription
              )}
              {dataItem(
                "CCU Number",
                equipment.CargoCarryingEquipment.Ccunumber
              )}
              {dataItem("Owner", ownerName)}
              {dataItem("Certificate", certificate)}
              {dataItem("Type", equipmentType)}
              {dataItem("Size", equipmentSize)}
              {dataItem("Category", category)}
              {dataItem("Sub-Category", subCategory)}
              {dataItem(
                "Volume Capacity",
                getMeasurementValue("VolumeCapacity")
              )}
              {dataItem("MaxGrossMass", getMeasurementValue("MaxGrossMass"))}
              {dataItem("TareMass", getMeasurementValue("TareMass"))}
              {dataItem("Payload", getMeasurementValue("Payload"))}
              {dataItem("Diameter", getMeasurementValue("Diameter"))}
              {dataItem("Height", getMeasurementValue("Height"))}
              {dataItem("Length", getMeasurementValue("Length"))}
              {dataItem("Width", getMeasurementValue("Width"))}
            </ul> */}
          </div>
          {checkUserPermission("equipment_edit_equipment_details") && (
          <div className="col-12 ml-0">
            <a
              href={`/edit-equipment/${equipment.CargoCarryingEquipment.CargoCarryingEquipmentId}`}
              className="mt-4 btn btn-dark btn-dim btn-sm "
            >
              <span>Edit Equipment</span>
            </a>
          </div>)}
        </div>
      )}
    </>
  );
}
