import apiCall from "../utils/apiCall";
import { dynamicSort, clearAPICache } from "../utils/utilityFunctions";

export default class LocationService {
  //Complete unpaginated list
  static extensiveList = async () => {
    return await apiCall(`/api/Location/Get/v2`, "GET", null, null, null, true);
  };
  static dashboardAllocation = async (
    page,
    pageSize,
    dateFrom,
    dateTo,
    locationTypeId
  ) => {
    page = page || 1;
    pageSize = pageSize || 10;
    dateFrom = dateFrom || "";
    dateTo = dateTo || "";
    locationTypeId = locationTypeId || "";
    return await apiCall(
      `/api/Location/Get/dashboard/installation-cost-allocated?pageCount=${page}&noPerPage=${pageSize}&locationTypeId=${locationTypeId}&dateFrom=${dateFrom}&dateTo=${dateTo}`
    );
  };

  static extensiveList2 = async () => {
    let response = await apiCall(`/api/Location/Get/v2`, "GET", null, null, null, true);
    if (response?.Data?.Data) {
      response = response?.Data?.Data;
    }
    return response;
  };

  static list = async (page, pageSize) => {
    page = page || 1;
    pageSize = pageSize || 50;
    let response = await apiCall(
      `/api/Location/Get/v2?page=${page}&pageSize=${pageSize}`, "GET", null, null, null, true
    );
    if (response?.Data?.Data) {
      response = response?.Data.Data;
    }
    return response;
  };
  static zoneList = async (page, pageSize, locationType, includeOMLs) => {
    page = page || 1;
    pageSize = pageSize || 1000;
    locationType = locationType || "Zone";
    includeOMLs = includeOMLs || true;
    let response = await apiCall(
      `/api/Location/Get/v2?page=${page}&pageSize=${pageSize}&locationType=${locationType}&includeOMLs=${includeOMLs}`, "GET", null, null, null, true
    );
    if (response?.Data?.Data) {
      response = response?.Data.Data;
    }
    return response;
  };
  static search = async (
    page,
    pageSize,
    searchQuery,
    locationTypeId,
    isActive,
    useCache
  ) => {
    page = page || 1;
    pageSize = pageSize || 50;
    searchQuery = searchQuery || "";
    if (!useCache) useCache = false;
    return await apiCall(
      `/api/Location/search?pageCount=${page}&noPerPage=${pageSize}&query=${searchQuery}&locationTypeId=${locationTypeId || ""
      }&isActive=${isActive == null ? "" : isActive}`,
      "GET",
      null,
      null,
      false,
      useCache
    );
  };

  static allLocations = async (params) => {
    let response = await apiCall(
      `/api/Location/Get/v2` + (params ? params : ""), "GET", null, null, null, true
    );
    if (response?.Data?.Data) {
      response = response?.Data.Data.sort(dynamicSort("LocationName"));
    }
    return response;
  };

  static get = async (locationId) => {
    if (!locationId) return null;
    return await apiCall(`/api/Location/GetExtended/${locationId}`);
  };

  static update = async (locationId, payload) => {
    clearAPICache(`Location`);
    return await apiCall(
      `/api/Location/update/${locationId}`,
      "PUT",
      payload,
      null,
      true,
      false,
      true
    );
  };

  static updateZoneCoordinates = async (locationId, payload) => {
    clearAPICache(`Location`);
    return await apiCall(
      `/api/Location/UpdateCoordinates/${locationId}`,
      "POST",
      payload
    );
  };

  static post = async (payload) => {
    clearAPICache(`Location`);
    return await apiCall(
      `/api/Location/post`,
      "POST",
      payload,
      null,
      true,
      false,
      true
    );
  };
  static postZone = async (payload) => {
    clearAPICache(`Location`);
    return await apiCall(
      `/api/Location/AddLocation`,
      "POST",
      payload,
      null,
      false,
      false
      // true
      // false
    );
  };

  static geTerminalActivitiesList = async (
    page,
    pageSize,
    voyagePlanningId,
    locationId
  ) => {
    page = page || 1;
    pageSize = pageSize || 1000;
    voyagePlanningId = voyagePlanningId || "";
    const response = await apiCall(
      `/api/Location/GetTerminalById/${locationId}/${page}/${pageSize}?voyagePlanningId=${voyagePlanningId}`
    );
    return response?.Data;
  };

  //location types

  static listLocationTypes = async (page, pageSize, useCache) => {
    page = page || 1;
    pageSize = pageSize || 50;
    if (!useCache) useCache = false;
    const response = await apiCall(
      `/api/LocationTypes/Get?pageCount=${page}&noPerPage=${pageSize}`,
      "GET",
      null,
      null,
      false,
      useCache
    );
    return response?.Data;
  };

  static getLocationType = async (id) => {
    if (!id) return null;
    return await apiCall(`/api/LocationTypes/Get/${id}`)?.Data;
  };

  static allActiveVesselsPosition = async (id) => {
    return await apiCall(`api/VesselPosition/GetActiveVesselPositions/${id}`);
  };

  static updateLocationType = async (id, payload) => {
    return await apiCall(`/api/LocationTypes/Put/${id}`, "PUT", payload);
  };

  static postLocationType = async (payload) => {
    return await apiCall(`/api/LocationTypes/post`, "PUT", payload);
  };

  //the ignoreMapping filter is to make it return data irrespective of user role
  static getAllActivePorts = async (
    page,
    pageSize,
    locationTypeId,
    isActive
  ) => {
    page = page || 1;
    pageSize = pageSize || 5000;
    if (!isActive) isActive = true;
    return await apiCall(
      `/api/Location/search?pageCount=${page}&noPerPage=${pageSize}&ignoreRoleMapping=true&locationTypeId=${locationTypeId || ""
      }&activeOnly=${isActive}`,
      "GET", null, null, null, true
    );
  };

  static searchPartyLocations = async (page, pageSize, partyId, useCache) => {
    page = page || 1;
    pageSize = pageSize || 50;
    if (!useCache) useCache = false;
    return await apiCall(
      `/api/Location/search?pageCount=${page}&noPerPage=${pageSize}&partyId=${partyId}`,
      "GET",
      null,
      null,
      false,
      useCache
    );
  };

  static changeLocationPoint = async (payload) => {
    return await apiCall(`/api/Location/Relocate`, "POST", payload);
  };
  static dashboardInstallationEfficiency = async (payload) => {
    return await apiCall(
      `/api/dashboard/top-perfoming-locations`,
      "POST",
      payload
    );
  };
  static dashboardInstallationEfficiencyExtended = async (payload) => {
    return await apiCall(`/api/dashboard/top-perfoming-locations-extended`, "POST", payload);
  };

  static getLocationHistory = async (locationId) => {
    if (!locationId) return null;
    return await apiCall(
      `/api/Location/RelocationHistory?locationId=${locationId}`
    );
  };
}
