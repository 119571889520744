import React, { useEffect, useState, useCallback, PureComponent } from "react";
import "../../styles/dataTable.scss";
import {
	useNavigate,
	useLocation,
	Link,
	Navigate,
	useParams,
	useSearchParams,
} from "react-router-dom";
import moment from "moment";
import ms from "ms";
import MainLayout from "../../layouts/mainLayout";
import BookingRequestService from "../../services/bookingRequestService";
import Select from "react-select";
import Spinner from "../../utils/spinner";
import toastr from "../../utils/toastr";
import DataTable from "../../components/dataTable";
import Paginate from "../../components/paginate";
import { exportToCSV } from "../../utils/exportCSV";
import ReactTooltip from "react-tooltip";
import BookingTable from "../../components/bookingTable";
import {
	generateQueryString,
	checkUserPermission,
	formatValue,
	toReadableDate,
} from "../../utils/utilityFunctions";
import VesselService from "../../services/vesselService";
import LocationService from "../../services/locationService";
import styled from "styled-components";
import CustomModal from "../../utils/customModal";
import {
	ComposedChart,
	Scatter,
	ScatterChart,
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	Line,
	ResponsiveContainer,
	Cell,
} from "recharts";
import { Accordion } from "../../utils/accordion";

export default function OffshoreInstallationEfficiency() {
	const [redirectUrl, setRedirectUrl] = useState(null);
	const [loading, setLoading] = useState(false);
	const [payload, setPayload] = useState({});
	const [pages, setPages] = useState([10, 20, 50, 100, 150]);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [selectedRecord, setSelectedRecord] = useState(null);
	const [totalRecords, setTotalRecords] = useState(10);
	const [utilizationList, setUtilizationList] = useState([]);
	const [exportData, setExportData] = useState([]);
	const [maxMonthHeader, setMaxMonthHeader] = useState("");
	const [secondMaxMonthHeader, setSecondMaxMonthHeader] = useState("");
	const [thirdMonthHeader, setThirdMaxMonthHeader] = useState("");
	const navigate = useNavigate();
	const [filterObject, setFilterObject] = useState({});
	const [vesselList, setVesselList] = useState([]);
	const [locationTypes, setLocationTypes] = useState([]);
	const [allLocationTypes, setAllLocationTypes] = useState([]);
	const [maxDate, setMaxDate] = useState(null);
	const [minDate, setMinDate] = useState(null);
	const [installationList, setOffshoreInstallationList] = useState([]);
	const [chartData, setChartData] = useState([]);
	const [newUtilizationDetails, setNewUtilizationDetails] = useState([]);
	const [unHideTable, setUnHideTable] = useState(false);
	const [showTooltip, setShowTooltip] = useState(false);
	const [activeAccordionIndex, setActiveAccordionIndex] = useState(false);
	const [activeVoyageCostTooltip, setActiveVoyageCostTooltip] = useState({});
	const [voyageCostTooltipColors, setVoyageCostTooltipColors] = useState({});

	const monthArray = [
		{ label: "January", value: 1 },
		{ label: "February", value: 2 },
		{ label: "March", value: 3 },
		{ label: "April", value: 4 },
		{ label: "May", value: 5 },
		{ label: "June", value: 6 },
		{ label: "July", value: 7 },
		{ label: "August", value: 8 },
		{ label: "September", value: 9 },
		{ label: "October", value: 10 },
		{ label: "November", value: 11 },
		{ label: "December", value: 12 },
	];

	//Array of locationType that count as Offshore installation
	let offshoreInstallationArray = [
		"offshore installation",
		"fso",
		"fpso",
		"onshore terminal",
		"offshore terminal",
	];
	// FPSO, FSO, Offshore installation, Onshore terminal and offshore terminal
	//Getting the current date and month and creating variables with integer month values for earlier months.
	//The filterObject.dateFrom retrieves filtered date option from the user Input
	let monthInRealTime = 1 + moment().month();
	let filteredYear = moment(filterObject.dateTo).get("year");
	let currentYear = moment().get("year") || filteredYear;
	let secondMonth = monthInRealTime - 1;
	const previousYear = currentYear - 1;
	let thirdMonth = secondMonth - 1;
	let filteredMonthFrom = moment(filterObject.dateFrom).get("month") + 1;
	let filteredMonthTo = moment(filterObject.dateTo).get("month") + 1;
	// const monthCheck = () => {
	if (monthInRealTime === 1) {
		secondMonth = 12;
		thirdMonth = 11;
	} else if (monthInRealTime === 2) {
		secondMonth = 1;
		thirdMonth = 12;
	} else if (monthInRealTime === 3) {
		secondMonth = monthInRealTime - 1;
		thirdMonth = monthInRealTime - 2;
	}

	let defaultQuery = {
		currency: "dollars",
		limit: 5,
		locationType: filterObject?.locationType,
		months: [
			{
				year: monthInRealTime < 3 ? previousYear : currentYear,
				month: thirdMonth,
			},
			{
				year: monthInRealTime + 1 < 3 ? previousYear : currentYear,
				month: secondMonth,
			},
			{
				year: currentYear,
				month: monthInRealTime,
			},
		],
	};

	useEffect(async () => {
		setLoading(true);
		await init();
		setLoading(false);
	}, []);

	useEffect(() => {
		const minToSeconds = ms("90d");
		const minimumDate = new Date(+new Date(filterObject.dateFrom));
		const maximumDate = new Date(
			+new Date(filterObject.dateFrom) + minToSeconds
		);
		setMinDate(moment(minimumDate).format("YYYY-MM-DD"));
		setMaxDate(moment(maximumDate).format("YYYY-MM-DD"));
	}, [filterObject.dateFrom]);

	const init = async () => {
		setLoading(true);
		// monthCheck()
		await loadChartData();
		let locationTypes = await LocationService.listLocationTypes();
		const filteredLocation = locationTypes?.filter((list) =>
			offshoreInstallationArray.find(
				(option) => option === list?.LocationType?.toLowerCase()
			)
		);
		setAllLocationTypes(filteredLocation);

		const locationsList = await LocationService.list();

		setLoading(false);
	};

	// let queryStr = generateQueryString(filterObject);

	const clearFilters = async () => {
		defaultQuery.locationType = "";
		defaultQuery.currency = "dollars";
		defaultQuery.limit = 5;
		// filteredYear = moment().get("year");
		// if (monthInRealTime == 1) {
		// 	secondMonth = 12;
		// 	thirdMonth = 11;
		// } else if (monthInRealTime === 2) {
		// 	secondMonth = 1;
		// 	thirdMonth = 12;
		// } else if (monthInRealTime === 3) {
		// 	secondMonth = 2;
		// 	thirdMonth = 1;
		// }

		defaultQuery.months = [
			{
				year: monthInRealTime < 3 ? previousYear : currentYear,
				month: thirdMonth,
			},
			{
				year: monthInRealTime + 1 < 3 ? previousYear : currentYear,
				month: secondMonth,
			},
			{
				year: currentYear,
				month: monthInRealTime,
			},
		];

		setFilterObject({
			dateFrom: "",
			dateTo: "",
			locationType: "",
		});

		await loadChartData(defaultQuery);
	};
	const filterData = async () => {
		secondMonth = filteredMonthFrom + 1;
		if (filteredMonthFrom == 12) {
			secondMonth = 1;
		} else if (filteredMonthFrom == 0) {
			filteredMonthFrom = monthInRealTime;
		}

		defaultQuery.months = [
			{
				// year: monthInRealTime < 3 ? currentYear : filteredYear,
				year: filteredYear || currentYear,
				month: filteredMonthFrom,
			},
			{
				// year: ((filteredMonthFrom + 1 < 3 ) && monthInRealTime < 1)? currentYear : filteredYear,
				year: filteredYear || currentYear,
				month: secondMonth,
			},
			{
				year: filteredMonthFrom < 3 ? previousYear : filteredYear,
				month: filteredMonthTo,
			},
		];
		//If the value of the "MonthTo" and second month are the same, remove the last month object
		// to avoid duplication in endpoint request data.
		if (filteredMonthTo > 0 && filteredMonthTo === secondMonth) {
			defaultQuery?.months.pop();
		} else if (filteredMonthTo > 0 && filteredMonthTo === filteredMonthFrom) {
			defaultQuery?.months.pop();
		}
		await loadChartData(defaultQuery);
	};

	let modifiedUtilizationDetailsTest = newUtilizationDetails
		?.map((list) =>
			list?.topPerformingLocations?.map(
				(items) =>
					// items?.InstallationUtilizations?.map((details) =>
					({
						...items,
					})
				// )
			)
		)
		.flat(2);

	const loadChartData = async () => {
		setLoading(true);

		let loadEfficiencyDetails =
			await LocationService.dashboardInstallationEfficiencyExtended(
				defaultQuery
			);
		if (!loadEfficiencyDetails) {
			setLoading(false);

			return toastr(
				"warning",
				"No records found, please filter using older date(s)"
			);
		}

		// await LocationService.dashboardInstallationEfficiency(defaultQuery);
		setNewUtilizationDetails(loadEfficiencyDetails);
		//I changed the payload to the mock endpoint
		let modifiedDataList = loadEfficiencyDetails
			?.map((list) =>
				list?.topPerformingLocations?.map((items) =>
					items?.InstallationUtilizations?.map((details) => ({
						PercentUtilization: details?.PercentUtilization,
						Activity: details?.Activity,
						LocationName: details?.LocationName,
						Month: monthArray.find((item) => item.value == list?.Month).label,
						ThirdMonthVariable: (
							<div>
								<p>
									{formatValue(
										modifiedUtilizationDetailsTest?.find(
											(item) =>
												details?.Month === thirdMonth &&
												item.Month === thirdMonth
										)?.PercentUtilization
									)?.toFixed(3) || "0.00"}
								</p>
							</div>
						),
						SecondMonthVariable: (
							<div>
								<p>
									{formatValue(
										modifiedUtilizationDetailsTest?.find(
											(item) =>
												details?.Month === secondMonth &&
												item.Month === secondMonth
										)?.PercentUtilization
									)?.toFixed(3) || "0.00"}
								</p>
							</div>
						),
						CurrentMonthVariable: (
							<div>
								<p>
									{modifiedUtilizationDetailsTest
										?.find(
											(item) =>
												details?.Month === monthInRealTime &&
												item.Month === monthInRealTime
										)
										?.PercentUtilization?.toFixed(3) || "0.00"}
								</p>
							</div>
						),
					}))
				)
			)
			.flat(2);
		setUtilizationList(modifiedDataList);
		setLoading(false);
	};

	const groupObjectByKey = (array, key) => {
		return array?.reduce((result, currentValue) => {
			(result[currentValue[key]] = result[currentValue[key]] || []).push(
				currentValue
			);
			return result;
		}, {});
	};

	const groupedUtilizationArray = groupObjectByKey(
		utilizationList,
		"LocationName"
	);
	const keys = groupedUtilizationArray && Object?.keys(groupedUtilizationArray);
	let values =
		groupedUtilizationArray && Object?.values(groupedUtilizationArray);
	let sampleData = [];
	keys?.forEach((v, i) => {
		const obj = {};
		obj.LocationName = v;
		obj.Activities = values[i];
		sampleData.push(obj);
	});
	const generateKeyValuePair = (sortedArray) => {
		const result = sortedArray.map((dataObj) => ({
			Utilization: dataObj.PercentUtilization?.toFixed(3),
			ActivityName: dataObj.Activity,
			Month: dataObj.Month,
			// [dataObj.Activity]: dataObj.PercentUtilization,
		}));
		return result;
	};
	sampleData = sampleData?.map((list) => ({
		LocationName: list?.LocationName,
		Activities: generateKeyValuePair(list.Activities),
	}));
	let newProductivityData = newUtilizationDetails?.map((list) =>
		list?.topPerformingLocations?.map((x) => ({
			LocationName: x?.LocationName,
			PercentUnproductive: x?.ProductiveCount?.toFixed(1),
			Percentproductive: x?.NonProductiveCount?.toFixed(1),
			Month: monthArray.find((month) => list.Month === month.value).label,
		}))
	);
	const flattenedProductivityData = newProductivityData?.flat();
	const moodifiedProductivityData = {};
	//converting the flattened array to a format that is compatible with the chart
	flattenedProductivityData?.map((item) => {
		// handles month modifiedChartPayload
		moodifiedProductivityData[item?.Month] = {
			//convert month to number
			...(moodifiedProductivityData[item.Month] || {}),
			[item.LocationName]: { ...item },
		};
	});
	const productivityBarData = Object.keys(moodifiedProductivityData).map(
		(key) => {
			return {
				Month: key,
				...moodifiedProductivityData[key],
			};
		}
	);
	const legendFormatter2 = (value, entry, index) => value?.split(".")[0];

	//This code below takes the number of items in the props.payload and retrieves the maximum number of locations while
	//using Object.keys to retieve the length of the unique locations to ensure we do not render more than necessary.
	//legendFormatter already formatted the locations name,so it returns an array of location name, total cost and Sum of Voayages
	const tooltipFormatter2 = (value, name, props, index) =>
		index < Object.keys(props.payload).length - 1
			? [
					`Productive: ${
						props.payload[legendFormatter(name)].Percentproductive
					},
				 Unproductive: ${props.payload[legendFormatter(name)].PercentUnproductive} `,
					legendFormatter(name),
			  ]
			: [];

	const productiveBarChart2 = () => {
		<ResponsiveContainer height={300}>
			<ComposedChart
				data={productivityBarData}
				style={{ backgroundColor: "#F4F4F4" }}
				margin={{
					top: 5,
					right: 30,
					left: 20,
					bottom: 5,
				}}>
				<XAxis dataKey="Month" />
				<YAxis />

				<Tooltip formatter={tooltipFormatter2} />
				<Legend formatter={legendFormatter2} />
				{Object.keys(uniqueLocations).map((item, key) => (
					<Bar
						dataKey={`${item}.PercentProductive`}
						fill={colors[key % colors.length]}
						key={key}
						yAxisId="left"
					/>
				))}
			</ComposedChart>
		</ResponsiveContainer>;
	};

	let modifiedUtilizationDetails = newUtilizationDetails?.map((list) =>
		list?.topPerformingLocations?.map((items, index) => ({
			LocationName: items?.LocationName,
			Month: monthArray?.find((mon) => mon.value === list.Month)?.label,
			NumberOfVoyages: items?.NumberOfVoyages,
			OffshoreInstallationId: items?.OffshoreInstallationId,
			TotalCost: items?.TotalCost,
			Positioning: index + 1,
		}))
	);
	let flattenedUtilizationDetails = modifiedUtilizationDetails?.flat();

	const headerList = [
		{
			Header: "Month",
			accessor: "Month",
		},
		{
			Header: "Activity Name",
			accessor: "ActivityName",
		},
		{
			Header: "Percentage Utilization",
			accessor: "Utilization",
		},
	];

	const colors = [
		"#003f5c",
		"#2f4b7c",
		"#665191",
		"#a05195",
		"#d45087",
		"#f95d6a",
		"#ff7c43",
		"#ffa600",
	];
	const productiveBarColors = ["#003f5c", "#a05195"];

	let modifiedChartPayload = {};
	const uniqueLocations = {};

	//converting the flattened array to a format that is compatible with the chart
	flattenedUtilizationDetails?.map((item) => {
		// handles month modifiedChartPayload
		modifiedChartPayload[item?.Month] = {
			//convert month to number
			...(modifiedChartPayload[item.Month] || {}),
			[item.LocationName]: { ...item },
		};
		// handles unique locations
		uniqueLocations[item.LocationName] = true;
	});
	const finalData = Object.keys(modifiedChartPayload).map((key) => {
		return {
			Month: key,
			...modifiedChartPayload[key],
		};
	});
	const totalCostData = [];
	const lineChartData = finalData.map((locations) => {
		Object.values(locations).map(
			(item) =>
				item.TotalCost &&
				totalCostData.push({
					TotalCost: item.TotalCost,
					Month: locations.Month,
					// LocationName: item.LocationName,
				})
		);
	});

	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			let tooltipContent = payload[0]?.payload;
			return (
				<div
					className="custom-tooltip bg-white text-center p-2 m-2 border border-secondary"
					style={{ position: "absolute;" }}>
					<p className="label">{`Installation Name : ${tooltipContent?.LocationName}`}</p>
					<p className="intro">{`Month: ${tooltipContent?.Month}`}</p>
					<p className="desc">{`Sum of Cost: ${(tooltipContent?.TotalCost).toFixed(
						3
					)}`}</p>
					<p className="desc">{`Voyage Count: ${tooltipContent?.NumberOfVoyages}`}</p>
				</div>
			);
		}

		return null;
	};

	// this function formats the legend being used below the chart and it also maps the legend color to the bars for use in the tooltips
	const legendFormatter = (value, entry, index) => {
		if (!voyageCostTooltipColors[value?.split(".")[0]]) {
			setVoyageCostTooltipColors((prev) => ({
				...prev,
				[value.split(".")[0]]: entry.color,
			}));
		}
		return value?.split(".")[0];
	};

	//This code below takes the number of items in the props.payload and retrieves the maximum number of locations while
	//using Object.keys to retieve the length of the unique locations to ensure we do not render more than necessary.
	//legendFormatter already formatted the locations name,so it returns an array of location name, total cost and Sum of Voayages
	const tooltipFormatter = (value, name, props, index) =>
		// PLEASE NOTE this function is now being performed and overwritten by CustomTooltip2
		index < Object.keys(props?.payload).length - 1
			? [
					`Voyages Count: ${
						props?.payload[legendFormatter(name)].NumberOfVoyages
					},
  		 Total Cost: ${props?.payload[legendFormatter(name)].TotalCost} `,
					legendFormatter(name),
			  ]
			: [];

	// This function modifies the data gotten from hovering over a bar so it can fit into the custom tooltip
	const generateVoyageCostTemplate = (data) => {
		const objKeys = Object.keys(data);
		const dataArray = [];
		objKeys.map(
			(obj) => data[obj]?.LocationName && dataArray.push({ ...data[obj] })
		);
		const formattedData = { month: data?.Month, data: dataArray };
		setActiveVoyageCostTooltip(formattedData);
	};

	// This creates a custom tooltip using information from the BAR hovered on rather than the line because the line doesn't work well so we need to use the
	// bar to generate the tooltip ddata
	const CustomTooltip2 = ({ active, payload, label }) => {
		if (active && payload && payload.length && activeVoyageCostTooltip?.month) {
			return (
				<div className="custom-tooltip bg-white p-3">
					<p className="label ">{activeVoyageCostTooltip?.month}</p>
					{activeVoyageCostTooltip?.data?.map((location, index) => (
						<p
							style={{ color: voyageCostTooltipColors[location.LocationName] }}
							key={index}>
							<span>{location.LocationName.toUpperCase()}</span>:
							<span className="mx-1">
								Voyages Count: {location?.NumberOfVoyages},
							</span>
							<span>Total Cost: {location?.TotalCost}</span>
						</p>
					))}
				</div>
			);
		}

		return null;
	};

	const RenderChart = () => (
		<ResponsiveContainer height={300}>
			<ComposedChart
				data={finalData}
				style={{ backgroundColor: "#F4F4F4" }}
				margin={{
					top: 5,
					right: 30,
					left: 20,
					bottom: 5,
				}}>
				<XAxis dataKey="Month" />
				<XAxis dataKey="Month" xAxisId="Month" hide />
				<YAxis
					yAxisId="left"
					orientation="left"
					stroke="#8884d8"
					label={{
						value: "Voyages",
						angle: -90,
						position: "insideLeft",
					}}
				/>
				<YAxis
					yAxisId="right"
					orientation="right"
					stroke="#7bcd32"
					label={{
						value: "Cost",
						angle: 90,
						position: "insideRight",
					}}
				/>
				<Tooltip formatter={tooltipFormatter} content={<CustomTooltip2 />} />
				<Legend formatter={legendFormatter} />

				{Object.keys(uniqueLocations).map((item, key) => (
					<Bar
						dataKey={`${item}.NumberOfVoyages`}
						fill={colors[key % colors.length]}
						key={key}
						yAxisId="left"
						onMouseEnter={(e) => generateVoyageCostTemplate(e?.payload || {})}
					/>
				))}

				<Line
					data={totalCostData}
					dataKey="TotalCost"
					// key={key}
					type="monotone"
					stroke="#7bcd32"
					// activeDot={{ r: 8 }}
					strokeWidth={2.5}
					connectNulls={false}
					fill="#ff7c43"
					yAxisId="right"
					xAxisId="Month"
					legendType="none"
					name={0}
					dot={false}
					activeDot={false}
				/>
			</ComposedChart>
		</ResponsiveContainer>
	);

	const legendFormatterForUtilization = (value, entry, index) =>
		value?.split(".")[0];

	const tooltipFormatterForUtilization = (value, name, props, index) =>
		index < Object.keys(props.payload).length - 1
			? [
					`Percent productive: ${
						props.payload[legendFormatterForUtilization(name)].Percentproductive
					}, 
					PercentUnproductive: ${
						props.payload[legendFormatterForUtilization(name)]
							.PercentUnproductive
					} `,
					legendFormatterForUtilization(name),
			  ]
			: [];

	const RenderChartUtilization = () => (
		<ResponsiveContainer height={300}>
			<ComposedChart
				data={productivityBarData}
				style={{ backgroundColor: "#F4F4F4" }}
				margin={{
					top: 5,
					right: 30,
					left: 20,
					bottom: 5,
				}}>
				<XAxis dataKey="Month" />
				{/* <XAxis dataKey="Month" /> */}
				<YAxis
					// yAxisId="left"
					// orientation="left"
					stroke="#8884d8"
					label={{
						value: "Percentage Utilization",
						angle: -90,
						position: "insideLeft",
					}}
				/>
				<Tooltip formatter={tooltipFormatterForUtilization} />
				{/* <Legend formatter={legendFormatterForUtilization} />  */}
				{Object.keys(uniqueLocations).map((item, key) => (
					<Bar
						dataKey={`${item}.Percentproductive`}
						fill="#003f5c"
						// key={key}
						stackId="a"
					/>
				))}
				{Object.keys(uniqueLocations).map((item, key) => (
					<Bar
						dataKey={`${item}.PercentUnproductive`}
						fill="#a05195"
						// key={key}
						stackId="a"
					/>
				))}
			</ComposedChart>
		</ResponsiveContainer>
	);
	const accordionData = [
		sampleData?.map((list) => ({
			// {
			title: list?.LocationName,
			content: (
				<BookingTable
					columns={headerList}
					data={list.Activities}
					cssArray={["font-weight-500", "", "", "text-left", "", ""]}
					enableSearch={false}
					emptyMessage="No installation record to show"
					disablePageSize={true}
				/>
			),
			// },
		})),
	];
	return (
		<>
			{loading ? (
				<div className="w-100 text-center center my-5 py-5">
					<Spinner />
				</div>
			) : (
				<>
					<div className="card-inner-group">
						<h5 className="text-center pt-3 pb-0 pl-1">
							Offshore Installation Efficiency(time spent in %)
						</h5>
						{utilizationList?.length < 0 ? null : (
							// The filter and pagination component

							<div className="card-inner position-relative card-tools-toggle">
								{
									<div className="card-title-group">
										<div className="card-tools ml-n1">
											<ul className="btn-toolbar gx-1">
												<li>
													<div className="dropdown">
														<a
															href="#"
															className="btn btn-trigger btn-icon dropdown-toggle "
															data-toggle="dropdown">
															<em className="icon ni ni-filter-alt" />
														</a>
														<div className="dropdown-menu dropdown-menu-xl dropdown-menu-left p-1">
															<form style={{ zoom: "85%" }}>
																<div className="dropdown-head">
																	<span className="sub-title dropdown-title">
																		Filter List
																	</span>
																	<div className="dropdown">
																		<a
																			className="btn btn-sm btn-icon"
																			data-toggle="dropdown">
																			<em
																				className="icon ni ni-cross"
																				title="close"
																			/>
																		</a>
																	</div>
																</div>
																<div className="dropdown-body dropdown-body-rg">
																	{/* gx-6 gy-3 */}
																	<div className="row ">
																		<div className="col-12">
																			<div
																				className="form-group"
																				onClick={(e) => e.stopPropagation()}>
																				<label className="overline-title overline-title-alt">
																					Location Type
																				</label>
																				<Select
																					className="border-transparent"
																					placeholder=""
																					maxMenuHeight={140}
																					options={allLocationTypes?.map(
																						(e) => ({
																							...e,
																							label: e.LocationType,
																							value: e.LocationTypeId,
																						})
																					)}
																					value={allLocationTypes
																						?.map((e) => ({
																							...e,
																							label: e.LocationType,
																							value: e.LocationTypeId,
																						}))
																						.find(
																							(e) =>
																								e.label ===
																								filterObject.locationType
																						)}
																					onChange={(option) =>
																						setFilterObject((prev) => ({
																							...prev,
																							locationType: option.label,
																						}))
																					}
																				/>
																			</div>
																		</div>
																		<br />
																		<div className="col-6">
																			<div className="form-group">
																				<label
																					className="form-label"
																					htmlFor="fw-last-name">
																					From
																				</label>
																				<div className="">
																					<input
																						type="date"
																						className="form-control "
																						placeholder=""
																						id="from-date-filter"
																						value={filterObject.dateFrom}
																						onChange={(option) =>
																							setFilterObject((prev) => ({
																								...prev,
																								dateFrom: option.target.value,
																							}))
																						}
																					/>
																				</div>
																			</div>
																		</div>

																		<div className="col-6">
																			<div className="form-group">
																				<label
																					className="form-label"
																					htmlFor="fw-last-name">
																					To
																				</label>
																				<div className="form-control-wrap">
																					<input
																						type="date"
																						className="form-control "
																						placeholder=""
																						id="from-date-filter"
																						value={filterObject.dateTo}
																						onChange={(option) =>
																							setFilterObject((prev) => ({
																								...prev,
																								dateTo: option.target.value,
																							}))
																						}
																						min={minDate}
																						max={maxDate}
																					/>
																				</div>
																			</div>
																		</div>

																		<div className="gap gap-10px" />

																		<div className="col-12">
																			<div className="form-group">
																				<button
																					type="button"
																					onClick={filterData}
																					className="btn btn-secondary">
																					<span>Filter</span>
																				</button>
																				<a
																					className="clickable ml-2"
																					onClick={() => clearFilters()}>
																					Reset Filter
																				</a>
																			</div>
																		</div>
																		<div className="gap gap-20px" />
																	</div>
																</div>
															</form>
														</div>
													</div>
												</li>
											</ul>
										</div>
										{unHideTable && (
											<div className="card-tools mr-n1">
												<ul className="btn-toolbar gx-1">
													<li>
														<button
															className="btn btn-trigger btn-icon "
															// onClick={page !== 1 ? prevLoaded : null}
															// disabled={page == 1 ? true : false}
															title="Previous voyages">
															<em className="icon ni ni-arrow-left" />
														</button>
													</li>
													<li className="btn-toolbar-sep" />
													<li>
														<button
															className="btn btn-trigger btn-icon "
															// onClick={page >= 1 ? nextLoaded : null}
															// disabled={page >= pageLimiter ? true : false}
															title="Next voyages">
															<em className="icon ni ni-arrow-right" />
														</button>
													</li>
													<li className="btn-toolbar-sep" />
													<li>
														<div className="g" data-select2-id={6}>
															<div
																className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3"
																data-select2-id={5}>
																<div>
																	<input
																		type="number"
																		className="form-control border search-input text-align-center"
																		placeholder=""
																		// onChange={(e) => goToPage(e.target.value)}
																		value={page}
																		style={{ width: "50px" }}
																	/>
																</div>
																{/* <div>of {`${pageLimiter}`}</div> */}
															</div>
														</div>
													</li>
												</ul>
											</div>
										)}
									</div>
								}
							</div>
						)}
						<>
							{unHideTable && (
								<div className="" style={{ width: "100%" }}>
									<div className="text-center">
										<button
											type="button"
											className="btn btn-sm btn-link m-1"
											onClick={() => setUnHideTable(false)}>
											View as a chart
										</button>
									</div>
									<div className="accordion">
										{accordionData[0].map(({ title, content }, index) => (
											<Accordion
												title={title}
												content={content}
												index={index}
												activeAccordionIndex={index === activeAccordionIndex}
												setActiveAccordionIndex={setActiveAccordionIndex}
											/>
										))}
									</div>
								</div>
							)}
						</>
					</div>
				</>
			)}
			{!unHideTable && (
				<div className="text-center">
					<div className="text-center">
						<button
							type="button"
							className="btn btn-sm btn-link m-1"
							onClick={() => setUnHideTable(true)}>
							View as a table
						</button>
					</div>
					<div className="row ">
						<div className="col-6 justify-center">
							{finalData.length > 0 ? (
								RenderChart()
							) : (
								<div className="p-4">
									<p>
										<b>No record to display!</b>
									</p>
								</div>
							)}
						</div>
						{/* </div> */}

						{/* <div className="row "> */}
						<div className="col-6 justify-center">
							{productivityBarData.length > 0 ? (
								RenderChartUtilization()
							) : (
								<div className="p-4">
									<p>
										<b>No record to display!</b>
									</p>
								</div>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
}
