import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import React, { useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import MapView3 from "../../components/vesselPools/mapView3";
import toastr from "../../utils/toastr";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import marker from "../../images/ferry.svg";
import L from "leaflet";

export default function MapViewTabComponent(selectedPoolForMapList, fleetId) {
	const [selectedMarker, setSelectedMarker] = useState(null);
	const [mapObject, setMapObject] = useState();
	const [loading, setLoading] = useState(true);
	const [selectedPosition, setSelectedPosition] = useState(null);
	const [message, setMessage] = useState(null);

	useEffect(() => {
		setLoading(true);
		displayMap();
		setLoading(false);
	}, []);

	const displayMap = () => {
		// Extracting all the needed details
		selectedPoolForMapList =
			selectedPoolForMapList?.Data?.map((detailsNeeded) => ({
				VesselName: detailsNeeded?.VesselName,
				Longitude: detailsNeeded?.Longitude,
				Latitude: detailsNeeded?.Latitude,
				Destination: detailsNeeded?.Destination,
				Eta: detailsNeeded?.Eta,
				LastPortId: detailsNeeded?.LastPortId,
				LastPortTime: detailsNeeded?.LastPortTime,
				Speed: detailsNeeded?.Speed,
				FleetName: detailsNeeded?.FleetName,
				LastPortName: detailsNeeded?.LastPortName,
				//   FleetName: pool.fleetName,
			}));
		if (
			selectedPoolForMapList === undefined ||
			selectedPoolForMapList.length < 1
		) {
			setMapObject(null);
			setMessage(
				<div
					className="alert alert-info alert-dismissible fade show"
					role="alert">
					There are no map coordinates for the vessels in this pool!
					<button
						type="button"
						className="close"
						data-dismiss="alert"
						aria-label="Close"></button>
				</div>
			);
		}
		if (selectedPoolForMapList) {
			let coords = selectedPoolForMapList.map((loc) => [
				loc?.Latitude,
				loc?.Longitude,
				loc,
			]);
			setMapObject({
				markerOnClick: (loc) => setSelectedMarker(loc[2]),
				useChildren: true,
				markerPositions: coords,
				readOnly: true,
			});
		}
	};

	return (
		<div>
			{selectedPoolForMapList?.length < 1 ? null : <div>{message}</div>}
			<br />
			{selectedPoolForMapList.selectedPoolForMapList?.length > 0 && (
				<MapView3 {...mapObject}>
					{selectedMarker && (
						<Popup
							position={[selectedMarker.Latitude, selectedMarker.Longitude]}
							onClose={() => {
								setSelectedMarker(null);
							}}>
							<div>
								<h6>Vessel Name: {selectedMarker.VesselName}</h6>
								<p>Destination: {selectedMarker.Destination}</p>
							</div>
						</Popup>
					)}
				</MapView3>
			)}
		</div>
	);
}
