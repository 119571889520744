import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/mainLayout";
import {
    MapContainer,
    Marker,
    Popup,
    TileLayer,
  } from "react-leaflet";
  import L from "leaflet";
  import osm from "../../utils/osm.providers";
  import "leaflet/dist/leaflet.css";
  import "leaflet-draw/dist/leaflet.draw.css";
  import marker from "../../images/ferry.svg";
import * as vesselPoolsMockEndpoint from "../../utils/getpoolvessels.json";


export default function PoolMapView(){

    const pageActions = (
        <div className="toggle-wrap nk-block-tools-toggle">
          <a
            href="#"
            className="btn btn-icon btn-trigger toggle-expand mr-n1"
            data-target="pageMenu"
          >
            <em className="icon ni ni-menu-alt-r" />
          </a>
          <div className="toggle-expand-content" data-content="pageMenu">
            <ul className="nk-block-tools g-3">
              <li>
                <button
                  onClick={() => window.history.back()}
                  className="btn btn-white btn-outline-light"
                >
                  <span>{"< "}Back to Pool list</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      );
    
    return(
        <MainLayout title={"Active Vessel Postions  🚢"} pageActions={pageActions}>
            </MainLayout>
    )
}