import React, { useDisclosure, useEffect, useState } from "react";
import Select from "react-select";
import toastr from "../../utils/toastr";
import MainLayout from "../../layouts/mainLayout";
import EquipmentService from "../../services/equipmentService";

import ReactModal from "react-modal";
import { defaultModalStyle } from "../../utils/customStyles";
import ProductService from "../../services/productService";

export default function CreateOrEditShipEquipment({
	callback,
	editDetails,
	show,
	setShowEquipmentModal,
}) {
	const [showModal, setShowModal] = useState(true);
	const [isOpen, setIsOpen] = useState(!!show);
	const [isNewEquipment, setIsNewEquipment] = useState(false);
	const [equipments, setEquipments] = useState([]);
	const [equipmentTypes, setEquipmentTypes] = useState([]);
	const [measurementUnits, setMeasurementUnits] = useState([]);
	const [filteredMeasurementUnits, setFilteredMeasurementUnits] = useState([]);
	const [measurementUnitTypes, setMeasurementUnitTypes] = useState([]);
	const [newEquipmentData, setNewEquipmentData] = useState({
		VesselSpecificationId: null,
		ProductId: null,
		EquipmentTypeId: null,
		EquipmentCount: 1,
		CapacityMeasure: 0,
		MeasurementUnitId: null,
		MeasurementUnitTypeId: null,
		EquipmentId: null,
		Description: null,
		EquipmentName: null,
	});
	const [tankProducts, setTankProducts] = useState([]);

	const [finalSaveloading, setFinalSaveLoading] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(async () => {
		setLoading(true);
		await retrieveMeasurementUnits();
		await retrieveEquipments();
		await retrieveEquipmentTypes();
		await retrieveMeasurementUnitTypes();
		await fetchTankProducts();
		if (editDetails) {
			setNewEquipmentData(editDetails);
		}
		setLoading(false);
	}, []);

	const retrieveMeasurementUnitTypes = async () => {
		const types = await EquipmentService.listMeasurementUnitTypes();
		if (types == null) setMeasurementUnitTypes({});
		else
			setMeasurementUnitTypes(
				types.map((e) => ({
					...e,
					label: e.MeasurementUnitType,
					value: e.MeasurementUnitTypeId,
					id: "MeasurementUnitTypeId",
				}))
			);
	};

	const retrieveEquipmentTypes = async () => {
		const EquipmentTypes = await EquipmentService.listEquipmentTypes();
		if (EquipmentTypes == null) setEquipmentTypes({});
		else
			setEquipmentTypes(
				EquipmentTypes.map((e) => ({
					...e,
					label: e.EquipmentType,
					value: e.EquipmentTypeId,
					id: "EquipmentTypeId",
				}))
			);
	};

	const retrieveMeasurementUnits = async () => {
		const units = await EquipmentService.listMeasurementUnits();
		if (units == null) setMeasurementUnits({});
		else
			setMeasurementUnits(
				units.map((e) => ({
					...e,
					label: e.MeasurementUnitSymbol,
					value: e.MeasurementUnitId,
					id: "MeasurementUnitId",
				}))
			);
	};

	const filterMeasurementUnits = async (equipment) => {
		const units = await EquipmentService.listMeasurementUnits();

		if (equipment.label == "Storage Tank") {
			setFilteredMeasurementUnits(
				units
					.map((e) => ({
						...e,
						label: e.MeasurementUnitSymbol,
						value: e.MeasurementUnitId,
						id: "MeasurementUnitId",
					}))
					?.filter((unit) => unit.UnitType == "volume")
			);
		} else if (equipment.label == "Pump") {
			setFilteredMeasurementUnits(
				units
					.map((e) => ({
						...e,
						label: e.MeasurementUnitSymbol,
						value: e.MeasurementUnitId,
						id: "MeasurementUnitId",
					}))
					?.filter((unit) => unit.UnitType == "pumping_rate")
			);
		} else {
			setFilteredMeasurementUnits(
				units.map((e) => ({
					...e,
					label: e.MeasurementUnitSymbol,
					value: e.MeasurementUnitId,
					id: "MeasurementUnitId",
				}))
			);
		}
	};

	const retrieveEquipments = async () => {
		const equips = await EquipmentService.listEquipment();
		if (equips == null) setEquipments({});
		else
			setEquipments(
				equips.map((e) => ({
					...e,
					label: e.EquipmentName,
					value: e.EquipmentId,
					id: "EquipmentId",
				}))
			);
	};

	const handleCloseModal = () => {
		setIsOpen(false);
		setShowEquipmentModal && setShowEquipmentModal(false);

		setNewEquipmentData({
			VesselSpecificationId: null,
			ProductId: null,
			EquipmentTypeId: null,
			EquipmentCount: 1,
			CapacityMeasure: 0,
			MeasurementUnitId: null,
			MeasurementUnitTypeId: null,
			EquipmentId: null,
			Description: null,
			EquipmentName: null,
		});
		setShowModal(false);
	};
	const onClose = () => {
		setIsOpen(false);
		setNewEquipmentData({
			VesselSpecificationId: null,
			ProductId: null,
			EquipmentTypeId: null,
			EquipmentCount: 1,
			CapacityMeasure: 0,
			MeasurementUnitId: null,
			MeasurementUnitTypeId: null,
			EquipmentId: null,
			Description: null,
			EquipmentName: null,
		});
		setShowModal(false);
	};
	const onOpen = () => {
		setIsOpen(true);
	};

	const addNewEquipmentData = async () => {
		let _payload = { ...newEquipmentData };
		if (isNewEquipment) {
			var newEquip = {
				EquipmentName: newEquipmentData.EquipmentName,
				MeasurementUnitTypeId: newEquipmentData.MeasurementUnitTypeId,
				EquipmentTypeId: newEquipmentData.EquipmentTypeId,
				EquipmentTypeName: newEquipmentData.EquipmentTypeName,
			};
			const equipAdded = await EquipmentService.addNewEquipment(newEquip);
			if (!equipAdded) {
				toastr("error", "New Equipment could not be added");
			} else {
				setNewEquipmentData({
					...newEquipmentData,
					EquipmentId: equipAdded.EquipmentId,
				});

				_payload = { ..._payload, EquipmentId: equipAdded.EquipmentId };

				var equips = equipments;
				equips.push({
					...equipAdded,
					label: equipAdded.EquipmentName,
					value: equipAdded.EquipmentId,
					id: "EquipmentId",
				});
				setEquipments(equips);
			}
		}

		delete _payload.action;
		// remove product if equipment is not a tank or pump
		if (
			_payload.EquipmentName != "Storage Tank" &&
			_payload.EquipmentName != "Pump"
		) {
			_payload.Product = "";
			_payload.ProductId = "";
		}

		setFinalSaveLoading(true);
		callback && callback(_payload);
		handleCloseModal();
		setIsNewEquipment(false);
	};

	const handleChange = (e) => {
		if (e.id === "EquipmentId") {
			setNewEquipmentData({
				...newEquipmentData,
				EquipmentName: e.label,
				ProductId: "",
				Product: "",
				[e.id]: e.value,
			});
		} else {
			setNewEquipmentData({
				...newEquipmentData,
				[e.id]: e.value,
			});
		}
	};
	const effectInput = (id, value) => {
		setNewEquipmentData({
			...newEquipmentData,
			[id]: value,
		});
	};

	const fetchTankProducts = async () => {
		let _products = await ProductService.listAll();
		_products = (_products || []).map((a) => ({
			...a,
			value: a.ProductId,
			label: a.ProductName,
			id: "ProductId",
		}));

		setTankProducts(_products);
	};

	return (
		<>
			<button
				className="btn btn-sm text-primary my-3"
				bg="#006886"
				border="none"
				onClick={(e) => {
					e.preventDefault();
					onOpen();
				}}>
				{editDetails ? (
					<em
						class="icon ni ni-edit text-primary fs-20px pointer mx-2"
						title="Edit"></em>
				) : (
					" Add Vessel Equipment"
				)}
			</button>

			<ReactModal
				isOpen={isOpen}
				onClose={onClose}
				contentLabel=""
				style={(defaultModalStyle, { overlay: { zIndex: "1200" } })}>
				<MainLayout
					loading={loading}
					showFullLoader={loading}
					preTitle={
						<span className="ml-2">
							<em
								className="icon ni ni-arrow-left pointer"
								onClick={() => handleCloseModal()}
							/>
							<span className="pointer" onClick={() => handleCloseModal()}>
								Return
							</span>
							<h4 className="ml-2">{editDetails ? "Edit" : "Add"} Equipment</h4>
						</span>
					}>
					<div className="card-inner p-5">
						<form>
							{!isNewEquipment ? (
								<div className="row align-items-center">
									<div className="col-sm-6" id="DivToHide1">
										<div className="form-group">
											<label className="form-label" htmlFor="fw-first-name">
												Select Equipment
											</label>
											<div className="form-control-wrap">
												<Select
													id="EquipmentId"
													name="EquipmentId"
													onChange={(item) => {
														handleChange(item);
														filterMeasurementUnits(item);
													}}
													defaultValue={
														editDetails && {
															label: editDetails.EquipmentName,
															value: editDetails.EquipmentId,
														}
													}
													options={equipments}
												/>
											</div>
										</div>
									</div>
									<button
										className="btn btn-sm text-primary my-3"
										bg="#006886"
										border="none"
										onClick={() => setIsNewEquipment(true)}>
										{" "}
										* Or add new Equipment{" "}
									</button>
									<div className="gap" />
								</div>
							) : (
								<div className="nk-wizard-content">
									<div className="row gy-3">
										<div className="col-sm-3" id="DivToHide1">
											<div className="form-group">
												<label className="form-label" htmlFor="fw-first-name">
													Equipment Name
												</label>
												<div className="form-control-wrap">
													<input
														onChange={(e) => {
															effectInput("EquipmentName", e.target.value);
														}}
														type="text"
														data-msg="Required"
														className="form-control required"
														id="EquipmentName"
														name="EquipmentName"
														required
													/>
												</div>
											</div>
										</div>
										<div className="col-sm-3" id="DivToHide1">
											<div className="form-group">
												<label className="form-label" htmlFor="fw-first-name">
													Equipment Type
												</label>
												<div className="form-control-wrap">
													<Select
														id="EquipmentTypeId"
														name="EquipmentTypeId"
														onChange={(item) => {
															handleChange(item);
														}}
														options={equipmentTypes}
													/>
												</div>
											</div>
										</div>
										<div className="col-sm-3" id="DivToHide1">
											<div className="form-group">
												<label className="form-label" htmlFor="fw-first-name">
													MeasurementUnit Type
												</label>
												<div className="form-control-wrap">
													<Select
														id="MeasurementUnitTypeId"
														name="MeasurementUnitTypeId"
														onChange={(item) => {
															handleChange(item);
														}}
														options={measurementUnitTypes}
													/>
												</div>
											</div>
										</div>
										<button
											className="btn btn-sm text-primary my-3"
											bg="#006886"
											border="none"
											onClick={() => setIsNewEquipment(false)}>
											{" "}
											*Select existing Equipment{" "}
										</button>
									</div>
								</div>
							)}
							<div className="nk-wizard-content">
								<div className="row gy-3">
									<div className="col-sm-6" id="DivToHide1">
										<div className="form-group">
											<label className="form-label" htmlFor="fw-first-name">
												Capacity Measure
											</label>
											<div className="form-control-wrap">
												<input
													onChange={(e) => {
														effectInput("CapacityMeasure", e.target.value);
													}}
													defaultValue={
														editDetails && editDetails.CapacityMeasure
													}
													type="number"
													data-msg="Required"
													className="form-control required"
													id="CapacityMeasure"
													name="CapacityMeasure"
													required
												/>
											</div>
										</div>
									</div>
									<div className="col-sm-6" id="DivToHide1">
										<div className="form-group">
											<label className="form-label" htmlFor="fw-first-name">
												Measurement Unit
											</label>
											<div className="form-control-wrap">
												<Select
													id="MeasurementUnitId"
													name="MeasurementUnitId"
													onChange={(item) => {
														handleChange(item);
													}}
													defaultValue={
														editDetails && {
															label: editDetails.MeasurementUnit,
															value: editDetails.MeasurementUnitId,
														}
													}
													options={filteredMeasurementUnits}
												/>
											</div>
										</div>
									</div>
									<div className="col-sm-6" id="DivToHide2">
										<div className="form-group">
											<label className="form-label" htmlFor="fw-last-name">
												Equipment Count
											</label>
											<div className="form-control-wrap">
												<input
													onChange={(e) => {
														effectInput("EquipmentCount", e.target.value);
													}}
													defaultValue={
														editDetails && editDetails.EquipmentCount
													}
													type="number"
													data-msg="Required"
													className="form-control required"
													id="EquipmentCount"
													name="EquipmentCount"
													required
												/>
											</div>
										</div>
										{(newEquipmentData?.EquipmentName == "Storage Tank" ||
											newEquipmentData?.EquipmentName == "Pump") && (
											<div className="form-group">
												<label className="form-label" htmlFor="fw-first-name">
													Select Product
												</label>
												<div className="form-control-wrap">
													<Select
														id="ProductId"
														name="ProductId"
														defaultValue={
															editDetails && {
																label: editDetails.Product,
																value: editDetails.ProductId,
															}
														}
														onChange={(item) => {
															handleChange(item);
														}}
														options={tankProducts}
													/>
												</div>
											</div>
										)}
									</div>
									<div className="col-sm-6" id="Tog2">
										<div className="form-group">
											<label className="form-label" htmlFor="fw-last-name">
												Description
											</label>
											<div className="form-control-wrap">
												<textarea
													onChange={(e) => {
														effectInput("Description", e.target.value);
													}}
													defaultValue={
														editDetails ? editDetails.Description : ""
													}
													placeholder="Fill in description"
													className="form-control no-resize"
													id="Description"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="nk-tb-col">
								<span>&nbsp;</span>
							</div>
							<div className="form-group">
								<button
									type="button"
									className="btn btn-sm btn-primary"
									onClick={() => addNewEquipmentData()}>
									Save
								</button>
								<button
									type="button"
									className="btn btn-sm btn-danger m-1"
									onClick={() => handleCloseModal()}>
									Cancel
								</button>
							</div>
						</form>
						<hr className="preview-hr" />
					</div>
				</MainLayout>
			</ReactModal>
		</>
	);
}
