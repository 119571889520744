import React, { useEffect, useState } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import Tabs from "../../utils/tabs";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../../components/fileInput";
import Spinner from "../../utils/spinner";
import {
  Validator,
  ValidateGroup,
  ClearValidator,
} from "../../components/validator";
import toastr from "../../utils/toastr";
import ReactTooltip from "react-tooltip";
import CustomModal from "../../utils/customModal";
import { toReadableDate } from "../../utils/utilityFunctions";
import { forEach } from "lodash";
import AddLocationCostComponent from "../../components/voyage/addLocationCostComponent";
import VoyageService from "../../services/voyageService";

export default function AddLocationCostPage() {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [voyage, setVoyage] = useState([]);

  useEffect(async () => {
    await init();
  }, []);

  const init = async () => {
    setLoading(true);
    let _voyage = await VoyageService.get(params.voyagePlanningId);
    setVoyage(_voyage || []);
    setLoading(false);
  };

  const tabHeaders = [{ icon: "icon ni ni-view-grid-wd", text: "Details" }];

  const tabContent = [
    <AddLocationCostComponent
      voyagePlanningId={params.voyagePlanningId}
      voyageUnderView={voyage}
    />,
  ];

  const _tabView = (
    <Tabs headers={tabHeaders} contents={tabContent} contentPadding="3" />
  );

  const pageActions = (
    <div className="toggle-wrap nk-block-tools-toggle">
      <a
        href="#"
        className="btn btn-icon btn-trigger toggle-expand mr-n1"
        data-target="pageMenu"
      >
        <em className="icon ni ni-menu-alt-r" />
      </a>
      <div className="toggle-expand-content" data-content="pageMenu">
        <ul className="nk-block-tools g-3">
          <li>
            <Link to={`/`} className="btn btn-white btn-outline-light">
              <em className="icon ni ni-arrow-left" />
              <span>Back to Dashboard</span>
            </Link>
          </li>

          {/* <li>
            <button
              onClick={() => window.history.back()}
              className="btn btn-white btn-outline-light"
            >
              <em className="icon ni ni-arrow-left" />
              <span>Back to Dashboard</span>
            </button>
          </li> */}
        </ul>
      </div>
    </div>
  );

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout
      title="Add Additional Voyage Costs"
      loading={loading}
      showFullLoader={loading}
      pageActions={pageActions}
    >
      <div className="">{_tabView}</div>
    </MainLayout>
  );
}
