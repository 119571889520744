import React, { useEffect, useState } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import BookingRequestService from "../../services/bookingRequestService";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../fileInput";
import Spinner from "../../utils/spinner";
import { Validator, ValidateGroup, ClearValidator } from "../validator";
import toastr from "../../utils/toastr";
import StartEndVoyageActivityListComponent from "./startEndVoyageActivityList";
import VoyageActivityService from "../../services/voyageActivityService";
import VoyageService from "../../services/voyageService";
import BulkListComponent from "../voyage/bulkList";
import DeckListComponent from "../voyage/deckList";
import ReactTooltip from "react-tooltip";
import EquipmentService from "../../services/equipmentService";
import CustomModal from "../../utils/customModal";
import { OffshoreInstallationLocationTypes, toReadableDate } from "../../utils/utilityFunctions";
import { camelizeKeys, dynamicSort } from "../../utils/utilityFunctions";
import { forEach } from "lodash";

import ReactModal from "react-modal";
import { defaultModalStyle } from "../../utils/customStyles";
import ActivityService from "../../services/activityService";
import CargoHandlingService from "../../services/cargoHandlingService";

export default function UpdateCargoLogsComponent({
  callback,
  cargo,
  activityName,
  voyageActivityId,
  selectedBulkCargoDetailId,
  selectedDeckCargoDetailId,
  locationList, voyageActivity
}) {


  const offshoreInstallationsList = [...OffshoreInstallationLocationTypes].map(e => e.toLocaleLowerCase());

  const filteredLocationList = locationList?.filter((fields) => offshoreInstallationsList?.find(item => (item.toLowerCase() === (fields?.LocationType).toLowerCase())))

  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [measurementUnits, setMeasurementUnits] = useState([]);
  const [litresUnit, setLitresUnit] = useState([]);


  const [payload, setPayload] = useState({
    numberOfLifts: cargo?.ActivityEndLog?.NumberOfLifts,
    weightOfLoadedItem: cargo?.ActivityEndLog?.WeightOfLoadedItem,
    measurementUnitId: cargo?.ActivityEndLog?.MeasurementUnitId,
    rob: cargo?.ActivityEndLog?.Rob,
    robUnitId: cargo?.ActivityEndLog?.RobUnitId,
    bulkQuantityInLitres: cargo?.ActivityEndLog?.BulkQuantity,
    pricePerLitre: cargo?.ActivityEndLog?.PricePerLitre,
    deliveryDestination: cargo?.OffshoreInstallation?.LocationName
  });
  const [postUpdateDetails, setPostUpdateDetails] = useState({
    voyageActivityId: "",
    deckCargoLogs: [{}] || null,
    bulkCargoLogs: [{}] || null,
  });

  const addPayload = new PayloadUpdater({
    payload: payload,
    setPayload: setPayload,
  }).update;
  const validationGroup = "endactivity";

  useEffect(async () => {
    await retrieveMeasurentUnits();
  }, []);

  const retrieveMeasurentUnits = async () => {

    setLoading(true);
    const _measurementUnits = await EquipmentService.listMeasurementUnits();

    const litreUnit = _measurementUnits.find((unit) => unit?.MeasurementUnit === "liters");
    setLitresUnit(litreUnit);
    setMeasurementUnits(_measurementUnits || []);

    setLoading(false);
  };

  payload.robUnitId = litresUnit.MeasurementUnitId;

  postUpdateDetails.voyageActivityId = voyageActivityId;
  postUpdateDetails.deckCargoLogs[0].voyageActivityId = voyageActivityId;
  postUpdateDetails.deckCargoLogs[0].deckCargoDetailId =
    selectedDeckCargoDetailId;
  postUpdateDetails.deckCargoLogs[0].measurementUnitId =
    payload.measurementUnitId || "00000000-0000-0000-0000-000000000000";
  postUpdateDetails.deckCargoLogs[0].numberOfLifts = payload.numberOfLifts || 0;
  postUpdateDetails.deckCargoLogs[0].weightOfLoadedItem =
    payload.weightOfLoadedItem || 0.0;
  postUpdateDetails.bulkCargoLogs[0].voyageActivityId = voyageActivityId;
  postUpdateDetails.bulkCargoLogs[0].bulkCargoDetailId =
    selectedBulkCargoDetailId;
  postUpdateDetails.bulkCargoLogs[0].robUnitId =
    payload.robUnitId || "00000000-0000-0000-0000-000000000000";
  postUpdateDetails.bulkCargoLogs[0].rob = payload.rob || 0.0;
  postUpdateDetails.bulkCargoLogs[0].deliveryDestination = payload.deliveryDestination || "00000000-0000-0000-0000-000000000000";
  postUpdateDetails.bulkCargoLogs[0].bulkQuantityInLitres = payload.bulkQuantityInLitres || 0;
  postUpdateDetails.bulkCargoLogs[0].pricePerLitre = payload.pricePerLitre || 0;

  const updateCargoLogValues = async () => {
    if (["Port Discharging Bulk cargo", "Offshore Handling Bulk cargo", "Port Loading Bulk cargo"].includes(activityName)) {
      await updateBulkCargoLogs();
      return;
    }
    const _payload = { ...postUpdateDetails };

    if (
      !_payload.bulkCargoLogs[0].bulkCargoDetailId &&
      !_payload.deckCargoLogs[0].deckCargoDetailId
    ) {
      toastr("error", "Select cargo(s)");
      return;
    }

    if (
      !_payload.deckCargoLogs[0].weightOfLoadedItem &&
      !_payload.deckCargoLogs[0].numberOfLifts &&
      !_payload.bulkCargoLogs[0].rob
    ) {
      toastr("error", "Input bulk/deck type values as necessary");
      return;
    }

    if (
      !_payload.deckCargoLogs[0].measurementUnitId &&
      !_payload.bulkCargoLogs[0].robUnitId
    ) {
      toastr("error", "Select bulk/deck type unit as necessary");
      return;
    }

    if (_payload.deckCargoLogs[0].deckCargoDetailId == null) {
      delete _payload.deckCargoLogs;
    }

    if (_payload.bulkCargoLogs[0].bulkCargoDetailId == null) {
      delete _payload.bulkCargoLogs;
    }

    setSaving(true);
    const response = await ActivityService.updateCargoLogs(_payload);
    if (response) {
      setPayload({});
      toastr("success", "Cargo logs have been updated");
      callback && callback();
    }
    setSaving(false);
  };

  const PortHandlingDetails = () => {
    let direction = cargo?.Direction?.toLowerCase();
    let expectedActivity = "";
    if (activityName == "Port Discharging Bulk cargo") {
      expectedActivity = "delivery";
    }
    else if (activityName == "Port Loading Bulk cargo") {
      expectedActivity = "loading"
    }

    const sep = <b className="ml-1 mr-1">|</b>;
    const instruction = <span className="small"><b>Location:</b> {voyageActivity?.locationType} {sep}  <b>Activity:</b> {activityName}</span>
    return { instruction, direction, expectedActivity }
  }

  const OffshoreHandlingDetails = () => {
    if (activityName.toLowerCase().includes("port")) {
      return PortHandlingDetails();
    }
    let direction = cargo.Direction.toLowerCase();
    let expectedActivity = "";
    if (direction == "outbound") {
      //cargo is moving from port to installation
      //since we are at offshore now, clearly we are here to deliver the cargo
      expectedActivity = "delivery";
    }
    else if (direction == "inbound") {
      //cargo is moving from installation to port
      //since we are at offshore now, clearly we are here to load the cargo
      expectedActivity = "loading"
    }
    else if (direction == "interfield") {
      //cargo is moving from installation to another installation
      //has the cargo been previously loaded? if yes then we are here to deliver
      //otherwise we are here to load

    }

    const sep = <b className="ml-1 mr-1">|</b>;
    const instruction = <span className="small"><b>Location:</b> {voyageActivity?.locationType} {sep} <b>Direction:</b> {direction} {sep} <b>Activity:</b> {expectedActivity}</span>
    return { instruction, direction, expectedActivity }
  }

  const updateBulkCargoLogs = async () => {


    //for interfield, the delivery destination should be selectable. However, for now, i am setting delivery destination to null

    const apiPayload = {
      BulkCargoDetailId: selectedBulkCargoDetailId,
      BulkQuantity: parseFloat(payload.bulkQuantityInLitres),
      ROB: parseFloat(payload.rob), UnitId: payload.robUnitId,
      VoyageActivityId: voyageActivity.voyageActivityId,
      DeliveryDestination: null
    };


    if (!apiPayload.BulkQuantity) {
      toastr("error", "Enter bulk quantity");
      return;
    }
    if (!apiPayload.ROB) {
      apiPayload.ROB = 0;
    }
    if (!apiPayload.UnitId) {
      toastr("error", "Unable to determine unit of measurement");
      return;
    }

    setSaving(true);
    let response = {};
    if (OffshoreHandlingDetails().expectedActivity == "delivery") {
      response = await CargoHandlingService.UpdateDeliveredBulkQty({ ...apiPayload });
    } else {
      response = await CargoHandlingService.UpdateLoadedBulkQty({ ...apiPayload });
    }
    setSaving(false);
    if (response) {
      toastr("success", "Cargo logs have been updated");
      callback && callback();
    } else {
      toastr("error", "An error occurred, please check your network");
    }

  }

  return (
    <>
      {loading ? (
        <div className="w-100 text-center center my-5 py-5">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="card-inner p-2">
            <form>
              {activityName !== "Port Loading Deck cargo" &&
                activityName !== "Port Discharging Deck cargo" &&
                activityName !== "Offshore Handling Deck cargo" ? null : (
                <>
                  <div className="row">
                    <div className="col-12">
                      <p>
                        <span className="preview-title-lg overline-title">
                          Cargo Weight &amp; Number of Lifts
                        </span>{" "}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label
                          className="form-label small"
                          htmlFor="fw-last-name"
                        >
                          Number of Lifts
                        </label>
                        <div className="form-control-wrap">
                          <input
                            defaultValue={cargo?.ActivityEndLog?.NumberOfLifts}
                            type="number"
                            className="form-control required"
                            onChange={(e) => {
                              addPayload("numberOfLifts", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="gap gap-10px" />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label
                          className="form-label small"
                          htmlFor="fw-last-name"
                        >
                          Weight of Cargo
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="number"
                            defaultValue={
                              cargo?.ActivityEndLog?.WeightOfLoadedItem
                            }
                            className="form-control required"
                            onChange={(e) => {
                              addPayload("weightOfLoadedItem", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="gap gap-10px" />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label
                          className="form-label small"
                          htmlFor="fw-last-name"
                        >
                          Cargo Weight Unit
                        </label>
                        <div className="form-control-wrap">
                          <Select
                            onChange={(e) => {
                              addPayload("measurementUnitId", e.value);
                            }}
                            defaultValue={measurementUnits
                              ?.map((c) => ({
                                ...c,
                                label:
                                  c.MeasurementUnit +
                                  " (" +
                                  c.MeasurementUnitSymbol +
                                  ")",
                                value: c.MeasurementUnitId,
                              }))
                              .find(
                                (a) =>
                                  a.value ==
                                  cargo?.ActivityEndLog?.MeasurementUnitId
                              )}
                            options={measurementUnits?.map((c) => ({
                              ...c,
                              label:
                                c.MeasurementUnit +
                                " (" +
                                c.MeasurementUnitSymbol +
                                ")",
                              value: c.MeasurementUnitId,
                            }))}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {activityName !== "Port Loading Bulk cargo"
                ? null : (
                  <>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label
                            className="form-label small"
                            htmlFor="fw-last-name"
                          >
                            Quantity Loaded
                          </label>
                          <div className="form-control-wrap">
                            <input
                              type="number"
                              defaultValue={cargo?.ActivityEndLog?.BulkQuantity}
                              className="form-control required"
                              onChange={(e) => {
                                addPayload("bulkQuantityInLitres", e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label
                            className="form-label small"
                            htmlFor="fw-last-name"
                          >
                            Remainder On Board
                          </label>
                          <div className="form-control-wrap">
                            <input
                              type="number"
                              defaultValue={cargo?.ActivityEndLog?.Rob}
                              className="form-control required"
                              onChange={(e) => {
                                addPayload("rob", e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">

                    </div>
                    <div className="gap gap-10px" />
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label
                            className="form-label small"
                            htmlFor="fw-last-name"
                          >
                            ROB Unit
                          </label>
                          <div className="form-control-wrap">
                            <Select
                              onChange={(e) => {
                                addPayload("robUnitId", e.value);
                              }}
                              defaultValue={measurementUnits
                                ?.map((c) => ({
                                  ...c,
                                  label:
                                    c.MeasurementUnit +
                                    " (" +
                                    c.MeasurementUnitSymbol +
                                    ")",
                                  value: c.MeasurementUnitId,
                                }))
                                .find(
                                  (a) =>
                                    a.value === litresUnit.MeasurementUnitId
                                )}
                              options={litresUnit}
                              isDisabled
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label
                            className="form-label small"
                            htmlFor="fw-last-name"
                          >
                            Delivery Destination
                          </label>
                          <div className="form-control-wrap">
                            <input
                              type="text"
                              defaultValue={cargo?.OffshoreInstallation?.LocationName}
                              className="form-control required"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              {activityName !== "Port Discharging Bulk cargo--Removed"
                ? null : (
                  <>
                    <div className="row">
                      <div className="col-12">
                        <p>
                          <span className="preview-title-lg overline-title">
                            Remainder On Board
                          </span>{" "}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label
                            className="form-label small"
                            htmlFor="fw-last-name"
                          >
                            Remainder On Board
                          </label>
                          <div className="form-control-wrap">
                            <input
                              type="number"
                              defaultValue={cargo?.ActivityEndLog?.Rob}
                              className="form-control required"
                              onChange={(e) => {
                                addPayload("rob", e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="gap gap-10px" />
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label
                            className="form-label small"
                            htmlFor="fw-last-name"
                          >
                            ROB Unit
                          </label>
                          <div className="form-control-wrap">
                            <Select
                              placeholder="Dami"
                              onChange={(e) => {
                                addPayload("robUnitId", e.value);
                              }}
                              defaultValue={measurementUnits
                                ?.map((c) => ({
                                  ...c,
                                  label:
                                    c.MeasurementUnit +
                                    " (" +
                                    c.MeasurementUnitSymbol +
                                    ")",
                                  value: c.MeasurementUnitId,
                                }))
                                .find(
                                  (a) =>
                                    a.value === litresUnit.MeasurementUnitId
                                )}
                              options={litresUnit}
                              isDisabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              {
                !["Port Discharging Bulk cargo", "Offshore Handling Bulk cargo"].includes(activityName) ? null : (
                  <>
                    <div className="alert alert-info" role="alert" style={{
                      marginLeft: "-36px", marginRight: "-36px",
                      padding: "10px", textAlign: "center", marginTop: "-40px", borderRadius: "0"
                    }}>
                      {OffshoreHandlingDetails().instruction}
                    </div>
                    <div className="row">

                      <div className="col-md-6">
                        <div className="form-group">
                          <label
                            className="form-label small"
                            htmlFor="fw-last-name"
                          >
                            {OffshoreHandlingDetails().expectedActivity == "delivery" ? "Quantity delivered (in liters)" : "Quantity loaded (in liters)"}

                          </label>
                          <div className="form-control-wrap">
                            <input
                              type="number"
                              defaultValue={OffshoreHandlingDetails().expectedActivity == "delivery" ? cargo?.ActivityEndLog?.BulkQuantityDelivered : cargo?.ActivityEndLog?.BulkQuantity}
                              className="form-control required"
                              onChange={(e) => {
                                addPayload("bulkQuantityInLitres", e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label
                            className="form-label small"
                            htmlFor="fw-last-name"
                          >
                            Remainder On Board
                          </label>
                          <div className="form-control-wrap">
                            <input
                              type="number"
                              defaultValue={OffshoreHandlingDetails().expectedActivity == "delivery" ? cargo?.ActivityEndLog?.ROBAfterDelivery : cargo?.ActivityEndLog?.Rob}
                              className="form-control required"
                              onChange={(e) => {
                                addPayload("rob", e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="gap gap-10px" />
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label
                            className="form-label small"
                            htmlFor="fw-last-name"
                          >
                            ROB Unit
                          </label>
                          <div className="form-control-wrap">
                            <Select
                              onChange={(e) => {
                                addPayload("robUnitId", e.value);
                              }}
                              defaultValue={measurementUnits
                                ?.map((c) => ({
                                  ...c,
                                  label:
                                    c.MeasurementUnit +
                                    " (" +
                                    c.MeasurementUnitSymbol +
                                    ")",
                                  value: c.MeasurementUnitId,
                                }))
                                .find(
                                  (a) =>
                                    a.value == litresUnit.MeasurementUnitId
                                )}
                              options={litresUnit}
                              isDisabled
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label
                            className="form-label small"
                            htmlFor="fw-last-name"
                          >
                            Location Of Loading
                          </label>
                          <div className="form-control-wrap">
                            <input
                              type="text"
                              defaultValue={cargo?.OffshoreInstallation?.LocationName}
                              className="form-control required"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                  </>
                )}
              {
                activityName !== "Port Loading Consumption bulk" ? null : (
                  <>
                    <div className="row">
                      <div className="col-12">
                        <p>
                          <span className="preview-title-lg overline-title pb-4">
                            Remainder On Board
                          </span>{" "}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label
                            className="form-label small"
                            htmlFor="fw-last-name"
                          >
                            Remainder On Board
                          </label>
                          <div className="form-control-wrap">
                            <input
                              type="number"
                              defaultValue={cargo?.ActivityEndLog?.Rob}
                              className="form-control required"
                              onChange={(e) => {
                                addPayload("rob", e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label
                            className="form-label small"
                            htmlFor="fw-last-name"
                          >
                            Quantity
                          </label>
                          <div className="form-control-wrap">
                            <input
                              type="number"
                              defaultValue={cargo?.ActivityEndLog?.BulkQuantity}
                              className="form-control required"
                              onChange={(e) => {
                                addPayload("bulkQuantityInLitres", e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="gap gap-10px" />
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label
                            className="form-label small"
                            htmlFor="fw-last-name"
                          >
                            ROB Unit
                          </label>
                          <div className="form-control-wrap">
                            <Select
                              onChange={(e) => {
                                addPayload("robUnitId", e.value);
                              }}
                              defaultValue={measurementUnits
                                ?.map((c) => ({
                                  ...c,
                                  label:
                                    c.MeasurementUnit +
                                    " (" +
                                    c.MeasurementUnitSymbol +
                                    ")",
                                  value: c.MeasurementUnitId,
                                }))
                                .find(
                                  (a) =>
                                    a.value == litresUnit.MeasurementUnitId
                                )}
                              options={litresUnit}
                              isDisabled
                            />
                          </div>
                        </div>
                      </div>
                      {activityName !== "Offshore Handling Bulk cargo" ? null : (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label
                              className="form-label small"
                              htmlFor="fw-last-name"
                            >
                              Price per litre
                            </label>
                            <div className="form-control-wrap">
                              <input
                                type="number"
                                // defaultValue={cargo?.ActivityEndLog?.Rob}
                                className="form-control required"
                                onChange={(e) => {
                                  addPayload("pricePerLitre", e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>)}
                    </div>
                    <div className="gap gap-10px" />

                    <div className="row">

                    </div>
                    <div className="gap gap-10px" />


                  </>
                )}
            </form>
            <div className="gap gap-40px" />
            {saving ? (
              <div className="form-group p-2 m-3">
                <Spinner size="1.5" />
              </div>
            ) : (
              <div className="form-group">
                <button
                  type="button"
                  onClick={() => updateCargoLogValues()}
                  className="btn btn-md btn-dark btn-wide px-5 mr-3"
                >
                  Save
                </button>
              </div>
            )}
            <div className="gap gap-10px" />
          </div>
        </>
      )}
      {/* {!modalIsClosed && <CustomModal title="Edit Booking Request" isClosed={modalIsClosed}
                content={<EditVesselDetailsComponent bookingRequestId={bookingRequestId} callback={() => onModalClose()} />} onClose={() => onModalClose()} />} */}
    </>
  );
}
