import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../../components/fileInput";
import Spinner from "../../utils/spinner";
import {
  Validator,
  ValidateGroup,
  ClearValidator,
} from "../../components/validator";
import toastr from "../../utils/toastr";
import ReactTooltip from "react-tooltip";
import CustomModal from "../../utils/customModal";
import { toReadableDate, checkUserPermission } from "../../utils/utilityFunctions";
import { forEach } from "lodash";
import { dateToYYYY_MM_DD, camelizeKeys } from "../../utils/utilityFunctions";
import SystemSettingsService from "../../services/systemSettingsService";

export default function UpdateSystemSettingsPage() {
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState([]);
  const [saving, setSaving] = useState(false);
  const [systemSettings, setSystemSettings] = useState([]);

  const addPayload = new PayloadUpdater({
    payload: payload,
    setPayload: setPayload,
  }).update;
  const validationGroup = "updateconfiguration";

  useEffect(async () => {
    await init();
  }, []);

  const init = async () => {
    setLoading(true);
    const _systemSettings = await SystemSettingsService.getSystemSettingsList();

    let modifiedDataList = camelizeKeys(_systemSettings || []);

    setPayload(modifiedDataList);

    setLoading(false);
  };

  const pageActions = (
    <div className="toggle-wrap nk-block-tools-toggle">
      <a
        href="#"
        className="btn btn-icon btn-trigger toggle-expand mr-n1"
        data-target="pageMenu"
      >
        <em className="icon ni ni-menu-alt-r" />
      </a>
      <div className="toggle-expand-content" data-content="pageMenu">
        <ul className="nk-block-tools g-3">
          <li>
            <button
              onClick={() => window.history.back()}
              className="btn btn-white btn-outline-light"
            >
              <em className="icon ni ni-arrow-left" />
              <span>Back to previous page</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );

  const submitForm = async (e) => {
    e.preventDefault();
    const _payload = [...payload];

    // if (!_payload.correspondingValue) {
    //   toastr("error", "Enter value");
    //   return;
    // }

    setSaving(true);

    const response = await SystemSettingsService.updateSystemSettings(_payload);
    if (response) {
      toastr("success", "Configuration setting updated successfully");
      await init();
    }
    setSaving(false);
  };

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout
      title="Configuration Settings"
      pageActions={pageActions}
      loading={loading}
      showFullLoader={loading}
    >
      <>
        <form onSubmit={submitForm}>
          <div className="card-inner">
            {payload?.map((setting, index) => (
              <div key={index}>
                <>
                  <div className="row">
                    <div className="gap" />
                    <div className="col-md-4">
                      <div className="form-group ">
                        <div className="form-control-wrap">
                          <h6>{setting.gLobalConfigurationTitle} value</h6>
                          <p>{setting.description}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group ">
                        <div className="form-control-wrap">
                          <input
                            type="number"
                            className="form-control"
                            defaultValue={setting.correspondingValue}
                            required
                            step="any"
                            onChange={(e) => {
                              setPayload((prev) => {
                                prev[index].correspondingValue = e.target.value;
                                return prev;
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="preview-hr" style={{ opacity: "0.45" }} />
                </>
              </div>
            ))}
          </div>

          {saving && (
            <div className="form-group p-2 m-3 float-right">
              <button type="button" className="btn btn-md btn-link m-1">
                <Spinner size="1.5" />
              </button>
            </div>
          )}
          {!loading && !saving && (
            <div className="form-group p-2 m-3 float-right">
          {checkUserPermission("settings_update_settings") && (  
              <button
                type="submit"
                className="btn btn-md btn-dark btn-wide px-5 mr-3"
              >
                Update
              </button>)}
              <button
                type="button"
                className="btn btn-sm btn-link m-1"
                onClick={() => window.history.back()}
              >
                Cancel
              </button>
            </div>
          )}
        </form>
      </>
    </MainLayout>
  );
}
