import React from "react";
import ReactTooltip from "react-tooltip";

function AddBerthOrWarehouseInput({
  selectedEntry,
  setSelectedEntry,
  saveEntry,
  discardChanges,
}) {
  return (
    <div className="form-control-wrap d-flex align-center mb-2">
      {/* edit berths */}
      <div className="form-group">
        <label className="form-label">Name</label>
        <input
          type="text"
          className="form-control required"
          required
          value={selectedEntry.data.locationName}
          onChange={(e) =>
            setSelectedEntry((prev) => ({
              ...prev,
              data: {
                ...prev.data,
                locationName: e.target.value,
              },
            }))
          }
        />
      </div>

      {selectedEntry.type !== "berth" && (
        <div className="form-group ml-2">
          <label className="form-label">
            Short name{" "}
            <em
              data-tip
              data-for="shortnameTip"
              class="icon ni ni-help-fill text-primary fs-16px"
            ></em>
          </label>
          <ReactTooltip id="shortnameTip">A 3 letter shortcode</ReactTooltip>
          <input
            type="text"
            className="form-control required"
            required
            minLength="3"
            maxLength="3"
            value={selectedEntry.data.shortName}
            onChange={(e) =>
              setSelectedEntry((prev) => ({
                ...prev,
                data: {
                  ...prev.data,
                  shortName: e.target.value,
                },
              }))
            }
          />
        </div>
      )}
      <em
        class="icon ni ni-check text-primary fs-22px pointer mx-3"
        title="Save"
        onClick={() => saveEntry(selectedEntry.type, selectedEntry)}
      ></em>
      <em
        class="icon ni ni-trash text-danger fs-22px pointer"
        title="Discard"
        onClick={(e) => discardChanges(selectedEntry.type, selectedEntry)}
      ></em>
    </div>
  );
}

export default AddBerthOrWarehouseInput;
