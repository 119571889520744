import apiCall from "../utils/apiCall";
import { dynamicSort } from "../utils/utilityFunctions";

export default class CargoHandlingService {
  static getLoadingCargos = async (voyagePlanningId, locationId) => {
    locationId = locationId || null;
    voyagePlanningId = voyagePlanningId || null;
    return await apiCall(
      `/api/Cargos/loading-cargos/by-location?voyagePlanningId=${voyagePlanningId}&locationId=${locationId}`,
      "GET"
    );
  };

  static getDischargingCargos = async (voyagePlanningId, locationId) => {
    locationId = locationId || null;
    voyagePlanningId = voyagePlanningId || null;
    return await apiCall(
      `/api/Cargos/discharging-cargos/by-location?voyagePlanningId=${voyagePlanningId}&locationId=${locationId}`,
      "GET"
    );
  };

  static getOffshoreCargos = async (voyagePlanningId, locationId) => {
    locationId = locationId || null;
    voyagePlanningId = voyagePlanningId || null;
    return await apiCall(
      `/api/Cargos/offshore-installation-cargos/by-location?voyagePlanningId=${voyagePlanningId}&locationId=${locationId}`,
      "GET"
    );
  };

  static getOffshoreHandlingBookingEquipmentForDeckCargos = async (
    voyagePlanningId,
    locationId
  ) => {
    locationId = locationId || null;
    voyagePlanningId = voyagePlanningId || null;
    return await apiCall(
      `/api/Cargos/offshore-installation-equipment-deckcargos/by-location?voyagePlanningId=${voyagePlanningId}&locationId=${locationId}`,
      "GET"
    );
  };

  static getPortLoadingBookingEquipmentForDeckCargos = async (
    voyagePlanningId,
    locationId
  ) => {
    locationId = locationId || null;
    voyagePlanningId = voyagePlanningId || null;
    return await apiCall(
      `/api/Cargos/loading-equipment-deckcargo/by-location?voyagePlanningId=${voyagePlanningId}&locationId=${locationId}`,
      "GET"
    );
  };

  static getPortDischargingBookingEquipmentForDeckCargos = async (
    voyagePlanningId,
    locationId
  ) => {
    locationId = locationId || null;
    voyagePlanningId = voyagePlanningId || null;
    return await apiCall(
      `/api/Cargos/discharging-equipment-deckcargo/by-location?voyagePlanningId=${voyagePlanningId}&locationId=${locationId}`,
      "GET"
    );
  };

  static getNumberOfLiftsForBookingEquipmentByVoyageActivity = async (
    voyageActivityId
  ) => {
    voyageActivityId = voyageActivityId || null;
    return await apiCall(
      `/api/Cargos/equipment-lifts-by-activity?voyageActivityId=${voyageActivityId}`,
      "GET"
    );
  };

  static postNumberOfLiftsForDeckCargoBookingEquipment = async (payload) => {
    return await apiCall(`/api/Cargos/capture-lifts`, "POST", payload);
  };

  static UpdateDeliveredBulkQty = async ({BulkCargoDetailId, BulkQuantity, ROB, UnitId, VoyageActivityId, DeliveryDestination}) => {
    const payload = {BulkCargoDetailId, BulkQuantity, ROB, UnitId, VoyageActivityId, DeliveryDestination};
    return await apiCall(`/api/Cargos/UpdateDeliveredBulkQty`, "POST", payload);
  };

  static UpdateLoadedBulkQty = async ({BulkCargoDetailId, BulkQuantity, ROB, UnitId, VoyageActivityId, DeliveryDestination}) => {
    const payload = {BulkCargoDetailId, BulkQuantity, ROB, UnitId, VoyageActivityId, DeliveryDestination};
    return await apiCall(`/api/Cargos/UpdateLoadedBulkQty`, "POST", payload);
  };
}
