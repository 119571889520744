import React, { useEffect, useState } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import BookingRequestService from "../../services/bookingRequestService";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../fileInput";
import Spinner from "../../utils/spinner";
import { Validator, ValidateGroup, ClearValidator } from "../validator";
import toastr from "../../utils/toastr";
import VoyageService from "../../services/voyageService";
import ReactTooltip from "react-tooltip";
import EquipmentService from "../../services/equipmentService";
import CustomModal from "../../utils/customModal";
import {
  toReadableDate,
  camelizeKeys,
  toReadableDateTime,
} from "../../utils/utilityFunctions";
import { forEach } from "lodash";
import VoyageActivityService from "../../services/voyageActivityService";
import moment from "moment";

export default function VoyageDetailsActivityComponent({
  voyage,
  voyagePlanningId,
  createdActivities,
}) {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [initializing, setInit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [payload, setPayload] = useState({});
  const [saving, setSaving] = useState(false);
  const [actualActivityStartDate, setActualActivityStartDate] = useState(null);
  const [actualActivityEndDate, setActualActivityEndDate] = useState(null);

  useEffect(async () => {
    await init();
  }, []);

  const init = async () => {
    setLoading(true);
    // const _voyageActivities =
    //   await VoyageActivityService.getVoyageActivitiesWithSailing(
    //     voyagePlanningId
    //   );
    // let modifiedDataList = camelizeKeys(_voyageActivities || [])?.filter(
    //   (voyageActivity) => voyageActivity?.isDummySailing == false
    // );
    let modifiedDataList = createdActivities?.filter(
      (voyageActivity) => voyageActivity?.isDummySailing == false
    );
    let startDates = modifiedDataList
      ?.filter((obj) => obj.actualStartDate !== null)
      .map((voyageActivity) => new Date(voyageActivity.actualStartDate));
    let endDates = modifiedDataList
      ?.filter((obj) => obj.actualEndDate !== null)
      .map((voyageActivity) => new Date(voyageActivity.actualEndDate));
    if (startDates.length !== 0 && endDates.length !== 0) {
      let minStartDate = new Date(Math.min(...startDates)).toISOString();
      let maxEndDate = new Date(Math.max(...endDates)).toISOString();
      setActualActivityStartDate(
        minStartDate ? toReadableDateTime(minStartDate) : "-"
      );
      setActualActivityEndDate(
        maxEndDate ? toReadableDateTime(maxEndDate) : "-"
      );
    }
    setLoading(false);
  };

  const dataItem = (label, value) => {
    if (value == "null") value = "";
    return (
      <li className="col-6 col-lg-3">
        <p>
          <span className="text-soft small">{label}</span> {value || "-"}
        </p>
      </li>
    );
  };

  const ShowVoyageTimeByStatus = () => {
    if (
      voyage.VoyagePlanningStatus === "Planned" ||
      voyage.VoyagePlanningStatus === "Active"
    ) {
      return (
        <>
          {dataItem(
            "Expected Date of Departure",
            toReadableDate(voyage.ExpectedDateOfDeparture) || "-"
          )}
          {dataItem(
            "Expected Date of Arrival",
            toReadableDate(voyage.ExpectedDateOfArrival) || "-"
          )}
        </>
      );
    } else
      return (
        <>
          {dataItem("Actual End Date", actualActivityEndDate || "-")}
          {dataItem("Actual Start Date", actualActivityStartDate || "-")}
        </>
      );
  };

  return (
    <>
      <div className="card sp-plan">
        <div className="row no-gutters">
          <div className="col-md-8">
            <div className="sp-plan-info card-inner">
              <div className="row gx-0 gy-3">
                <div className="col-xl-9 col-sm-8">
                  <div className="sp-plan-name">
                    <h6 className="title">
                      <Link to={"#"}>
                        {voyage.VoyageCode}
                        {voyage?.VoyagePlanningStatus && (
                          <span className="badge badge-light badge-pill ml-3">
                            {voyage?.VoyagePlanningStatus}
                          </span>
                        )}
                      </Link>
                    </h6>
                    <p style={{ visibility: "hidden" }}>
                      '': <span className="text-base">{"-"}</span>
                    </p>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-4"></div>
              </div>
            </div>
            <div className="sp-plan-desc card-inner">
              {/* <p>
                <span className="preview-title-lg overline-title">Voyage</span>{" "}
              </p> */}
              <ul className="row gx-1">
                {dataItem("Voyage Code", voyage.VoyageCode)}
                {dataItem("Vessel Name", voyage.VesselName)}
                {dataItem("Zone Name", voyage.ZoneName)}
                {dataItem("Sailing Number", voyage?.SailingDetails?.SailingNo)}
                {/* {dataItem("Sailing Visit Count", sailingVisitCount)} */}
                {dataItem(
                  "Sailing Status",
                  voyage?.SailingDetails?.SailingStatus
                )}
                {/* {dataItem(
                  "Expected Date of Departure",
                  toReadableDate(voyage.ExpectedDateOfDeparture) || "-"
                )}
                {dataItem(
                  "Expected Date of Arrival",
                  toReadableDate(voyage.ExpectedDateOfArrival) || "-"
                )} */}
                {dataItem("Departure Port", voyage.DeparturePortName)}
                {dataItem("Arrival Port", voyage.ArrivalPortName || "-")}
                {ShowVoyageTimeByStatus()}
                {/* {dataItem(
                  "Actual End Date",
                  voyage.VoyagePlanningStatus !== "Planned"
                    ? actualActivityEndDate || "-"
                    : "-"
                )}
                {dataItem(
                  "Actual Start Date",
                  voyage.VoyagePlanningStatus !== "Planned"
                    ? actualActivityStartDate || "-"
                    : "-"
                )} */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
