import React, { useEffect, useState } from "react";
import MyTable from "../dataTable";
import { toReadableDate } from "../../utils/utilityFunctions";
import Spinner from "../../utils/spinner";
import CommercialService from "../../services/commercialService";
import { Link } from "react-router-dom";

export default function VesselContracts({
  dataList,
  reload,
  deleteAction,
  vesselSpecId = "",
}) {
  const [tableData, setTableData] = useState(dataList || []);
  const [loading, setLoading] = useState(true);

  const headerList = [
    {
      Header: "Charterer",
      accessor: "ChartererPartyName",
    },
    {
      Header: "Status",
      accessor: "CommercialStatus",
    },
    {
      Header: "Start Date",
      accessor: "ContractStartDate",
    },
    {
      Header: "End Date",
      accessor: "ContractEndDate",
    },
    {
      Header: "Type",
      accessor: "ContractType",
    },
    {
      Header: "Daily Rates",
      accessor: "DailyRate",
    },
    {
      Header: "Currency",
      accessor: "Currency",
    },
    {
      Header: "",
      accessor: "route",
    },
  ];

  useEffect(async () => {
    setLoading(true);
    await retrieveVesselContracts();

    setLoading(false);
  }, [dataList]);

  const retrieveVesselContracts = async () => {
    setLoading(true);
    const _res = await CommercialService.getListOfCommercialForVessel(
      vesselSpecId
    );

    const vesselContracts = _res?.map((contract) => ({
      ...contract,

      ContractStartDate: toReadableDate(contract.ContractStartDate),
      ContractEndDate: toReadableDate(contract.ContractEndDate),
      route: (
        <Link to={`/commercial/${contract.VesselCommercialId}`}>View</Link>
      ),
    }));

    setTableData(vesselContracts || []);
    setLoading(false);
  };
  return (
    <div style={{ zoom: "0.85" }}>
      {loading ? (
        <div className="center mt-5 pt-5">
          <Spinner />
        </div>
      ) : (
        <MyTable
          columns={headerList}
          cssArray={["font-weight-500", "", "", ""]}
          data={tableData}
          reloadData={(page, pageSize, query) =>
            reload && reload(page, pageSize, query)
          }
          enableSearch={false}
          deleteAction={deleteAction}
          emptyMessage="No Contracts For This Vessel"
          paginationStyle="2"
        />
      )}
    </div>
  );
}
