import React, { useEffect, useRef, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import LocationService from "../../services/locationService";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import Spinner from "../../utils/spinner";
import {
  Validator,
  ValidateGroup,
  ClearValidator,
} from "../../components/validator";
import toastr from "../../utils/toastr";
import ActivityService from "../../services/activityService";
import VoyageService from "../../services/voyageService";
import VesselService from "../../services/vesselService";
import ConfigurationService from "../../services/configurationService";
import SailingService from "../../services/sailingService";
import VoyageActivityService from "../../services/voyageActivityService";
import EquipmentService from "../../services/equipmentService";
import {
  camelizeKeys,
  toReadableDateTime,
  toReadableDate,
  durationByHours,
  convertToUTC
} from "../../utils/utilityFunctions";
import VoyageDetailsActivityComponent from "../../components/voyageReporting/voyageDetailsActivityComponent";
import VoyageActivitiesListComponent from "../../components/voyageReporting/voyageActivitiesList";
import VoyageSailingVisitsComponent from "../../components/voyage/voyageSailingVisitsComponent";
import { Accordion } from "../../utils/accordion";
import "../../styles/accordion.scss";
import moment from "moment";

export default function AddVoyageActivity() {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [installationLocations, setInstallationLocations] = useState([]);
  const [payload, setPayload] = useState({
    tankCleaning: [],
  });
  const [saving, setSaving] = useState(false);
  const [activities, setActivities] = useState([]);
  const [locationTypes, setLocationTypes] = useState([]);
  const [reloading, setReloading] = useState(false);
  const [voyage, setVoyage] = useState([]);
  const [availableTanks, setAvailableTanks] = useState([]);
  const [tanksToBeCleaned, setTanksToBeCleaned] = useState([]);
  const [cleaningRequirements, setCleaningRequirements] = useState([]);
  const [createdActivities, setCreatedActivities] = useState([]);
  const [sailingVisits, setSailingVisits] = useState([]);
  const [activeAccordionIndex, setActiveAccordionIndex] = useState(false);

  const activityCategoryOptions = [
    {
      label: "Direct Attributable",
      value: "Direct Attributable",
    },
    {
      label: "Indirect Attributable",
      value: "Indirect Attributable",
    },
    {
      label: "Non-Attributable",
      value: "Non-Attributable",
    },
  ];

  const productivityOfActivityOptions = [
    {
      label: "Productive",
      value: "Productive",
    },
    {
      label: "Avoidable Non-Productive",
      value: "Avoidable Non-Productive",
    },
    {
      label: "Unavoidable Non-Productive",
      value: "Unavoidable Non-Productive",
    },
  ];

  const activityTypeOptions = [
    {
      label: "Planned",
      value: "planned",
    },
    {
      label: "Unplanned",
      value: "unplanned",
    },
  ];
  const addPayload = new PayloadUpdater({
    payload: payload,
    setPayload: setPayload,
  }).update;
  const validationGroup = "add_voyage_activity";


  useEffect(async () => {
    await init();

    return () => {
      ClearValidator(validationGroup);
    };
  }, []);

  const init = async () => {
    setLoading(true);

    let _voyage = await VoyageService.get(params.voyageId);
    setVoyage(_voyage || []);

    await retrieveLocations(_voyage.SailingId);
    // await retrieveVoyageActivities(params.voyageId);

    let _activities = await ActivityService.activitiesList(1, 200);
    _activities = (_activities || [])
      .map((e) => ({
        ...e,
        label: e.ActivityName,
        value: e.ActivityId,
      }))
      ?.filter(
        (activity) => activity?.ActivityName.toLowerCase() !== "sailing"
      );
    setActivities(_activities);

    let _cleaningRequirements = await ConfigurationService.getConfigCategory(
      "cleaningRequirements"
    );
    setCleaningRequirements(_cleaningRequirements || []);

    let _tanks = await VesselService.listTanks(_voyage?.VesselSpecificationId);
    let tankList = _tanks.map((l) => ({
      ...l,
      literMeasure: `${l.CapacityMeasure}${l.unit}`,
    }));
    setAvailableTanks(tankList || []);

    setLoading(false);
  };

  const retrieveVoyageActivities = async (voyagePlanningId, offshoreInstallationInSailing) => {

    let _voyageActivities =
      await VoyageActivityService.getVoyageActivitiesWithSailing(
        voyagePlanningId
      );
    let modifiedDataList = camelizeKeys(_voyageActivities || []);
    modifiedDataList = modifiedDataList?.map((voyageActivity) => ({
      ...voyageActivity,
      intendedStartDate: toReadableDateTime(voyageActivity.estimatedStartTime),
      intendedEndDate: toReadableDateTime(voyageActivity.estimatedEndDate),
      activityDuration: durationByHours(voyageActivity.durationOfActivity),
      isParallel: voyageActivity.allowsParallel == true ? "Yes" : "No",
      approximateSailingDistance: (voyageActivity?.sailingDistance).toFixed(2),
      locationNameWithType: `${voyageActivity?.locationName} - ${voyageActivity?.locationType}`,
      offshoreLocation: (offshoreInstallationInSailing.find((location) => location?.LocationId === voyageActivity?.installationId)?.LocationName) || "N/A"
    }));
    setCreatedActivities(modifiedDataList);
  };





  const retrieveLocations = async (sailingId) => {
    const units = await EquipmentService.listMeasurementUnits();

    const _locations = await LocationService.list(1, 1000);

    const _sailingLocations = await SailingService.getSailingLocationBySailing(
      sailingId
    );

    const _visitList = (_sailingLocations || []).map((e) => ({
      ...e,
      IsBulkRequirement: e.IsBulkRequirement ? "Yes" : "No",
      MeasurementUnit:
        units.find((m) => m.MeasurementUnitId == e.MeasurementUnitId)
          ?.MeasurementUnit || "",
      Location:
        _locations.find((m) => m.LocationId == e.LocationId)?.LocationName ||
        "",
      DayOnLocationFormatted: toReadableDate(e.DayOnLocation),
    }));
    setSailingVisits(_visitList);

    const _sailingLocationIds = _sailingLocations?.map(
      (location) => location.LocationId
    );


    let _filteredLocations = (_locations || []).filter(
      (location) =>
        location.LocationTypeName.toLowerCase() == "port" ||
        location.LocationTypeName.toLowerCase() == "port terminal" ||
        _sailingLocationIds?.includes(location.LocationId)
    );


    const offshoreInstallationInSailing = _sailingLocations.filter((location) => location.LocationType.toLowerCase() !== "port" &&
      location.Location?.toLowerCase() !== "port terminal")
    setInstallationLocations(offshoreInstallationInSailing)
    let _requiredLocations = _filteredLocations.map((e) => ({
      ...e,
      label: e.LocationTypeName
        ? e.LocationName + " - " + e.LocationTypeName
        : e.LocationName + " - " + e.LocationType,
      value: e.LocationId,
    }));
    _requiredLocations = [...new Set(_requiredLocations)];

    setLocations(_requiredLocations || []);

    await retrieveVoyageActivities(params.voyageId, offshoreInstallationInSailing);

  };

  const submitForm = async () => {
    addPayload("tankCleaning", tanksToBeCleaned);
    let _payload = { ...payload };
    _payload.plannedStartDate = convertToUTC(payload?.plannedStartDate)
    _payload.endDate = convertToUTC(payload?.endDate)

    if (!_payload.activityId) {
      toastr("error", "Select activity");
      return;
    }

    let _plannedStartDate = moment.utc(_payload.plannedStartDate);
    let _endDate = moment.utc(_payload.endDate);
    let _expectedDateOfDeparture = moment.utc(voyage.ExpectedDateOfDeparture);
    let _expectedDateOfArrival = moment.utc(voyage.ExpectedDateOfArrival);

    const _selectedActivity = activities.find(
      (activity) => activity.ActivityId == _payload.activityId
    );

    if (_selectedActivity.AllocationCategory) {
      _payload.activityCategory = _selectedActivity.AllocationCategory;
    }

    if (_selectedActivity.IsProductive == true) {
      _payload.productivityOfActivity = "Productive";
    } else {
      _payload.productivityOfActivity = "Non-Productive";
    }

    if (voyage.VoyagePlanningStatus == "Planned") {
      _payload.activityType = "planned";
    } else {
      _payload.activityType = "unplanned";
    }

    if (!_payload.locationId) {
      toastr("error", "Select a location");
      return;
    }

    if (!_payload.plannedStartDate) {
      toastr("error", "Set a planned start date");
      return;
    }

    if (!_payload.endDate) {
      toastr("error", "Set a planned end date");
      return;
    }

    if (_plannedStartDate.diff(_endDate, "days") > 0) {
      toastr(
        "error",
        "Planned start time must be earlier than planned end time"
      );
      return;
    }

    if (_expectedDateOfDeparture.diff(_plannedStartDate, "days") > 0) {
      toastr(
        "error",
        `Activity cannot be planned to begin before voyage start date on ${toReadableDate(
          voyage.ExpectedDateOfDeparture
        )}`
      );
      return;
    }

    if (_plannedStartDate.diff(_expectedDateOfArrival, "days") > 0) {
      toastr(
        "error",
        `Activity cannot be planned to begin after voyage end date on ${toReadableDate(
          voyage.ExpectedDateOfArrival
        )}`
      );
      return;
    }

    if (_endDate.diff(_expectedDateOfArrival, "days") > 0) {
      toastr(
        "error",
        `Activity cannot be planned to end after voyage end date on ${toReadableDate(
          voyage.ExpectedDateOfArrival
        )}`
      );
      return;
    }

    if (
      _payload.activityName == "Port Tank Cleaning" &&
      tanksToBeCleaned.length == 0
    ) {
      toastr("error", "Select tank(s) to be cleaned");
      return;
    }

    if (
      _payload.activityName !== "Port Tank Cleaning" &&
      tanksToBeCleaned.length !== 0
    ) {
      setTanksToBeCleaned([]);
      //toastr("error", "Select tank cleaning activity");
      //return;
    }

    _payload.voyagePlanningId = params.voyageId;

    const validationFailed = tanksToBeCleaned.find(
      (tank) =>
        tank.shipSideEquipmentId == null || tank.cleaningConfigurationId == null
    );
    if (validationFailed) {
      toastr("error", " Selected tanks missing some information");
      return;
    }

    delete _payload.activityName;
    setSaving(true);
    const response = await ActivityService.postVoyageActivity(_payload);
    if (response) {
      setPayload({});
      toastr("success", "Activity added successfully");
      setTanksToBeCleaned([]);
      setActiveAccordionIndex(false);
      await init();
      // reloadPage();
    }
    setSaving(false);
  };

  const reloadPage = () => {
    setReloading(true);
    setTimeout(() => {
      setReloading(false);
    }, 200);
  };

  const selectTank = (value, index) => {
    let _tanks = tanksToBeCleaned;
    _tanks[index]["shipSideEquipmentId"] = value.value;
    setTanksToBeCleaned(_tanks);
  };

  const selectCleaningStandard = (value, index) => {
    let _tanks = tanksToBeCleaned;
    _tanks[index]["cleaningConfigurationId"] = value.value;
    setTanksToBeCleaned(_tanks);
  };

  const removeTank = (index) => {
    let _tanks = [...tanksToBeCleaned];
    _tanks.splice(index, 1);
    setTanksToBeCleaned(_tanks);
  };

  const pageActions = (
    <div className="toggle-wrap nk-block-tools-toggle">
      <a
        href="#"
        className="btn btn-icon btn-trigger toggle-expand mr-n1"
        data-target="pageMenu"
      >
        <em className="icon ni ni-menu-alt-r" />
      </a>
      <div className="toggle-expand-content" data-content="pageMenu">
        <ul className="nk-block-tools g-3">
          <li>
            <a
              href={`/voyage-activities/${params.voyageId}`}
              className="btn btn-white btn-outline-light btn-sm"
            >
              <em className="icon ni ni-arrow-left" />
              <span>Go to Activities List</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );

  const accordionData = [
    {
      title: "Voyage Details",
      content: (
        <VoyageDetailsActivityComponent
          voyage={voyage}
          voyagePlanningId={params.voyageId}
          createdActivities={createdActivities}
        />
      ),
    },
    {
      title: "Sailing Visits",
      content: (
        <VoyageSailingVisitsComponent
          voyage={voyage}
          visits={sailingVisits}
          loading={loading}
        />
      ),
    },
    {
      title: "Created Activities",
      content: <VoyageActivitiesListComponent dataList={createdActivities} />,
    },
  ];
  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout
      title="Add Voyage Activity"
      pageActions={pageActions}
      loading={loading}
      showFullLoader={loading}
    >
      {!loading && (
        <>
          {reloading ? (
            <Spinner />
          ) : (
            <div className="row px-2">
              <div className="col-6 p-2">
                <form>
                  <div className="card-inner">
                    <div>
                      <div className="row">
                        <div className="col-8">
                          <div className="form-group " style={{ zIndex: 106 }}>
                            <label className="form-label small">
                              Activity{" "}
                            </label>
                            <div className="form-control-wrap">
                              <Select
                                onChange={(item) =>
                                  addPayload(
                                    ["activityName", "activityId"],
                                    [item.label, item.value]
                                  )
                                }
                                value={activities.find(
                                  (a) => a.value == payload?.activityId
                                )}
                                options={activities}
                                placeholder="select activity"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {((payload.activityName ===
                        "Port Loading Bulk cargo") || payload.activityName === "Port Discharging Bulk cargo") &&
                        (<div className="row">
                          <div className="col-8 mt-4">
                            <div className="form-group" style={{ zIndex: 105 }}>
                              <label className="form-label small">
                                Offshore Installation{" "}
                              </label>
                              <div className="form-control-wrap">
                                <Select
                                  onChange={(item) =>
                                    addPayload(["installationId"], [item.value])
                                  }
                                  value={installationLocations.map(
                                    (a) =>
                                    ({
                                      ...a,
                                      label: a.LocationName,
                                      value: a.LocationId
                                    })
                                  ).find((a) => a.value === payload.installationId)}
                                  options={installationLocations?.map((a) => ({
                                    ...a,
                                    label: a.LocationName,
                                    value: a.LocationId
                                  }))}
                                  placeholder="select installation"
                                />
                              </div>
                            </div>
                          </div>
                        </div>)}
                      {/* <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-5">
                      <div className="form-group mt-4" style={{ zIndex: "70" }}>
                        <label className="form-label small">
                          Activity Type{" "}
                        </label>
                        <div className="form-control-wrap">
                          <Select
                            onChange={(item) =>
                              addPayload(["activityType"], [item.value])
                            }
                            value={activityTypeOptions.find(
                              (a) => a.value == payload?.activityType
                            )}
                            options={activityTypeOptions}
                            placeholder="select type"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-5">
                      <div className="form-group mt-4" style={{ zIndex: "20" }}>
                        <label className="form-label small">Category </label>
                        <div className="form-control-wrap">
                          <Select
                            onChange={(item) =>
                              addPayload(["activityCategory"], [item.value])
                            }
                            value={activityCategoryOptions.find(
                              (a) => a.value == payload?.activityCategory
                            )}
                            options={activityCategoryOptions}
                            placeholder="select category"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-5">
                      <div className="form-group mt-4" style={{ zIndex: "10" }}>
                        <label className="form-label small">
                          Productivity Of Activity{" "}
                        </label>
                        <div className="form-control-wrap">
                          <Select
                            onChange={(item) =>
                              addPayload(
                                ["productivityOfActivity"],
                                [item.value]
                              )
                            }
                            value={productivityOfActivityOptions.find(
                              (a) => a.value == payload?.productivityOfActivity
                            )}
                            options={productivityOfActivityOptions}
                            placeholder="select productivity level"
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}
                      <div className="row">
                        <div className="col-8 mt-4">
                          <div className="form-group" style={{ zIndex: 102 }}>
                            <label className="form-label small">
                              Activity location{" "}
                            </label>
                            <div className="form-control-wrap">
                              <Select
                                onChange={(item) =>
                                  addPayload(["locationId"], [item.value])
                                }
                                value={locations.find(
                                  (a) => a.value == payload?.locationId
                                )}
                                options={locations}
                                placeholder="select location"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-8 mt-4">
                          <div className="row">
                            <div className="col-6">
                              <div
                                className="form-group "
                                style={{ zIndex: 101 }}
                              >
                                <label className="form-label small">
                                  Planned start at{" "}
                                </label>
                                <div className="form-control-wrap">
                                  <input
                                    type="datetime-local"
                                    className="form-control"
                                    onChange={(e) =>
                                      addPayload(
                                        ["plannedStartDate"],
                                        [e.target.value]
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-6">
                              <div
                                className="form-group "
                                style={{ zIndex: 101 }}
                              >
                                <label className="form-label small">
                                  Planned to end at{" "}
                                </label>
                                <div className="form-control-wrap">
                                  <input
                                    type="datetime-local"
                                    className="form-control"
                                    onChange={(e) =>
                                      addPayload(["endDate"], [e.target.value])
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="gap" />

                      {payload.activityName == "Port Tank Cleaning" ? (
                        <div className="row">
                          <div className="col-10 mt-4">
                            <span>
                              <h6>
                                Attach Tanks (only applicable to Port Tank
                                Cleaning)
                              </h6>
                            </span>
                            <div className="gap" />
                            {tanksToBeCleaned.map((tank, index) => (
                              <>
                                <div
                                  className="form-control-wrap d-flex align-center mb-2"
                                  key={index}
                                >
                                  <div
                                    className="form-group col mb-0 pr-2"
                                    style={{ zIndex: 100 - index }}
                                  >
                                    <label className="form-label small">
                                      Tank
                                    </label>
                                    <Select
                                      onChange={(item) =>
                                        selectTank(item, index)
                                      }
                                      value={availableTanks
                                        .map((c) => ({
                                          ...c,
                                          label:
                                            c.tankType + " - " + c.literMeasure,
                                          value: c.ShipSideEquipmentId,
                                        }))
                                        .find(
                                          (a) =>
                                            a.value ==
                                            payload?.shipSideEquipmentId
                                        )}
                                      options={availableTanks.map((c) => ({
                                        ...c,
                                        label:
                                          c.tankType + " - " + c.literMeasure,
                                        value: c.ShipSideEquipmentId,
                                      }))}
                                      placeholder="select tank"
                                    />
                                  </div>

                                  <div
                                    className="form-group col mb-0 pl-2"
                                    style={{ zIndex: 100 - index }}
                                  >
                                    <label className="form-label small">
                                      Cleaning Requirement
                                    </label>
                                    <Select
                                      onChange={(item) =>
                                        selectCleaningStandard(item, index)
                                      }
                                      value={cleaningRequirements
                                        .map((c) => ({
                                          ...c,
                                          label: c.CorrespondingData,
                                          value: c.ConfigurationId,
                                        }))
                                        .find(
                                          (a) =>
                                            a.value ==
                                            payload?.cleaningConfigurationId
                                        )}
                                      options={cleaningRequirements.map(
                                        (c) => ({
                                          ...c,
                                          label: c.CorrespondingData,
                                          value: c.ConfigurationId,
                                        })
                                      )}
                                      placeholder="select standard"
                                    />
                                  </div>
                                  <div>
                                    <div className="gap" />
                                    <em
                                      class="icon ni ni-cross text-danger fs-22px pointer mx-3"
                                      title="Discard"
                                      onClick={() => removeTank(index)}
                                    ></em>
                                  </div>
                                </div>

                                {/* <div className="row" key={index}>
                              <div className="col">
                                <div
                                  className="form-group "
                                  style={{ zIndex: 100 - index }}
                                >
                                  <label className="form-label small">
                                    Tank
                                  </label>
                                  <div className="form-control-wrap">
                                    <Select
                                      onChange={(item) =>
                                        selectTank(item, index)
                                      }
                                      value={availableTanks
                                        .map((c) => ({
                                          ...c,
                                          label:
                                            c.tankType + " - " + c.literMeasure,
                                          value: c.ShipSideEquipmentId,
                                        }))
                                        .find(
                                          (a) =>
                                            a.value ==
                                            payload?.shipSideEquipmentId
                                        )}
                                      options={availableTanks.map((c) => ({
                                        ...c,
                                        label:
                                          c.tankType + " - " + c.literMeasure,
                                        value: c.ShipSideEquipmentId,
                                      }))}
                                      placeholder="select tank"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col">
                                <div
                                  className="form-group "
                                  style={{ zIndex: 100 - index }}
                                >
                                  <label className="form-label small">
                                    Cleaning Requirement
                                  </label>
                                  <div className="form-control-wrap">
                                    <Select
                                      onChange={(item) =>
                                        selectCleaningStandard(item, index)
                                      }
                                      value={cleaningRequirements
                                        .map((c) => ({
                                          ...c,
                                          label: c.CorrespondingData,
                                          value: c.ConfigurationId,
                                        }))
                                        .find(
                                          (a) =>
                                            a.value ==
                                            payload?.cleaningConfigurationId
                                        )}
                                      options={cleaningRequirements.map(
                                        (c) => ({
                                          ...c,
                                          label: c.CorrespondingData,
                                          value: c.ConfigurationId,
                                        })
                                      )}
                                      placeholder="select standard"
                                    />
                                    <em
                                      class="icon ni ni-cross text-danger fs-22px pointer mx-3"
                                      title="Discard"
                                      // onClick={discardChanges}
                                    ></em>
                                  </div>
                                </div>
                              </div>
                              <div className="col-2">
                                <div
                                  className="form-group "
                                  style={{ zIndex: 99 - index }}
                                >
                                  <button
                                    type="button"
                                    onClick={() => removeTank(index)}
                                    className="btn btn-md text-danger py-2 my-4"
                                  >
                                    <em className="icon ni ni-cross"></em>
                                  </button>
                                </div>
                              </div>
                            </div> */}
                              </>
                            ))}
                            <button
                              type="button"
                              title="Add Tank"
                              className="btn btn-xs text-primary mt-2"
                              onClick={() =>
                                setTanksToBeCleaned([...tanksToBeCleaned, {}])
                              }
                            >
                              <em className="icon ni ni-plus"></em> Add Tank
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {saving && (
                    <div className="form-group p-2 m-3">
                      <button type="button" className="btn btn-md btn-link m-1">
                        <Spinner size="1.5" />
                      </button>
                    </div>
                  )}
                  {!saving && (
                    <div className="form-group p-2 m-3">
                      <button
                        type="button"
                        onClick={() => submitForm()}
                        className="btn btn-md btn-dark btn-wide px-5 mr-3"
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-link m-1"
                        onClick={() => window.history.back()}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </form>
              </div>
              <div className="col-6 px-2">
                <div>
                  <div className="accordion">
                    {accordionData.map(({ title, content }, index) => (
                      <Accordion
                        title={title}
                        content={content}
                        index={index}
                        activeAccordionIndex={index === activeAccordionIndex}
                        setActiveAccordionIndex={setActiveAccordionIndex}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </MainLayout>
  );
}
