import React, { useEffect, useState } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import Tabs from "../../utils/tabs";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../../components/fileInput";
import Spinner from "../../utils/spinner";
import {
  Validator,
  ValidateGroup,
  ClearValidator,
} from "../../components/validator";
import toastr from "../../utils/toastr";
import ReactTooltip from "react-tooltip";
import CustomModal from "../../utils/customModal";
import { camelizeKeys, toReadableDate } from "../../utils/utilityFunctions";
import { forEach } from "lodash";
import UserService from "../../services/userService";
import EditUserDetailsComponent from "../../components/users/editUserDetailsComponent";
import AssignUserRoleComponent from "../../components/users/assignUserRoleComponent";
import { checkUserPermission } from "../../utils/utilityFunctions";
import LocationService from "../../services/locationService";
import VesselService from "../../services/vesselService";

export default function EditUserDetailsPage() {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [commercial, setCommercial] = useState({});
  const [user, setUser] = useState([]);
  const [userRoleIds, setUserRoleIds] = useState([]);
  const [saving, setSaving] = useState(false);
  const [modalIsClosed, setModalIsClosed] = useState(true);
  const [locations, setLocations] = useState([]);
  const [vessels, setVessels] = useState([]);

  useEffect(async () => {
    await init();
  }, [params.userId]);

  const init = async () => {
    setLoading(true);
    let _user = await UserService.getUserById(params.userId);
    _user = camelizeKeys(_user || []);
    setUser(_user);
    setUserRoleIds(_user?.roles?.map((role) => role.roleId));

    await retrieveAllLocations();
    await retrieveAllVessels();
    setLoading(false);
  };

  const retrieveAllLocations = async () => {
    let _locations = await LocationService.list(1, 1000);
    _locations = camelizeKeys(_locations || [])?.map((location) => ({
      ...location,
      label:
        location.locationName +
        " - " +
        location.locationTypeName +
        " - " +
        location.partyName,
      value: location.locationId,
    }));
    setLocations(_locations || []);
  };

  const retrieveAllVessels = async () => {
    let _vessels = await VesselService.listAllVessels();
    _vessels = camelizeKeys(_vessels || [])?.map((vessel) => ({
      ...vessel,
      label: vessel.vesselName,
      value: vessel.vesselSpecificationId,
    }));
    setVessels(_vessels || []);
  };

  const tabHeaders = [
    { icon: "icon ni ni-view-grid-wd", text: "Details" },
    { icon: "icon ni ni-user", text: "Assign Role" },
  ];

  const tabContent = [
    <EditUserDetailsComponent
      user={user}
      userId={params.userId}
      callback={init}
      locations={locations}
      vessels={vessels}
    />,
    <AssignUserRoleComponent
      userRoleIds={userRoleIds}
      userId={params.userId}
      locations={locations}
    />,
  ];

  const _tabView = (
    <Tabs headers={tabHeaders} contents={tabContent} contentPadding="3" />
  );

  const pageActions = (
    <div className="toggle-wrap nk-block-tools-toggle">
     
      <div className="toggle-expand-content" data-content="pageMenu">
        <ul className="nk-block-tools g-3">
          {!loading &&
            checkUserPermission("user_password_reset_super_admin") && (
              <li>
                <a
                  role="button"
                  onClick={() => setModalIsClosed(false)}
                  className="btn btn-white btn-outline-light btn-sm"
                >
                  {/* <em className="icon ni ni-arrow-left" /> */}
                  <span>Reset Password</span>
                </a>
              </li>
            )}
          <li>
            <Link
              role="button"
              to="/users"
              className="btn btn-white btn-outline-light btn-sm"
            >
              <em className="icon ni ni-arrow-left" />
              <span>Back to Users</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );

  const onModalClose = () => {
    setModalIsClosed(true);
  };

  const AdminResetUserPassword = async () => {
    if (user) {
      const _payload = { email: user.email };
      setSaving(true);

      const response = await UserService.adminPasswordReset(_payload);

      if (response) {
        toastr("success", response);
        onModalClose();
        await init();
      } else {
        toastr("error", "Password reset request not successful");
        setSaving(false);
      }
      setSaving(false);
    }
  };

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout
      title={
        user.firstName == null || user.lastName == null
          ? "Edit User Details"
          : <>Edit User Details  <span className="small" style={{fontWeight: "normal"}}>{`${user.firstName} ${user.lastName}`}</span></>
      }
      loading={loading}
      showFullLoader={loading}
      pageActions={pageActions}
    >
      <div className="">{_tabView}</div>
      {modalIsClosed == false && (
        <CustomModal
          title={
            <span>
              <em class="icon ni ni-info-i"></em> Reset User Password
            </span>
          }
          content={
            <>
              <div className="text-center">
                <h6 className="mb-2">
                  Please confirm that you want to reset
                  <br />
                  the current user's password
                </h6>
              </div>
              <div className="row my-1 p-1">
                <div className="col-12 text-center">
                  {saving ? (
                    <Spinner size="1.5" />
                  ) : (
                    <a
                      className="btn btn-lg btn-dark active"
                      onClick={AdminResetUserPassword}
                    >
                      Continue
                    </a>
                  )}
                </div>
              </div>
            </>
          }
          onClose={() => setModalIsClosed(true)}
          isClosed={false}
        />
      )}
    </MainLayout>
  );
}
