import apiCall from "../utils/apiCall";
import { dynamicSort } from "../utils/utilityFunctions";

export default class ConfigurationService {
  static getConfigList = async () => {
    return await apiCall(`/api/ConfigurationList`, "GET", null, null, null, true);
  };

  static getConfigCategory = async (category) => {
    return await apiCall(`/api/ConfigurationList/${category}`, "GET", null, null, null, true);
  };
}
