import React, { useEffect, useState } from 'react';
import { Link, Navigate, useParams } from "react-router-dom";
import MainLayout from '../../layouts/mainLayout';
import LocationService from '../../services/locationService';
import Select from "react-select";
import SelectPartyComponent from '../../components/party/selectParty';
import FileInput from '../../components/fileInput';
import Spinner from '../../utils/spinner';
import toastr from '../../utils/toastr';
import DataTable from '../../components/dataTable';
import CustomModal from '../../utils/customModal';
import LocationDetailsComponent from '../../components/location/locationDetails';
import Paginate from '../../components/paginate';
import PersonWithInitial from '../../components/personWithInitial';
import MapView from '../../components/mapView';
import { width } from 'dom-helpers';
import { exportToCSV } from '../../utils/exportCSV';
import MapView2 from '../../components/mapView2';
import ActivityService from '../../services/activityService';
import VoyageActivityDetailsComponent from './voyageActivityDetails';


export default function VoyageActivitiesList({voyagePlanningId}) {
    const [redirectUrl, setRedirectUrl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [locationTypes, setLocationTypes] = useState([]);
    const [locations, setLocations] = useState([]);
    const [search, setSearch] = useState('');
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [activities, setActivities] = useState([]);


    useEffect(async () => {
        await init();
    }, []);

    const init = async () => {
        setLoading(true);
        const _locationTypes = await LocationService.listLocationTypes(1, 1000, true);
        setLocationTypes(_locationTypes || []);

        let _locations = await LocationService.search(1, 10000, "", "", true);
        _locations = _locations?.Data || [];
        setLocations(_locations);

        const voyageActivities = (await ActivityService.listByVoyageId(voyagePlanningId))?.Data?.voyageActivities || [];

        voyageActivities.forEach(a => {
            a.LocationName = _locations.find(a=> a.LocationId == a.LocationId)?.LocationName;
            a.actionsComponent= overflowMenu(a)
        });

        setActivities(voyageActivities || []);
       

        setLoading(false);
    }



    const overflowMenu = (voyageActivity) => {
        return <ul className="nk-tb-actions gx-2">
            {null && <li className="nk-tb-action-hidden">
                <Link to={"#"} className="btn btn-sm btn-icon btn-trigger" data-toggle="tooltip" data-placement="top" title="Suspend">
                    <em className="icon ni ni-user-cross-fill" />
                </Link>
            </li>}
            <li>
                <div className="drodown">
                    <Link to={"#"} className="btn btn-sm btn-icon btn-trigger dropdown-toggle" data-toggle="dropdown"><em className="icon ni ni-more-h" /></Link>
                    <div className="dropdown-menu dropdown-menu-right">
                        <ul className="link-list-opt no-bdr">
                            <li className="clickable" onClick={() => setSelectedRecord(voyageActivity)}><a><em className="icon ni ni-eye" /><span>View Details</span></a></li>
                            <li><Link to={`/edit-location/${voyageActivity.LocationId}`}><em className="icon ni ni-file-docs" /><span>Edit location details</span></Link></li>
                            {/* <li><Link to={"#"}><em class="icon ni ni-edit" onclick="return false"></em>Edit Vessel Ownership</span></a></li> */}
                            <li className="divider" />
                            <li><Link to={`/location/${voyageActivity.LocationId}/fleets_vessels`}><em className="icon ni ni-file-docs" /><span>Fleets and vessels</span></Link></li>
                        </ul>
                    </div>
                </div>
            </li>
        </ul>

    }

  
    const headerList = [
        {
            Header: "Start time",
            accessor: "PlannedStartDate",
        },
        {
            Header: "End time ",
            accessor: "EndDate",
        },
        {
            Header: "Activity",
            accessor: "ActivityName",
        },
        {
            Header: "Category",
            accessor: "ActivityCategory",
        },
        {
            Header: "Productivity",
            accessor: "ProductivityOfActivity",
        },
        {
            Header: "Status",
            accessor: "ActivityStatus",
        },
        {
            Header: "",
            accessor: "actionsComponent"
        }
    ];

    const doSearch = async (query) => {
        setSearch(query);
        //await loadData(1, null, query, filter);
    }

    const doFilter = async (location) => {
        //setFilter(location.LocationTypeId);
       // await loadData(1, null, null, location.LocationTypeId)
    }
    
    const clearFilter = async (location) => {
       // setFilter(location.LocationTypeId);
       // await loadData(1, null, null, null)
    };




  


    return (
        redirectUrl ? <Navigate to={redirectUrl} replace={true} /> :<>
                <div className="p-3">
                    {loading && <Spinner/>}

                    <div className="card-inner position-relative card-tools-toggle">

                        <div className="card-title-group">
                            <div className="card-tools">
                            <div className="row">
                                <div className="form-inline flex-nowrap gx-3 search-form">
                                    <em className="icon ni ni-search" />
                                    <input type="text" className="form-control border-transparent search-input " placeholder="search location"
                                        onChange={(e) => doSearch(e.target.value)} value={search} />

                                </div>{/* .form-inline */}

                                <div className="form-inline flex-nowrap gx-3 search-form">
                                    <div style={{width: '50px'}}>
                                    </div>
                                </div>

                                {<div className="form-inline flex-nowrap gx-3 search-form">
                                    <em className="icon ni ni-filter" />
                                    <div style={{width: '210px'}}>
                                        <Select className="border-transparent" placeholder="Filter by Location Type" defaultValue="" options={locationTypes.map((f) => ({ ...f, label: f.LocationType, value: f.LocationTypeId }))} onChange={(location) => doFilter(location)} /> 
                                    </div>

                                </div>}{/* .form-inline */}
                                <div className="form-inline flex-nowrap gx-3 search-form">
                                    <div style={{width: '35px'}}>
                                    </div>
                                </div>
                                <div className="form-inline flex-nowrap gx-3 search-form">
                                    <button type="button" onClick={(location) => clearFilter(location)} className="btn btn-white btn-outline-light"><em className="icon ni ni-cross" /><span>Clear Filter</span></button>
                                </div>
                            </div>
                            </div>{/* .card-tools */}

                            <div className="card-tools mr-n1">
                                <ul className="btn-toolbar gx-1">

                                    <li className="btn-toolbar-sep" />{/* li */}



                                    <li>
                                        <div className="toggle-wrap">
                                            <Link to={"#"} className="btn btn-icon btn-trigger toggle" data-target="cardTools"><em className="icon ni ni-menu-right" /></Link>
                                            <div className="toggle-content" data-content="cardTools">
                                                <ul className="btn-toolbar gx-1">
                                                    <li className="toggle-close">
                                                        <Link to={"#"} className="btn btn-icon btn-trigger toggle" data-target="cardTools"><em className="icon ni ni-arrow-left" /></Link>
                                                    </li>{/* li */}

                                                   
                                                </ul>{/* .btn-toolbar */}
                                            </div>{/* .toggle-content */}
                                        </div>{/* .toggle-wrap */}
                                    </li>{/* li */}
                                </ul>{/* .btn-toolbar */}
                            </div>{/* .card-tools */}
                        </div>



                    </div>{/* .card-inner */}
                    {loading || <><DataTable columns={headerList}
                        data={activities}
                        cssArray={[
                            "font-weight-500",
                            "",
                            "",
                            "text-left", "", ""
                        ]}

                        enableSearch={false} emptyMessage="No activity records to show on page"
                        disablePageSize={true}

                    />
                       

                    </>}

                </div>
                {selectedRecord && <CustomModal title={"Activity Details"} content={<><VoyageActivityDetailsComponent _record={selectedRecord} /> <hr style={{opacity: "0.5", marginBottom: "px"}}/>
                       
                </>}
                    onClose={() => setSelectedRecord(null)} isClosed={false} />}
                
            </>
    );
}
