import apiCall from "../utils/apiCall";

export default class ConsumptionBulkService {

    static getLog = async (voyageActivityId) => {
        return await apiCall(
            `/api/ConsumptionBulk/Get?voyageActivityId=${voyageActivityId}`, "get", null, null, true
        );
    };

    static CreateLog = async ({VoyageActivityId, BulkQuantityInLitres, ProductId, RobInLitres}) => {
        const payload = {VoyageActivityId, BulkQuantityInLitres, ProductId, RobInLitres};
        return await apiCall(
            `/api/ConsumptionBulk/Create`, "post", payload
        );
    };

    static UpdateLog = async ({VoyageActivityId, BulkQuantityInLitres, ProductId, RobInLitres}) => {
        const payload = {VoyageActivityId, BulkQuantityInLitres, ProductId, RobInLitres};
        return await apiCall(
            `/api/ConsumptionBulk/Update`, "post", payload
        );
    };

}