import React, { useEffect, useState } from "react";
import MyTable from "../dataTable";
import { toReadableDate } from "../../utils/utilityFunctions";
import Spinner from "../../utils/spinner";

export default function VoyageActivityPortBulkCargoList({ dataList }) {
	const [tableData, setTableData] = useState(null);
	const [initializing, setInit] = useState(true);

	const headerList = [
		{
			Header: "Cargo",
			accessor: "cargoName",
		},
		{
			Header: "Quantity Loaded",
			accessor: "quantityLoaded",
		},
		{
			Header: "ROB",
			accessor: "rob",
		},
		{
			Header: "ROB Unit",
			accessor: "quantityUnit",
		},
		{
			Header: "Destination",
			accessor: "installationName",
		},
		{
			Header: "",
			accessor: "updateButtonComponent",
		},
	];

	useEffect(async () => {
		setInit(true);

		setTableData(dataList);
		setInit(false);
	}, [dataList, tableData]);

	return (
		<div
			style={{
				overflow: "auto",
				boxShadow: "rgb(221, 221, 221) 2px 2px 5px 0.1px",
				// maxHeight: 400,
				// width: 1024,
			}}>
			{initializing && <Spinner />}
			{!initializing && (
				<MyTable
					columns={headerList}
					cssArray={["font-weight-500", "", "", "text-center"]}
					data={dataList || []}
					disablePageSize={true}
					enableSearch={false}
					emptyMessage="Bulk cargo unavailable"
				/>
			)}
		</div>
	);
}
