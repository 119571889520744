import jspreadsheet from "jspreadsheet-ce";
import React, { useRef, useEffect, useState } from "react";
import "../../node_modules/jspreadsheet-ce/dist/jspreadsheet.css";
import "jsuites/dist/jsuites.css";

export default function DeckCargoSheet({ dataToEdit, bookingData, editedDeckCargoData,
    clickedVal, cargoEquipment, preferredEquipment, measurementUnitArray,
    dgList, unCodeList, pgList, hazardClassList, entireBookingData, newNewEntry,
    deckCallBack = () => null
}) {
    const originalData = dataToEdit;
    const [deckCargoData, setDeckCargoData] = useState([]);
    const [bookingOptions, setBookingOptions] = useState([{}]);
    const [hasDisabledFields, setHasDisabledFields] = useState(false);

    const jRef = useRef(null);


    let measurementsPrime = [];
    measurementsPrime.push(...measurementUnitArray);
    const isDgValue = ["Yes", "No"]

    const retrieveData = () => {
        let ret = jRef.current?.jexcel?.getData();
        setDeckCargoData(ret);

    }
    let changed = function (instance, cell, x, y, value) {
        let cellName = jRef.current.jexcel.getColumnData(x, y);
        retrieveData();
    }

    let deleteExcessColumns = () => {
        //This function deletes the excess of API data returned to the table.
        try {
            jRef?.current?.jexcel.deleteColumn([25], [18]);
        } catch (err) {
            console.log("Error with function: ", err.stack)
        }
    }

    //The tableEntryTracker variable helps in tracking when real changes are made to the table
    //By using this, it deletes all empty strings, null values, undefined, NaN and false => All falsy values
    //from the array
    let tableEntryTracker = editedDeckCargoData?.flat()?.filter(Boolean)

    //if edited cargo details is available, it sets it to the data source for the table
    //hence, displaying the table with the new updated entries and overwrites the edit Payload.
    if (editedDeckCargoData?.length > 1) {
        dataToEdit = editedDeckCargoData;
    }
    else if (dataToEdit?.length === 0) {
        dataToEdit = [
            ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
        ];

        if (tableEntryTracker?.length > 0) {
            dataToEdit = editedDeckCargoData;
        }
    }
    else if ((dataToEdit?.length > 0) && (tableEntryTracker?.length > 0)) {
        dataToEdit = editedDeckCargoData;
    }
    else {
       
    }
    // else if ((editedDeckCargoData?.length > 0) && (tableEntryTracker?.length > 0)) {
    //     dataToEdit = editedDeckCargoData;
    // }

    const getExcelColumnName = (num) => {
        for (var __ret = '', a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
            __ret = String.fromCharCode(parseInt((num % b) / a) + 65) + __ret;
        }
        return __ret;
    }

    const trackReadonly = (instance) => {
        //cell.classList.add('readonly');
        if (!instance) instance = jRef?.current;
        if (instance) {
            const sheetColumns = instance.jexcel.options.columns;
            for (let i = 0; i < originalData.length; i++) {
                if (originalData[i].IsLocked === true) {
                    const rowNumber = i + 1;
                    sheetColumns.forEach((element, j) => {
                        const columnNumber = j + 1;
                        const cellAddress = `${getExcelColumnName(columnNumber)}${rowNumber}`;
                        const cell1 = instance.jexcel.getCell(cellAddress);
                        cell1.classList.add('readonly');
                        setHasDisabledFields(true);
                    });

                }

            }
        }

        //getRowData, getCell
    }

    useEffect(() => {

        const options = {
            minDimensions: [22, 1],
            data: dataToEdit,
            tableOverflow: true,
            tableWidth: "100%",
            columnSorting: false,
            onload: trackReadonly,
            ondeleterow: retrieveData,
            onchange: changed,
            onload: deleteExcessColumns,
            // ondeleterow: deletedRowData,
            // oninsertrow:
            // onresizerow: setFinalRowHeight,
            columns: [
                {
                    type: 'dropdown',
                    title: 'Booking Equipment *',
                    width: 300,
                    options: 'picker',
                    source: newNewEntry,
                    lazyLoading: true,
                    name: "name",
                    // name: "specificCargoEquipmentId",
                },
                {
                    type: 'text',
                    title: 'Serial No.',
                    width: 120,
                    name: "SerialNumber",
                },
                {
                    type: 'numeric',
                    title: 'Gross Weight Value *',
                    width: 140,
                    name: "GrossWeightValue",
                },

                {
                    type: 'dropdown',
                    title: 'Gross Weight Unit *',
                    source: measurementsPrime,
                    width: 200,
                    options: 'picker',
                    lazyLoading: true,
                    name: "GrossWeightUnitId",
                },
                {
                    type: 'text',
                    title: 'Net Weight Value *',
                    width: 135,
                    name: "NetWeightValue",
                },
                {
                    type: 'dropdown',
                    title: 'Net Weight Unit *',
                    source: measurementsPrime,
                    width: 200,
                    options: 'picker',
                    lazyLoading: true,
                    name: "NetWeightUnitId",
                },

                {
                    type: 'numeric',
                    title: 'Measurement Value *',
                    width: 140,
                    name: "MeasurementValue",
                },
                {
                    type: 'dropdown',
                    title: 'Measurement Unit *',
                    source: measurementsPrime,
                    width: 200,
                    options: 'picker',
                    lazyLoading: true,
                    name: "MeasurementValueUnitId",
                },
                {
                    type: 'dropdown',
                    title: 'Customs Status',
                    source: ['Cleared', 'Awaiting Clearance'],
                    width: 140,
                    options: 'picker',
                    lazyLoading: true,
                    name: "CustomsStatus",
                },
                {
                    type: 'text',
                    title: 'Description Of Goods *',
                    width: 150,
                    name: "DescriptionOfGoods",
                },
                {
                    type: 'text',
                    title: 'HS code',
                    width: 120,
                    name: "HSCode",
                },

                {
                    type: 'text',
                    title: 'Number Of Packages *',
                    width: 150,
                    name: "NumberOfPackages",
                },
                {
                    type: 'text',
                    title: 'Type Of Package',
                    width: 120,
                    name: "TypeOfPackage",
                },
                {
                    type: 'dropdown',
                    title: 'Dangerous Goods? *',
                    width: 200,
                    source: isDgValue,
                    options: 'picker',
                    lazyLoading: true,
                    name: "newDg",
                    autocomplete: true,

                },
                {
                    type: 'dropdown',
                    title: 'DG Classification ',
                    source: dgList,
                    width: 120,
                    options: 'picker',
                    lazyLoading: true,
                    autocomplete: true,
                    name: "DGClassificationConfigId",
                },
                {
                    type: 'dropdown',
                    title: 'UN number ',
                    width: 120,
                    source: unCodeList,
                    options: 'picker',
                    lazyLoading: true,
                    name: "UNNumberConfigId",
                },
                {
                    type: 'text',
                    title: 'Subsidiary Risks',
                    width: 120,
                    name: "SubsidiaryRisk",
                },
                {
                    type: 'text',
                    title: 'MARPOL pollution Code',
                    width: 120,
                    name: "MARPOLPollutionCode",
                },
                {
                    type: 'dropdown',
                    title: 'IMO Hazard Class ',
                    width: 150,
                    source: hazardClassList,
                    options: 'picker',
                    lazyLoading: true,
                    name: "IMOHazardClassConfigId",
                },
                {
                    type: 'dropdown',
                    title: 'Packing Group ',
                    width: 180,
                    source: pgList,
                    options: 'picker',
                    lazyLoading: true,
                    name: "PackingGroupConfigId",
                },
                {
                    type: 'text',
                    title: 'Flashpoint',
                    width: 120,
                    name: "Flashpoint",
                },
                {
                    type: 'text',
                    title: 'EmS',
                    width: 120,
                    name: "EmS",
                },
                {
                    type: 'hidden',
                    title: 'DeckCargoDetailId',
                    width: 120,
                    options: 'picker',
                    lazyLoading: true,
                    name: 'DeckCargoDetailId',
                },
                {
                    type: 'hidden',
                    title: 'BookingRequestId',
                    width: 120,
                    options: 'picker',
                    lazyLoading: true,
                    name: 'BookingRequestId',
                },
                {
                    type: 'hidden',
                    title: 'BookingEquipmentDetailId',
                    width: 120,
                    options: 'picker',
                    lazyLoading: true,
                    name: 'BookingEquipmentDetailId',
                },

            ],
        };
        if (!jRef?.current?.jexcel) {
            jspreadsheet(jRef?.current, options);
        }
        retrieveData();

    }, []);

    useEffect(() => {
        deckCallBack(deckCargoData);

    }, [deckCargoData]);

    let retrievedRowInfo2 = clickedVal;
    if (retrievedRowInfo2 === true) {
        jRef.current.jexcel.insertRow();
    }
    const addRow = () => {
        jRef.current.jexcel.insertRow();
    }
    return (
        <div>
            <p className="text-danger mb-0 small"><b className="mr-2">-</b>(Required fields are marked by *, please <b>double-click</b> to see drop-down options ) </p>
            <p className="text-danger mt-0 small"><b className="mr-2">-</b>For Dangerous goods, the following fields are required - "DG Classification", "UN number" , "IMO hazard Class" ,"Packing Group". </p>

            {hasDisabledFields && <><hr /><p className="small text-danger"><b className="mr-2">-</b>Rows that are disabled include cargo that is already a part of a planned voyage.</p></>}
            <div ref={jRef} />

            <br /> <br />
            <input type="button" className="btn-btn-dim" onClick={addRow} value="Add more" />
        </div>
    );
}
