import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import LocationService from "../../services/locationService";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import Spinner from "../../utils/spinner";
import { Validator, ValidateGroup, ClearValidator } from "../validator";
import toastr from "../../utils/toastr";
import ProductService from "../../services/productService";
import EquipmentService from "../../services/equipmentService";
import UsageReportComponent from "../product/usageReport";
import VoyageService from "../../services/voyageService";
import { Link } from "react-router-dom";
import FuelUsageTableComponent from "../voyageReporting/fuelUsageTableComponent";
import { useCallback } from "react";
import { currencyFormat, toReadableDate } from "../../utils/utilityFunctions";

export default function FuelUsageReportComponent({
	voyagePlanningId,
	voyageUnderView,
	fuelConsumptionHistory,
}) {
	const params = useParams();
	const [redirectUrl, setRedirectUrl] = useState(null);
	const [loading, setLoading] = useState(false);
	const [products, setProducts] = useState([]);
	const [units, setUnits] = useState([]);
	const [litresUnit, setLitresUnit] = useState([]);
	const [payload, setPayload] = useState({});
	const [saving, setSaving] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [voyage, setVoyage] = useState({});
	const [fuelConsumptionHistoryNew, setFuelConsumptionHistoryNew] = useState(null);
	const [totalConsumptionFigure, setTotalConsumptionFigure] = useState((fuelConsumptionHistory && fuelConsumptionHistory[0])?.totalConsumption || 0);


	const addPayload = new PayloadUpdater({
		payload: payload,
		setPayload: setPayload,
	}).update;
	const validationGroup = "fuel_consumption_log";

	const reloadConsumptionHistory = async() => {
		setLoading(true);
		const consumptionLog = await VoyageService.getConsumptionLog(voyagePlanningId);


		//The line below sums up the Quantity of for objects with
		//the same keys(ConsumptionType)
		const aggregatedArray = Array.from(
			consumptionLog.reduce(
				(list, { ConsumptionType, Quantity }) =>
					list.set(ConsumptionType, (list.get(ConsumptionType) || 0) + Quantity),
				new Map()
			),
			([ConsumptionType, Quantity]) => ({ ConsumptionType, Quantity })
		);
		const totalFuelConsumption = aggregatedArray?.find((list) => list.ConsumptionType.toLowerCase() === "fuel")?.Quantity
		const totalWaterConsumption = aggregatedArray?.find((list) => list.ConsumptionType.toLowerCase() === "water")?.Quantity
		let waterUsage = consumptionLog?.filter((list) => list.ConsumptionType.toLowerCase() === "water");
		waterUsage = waterUsage?.map((list) => ({
			Quantity: list?.Quantity,
			DateConsumed: toReadableDate(list.DateCreated),
			totalConsumption: totalWaterConsumption
		}))

		let fuelUsage = consumptionLog?.filter((list) => list.ConsumptionType.toLowerCase() === "fuel")

		fuelUsage = fuelUsage?.map((list) => ({
			Quantity: list?.Quantity,
			DateConsumed: toReadableDate(list.DateCreated),
			totalConsumption: totalFuelConsumption
		}))

		setTotalConsumptionFigure(fuelUsage[0]?.totalConsumption || 0);
		setFuelConsumptionHistoryNew(fuelUsage);
		setLoading(false);
	}

	useEffect(async () => {
		await init();

		return () => {
			ClearValidator(validationGroup);
		};
	}, []);

	const init = async () => {
		setLoading(true);

		let _units = await EquipmentService.listMeasurementUnits();
		_units = (_units || [])
			.map((a) => ({
				...a,
				value: a.MeasurementUnitId,
				label: `${a.MeasurementUnit} (${a.MeasurementUnitSymbol})`,
			}))
			.filter((a) => a.UnitType == "volume");
		let litreUnit = _units.find((unit) => unit.MeasurementUnitSymbol === "L");
		setLitresUnit(litreUnit);
		setUnits(_units);

		if (voyageUnderView) {
			setVoyage(voyageUnderView);
		} else {
			let _voyage = await VoyageService.get(voyagePlanningId);
			setVoyage(_voyage || {});
		}

		setLoading(false);
	};

	const submitForm = async () => {
		const _payload = {
			...payload,
			voyagePlanningId: voyagePlanningId,
			FuelMeasurementUnitId: litresUnit?.MeasurementUnitId,
		};

		if (
			voyage.FuelMeasurementUnitId !== "00000000-0000-0000-0000-000000000000"
		) {
			_payload.FuelMeasurementUnitId = voyage.FuelMeasurementUnitId;
		}
		setSaving(true);
		const response = await ProductService.fuelConsumptionLog(_payload);
		if (response) {
			setPayload({});
			toastr("success", "Consumption log saved successfully");
			await reloadConsumptionHistory();
		}
		setSaving(false);
	};


	return redirectUrl ? (
		<Navigate to={redirectUrl} replace={true} />
	) : (
		<>
			{loading && (
				<div className="w-100 text-center center my-5 py-5">
					<Spinner />
				</div>
			)}
			{!loading && (
				<div
					className="alert alert-info alert-dismissible fade show"
					role="alert">
					This displays all the logs of fuel consumed
					by the vessel during the voyage.
					<button
						type="button"
						className="close"
						data-dismiss="alert"
						aria-label="Close"></button>
				</div>
			)}
			{!loading && (
				<div className="row">
					<div className="col-6">
						<h6>
							<b>Total Consumption: {totalConsumptionFigure && currencyFormat(totalConsumptionFigure,0) } </b>{" "}
							{totalConsumptionFigure ? "liters" : "N/A"}
						</h6>
						<form className="p-1">
							<div className="card-inner p-0 pt-5 mt-0">
								<div className="row">
									<div className="col">
										<div className="form-group row">
											<div className="form-control-wrap col-4">
												<label className="control-label">Quantity</label>

												<input
													type="number"
													className="form-control required"
													placeholder="Quantity"
													onChange={(e) =>
														addPayload("QuantityofFuelConsumed", e.target.value)
													}
												/>
											</div>
											<div className="form-control-wrap col col-md-4">
												<label className="control-label">
													Unit of measurement
												</label>
												<Select
													// onChange={(item) =>
													// 	addPayload("FuelMeasurementUnitId", item.value)
													// }
													placeholder="unit of measurement"
													value={units.find(
														(a) => a.value == litresUnit?.MeasurementUnitId
													)}
													options={litresUnit}
													isDisabled={
														voyage?.FuelMeasurementUnitSymbol !== null ||
														litresUnit?.MeasurementUnitId
													}
												/>
											</div>
										</div>
									</div>
								</div>
								<div>
									{
										// products.map((product, index)=> <div style={{zIndex: (products.length - index)}} key={index} className="my-2"><UsageReportComponent productId={product.ProductId}  /></div>)
									}
								</div>
							</div>
							{saving && (
								<div className="form-group p-0 mt-4">
									<button type="button" className="btn btn-md btn-link m-1">
										<Spinner size="1.5" />
									</button>
								</div>
							)}
							{!saving && (
								<div className="form-group p-0 mt-4">
									<button
										type="button"
										onClick={() => submitForm()}
										className="btn btn-md btn-dark btn-wide  mr-3">
										Add Fuel Consumption
									</button>
									<Link to={`/voyages`} className="btn btn-sm btn-link m-1">
										Cancel
									</Link>
								</div>
							)}
						</form>
					</div>
					<div className="col-6">
					 	{!saving && <FuelUsageTableComponent
							fuelConsumptionHistory={fuelConsumptionHistoryNew || fuelConsumptionHistory}
						/>}
					</div>
				</div>
			)}
		</>
	);
}
