import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ActivityTable from "../../components/dataTable";
import ActivityService from "../../services/activityService";
import Paginate from "../../components/paginate";
import Spinner from "../../utils/spinner";

export default function ActivityListComponent() {
  const [initializing, setInit] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState([10, 20, 30, 50]);
  const [tableData, setTableData] = useState([]);
  const [activitiesCount, setActivitiesCount] = useState();

  useEffect(async () => {
    await loadData();
  }, []);
  const loadData = async (_page, _pageSize, _search = "") => {
    setInit(true);
    _page = _page || currentPage;
    _pageSize = _pageSize || pageSize;
    setSearchParam(_search);

    const _activities = await ActivityService.activitiesPaginatedList(
      _page,
      _pageSize,
      _search
    );
    let modifiedDataList = _activities?.Data || [];
      setActivitiesCount(_activities?.TotalCount || 0);
    modifiedDataList = modifiedDataList?.map((e) => ({
      Id: e.ActivityId,
      Longname: e.ActivityName,
      Shortname: e.ActivityShortName,
      Description: e.ActivityDescription,
      AllowParallel: e.AllowsParallel ? "Yes" : "No",
      AllocationCategory: e.AllocationCategory,
      actionsComponent: overflowMenu(e),
    }));
    setTableData(modifiedDataList);
    currentPage !== _page && setCurrentPage(_page);
    pageSize !== _pageSize && setPageSize(_pageSize);
    setInit(false);
  };
  const overflowMenu = (activity) => {
    return (
      <ul className="nk-tb-actions gx-2">
        {null && (
          <li className="nk-tb-action-hidden">
            <a
              href="#"
              className="btn btn-sm btn-icon btn-trigger"
              data-toggle="tooltip"
              data-placement="top"
              title="Suspend"
            >
              <em className="icon ni ni-user-cross-fill" />
            </a>
          </li>
        )}
        <li>
          <div className="drodown">
            <a
              href="#"
              className="btn btn-sm btn-icon btn-trigger dropdown-toggle"
              data-toggle="dropdown"
            >
              <em className="icon ni ni-more-h" />
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <ul className="link-list-opt no-bdr">
                {/* <li className="clickable" onClick={() => setSelectedRecord(location)}><a><em className="icon ni ni-eye" /><span>View Details</span></a></li> */}
                <li>
                  {" "}
                  <Link to={`/view-activity/${activity.ActivityId}`}>
                    <em className="icon ni ni-eye" />
                    <span>View Activity details</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    );
  };
  const headerList = [
    {
      Header: "Shortname",
      accessor: "Shortname",
      link: "/view-activity/",
      linkId: "Id",
    },
    {
      Header: "Longname",
      accessor: "Longname",
    },
    {
      Header: "Allows Parallel?",
      accessor: "AllowParallel",
    },
    {
      Header: "Category of Allocation",
      accessor: "AllocationCategory",
    },
    {
      Header: "Description",
      accessor: "Description",
    },
    {
      Header: "",
      accessor: "actionsComponent",
    },
  ];

  const doSearch = async () => {
    await loadData(1, null, searchParam);
  };

  const clearFilter = async () => {
    setSearchParam("");

    await loadData(1, null);
  };

  return (
    <>
      {initializing ? (
        <div className="w-100 text-center center my-5 py-5">
          <Spinner />
        </div>
      ) : (
        <div className="table-component">
          <div className="card-inner-group">
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
                <div className="card-tools">
                  <div className="dropdown">
                    <a
                      href="#"
                      className="btn btn-trigger btn-icon dropdown-toggle "
                      data-toggle="dropdown"
                    >
                      <em className="icon ni ni-filter-alt" />
                    </a>
                    <div className="dropdown-menu dropdown-menu-xl dropdown-menu-left p-1">
                      <form style={{ zoom: "80%" }}>
                        <div className="dropdown-head">
                          <span className="sub-title dropdown-title">
                            Filter Activities
                          </span>
                          <div className="dropdown">
                            <a
                              className="btn btn-sm btn-icon"
                              data-toggle="dropdown"
                            >
                              <em className="icon ni ni-cross" title="close" />
                            </a>
                          </div>
                        </div>
                        <div className="dropdown-body dropdown-body-rg">
                          <div className="row gx-6 gy-3">
                            <>
                              <div className="col-12">
                                <div className="form-group">
                                  <label
                                    className="overline-title overline-title-alt"
                                    htmlFor="from-date-filter"
                                  >
                                    Search
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control "
                                    placeholder=""
                                    onChange={(e) =>
                                      setSearchParam(e.target.value)
                                    }
                                    value={searchParam}
                                  />
                                </div>
                              </div>
                            </>

                            <div className="gap gap-10px" />

                            <div className="col-12">
                              <div className="form-group">
                                <button
                                  type="button"
                                  onClick={doSearch}
                                  className="btn btn-secondary"
                                >
                                  <span>Filter</span>
                                </button>
                                <a
                                  className="clickable ml-2"
                                  onClick={() => clearFilter()}
                                >
                                  Reset Filter
                                </a>
                              </div>
                            </div>
                            <div className="gap gap-50px" />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  {/* .form-inline */}
                </div>
                {/* .card-tools */}
                <div className="card-tools mr-n1">
                  <ul className="btn-toolbar gx-1">
                    <li className="btn-toolbar-sep" />
                    {/* li */}
                    <li>
                      <div className="toggle-wrap">
                        <a
                          href="#"
                          className="btn btn-icon btn-trigger toggle"
                          data-target="cardTools"
                        >
                          <em className="icon ni ni-menu-right" />
                        </a>
                        <div
                          className="toggle-content"
                          data-content="cardTools"
                        >
                          <ul className="btn-toolbar gx-1">
                            <li className="toggle-close">
                              <a
                                href="#"
                                className="btn btn-icon btn-trigger toggle"
                                data-target="cardTools"
                              >
                                <em className="icon ni ni-arrow-left" />
                              </a>
                            </li>
                            {/* li */}

                            <li>
                              <div className="dropdown">
                                <a
                                  href="#"
                                  className="btn btn-trigger btn-icon dropdown-toggle"
                                  data-toggle="dropdown"
                                >
                                  <em className="icon ni ni-setting" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-xs dropdown-menu-right">
                                  <ul className="link-check">
                                    <li>
                                      <span>Show</span>
                                    </li>
                                    {pages.map((item, index) => (
                                      <li
                                        onClick={() => loadData(null, item)}
                                        key={index}
                                        className={`${
                                          currentPage === item && "active"
                                        }`}
                                      >
                                        <Link to={"#"}>{item}</Link>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                              {/* .dropdown */}
                            </li>
                            {/* li */}
                          </ul>
                          {/* .btn-toolbar */}
                        </div>
                        {/* .toggle-content */}
                      </div>
                      {/* .toggle-wrap */}
                    </li>
                    {/* li */}
                  </ul>
                  {/* .btn-toolbar */}
                </div>
                {/* .card-tools */}
              </div>
            </div>
          </div>
          {/* .card-inner */}
          {!initializing && (
            <>
              {" "}
              <ActivityTable
                columns={headerList}
                data={tableData}
                cssArray={["font-weight-500", "", "", "text-left"]}
                emptyMessage="No activity record to display"
                disablePageSize={true}
                enableSearch={false}
              />
                <div className="">
                  <Paginate
                    onChange={(p) => loadData(p)}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    totalRecordsCount={activitiesCount}
                  />
                </div>
            </>
          )}
        </div>
      )}
    </>
  );
}
