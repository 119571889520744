import apiCall from "../utils/apiCall";
import { dynamicSort, clearAPICache } from "../utils/utilityFunctions";

export default class RoleService {
  static getAllRoles = async () => {
    let response = await apiCall(`/api/Roles/GetAll`, "GET", null, null, null, true);
    if (response) {
      response.sort(dynamicSort("Name"));
    }
    return response;
  };

  static getAllRolesPaginated = async (page, pageSize) => {
    let response = await apiCall(
      `/api/Roles?page=${page}&pageSize=${pageSize}`,
      "GET"
    );
    return response;
  };

  static getRolesList = async (page, pageSize) => {
    let response = await apiCall(
      `/api/Roles/Get?pageCount=${page}&noPerPage=${pageSize}`,
      "GET"
    );
    if (response) {
      response?.Data.sort(dynamicSort("Name"));
    }
    return response;
  };

  static addNewRole = async (payload) => {
    clearAPICache(`/api/Roles/GetAll`);
    return await apiCall(`/api/Roles/Post`, "POST", payload);
  };

  static getAllPermissions = async () => {
    let response = await apiCall(`/api/Roles/Permissions/GetAll`, "GET", null, null, null, true);
    if (response) {
      response.sort(dynamicSort("Permission"));
    }
    return response;
  };

  static getRolePermissions = async (roleId) => {
    let response = await apiCall(
      `/api/Roles/RolePermission?roleId=${roleId}`,
      "GET"
    );
    return response;
  };

  static addPermissionsToRoles = async (payload) => {
    clearAPICache(`/api/Roles/GetAll`);
    return await apiCall(`/api/Roles/AddPermissionsToRoles`, "POST", payload);
  };

  static removeRoleById = async (roleId) => {
    clearAPICache(`/api/Roles/GetAll`);
    return await apiCall(`/api/Roles/Delete/${roleId}`, "DELETE");
  };

  static getRoleById = async (roleId) => {
    return await apiCall(`/api/Roles/Get/${roleId}`, "GET");
  };

  static removePermissionsFromRole = async (payload) => {
    clearAPICache(`/api/Roles/GetAll`);
    return await apiCall(`/api/Roles/RemovePermission`, "POST", payload);
  };

  static removeRolesFromUser = async (payload) => {
    return await apiCall(`/api/Roles/RemoveUser`, "POST", payload);
  };
}
