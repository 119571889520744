import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import BookingRequestService from "../../services/bookingRequestService";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../../components/fileInput";
import Spinner from "../../utils/spinner";
import {
  Validator,
  ValidateGroup,
  ClearValidator,
} from "../../components/validator";
import toastr from "../../utils/toastr";
import VoyageService from "../../services/voyageService";
import ReactTooltip from "react-tooltip";
import EquipmentService from "../../services/equipmentService";
import CustomModal from "../../utils/customModal";
import { toReadableDate } from "../../utils/utilityFunctions";
import RemoveBulkListComponent from "../../components/voyage/removeBulkList";
import RemoveDeckListComponent from "../../components/voyage/removeDeckList";
import DeckListComponent from "../../components/voyage/deckList";
import { forEach } from "lodash";

export default function RemoveCargo() {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [bulkLoading, setBulkLoading] = useState(false);
  const [deckLoading, setDeckLoading] = useState(false);
  const [payload, setPayload] = useState({});
  const [saving, setSaving] = useState(false);
  const [bulkCargo, setBulkCargo] = useState([]);
  const [deckCargo, setDeckCargo] = useState([]);
  const [voyage, setVoyage] = useState([]);

  const [postVoyageDetails, setPostVoyageDetails] = useState({
    BulkCargos: [],
    DeckCargos: [],
  });

  const addPayload = new PayloadUpdater({
    payload: postVoyageDetails,
    setPayload: setPostVoyageDetails,
  }).update;
  const validationGroup = "createVoyage";

  useEffect(async () => {
    await init();
  }, []);

  const init = async () => {
    setLoading(true);
    const _voyage = await VoyageService.get(params.voyagePlanningId);
    setVoyage(_voyage || []);

    if (_voyage?.BulkCargos) {
      let _bCargo = _voyage.BulkCargos?.map((l) => ({
        ...l,
        deleteIcon: deleteBulkIcon(l),
      }));
      setBulkCargo(_bCargo || []);
    }

    if (_voyage?.DeckCargos) {
      let _dCargo = _voyage.DeckCargos?.map((l) => ({
        ...l,
        deleteIcon: deleteDeckIcon(l),
      }));
      setDeckCargo(_dCargo || []);
    }

    setLoading(false);
  };

  const deleteBulkIcon = (bulkCargo) => {
    const deleteBulkCargo = async () => {
      setBulkLoading(true);
      if (bulkCargo) {
        const request = await VoyageService.removeBulkCargo(
          bulkCargo.BulkCargoDetailId
        );
        if (request) {
          toastr("success", "Bulk cargo deleted");
          await init();
          // setTimeout(() => window.location.reload(false), 5000);
          // window.location.reload(false);
        } else {
          toastr("error", "Bulk cargo not deleted");
          return;
        }
      }
      setBulkLoading(false);
    };

    return (
      <>
        <ReactTooltip id="trash">Delete Cargo</ReactTooltip>
        <a
          href="#"
          className="btn btn-sm btn-icon btn-trigger"
          data-toggle="tooltip"
          data-placement="top"
          data-original-title="Delete cargo"
          data-tip
          data-for="trash"
        >
          <em className="icon ni ni-trash" onClick={deleteBulkCargo} />
        </a>
      </>
    );
  };

  const deleteDeckIcon = (deckCargo) => {
    const deleteDeckCargo = async () => {
      setDeckLoading(true);
      if (deckCargo) {
        const request = await VoyageService.removeDeckCargo(
          deckCargo.DeckCargoDetailId
        );
        if (request) {
          toastr("success", "Deck cargo deleted");
          await init();
          // window.location.reload(false);
        } else {
          toastr("error", "Deck cargo not deleted");
          return;
        }
      }
      setDeckLoading(false);
    };

    return (
      <>
        <ReactTooltip id="trash">Delete Cargo</ReactTooltip>
        <a
          href="#"
          className="btn btn-sm btn-icon btn-trigger"
          data-toggle="tooltip"
          data-placement="top"
          data-original-title="Delete cargo"
          data-tip
          data-for="trash"
        >
          <em className="icon ni ni-trash" onClick={deleteDeckCargo} />
        </a>
      </>
    );
  };

  const pageActions = (
    <div className="toggle-wrap nk-block-tools-toggle">
      <a
        href="#"
        className="btn btn-icon btn-trigger toggle-expand mr-n1"
        data-target="pageMenu"
      >
        <em className="icon ni ni-menu-alt-r" />
      </a>
      <div className="toggle-expand-content" data-content="pageMenu">
        <ul className="nk-block-tools g-3">
          <li>
            <button
              onClick={() => window.history.back()}
              className="btn btn-white btn-outline-light"
            >
              <em className="icon ni ni-arrow-left" />
              <span>Return to Previous Page</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout
      title="Remove Cargo"
      loading={loading}
      pageActions={pageActions}
      showFullLoader={loading}
    >
      <form>
        <div className="card-inner">
          <div className="row">
            <div className="col-12">
              <p>
                <span className="preview-title-lg overline-title">
                  Bulk Cargo Details
                </span>{" "}
              </p>
            </div>
            {bulkLoading ? (
              <Spinner />
            ) : (
              <RemoveBulkListComponent dataList={bulkCargo} />
            )}
          </div>
          <br />
          <br />

          <div className="row">
            <div className="col-12">
              <p>
                <span className="preview-title-lg overline-title">
                  Deck Cargo Details
                </span>{" "}
              </p>
            </div>
            {deckLoading ? (
              <Spinner />
            ) : (
              <RemoveDeckListComponent dataList={deckCargo} />
            )}
          </div>
        </div>
      </form>
    </MainLayout>
  );
}
