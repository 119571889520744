import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import FuelUsageReportComponent from "../../components/voyage/FuelUsageReportComponent";
import VoyageService from "../../services/voyageService";
import { toReadableDate } from "../../utils/utilityFunctions";

export default function FuelUsageReportPage() {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fuelConsumptionHistory, setFuelConsumptionHistory] = useState([]);

  useEffect(async () => {
    await init();
  }
    , [])

  const init = async () => {
    const consumptionLog = await VoyageService.getConsumptionLog(params.voyageId);


    //The line below sums up the Quantity of for objects with
    //the same keys(ConsumptionType)
    const aggregatedArray = Array.from(
      consumptionLog.reduce(
        (list, { ConsumptionType, Quantity }) =>
          list.set(ConsumptionType, (list.get(ConsumptionType) || 0) + Quantity),
        new Map()
      ),
      ([ConsumptionType, Quantity]) => ({ ConsumptionType, Quantity })
    );
    const totalFuelConsumption = aggregatedArray?.find((list) => list.ConsumptionType.toLowerCase() === "fuel")?.Quantity
    let fuelUsage = consumptionLog?.filter((list) => list.ConsumptionType.toLowerCase() === "fuel")

    fuelUsage = fuelUsage?.map((list) => ({
      Quantity: list?.Quantity,
      DateConsumed: toReadableDate(list.DateCreated),
      totalConsumption: totalFuelConsumption
    }))
    // ConsumptionType:"water"
    setFuelConsumptionHistory(fuelUsage);

  }

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout title={`Fuel consumption log`} loading={loading}>
      <>
        <FuelUsageReportComponent voyagePlanningId={params.voyageId} fuelConsumptionHistory={fuelConsumptionHistory} />
      </>
    </MainLayout>
  );
}
