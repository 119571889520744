import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ActivityService from "../../services/activityService";
import CountryService from "../../services/countriesService";
import LocationService from "../../services/locationService";
import ProvinceService from "../../services/provinceService";
import Spinner from "../../utils/spinner";

export default function VoyageActivityDetailsComponent({ id, _record }) {
    const [loading, setLoading] = useState(false);
    const [record, setRecord] = useState(_record || {});
    const [country, setCountry] = useState(null);
    const [region, setRegion] = useState(null);
    const [location, setLocation] = useState({});
    const [activity, setActivity] = useState({});


    const init = async () => {
        if (!_record) {
            setLoading(true);
            const _r = await ActivityService.singleVoyageActivity(id);
            setRecord(_r || {});
            
        }

        const actualRecord = _record || record;

        if(actualRecord.ActivityId){
            const _activity = await ActivityService.singleActivity(actualRecord.ActivityId);
            setActivity(_activity || {});
        }

        if(actualRecord.LocationId){
            const locations = await LocationService.list(1,5000);
            const _location = (locations || []).find(c=> c.LocationId == actualRecord.LocationId);
            setLocation(_location || {});
        }

        


        setLoading(false);
    }

    useEffect(async () => {
        await init();

        return () => {
        }
    }, [])

    const dataItem = (label, value, isFullWidth) => {
        if (value == "null") value = "";
        return <li className={isFullWidth?"col-12": "col-6 "}><p><span className="text-soft small">{label}</span> {value || "-"}</p></li>
    }


    return (
        <>
            {loading && <Spinner />}
            {loading || <div className="card ">
                    <div className="sp-plan-desc card-inner">
                        <ul className="row gx-1">
                            {dataItem("Activity", activity?.ActivityName)}
                            {dataItem("Location", location?.LocationName)}
                            {dataItem("Category", record?.ActivityCategory)}
                            {dataItem("Productivity", record?.ProductivityOfActivity)}
                            {dataItem("Duration", record?.DurationOfActivity)}
                            {dataItem("Planned Dates",  `From: ${record?.PlannedStartDate} x To: ${record?.EndDate}`, true)}
                            {dataItem("Actual Dates", `From: ${record?.ActualStartDate} x To: ${record?.ActualEndDate}`, true)}
                        </ul>
                    </div>
                    
            </div>}
        </>
    );
}

