import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import PayloadUpdater from "../../utils/payloadUpdater";
import Spinner from "../../utils/spinner";
import toastr from "../../utils/toastr";
import VoyageActivityService from "../../services/voyageActivityService";
import VoyageActivitiesListComponent from "./voyageActivitiesList";
import VoyageService from "../../services/voyageService";
import SailingService from "../../services/sailingService";
import ReactTooltip from "react-tooltip";
import CustomModal from "../../utils/customModal";
import {
  toReadableDate,
  toReadableDateTime,
  checkUserPermission,
  convertFromKilometresToNauticalMiles,
  camelizeKeys,
  dynamicSort,
  durationByHours,
  durationInHoursFromMilliSeconds,
  convertToUTC
} from "../../utils/utilityFunctions";
import Carousel from "react-elastic-carousel";
import portImg from "../../images/port.png";
import offshoreImg from "../../images/oil-platform.png";
import oceanImg from "../../images/sea-wave.png";
import LocationService from "../../services/locationService";
import { calcDistanceBtwCoordinates } from "../../utils/calcDistanceBtwCoordinates";

const cautionIcon = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAADB0lEQVR4nL2Xz08TQRTHN/JP+Oui/gdqvHjxgIIS6HYJrYmpAiYtCJgYguEiJQHDQTF6IkBpaWeJpSGtJYaWH7K7tSWhSRMoFAT1aHf7Ew8YTdplzMNQRJdmt6Wd5DWT7ex8Mm/ee9+3BKFwOBwNFXOmyrOzo7eugMEcnhGlGPNjNRcZq6rXN0FtBBxaPjTdHI3MtyfAYB6wa3kfTW3AGlhbNHBmpPo8R1PuoEsn8OHnYjZuxjiNJC0Tt2A+3C8GnTrBh9Tv4N2CoIs2dVtgUiOktgfF42DHWWprUFyya2OsVfVINtDhaKjgbOqxNa8hvZe0KgL+bWLSisPe1u8cTZllxQCHSPNX/9PdQoH/2hd/1y4cJL97kcqw4tGnTwp6YGvelh3Won4sCYVggDstxr353O5/q4lJBhxHk+7kpxd7cjb6KYzj+w8f4GZ9o/yA234lQrQfgX4Yrr4UdOlicjf58c2Cr92oxdcrVYpOHnTqhCN5zqDaXiHcny01mF/tF1kbacyBfah+EwpAqcHZuBlzE9R6Lm+X7Hd5JRsUCsZphAOT2uh+Xi8M1ZwLuZvLBg5NN0U9w1VniDnT7auRhY5EucCR+fYEqBoBP2UFz7UlPMNVlwnQ05C7qXyudjdGGcud0/vBFVAYXNmEFS+8H8CLMwPKg8uu5XOiASKuJJ0KtUzcgn0T9WuH4mCrM/IKCgiUzCedetzZZcC/hHHZ4OhKX5ZBqmdHS6ZTfslcX36zf8dg68HXssHLU7rYf60Rh0hXclOeSIhJhEeGuvHoUDfeS9lkQVNbL0WOppySsui3a2IgYSd9t2LijyxCsZLUZNZG6kvRCIS9hh0GqTokoTk4UpugXTkp6OePXbssIk15oQeiAT1S2KNPF+N2cC94Dw6iqOFnxmtb4c4hKJRCIUjhThlLXQtRcEOP1C5IA361L5uvyGTiZgxrlqfuxTiadB4bSISCAbnHIrKHo6kIlDyQtsNPmKZowK7h4T/WSvbMjty8UDRQahiNxlOgpwcfbTCHZ0o3+g3nFcSchxQ6wQAAAABJRU5ErkJggg==";

export default function VoyageActivitiesComponent({
  voyagePlanningId,
  voyage,
}) {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [endActivityModalIsClosed, setEndActivityModalIsClosed] =
    useState(true);
  const [initializing, setInit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [payload, setPayload] = useState({});
  const [saving, setSaving] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalRecords, setTotalRecords] = useState(15);
  const [voyageActivities, setVoyageActivities] = useState([]);
  const [displayedVoyageActivities, setDisplayedVoyageActivities] = useState(
    []
  );
  const [locationOptions, setLocationOptions] = useState([]);
  const [finalMainActivity, setFinalMainActivity] = useState([]);
  const [
    voyageActivitiesInProgressListChecker,
    setVoyageActivitiesInProgressListChecker,
  ] = useState([]);
  const [priorActivitiesToSailing, setPriorActivitiesToSailing] = useState([]);
  const dummySailingObject = {
    isDummySailing: true,
    locationName: "DUMMY LOCATION",
    locationType: "",
    vesselName: "",
    sailingDistance: 0.0,
    durationOfActivityExpression: "0hrs 0mins",
    activityName: "Sailing",
    activityShortName: "S",
    activityDescription: "Time spend on sailing on economic speed.",
    locationGroup: null,
    locationTypeId: null,
    allowsParallel: false,
    voyageActivityId: "",
    voyagePlanningId: "",
    activityId: "",
    locationId: "",
    activityCategory: "",
    productivityOfActivity: null,
    durationOfActivity: "0hrs",
    endDate: "",
    plannedStartDate: "",
    activityStatus: "not-started",
    activityType: "planned",
    actualStartDate: "",
    actualEndDate: "",
    estimatedStartTime: "",
    estimatedEndDate: "",
  };
  const [sailingLocation, setSailingLocation] = useState([]);


  const addPayload = new PayloadUpdater({
    payload: payload,
    setPayload: setPayload,
  }).update;

  useEffect(async () => {
    await init();
  }, []);

  const init = async () => {
    setLoading(true);
    let _voyage = await VoyageService.get(params.voyagePlanningId);
    const _sailingLocations = await SailingService.getSailingLocationBySailing(
      _voyage.SailingId
    );
    setSailingLocation(_sailingLocations || [])

    const _locations = await LocationService.list(1, 1000);
    const _camelizedLocations = camelizeKeys(_locations || []);

    const _voyageActivities =
      await VoyageActivityService.getVoyageActivitiesWithSailing(
        voyagePlanningId
      );
    const _camelizedVoyageActivities = camelizeKeys(_voyageActivities || []);
    const _returnPortLocation = _camelizedLocations.find(
      (location) => location?.locationId === voyage?.arrivalPort
    );

    const _lastLocationBeforeReturnPort = _camelizedLocations.find(
      (location) =>
        location?.locationId ===
        _camelizedVoyageActivities[_camelizedVoyageActivities.length - 1]
          ?.locationId
    );

    const _returnPortSailingDistance = convertFromKilometresToNauticalMiles(
      calcDistanceBtwCoordinates(
        _returnPortLocation?.locationName,
        _returnPortLocation?.latitude,
        _returnPortLocation?.longitude,
        _lastLocationBeforeReturnPort?.locationName,
        _lastLocationBeforeReturnPort?.latitude,
        _lastLocationBeforeReturnPort?.longitude
      )?.distance_kilometres
    );

    // if else block (for sailing activity back to return port)
    // the if portion contains the dummy sailing activity processing in a case when there is no sailing activity back to the return port
    // since the final activity added on the list isn't at the return port. As a result, the finalSailing object in if statement is
    // added to the voyage activities list as a sailing to the return port and the final activity while the else portion follows in a
    // case when an activity has been added to the return port meaning that a dummy sailing will be shown by the
    // addDummySailingsToDataList function due to the noticeable switch in location

    if (
      _camelizedVoyageActivities[_camelizedVoyageActivities.length - 1]
        ?.locationId !== voyage?.arrivalPort &&
      _returnPortLocation &&
      _lastLocationBeforeReturnPort
    ) {
      // the finalSailing object contains necessary information for the list view of that particular record
      // on the voyage activities table

      const finalSailing = {
        isDummySailing: true,
        locationType: _returnPortLocation?.locationTypeName,
        activityType: "planned",
        activityStatus: "not-started",
        activityName: "Sailing",
        activityShortName: "S",
        activityDescription: "Time spend on sailing on economic speed.",
        allowsParallel: false,
        locationId: voyage?.arrivalPort,
        locationName: voyage?.arrivalPortName,
        estimatedStartTime: returnDummySailingStartDate(_camelizedVoyageActivities, _camelizedVoyageActivities?.length),
        estimatedEndDate: null,
        durationOfActivityExpression: null,
        sailingDistance: _returnPortSailingDistance,
      };

      const _voyageActivitiesWithSailingToReturnPort = [];
      _voyageActivitiesWithSailingToReturnPort.push(
        ..._camelizedVoyageActivities,
        finalSailing
      );

      const _voyageActivitiesMatchedToLocations =
        _voyageActivitiesWithSailingToReturnPort.map((voyageActivity) => ({
          ...voyageActivity,
          latitude: _camelizedLocations.find(
            (location) => location.locationId === voyageActivity.locationId
          )?.latitude,
          longitude: _camelizedLocations.find(
            (location) => location.locationId === voyageActivity.locationId
          )?.longitude,
        }));

      // add dummy sailings between activities with different locations were the latter is not a sailing
      const _dataListWithDummySailing = addDummySailingsToDataList(
        _voyageActivitiesMatchedToLocations
      );

      let modifiedDataList = _dataListWithDummySailing;

      const _finalMainActivity = _camelizedVoyageActivities?.filter(
        (voyageActivity) =>
          voyageActivity.allowsParallel == false &&
          voyageActivity.activityStatus == "in-progress"
      );
      setFinalMainActivity(_finalMainActivity);

      modifiedDataList = modifiedDataList.map((voyageActivity, index) => {
        // to make ETA of next sailing ETD of previous activity location
        // to make location of next sailing match next activity location
        // to make ETD of sailing ETA of next activity location

        if (
          modifiedDataList[index - 1] &&
          modifiedDataList[index + 1] &&
          modifiedDataList[index]?.activityName.toLowerCase() === "sailing" &&
          modifiedDataList[index]?.isDummySailing === true
        ) {
          modifiedDataList[index]["estimatedStartTime"] =
            returnDummySailingStartDate(modifiedDataList, index);
          modifiedDataList[index]["locationName"] =
            modifiedDataList[index + 1]?.locationName;
          modifiedDataList[index]["locationType"] =
            modifiedDataList[index + 1]?.locationType;
          modifiedDataList[index]["estimatedEndDate"] =
            modifiedDataList[index + 1]?.estimatedStartTime;
        }

        // to calculate duration of dummy sailing using ETA of next activity location & ETD of previous activity location

        if (
          modifiedDataList[index - 1] &&
          modifiedDataList[index + 1] &&
          modifiedDataList[index - 1]?.estimatedEndDate !== null &&
          modifiedDataList[index + 1]?.estimatedStartTime !== null &&
          modifiedDataList[index]?.activityName.toLowerCase() === "sailing" &&
          modifiedDataList[index]?.isDummySailing === true
        ) {
          modifiedDataList[index]["durationOfActivityExpression"] =
            durationInHoursFromMilliSeconds(
              modifiedDataList[index - 1]?.estimatedEndDate,
              modifiedDataList[index + 1]?.estimatedStartTime
            );
        }

        // to calculate distance of dummy sailing from next activity location using the coordinates
        // of next activity location & previous activity location

        if (
          modifiedDataList[index - 1] &&
          modifiedDataList[index + 1] &&
          modifiedDataList[index - 1].latitude !== null &&
          modifiedDataList[index + 1].latitude !== null &&
          modifiedDataList[index - 1].longitude !== null &&
          modifiedDataList[index + 1].longitude !== null &&
          modifiedDataList[index]?.activityName.toLowerCase() === "sailing" &&
          modifiedDataList[index]?.isDummySailing === true
        ) {
          modifiedDataList[index]["sailingDistance"] =
            convertFromKilometresToNauticalMiles(
              calcDistanceBtwCoordinates(
                modifiedDataList[index + 1]?.locationName,
                modifiedDataList[index + 1]?.latitude,
                modifiedDataList[index + 1]?.longitude,
                modifiedDataList[index - 1]?.locationName,
                modifiedDataList[index - 1]?.latitude,
                modifiedDataList[index - 1]?.longitude
              )?.distance_kilometres
            );
        } else if (!modifiedDataList[index]?.sailingDistance) {
          modifiedDataList[index]["sailingDistance"] = 0.0;
        }
        return {
          ...voyageActivity,
          intendedStartDate:
            toReadableDateTime(voyageActivity?.estimatedStartTime) || "-",
          intendedEndDate:
            toReadableDateTime(voyageActivity?.estimatedEndDate) || "-",
          overflowComponent:
            voyageActivity.isDummySailing === true
              ? null
              : overflowMenu(voyageActivity),
          startEndComponent:
            voyageActivity.isDummySailing === true
              ? null
              : startEndButton(voyageActivity),
          imageComponent: image(voyageActivity),
          activityDuration: durationByHours(voyageActivity.durationOfActivity),
          isParallel: voyageActivity.allowsParallel == true ? "Yes" : "No",
          approximateSailingDistance:
            voyageActivity?.activityName.toLowerCase() === "sailing"
              ? (voyageActivity?.sailingDistance).toFixed(2)
              : (0.0).toFixed(2),
          locationNameWithType: `${voyageActivity?.locationName} - ${voyageActivity?.locationType}`,
          index: index,
        };
      });

      const _voyageActivitiesInProgressListChecker = modifiedDataList.filter(
        (voyageActivity) => voyageActivity.activityStatus === "in-progress"
      );

      const _nextSailingsToBegin = modifiedDataList.filter(
        (voyageActivity) => voyageActivity.isDummySailing === true
      );

      const _priorActivitiesToSailing = modifiedDataList.filter(
        (voyageActivity) =>
          voyageActivity.activityStatus !== "completed" &&
          voyageActivity.index < _nextSailingsToBegin[0]?.index
      );
      setPriorActivitiesToSailing(_priorActivitiesToSailing || []);

      let startSailingFound = true;
      let activitiesLocation = [];

      const modifiedDataListForCarousel = modifiedDataList.map(
        (voyageActivity) => {
          if (
            voyageActivity.activityName.toLowerCase() == "sailing" &&
            !startSailingFound
          ) {
            startSailingFound = true;
            activitiesLocation.push({ ...voyageActivity });
          } else if (
            voyageActivity.activityName.toLowerCase() !== "sailing" &&
            startSailingFound
          ) {
            startSailingFound = false;
            activitiesLocation.push({ ...voyageActivity });
          }

          // to highlight the current activity
          if (
            activitiesLocation.at(-1)?.locationId ===
            voyageActivity?.locationId &&
            voyageActivity?.activityStatus.toLowerCase() === "in-progress"
          ) {
            activitiesLocation.at(-1).activityStatus = "in-progress";
          }
          return {
            ...voyageActivity,
          };
        }
      );

      activitiesLocation = activitiesLocation.map((voyageActivity, index) => {
        // to make ETD of activity location ETA of next activity location

        if (
          activitiesLocation[index] &&
          activitiesLocation[index + 1]?.activityName.toLowerCase() ===
          "sailing"
        ) {
          activitiesLocation[index]["estimatedEndDate"] =
            activitiesLocation[index + 1]?.estimatedStartTime;
        }
        return {
          ...voyageActivity,
        };
      });

      let activitiesLocationReduced = activitiesLocation.map((location) => ({
        locationIcon: image(location),
        locationId: location?.locationId,
        locationName: `${location?.locationName} - ${location?.locationType}`,
        activityName: location?.activityName,
        activityStatus: location?.activityStatus,
        sailingDistance: (location?.sailingDistance).toFixed(2),
        locationActivityStartDate:
          location.estimatedStartTime == null
            ? "unavailable"
            : toReadableDateTime(location.estimatedStartTime),
        locationActivityEndDate:
          location.estimatedEndDate == null
            ? "unavailable"
            : toReadableDateTime(location.estimatedEndDate),
      }));

      setVoyageActivities(modifiedDataList || []);
      setDisplayedVoyageActivities(modifiedDataList || []);
      setVoyageActivitiesInProgressListChecker(
        _voyageActivitiesInProgressListChecker
      );
      setLocationOptions(activitiesLocationReduced || []);
      setLoading(false);
    } else {
      const _voyageActivitiesMatchedToLocations =
        _camelizedVoyageActivities?.map((voyageActivity) => ({
          ...voyageActivity,
          latitude: _camelizedLocations.find(
            (location) => location.locationId === voyageActivity.locationId
          )?.latitude,
          longitude: _camelizedLocations.find(
            (location) => location.locationId === voyageActivity.locationId
          )?.longitude,
        }));

      // add dummy sailings between activities with different locations were the latter is not a sailing
      const _dataListWithDummySailing = addDummySailingsToDataList(
        _voyageActivitiesMatchedToLocations
      );

      let modifiedDataList = _dataListWithDummySailing;

      const _finalMainActivity = _camelizedVoyageActivities?.filter(
        (voyageActivity) =>
          voyageActivity.allowsParallel == false &&
          voyageActivity.activityStatus == "in-progress"
      );
      setFinalMainActivity(_finalMainActivity);

      modifiedDataList = modifiedDataList.map((voyageActivity, index) => {
        // to make ETA of next sailing ETD of previous activity location
        // to make location of next sailing match next activity location
        // to make ETD of sailing ETA of next activity location

        if (
          modifiedDataList[index - 1] &&
          modifiedDataList[index + 1] &&
          modifiedDataList[index]?.activityName.toLowerCase() === "sailing" &&
          modifiedDataList[index]?.isDummySailing === true
        ) {
          modifiedDataList[index]["estimatedStartTime"] =
            returnDummySailingStartDate(modifiedDataList, index);
          modifiedDataList[index]["locationName"] =
            modifiedDataList[index + 1]?.locationName;
          modifiedDataList[index]["locationType"] =
            modifiedDataList[index + 1]?.locationType;
          modifiedDataList[index]["estimatedEndDate"] =
            modifiedDataList[index + 1]?.estimatedStartTime;
        }

        // to calculate duration of dummy sailing using ETA of next activity location & ETD of previous activity location

        if (
          modifiedDataList[index - 1] &&
          modifiedDataList[index + 1] &&
          modifiedDataList[index - 1]?.estimatedEndDate !== null &&
          modifiedDataList[index + 1]?.estimatedStartTime !== null &&
          modifiedDataList[index]?.activityName.toLowerCase() === "sailing" &&
          modifiedDataList[index]?.isDummySailing === true
        ) {
          modifiedDataList[index]["durationOfActivityExpression"] =
            durationInHoursFromMilliSeconds(
              modifiedDataList[index - 1]?.estimatedEndDate,
              modifiedDataList[index + 1]?.estimatedStartTime
            );
        }

        // to calculate distance of dummy sailing from next activity location using the coordinates
        // of next activity location & previous activity location

        if (
          modifiedDataList[index - 1] &&
          modifiedDataList[index + 1] &&
          modifiedDataList[index - 1].latitude !== null &&
          modifiedDataList[index + 1].latitude !== null &&
          modifiedDataList[index - 1].longitude !== null &&
          modifiedDataList[index + 1].longitude !== null &&
          modifiedDataList[index]?.activityName.toLowerCase() === "sailing" &&
          modifiedDataList[index]?.isDummySailing === true
        ) {
          modifiedDataList[index]["sailingDistance"] =
            convertFromKilometresToNauticalMiles(
              calcDistanceBtwCoordinates(
                modifiedDataList[index + 1]?.locationName,
                modifiedDataList[index + 1]?.latitude,
                modifiedDataList[index + 1]?.longitude,
                modifiedDataList[index - 1]?.locationName,
                modifiedDataList[index - 1]?.latitude,
                modifiedDataList[index - 1]?.longitude
              )?.distance_kilometres
            );
        } else if (!modifiedDataList[index]?.sailingDistance) {
          modifiedDataList[index]["sailingDistance"] = 0.0;
        }
        return {
          ...voyageActivity,
          intendedStartDate:
            toReadableDateTime(voyageActivity?.estimatedStartTime) || "-",
          intendedEndDate:
            toReadableDateTime(voyageActivity?.estimatedEndDate) || "-",
          overflowComponent:
            voyageActivity.isDummySailing === true
              ? null
              : overflowMenu(voyageActivity),
          startEndComponent:
            voyageActivity.isDummySailing === true
              ? null
              : startEndButton(voyageActivity),
          imageComponent: image(voyageActivity),
          activityDuration: durationByHours(voyageActivity.durationOfActivity),
          isParallel: voyageActivity.allowsParallel == true ? "Yes" : "No",
          approximateSailingDistance:
            voyageActivity?.activityName.toLowerCase() === "sailing"
              ? (voyageActivity?.sailingDistance).toFixed(2)
              : (0).toFixed(2),
          locationNameWithType: `${voyageActivity?.locationName} - ${voyageActivity?.locationType}`,
          index: index,
        };
      });

      const _voyageActivitiesInProgressListChecker = modifiedDataList.filter(
        (voyageActivity) => voyageActivity.activityStatus === "in-progress"
      );

      const _nextSailingsToBegin = modifiedDataList.filter(
        (voyageActivity) => voyageActivity.isDummySailing === true
      );

      const _priorActivitiesToSailing = modifiedDataList.filter(
        (voyageActivity) =>
          voyageActivity.activityStatus !== "completed" &&
          voyageActivity.index < _nextSailingsToBegin[0]?.index
      );
      setPriorActivitiesToSailing(_priorActivitiesToSailing || []);

      let startSailingFound = true;
      let activitiesLocation = [];

      const modifiedDataListForCarousel = modifiedDataList.map(
        (voyageActivity) => {
          if (
            voyageActivity.activityName.toLowerCase() == "sailing" &&
            !startSailingFound
          ) {
            startSailingFound = true;
            activitiesLocation.push({ ...voyageActivity });
          } else if (
            voyageActivity.activityName.toLowerCase() !== "sailing" &&
            startSailingFound
          ) {
            startSailingFound = false;
            activitiesLocation.push({ ...voyageActivity });
          }

          // to highlight the current activity
          if (
            activitiesLocation.at(-1)?.locationId ===
            voyageActivity?.locationId &&
            voyageActivity?.activityStatus.toLowerCase() === "in-progress"
          ) {
            activitiesLocation.at(-1).activityStatus = "in-progress";
          }
          return {
            ...voyageActivity,
          };
        }
      );

      activitiesLocation = activitiesLocation.map((voyageActivity, index) => {
        // to make ETD of activity location ETA of next activity location

        if (
          activitiesLocation[index] &&
          activitiesLocation[index + 1]?.activityName.toLowerCase() ===
          "sailing"
        ) {
          activitiesLocation[index]["estimatedEndDate"] =
            activitiesLocation[index + 1]?.estimatedStartTime;
        }
        return {
          ...voyageActivity,
        };
      });

      let activitiesLocationReduced = activitiesLocation.map((location) => ({
        locationIcon: image(location),
        locationId: location?.locationId,
        locationName: `${location?.locationName} - ${location?.locationType}`,
        activityName: location?.activityName,
        activityStatus: location?.activityStatus,
        sailingDistance: (location?.sailingDistance).toFixed(2),
        locationActivityStartDate:
          location.estimatedStartTime == null
            ? "unavailable"
            : toReadableDateTime(location?.estimatedStartTime),
        locationActivityEndDate:
          location.estimatedEndDate == null
            ? "unavailable"
            : toReadableDateTime(location?.estimatedEndDate),
      }));

      setVoyageActivities(modifiedDataList || []);

      setDisplayedVoyageActivities(modifiedDataList || []);
      setVoyageActivitiesInProgressListChecker(
        _voyageActivitiesInProgressListChecker
      );
      setLocationOptions(activitiesLocationReduced || []);
      setLoading(false);
    }
  };

  // adds dummy sailings between activities with different locations were the latter is not a sailing
  const addDummySailingsToDataList = (data) => {
    let originalNewData = [];
    data = data.map((e) => {
      if (e) {
        originalNewData.push(e);
      }
      if (
        originalNewData.at(-1)?.locationId !==
        originalNewData.at(-2)?.locationId &&
        originalNewData.at(-1)?.activityName.toLowerCase() !== "sailing" &&
        originalNewData.at(-2)
      ) {
        originalNewData?.splice(
          originalNewData.length - 1,
          0,
          dummySailingObject
        );
      }
      return { ...e };
    });
    return originalNewData;
  };

  // the returnDummySailingStartDate function serves to return the most recent activity estimated end date from the supplied array of voyage activity objects that have indexes lower the dummy sailing. The returned value is set as the estimated start date for the dummy sailing
  const returnDummySailingStartDate = (data, index) => {
    // the spread operator within the array is used to prevent a mutation of the original array
    let modifiedActivitiesArray = [...data]
    // Array.length helps to modify our array by resizing its elements to the value of length
    modifiedActivitiesArray.length = index <= 0 ? 1 : index
    // we use reduce() to compare new values against incoming ones while retaining the object that satisfies our condition
    const activityWithLatestEndDate = modifiedActivitiesArray?.reduce((a, b) => new Date(a.estimatedEndDate) > new Date(b.estimatedEndDate) ? a : b)
    return activityWithLatestEndDate?.estimatedEndDate
  }

  const finalizedVoyageStages = (voyage) => {
    if (
      voyage.voyagePlanningStatus === "Finalized" ||
      voyage.voyagePlanningStatus === "CostAllocated" ||
      voyage.voyagePlanningStatus === "Invoiced"
    )
      return true;
    else return false;
  };

  const overflowMenu = (voyageActivity) => {
    const deleteVoyageActivity = async () => {
      if (voyageActivity) {
        let response = await VoyageActivityService.deleteVoyageActivity(
          voyageActivity.voyageActivityId
        );
        if (response) {
          toastr("success", "Activity removed successfully");
          await init();
        } else {
          toastr("error", "Activity not removed");
        }
      }
      setLoading(false);
    };

    return (
      <ul className="nk-tb-actions gx-2">
        {null && (
          <li className="nk-tb-action-hidden">
            <a
              href="#"
              className="btn btn-sm btn-icon btn-trigger"
              data-toggle="tooltip"
              data-placement="top"
              title="Suspend"
            >
              <em className="icon ni ni-user-cross-fill" />
            </a>
          </li>
        )}
        <li>
          <div className="drodown">
            <a
              href="#"
              className="btn btn-sm btn-icon btn-trigger dropdown-toggle"
              data-toggle="dropdown"
            >
              <em className="icon ni ni-more-h" />
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <ul className="link-list-opt no-bdr">
                <li>
                  <Link
                    to={`/voyage-activity-details/${voyageActivity.voyageActivityId}`}
                  >
                    <em className="icon ni ni-eye" />
                    <span>View Details</span>
                  </Link>
                </li>

                {voyageActivity.activityStatus == "in-progress" ||
                  finalizedVoyageStages(voyage) ||
                  voyageActivitiesInProgressListChecker.length !== 0 ? null : (
                  <>
                    <li>
                      <Link
                        to={`/update-voyage-activity/${voyageActivity.voyageActivityId}`}
                      >
                        <em className="icon ni ni-files" />
                        <span>Update Activity</span>
                      </Link>
                    </li>
                  </>
                )}

                {voyageActivity.activityStatus == "in-progress" ||
                  finalizedVoyageStages(voyage) ? null : (
                  <li>
                    {checkUserPermission(
                      "voyage-planning_delete_activities"
                    ) && (
                        <a
                          style={{ color: "darkred" }}
                          role="button"
                          onClick={deleteVoyageActivity}
                        >
                          <em className="icon ni ni-cross" />
                          <span>Remove Activity</span>
                        </a>
                      )}
                  </li>
                )}
              </ul>
            </div>
          </div>
        </li>
      </ul>
    );
  };

  const startEndButton = (voyageActivity) => {
    if (finalizedVoyageStages(voyage) === false) {
      if (
        voyageActivity &&
        voyageActivity.activityStatus == "not-started" &&
        voyageActivity.allowsParallel == true &&
        checkUserPermission("voyage-planning_start_activities")
      ) {
        return (
          <div>
            <Link to={`/start-voyage-activity/${voyageActivity.voyageActivityId}`}
              className="btn btn-sm btn-success active"
              title="Parallel Activity"
            >
              Start
            </Link>
          </div>
        );
      } else if (
        voyageActivity &&
        voyageActivity.activityStatus == "not-started" &&
        voyageActivity.allowsParallel == false &&
        checkUserPermission("voyage-planning_start_activities")
      ) {
        return (
          <div>
            <Link to={`/start-voyage-activity/${voyageActivity.voyageActivityId}`}
              className="btn btn-sm btn-success active"
              title="Main Activity"
            >
              Start
            </Link>
          </div>
        );
      } else if (
        voyageActivity &&
        voyageActivity.activityStatus == "in-progress" &&
        voyageActivity.allowsParallel == true &&
        checkUserPermission("voyage-planning_end_activities")
      ) {
        return (
          <div>
            <Link to={`/end-voyage-activity/${voyageActivity.voyageActivityId}`}
              className="btn btn-sm btn-danger active"
            >
              End
            </Link>
          </div>
        );
      } else if (
        voyageActivity &&
        voyageActivity.activityStatus == "in-progress" &&
        voyageActivity.allowsParallel == false &&
        checkUserPermission("voyage-planning_end_activities")
      ) {
        return (
          <div>
            <ReactTooltip className="text-center" id="lock">
              This activity will end automatically
              <br /> on start of next main activity
            </ReactTooltip>
            <a
              role="button"
              className="btn btn-sm btn-dim btn-danger disabled"
              onclick="return false"
              data-toggle="tooltip"
              data-placement="top"
              data-original-title="Changes locked"
              data-tip
              data-for="lock"
            >
              End
            </a>
          </div>
        );
      } else if (
        voyageActivity &&
        voyageActivity.activityStatus == "completed"
      ) {
        return (
          <div>
            <a
              role="button"
              className="btn btn-sm btn-dim btn-primary disabled"
              onclick="return false"
            >
              Done
            </a>
          </div>
        );
      }
    } else return null;
  };

  const image = (voyageActivity) => {
    if (voyageActivity.activityName == "Sailing") {
      return <img src={oceanImg} alt="ocean_img" />;
    } else if (
      voyageActivity.locationType == "Port" ||
      voyageActivity.locationType == "Port Terminal"
    ) {
      return <img src={portImg} alt="port_img" />;
    } else {
      return <img src={offshoreImg} alt="offshore_img" />;
    }
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1024, itemsToShow: 4 },
  ];

  const filterByLocation = (id) => {
    setLoading(true);
    let filteredActivities = voyageActivities.filter(
      (activity) => id == activity.locationId
    );
    setDisplayedVoyageActivities(filteredActivities);
    setLoading(false);
    // await loadData(1, null, _terminalId);
  };

  const clearFilter = () => {
    setLoading(true);
    setDisplayedVoyageActivities(voyageActivities);
    setLoading(false);
  };

  const endActivity = async () => {
    const _payload = {
      voyageActivityId: finalMainActivity[0].voyageActivityId,
      endTime: payload.actualEndDate,
    };

    if (!_payload.endTime) {
      toastr("error", "Pick actual end date");
      return;
    }

    setSaving(true);

    const endActivityResponse = await VoyageActivityService.endVoyageActivity(
      _payload
    );
    if (endActivityResponse && endActivityResponse.Success == true) {
      // setRedirectUrl(`/voyage-activities/${voyagePlanningId}`);
      setEndActivityModalIsClosed(true);
      toastr("success", "Voyage activity ended successfully");
      setPayload({});
      await init();
    } else if (
      endActivityResponse &&
      endActivityResponse.data.Success == false
    ) {
      toastr("error", `${endActivityResponse.data.Message[0]}`);
      setPayload({});
      setSaving(false);
    }

    setSaving(false);
  };

  let lastSailingObject = displayedVoyageActivities.slice(-1);

  //adding the  affected installations for PLB and PDB
  let displayedVoyageActivitiesWithInstallation = displayedVoyageActivities.map((info) => ({
    ...info,
    offshoreLocation: sailingLocation.find((location) => location.LocationId === info.installationId)?.LocationName
  }))

  return (
    <>
      {loading ? (
        <div className="w-100 text-center center my-5 py-5">
          <Spinner />
        </div>
      ) : (
        <>
          {voyageActivities.length == 0 ? (
            <div className="w-100 text-center center my-5 py-5">
              No voyage activity records to show
            </div>
          ) : (
            <div className="card-inner p-0">
              <form>
                <span className="preview-title-lg overline-title text-center mb-3">
                  Vessel Voyage View{" "}
                </span>
                <div className="code-block border-gray">
                  <Carousel breakPoints={breakPoints}>
                    {locationOptions.map((voyageActivity, index) => (
                      <a
                        className="code-block border-gray"
                        style={{
                          width: "200px",
                          height: "240px",
                          backgroundColor:
                            voyageActivity.activityStatus === "in-progress"
                              ? "#EDE7F6"
                              : "",
                        }}
                        role="button"
                        onClick={() =>
                          filterByLocation(voyageActivity?.locationId)
                        }
                        key={index}
                      >
                        {voyageActivity.activityName.toLowerCase() !==
                          "sailing" ? (
                          <div
                            className="text-center"
                            key={voyageActivity.locationId}
                          >
                            {voyageActivity.locationIcon}
                            <p>{voyageActivity.locationName}</p>
                            <p>
                              ETA: {voyageActivity.locationActivityStartDate}
                            </p>
                            <p>ETD: {voyageActivity.locationActivityEndDate}</p>
                          </div>
                        ) : (
                          <div
                            className="text-center"
                            key={voyageActivity.locationId}
                          >
                            <div className="gap gap-40px" />
                            {voyageActivity.locationIcon}
                            <p>Sailing</p>
                            <p>Distance: {voyageActivity.sailingDistance}nm</p>
                          </div>
                        )}
                      </a>
                    ))}
                  </Carousel>
                </div>
                <div className="gap" />
                <div className="table code-block border-info  pl-0 pr-0">
                  <div className="text-center">
                    {finalizedVoyageStages(voyage) === true ? null : (
                      <>
                        {(priorActivitiesToSailing.length === 0) && (lastSailingObject[0].activityStatus !== "completed") && (
                          (voyageActivities || []).find(a => a.activityStatus == "not-started") && <Link to={`/start-sailing-voyage-activity/${voyagePlanningId}`}
                            className="btn btn-white btn-outline-light mr-2"
                          >
                            Start Sailing
                          </Link>
                        )}
                        {finalMainActivity.length == 1 &&
                          finalMainActivity[0].activityStatus == "in-progress" &&
                          checkUserPermission(
                            "voyage-planning_end_activities"
                          ) ? (
                          <a
                            role="button"
                            onClick={() => setEndActivityModalIsClosed(false)}
                            className="btn btn-white btn-outline-light mr-2"
                          >
                            End Current Main Activity
                          </a>
                        ) : null}
                      </>
                    )}
                    <a
                      role="button"
                      className="btn btn-white btn-outline-light ml-2"
                      onClick={clearFilter}
                    >
                      Remove Filter
                    </a>
                  </div>
                  <div className="gap" />
                  <VoyageActivitiesListComponent
                    dataList={
                      (displayedVoyageActivitiesWithInstallation || []).map(e => ({
                        // (displayedVoyageActivities || []).map(e => ({
                        ...e,
                        approximateSailingDistance: ((e.sailingDistance && e.sailingDistance > 0) || e.activityName != "Sailing") ? e.approximateSailingDistance : <img style={{ height: "15px" }} className="pointer" title="Unable to determine sailing distance due to incorrect or unavailable coordinates for one or more locations" src={cautionIcon} />
                      }))}
                  />
                </div>
                <div className="gap" />
                <div className="table code-block border-light">
                  <div className="text-center p-2 m-1">
                    <Link to={`/vessel/${voyage.vesselSpecificationId}`}
                      className="p-1 m-1"
                    >
                      <span className="badge badge-pill badge-md badge-light">
                        View Vessel Details
                      </span>
                    </Link>
                    <Link to="/weather-report"
                      className="p-1 m-1"
                    >
                      <span className="badge badge-pill badge-md badge-light ">
                        Weather Information
                      </span>
                    </Link>

                    {
                      voyage?.voyagePlanningStatus == "Completed" && voyageActivities && voyageActivities.length && !voyageActivities.find(a => a.activityStatus != "completed") && checkUserPermission("voyage-planning_finalize_voyage") && <Link to={`/review-voyage/${voyage.voyagePlanningId}`} className="p-1 m-1">
                        <span className="badge badge-pill badge-md badge-danger ">Review Voyage</span>
                      </Link>
                    }
                  </div>
                </div>
                <div className="gap" />
              </form>

            </div>
          )}
        </>
      )}

      {!endActivityModalIsClosed && (
        <CustomModal
          title="End Activity"
          isClosed={endActivityModalIsClosed}
          content={
            <>
              <div className="card-inner p-2">
                <form>
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="fw-last-name">
                          Actual end date / time
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="datetime-local"
                            data-msg="Required"
                            className="form-control required"
                            id="fw-last-name"
                            name="fw-last-name"
                            required=""
                            onChange={(e) =>
                              addPayload(
                                "actualEndDate",
                                convertToUTC(e.target.value)
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <div className="float-right">
                  {saving ? (
                    <div className="form-group p-0 mt-4">
                      <Spinner size="1.5" />
                    </div>
                  ) : (
                    <div className="form-group p-0 mt-4">
                      <button
                        type="button"
                        onClick={() => endActivity()}
                        className="btn btn-sm btn-dark btn-wide"
                      >
                        End Activity
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-link m-1"
                        onClick={() => setEndActivityModalIsClosed(true)}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </>
          }
          onClose={() => setEndActivityModalIsClosed(true)}
        />
      )}
    </>
  );
}
