import React, { useEffect, useState } from 'react';

export default function PersonWithInitial({ name }) {

    const nameInitialStyles = {
        1: "success", 2: "purple", 3: "danger", 4: "dark", 5: "warning"
    }

    const [style, setStyle] = useState("");


    useEffect(() => {
        const styleKey = Math.floor(Math.random() * 5) + 1;
        let _style = nameInitialStyles[styleKey];
        setStyle(_style);

    }, [name]);



    return (
        name && <div className="user-card">
            <div className={`user-avatar xs bg-${style}`}>
                <span>{name && name.substring(0, 2).toUpperCase()}</span>
            </div>
            <div className="user-name">
                <span className="tb-lead">{name}</span>
            </div>
        </div>
    );
}


