import React, { useEffect, useState } from "react";
import MyTable from "../dataTable";
import { numberOrNA, OffshoreInstallationLocationTypes, toReadableDate } from "../../utils/utilityFunctions";
import Spinner from "../../utils/spinner";

export default function VoyageActivityBulkCargoList({ dataList, voyageActivity }) {
  const [tableData, setTableData] = useState(null);
  const [initializing, setInit] = useState(true);



  const CheckActivityType = () => {
    const direction = dataList[0]?.direction?.toLowerCase();
    let expectedActivity = "";
    if (OffshoreInstallationLocationTypes.includes(voyageActivity.locationType)) {
      //we are at offshore installation
      if (direction == "outbound") {
        //cargo is moving from port to installation
        //since we are at offshore now, clearly we are here to deliver the cargo
        expectedActivity = "delivery";
      }
      else if (direction == "inbound") {
        //cargo is moving from installation to port
        //since we are at offshore now, clearly we are here to load the cargo
        expectedActivity = "loading"
      }
      else if (direction == "interfield") {
        //cargo is moving from installation to another installation
        //has the cargo been previously loaded? if yes then we are here to deliver
        //otherwise we are here to load

      }

    } else {
      //at a port
      if (direction == "outbound") {
        //cargo is moving from port to installation
        //since we are at port now, clearly we are here to load the cargo
        expectedActivity = "loading";
      }
      else if (direction == "inbound") {
        //cargo is moving from installation to port
        //since we are at port now, clearly we are here to deliver the cargo
        expectedActivity = "delivery"
      }
      else if (direction == "interfield") {
        //cargo is moving from installation to another installation
        //has the cargo been previously loaded? if yes then we are here to deliver
        //otherwise we are here to load

      }
    }


    const sep = <b className="ml-1 mr-1">|</b>;
    const instruction = <span className="small"><b>Location:</b> {voyageActivity.locationType} {sep} <b>Direction:</b> {direction} {sep} <b>Activity:</b> {expectedActivity}</span>
    return { instruction, direction, expectedActivity }
  }

  const headerListForDelivery = [
    {
      Header: "Cargo",
      accessor: "cargoName",
    },
    {
      Header: "Quantity Loaded",
      accessor: "bulkQuantityInLitres",
    },
    {
      Header: "Quantity Delivered",
      accessor: "BulkQuantityDelivered",
    },
    {
      Header: "ROB",
      accessor: "ROBAfterDelivery",
    },
    {
      Header: "ROB Unit",
      accessor: "quantityUnit",
    },
    {
      Header: "Loaded From",
      accessor: "installationName",
    },
    {
      Header: "",
      accessor: "updateButtonComponent",
    },
  ];
  const headerListForLoading = [
    {
      Header: "Cargo",
      accessor: "cargoName",
    },
    {
      Header: "Quantity Loaded",
      accessor: "bulkQuantityInLitres",
    },
    {
      Header: "ROB",
      accessor: "rob",
    },
    {
      Header: "ROB Unit",
      accessor: "quantityUnit",
    },
    {
      Header: "",
      accessor: "updateButtonComponent",
    },
  ];

  useEffect(async () => {
    setInit(true);

    setTableData((dataList || []).map(e => ({ ...e, ROBAfterDelivery: numberOrNA(e.ROBAfterDelivery) })));
    setInit(false);
  }, [dataList]);






  return (<>
    <h6 className="preview-title-lg overline-title">Bulk Cargos</h6>
    <div
      style={{
        overflow: "auto",
        boxShadow: dataList[0] ? "rgb(221, 221, 221) 2px 2px 5px 0.1px" : "",
        // maxHeight: 400,
        // width: 1024,
      }}
    >
      {initializing && <Spinner />}
      {!initializing && !dataList[0] && <p className="with-caution mt-3">
        There is no booking request with bulk cargos to process at this location
      </p>}

      {!initializing && dataList[0] && (
        <MyTable
          columns={CheckActivityType().expectedActivity == "loading" ? headerListForLoading : headerListForDelivery}
          cssArray={["font-weight-500", "", "", "text-center"]}
          data={tableData || []}
          disablePageSize={true}
          enableSearch={false}
          emptyMessage="Bulk cargo unavailable"
        />
      )}
    </div>

  </>);
}
