import React, { useEffect, useState } from "react";
import MyTable from "../dataTable";
import { toReadableDate } from "../../utils/utilityFunctions";
import BookingRequestService from "../../services/bookingRequestService";
import Spinner from "../../utils/spinner";

export default function EquipmentListComponent({ dataList }) {
  const [tableData, setTableData] = useState(null);
  const [initializing, setInit] = useState(true);

  const headerList = [
    {
      Header: "Owner",
      accessor: "EquipmentOwner",
    },
    {
      Header: "Equipment Type",
      accessor: "EquipmentType",
    },
    {
      Header: "Container Number",
      accessor: "CCUNumber",
    },
    {
      Header: "Seal Number",
      accessor: "SealNo",
    },
    {
      Header: "Quantity",
      accessor: "QuantityOfEquipment",
    },
    {
      Header: "Description",
      accessor: "Description",
    },
    {
      Header: "Estimated Gross Weight",
      accessor: "EstimatedGrossWeight",
    },
    {
      Header: "Full Empty Indication",
      accessor: "FullEmptyIndication",
    },
  ];

  useEffect(async () => {
    setInit(true);

    setTableData(dataList);
    setInit(false);
  }, [dataList, tableData]);

  return (
    <div
      style={{
        overflow: "auto",
        boxShadow: "rgb(221, 221, 221) 2px 2px 5px 0.1px",
        maxHeight: 300,
        width: "100%",
      }}
    >
      {initializing && <Spinner />}
      {!initializing && (
        <MyTable
          columns={headerList}
          cssArray={["font-weight-500", "", "", "text-center"]}
          data={dataList || []}
          disablePageSize={true}
          enableSearch={false}
          emptyMessage="You have not added any equipments records"
        />
      )}
    </div>
  );
}
