import React, { useEffect, useState } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import VoyageService from "../../services/voyageService";
import TerminalActivitiesComponent from "../../components/voyageReporting/terminalActivitiesComponent";
import Tabs from "../../utils/tabs";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../../components/fileInput";
import Spinner from "../../utils/spinner";
import {
  Validator,
  ValidateGroup,
  ClearValidator,
} from "../../components/validator";
import toastr from "../../utils/toastr";
import ReactTooltip from "react-tooltip";
import CustomModal from "../../utils/customModal";
import { camelizeKeys, toReadableDate } from "../../utils/utilityFunctions";
import RemoveBulkListComponent from "../../components/voyage/removeBulkList";
import RemoveDeckListComponent from "../../components/voyage/removeDeckList";
import DeckListComponent from "../../components/voyage/deckList";
import { camelCase, forEach } from "lodash";
import LocationService from "../../services/locationService";

export default function TerminalActivitiesPage() {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({});
  const [saving, setSaving] = useState(false);
  const [bulkCargo, setBulkCargo] = useState([]);
  const [deckCargo, setDeckCargo] = useState([]);
  const [voyage, setVoyage] = useState({});
  const [location, setLocation] = useState({});

  useEffect(async () => {
    setLoading(true);
    await init();
    await getLocation();
    setLoading(false);
  }, [params.locationId]);

  const init = async () => {};

  const getLocation = async () => {
    const _location = await LocationService.get(params.locationId);
    setLocation(camelizeKeys(_location) || []);
  };

  const tabHeaders = [
    // { icon: "icon ni ni-view-grid-wd", text: "Details" },
    // { icon: "icon ni ni-files", text: "Voyage Documents" },
  ];

  const tabContent = [
    <TerminalActivitiesComponent locationId={params.locationId} />,
    // <VoyageDocumentsComponent voyagePlanningId={params.voyagePlanningId} />,
  ];

  const _tabView = (
    <Tabs headers={tabHeaders} contents={tabContent} contentPadding="3" />
  );

  const pageActions = (
    <div className="toggle-wrap nk-block-tools-toggle">
      <a
        href="#"
        className="btn btn-icon btn-trigger toggle-expand mr-n1"
        data-target="pageMenu"
      >
        <em className="icon ni ni-menu-alt-r" />
      </a>
      <div className="toggle-expand-content" data-content="pageMenu">
        <ul className="nk-block-tools g-3">
          <li>
            <button
              onClick={() => window.history.back()}
              className="btn btn-white btn-outline-light"
            >
              <em className="icon ni ni-arrow-left" />
              <span>Back to Locations</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout
      title={
        location.locationName == null
          ? "Activities - Terminal"
          : `Activities - ${location.locationName}`
      }
      loading={loading}
      pageActions={pageActions}
    >
      <div className="">{_tabView}</div>
    </MainLayout>
  );
}
