import React, { useEffect, useState } from "react";
import MyTable from "../dataTable";
import { toReadableDate } from "../../utils/utilityFunctions";
import Spinner from "../../utils/spinner";

export default function WaterUsageTableComponent({ waterConsumptionHistory }) {
	const [tableData, setTableData] = useState(null);
	const [initializing, setInit] = useState(true);

	const headerList = [
		{
			Header: "Log Date",
			accessor: "DateConsumed",
		},
		{
			Header: "Quantity ( in liters )",
			accessor: "Quantity",
		},
	];

	useEffect(async () => {
		setInit(true);

		setTableData(waterConsumptionHistory);
		setInit(false);
	}, [waterConsumptionHistory, tableData]);

	return (
		<div
			className="small-scrollbar"
			style={{
				overflow: "auto",
				boxShadow: "rgb(221, 221, 221) 2px 2px 5px 0.1px",
				maxHeight: 200,
				// width: 1024,
			}}>
			{initializing && <Spinner />}
			{!initializing && (
				<MyTable
					columns={headerList}
					cssArray={["font-weight-500", "", "", "text-center"]}
					data={waterConsumptionHistory || []}
					disablePageSize={true}
					enableSearch={false}
					emptyMessage="No Consumption record"
				/>
			)}
		</div>
	);
}
