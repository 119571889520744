import VoyageTableComponent from '../../components/voyageTableComponent';

import React, { useEffect, useState } from 'react';
import MainLayout from '../../layouts/mainLayout';
import payloadUpdater from "../../utils/payloadUpdater";
import { Validator, ValidateGroup } from "../../components/validator";
import Select from "react-select";
import toastr from '../../utils/toastr';
import VesselService from '../../services/vesselService';
import ZoneService from '../../services/zoneService';
import { Link, useLocation, BrowserRouter as Router } from "react-router-dom";


export default function VoyageHistory() {
    const query = new URLSearchParams(useLocation().search);
    const [voyages, setVoyages] = useState([]);
    const [voyageHistoryCount, setVoyageHistoryCount] = useState(0);
    const [vesselId, setVesselId] = useState(query.get("vesselId"));
    const [vesselMMSI, setVesselMMSI] = useState("");
    const [finalSaveloading, setFinalSaveLoading] = useState(false);

    useEffect(async () => {
        const _vesselId =query.get("vesselId"); 
        setVesselId(_vesselId);
        await init(_vesselId);
        
    }, []);

    const init = async (_vesselId)=>{
        setFinalSaveLoading(true);
        const voyageHistory =  await retrieveVoyages(_vesselId);
        if(!voyageHistory || voyageHistory.length <=0 ){
            await UpdateVoyage(_vesselId);
        }
        
        setFinalSaveLoading(false);
    }

    const retrieveVoyages = async (_vesselId) => {
        const vId = _vesselId || vesselId;
        const voyageList = await VesselService.fetchVoyageHistory(vId);
        if(voyageList  &&   voyageList.length > 0){
            setVesselMMSI(voyageList[0].Mmsi);
            setVoyages(voyageList.map(e=> ({ Timestamp:e.Timestamp, Mmsi:e.Mmsi, Lon:e.Lon, Lat:e.Lat, Course:e.Course, Heading:e.Heading , Speed:e.Speed})));
        }else{
            setVoyages([]);
        }
        await getTotalVoyageCount(vId);
        return voyageList;
    }
    const getVoyages = async (currentPage,PageSize) => {
        
        const voyageList =  await VesselService.fetchVoyageHistory(vesselId,currentPage,PageSize);
       
       var v = voyageList.map(e=> ({ Timestamp:e.Timestamp, Mmsi:e.Mmsi, Lon:e.Lon, Lat:e.Lat, Course:e.Course, Heading:e.Heading , Speed:e.Speed})) || [];

       return v;
    }

    const getTotalVoyageCount = async (_vesselId) => {
        const vId = _vesselId || vesselId;
        const count = await VesselService.countVoyageHistory(vId);
        if(count != null){
            setVoyageHistoryCount(count);
        }
    }


    const UpdateVoyage = async (_vesselId)=>{
        const vId = _vesselId || vesselId;
        var hasFieldErrs = false;
        if(!vId){
            toastr("error", "Valid vessel not detected");
            hasFieldErrs = true;
        }
        
        if(hasFieldErrs) return;
        setFinalSaveLoading(true);
        const vesselAdded = await VesselService.updateVoyageHistory(vId);
        if(!vesselAdded){
            toastr("error", "Voyage could not be updated");
            setFinalSaveLoading(false);
            return;
        }else{
            toastr("success", "Voyage history successfully updated");
            setFinalSaveLoading(false);
            await retrieveVoyages(vId);
        }
    }

    const pageActions = <div className="toggle-wrap nk-block-tools-toggle">
    <Link to={"#"} className="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em className="icon ni ni-menu-alt-r" /></Link>
    <div className="toggle-expand-content" data-content="pageMenu">
        <ul className="nk-block-tools g-3">
            <li><Link to={"#"} className="btn btn-white btn-outline-light"><em className="icon ni ni-download-cloud" /><span>Export</span></Link>
            </li>
            <li className="nk-block-tools-opt">
                <div className="drodown">
                    <Link to={"#"} className="dropdown-toggle btn btn-icon btn-primary" data-toggle="dropdown"><em className="icon ni ni-plus" /></Link>
                    <div className="dropdown-menu dropdown-menu-right">
                        <ul className="link-list-opt no-bdr">
                            <li>
                                <a   onClick={() => UpdateVoyage()} className="pointer" >
                                    <span>
                                        Update Voyage VoyageHistory
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>

    const tableArguments = {
        columns: [{
            label: "Date/Time"
        },
        {
            label: "Port", size: 'md'
        },{
            label: "Lon", size: 'sm'
        },
        {
            label: "Lat", size: 'md'
        },
        {
            label: "Course", size: 'lg'
        },{
            label: "Heading", size: 'lg'
        },
        {
            label: "Speed", size: 'lg'
        }],
        overflowActions: null, rowData: voyages, voyageHistoryCount: voyageHistoryCount,
        callback: getVoyages
    }
    return (
        <MainLayout title="Voyage History" pageActions={pageActions} loading={finalSaveloading}>
            <div className="col">
                <div className="card  card-stretch">
                    {
                        finalSaveloading?<p>Loading voyage history</p>:
                        !finalSaveloading && (!voyages || voyages.length <= 0) ? <p>No voyage history available for this vessel</p>
                        :
                        <VoyageTableComponent {...tableArguments} />
                    }
                    
                </div>{/* .card */}
            </div>{/* .nk-block */}
        </MainLayout>
    );
}
