import React, { useEffect, useState } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import VoyageService from "../../services/voyageService";
import VoyageDetailsComponent from "../../components/voyage/voyageDetailsComponent";
import VoyageDocumentsComponent from "../../components/voyage/voyageDocumentsComponent";
import Tabs from "../../utils/tabs";
import { checkUserPermission, toReadableDate } from "../../utils/utilityFunctions";
import VoyageSailingVisitsComponent from "../../components/voyage/voyageSailingVisitsComponent";
import SailingService from "../../services/sailingService";
import EquipmentService from "../../services/equipmentService";
import LocationService from "../../services/locationService";
import SailingVisitsListComponent from "../../components/sailingSchedule/sailingVisitsList";

export default function VoyageDetailsPage() {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [voyage, setVoyage] = useState([]);
  const [visits, setVisits] = useState([]);
  const [sailingVisitCount, setSailingVisitCount] = useState(null);

  useEffect(async () => {
    await init();
  }, [params.voyagePlanningId]);

  const init = async () => {
    setLoading(true);
    const _voyage = await VoyageService.get(params.voyagePlanningId);
    setVoyage(_voyage || []);
    await retrieveSailingLocations(_voyage.SailingId);
    setLoading(false);
  };

  const retrieveSailingLocations = async (sailingId) => {
    const units = await EquipmentService.listMeasurementUnits();

    const _locations = await LocationService.list(1, 1000);

    const sailingLocations = await SailingService.getSailingLocationBySailing(
      sailingId
    );

    const visitList = (sailingLocations || []).map((e) => ({
      ...e,
      IsBulkRequirement: e.IsBulkRequirement ? "Yes" : "No",
      MeasurementUnit:
        units.find((m) => m.MeasurementUnitId == e.MeasurementUnitId)
          ?.MeasurementUnit || "",
      Location:
        _locations.find((m) => m.LocationId == e.LocationId)?.LocationName ||
        "",
      DayOnLocationFormatted: toReadableDate(e.DayOnLocation),
    }));
    setVisits(visitList || []);
    setSailingVisitCount(sailingLocations.length);
  };

  const tabHeaders = [
    { icon: "icon ni ni-view-grid-wd", text: "Details" },
    { icon: "icon ni ni-tile-thumb", text: "Sailing Visits" },
    { icon: "icon ni ni-files", text: "Voyage Documents" },
  ];

  const tabContent = [
    <VoyageDetailsComponent
      voyagePlanningId={params.voyagePlanningId}
      voyage={voyage}
      sailingVisitCount={sailingVisitCount}
    />,
    <>
      <VoyageSailingVisitsComponent visits={visits} loading={loading} />
    </>,
    <VoyageDocumentsComponent voyagePlanningId={params.voyagePlanningId} />,
  ];

  const _tabView = (
    <Tabs headers={tabHeaders} contents={tabContent} contentPadding="3" />
  );

  const pageActions = (
    <div className="toggle-wrap nk-block-tools-toggle">
     
      <div className="toggle-expand-content" data-content="pageMenu">
        <ul className="nk-block-tools g-3">
          {voyage.length == 0 ||
          voyage.IsLocked == true ||
          voyage.VoyagePlanningStatus === "Finalized" ||
          voyage.VoyagePlanningStatus === "CostAllocated" ||
          voyage.VoyagePlanningStatus === "Invoiced" ? null : (
            <>
             {checkUserPermission("voyage-planning_add_cargo") && ( <li>
                <Link
                  to={`/add-cargo/${params.voyagePlanningId}`}
                  className="btn btn-white btn-outline-light  btn-sm"
                >
                  <em className="icon ni ni-plus" />
                  <span>Add Cargo(s)</span>
                </Link>
              </li>)}
              {checkUserPermission("voyage-planning_remove_cargos") && (
           <li>
                <Link
                  to={`/remove-cargo/${params.voyagePlanningId}`}
                  className="btn btn-white btn-outline-light  btn-sm"
                >
                  <em className="icon ni ni-trash" />
                  <span>Remove Cargo(s)</span>
                </Link>
              </li>)}
            </>
          )}
          {voyage.length == 0 ||
          voyage.VoyagePlanningStatus === "Finalized" ||
          voyage.VoyagePlanningStatus === "CostAllocated" ||
          voyage.VoyagePlanningStatus === "Invoiced" ? null : (
            <li>
              <Link
                to={`/upload-cargo-evidence/${params.voyagePlanningId}`}
                className="btn btn-white btn-outline-light  btn-sm"
              >
                <em className="icon ni ni-files" />
                <span>Upload Cargo Evidence</span>
              </Link>
            </li>
          )}

          <li>
            <Link to="/voyages" className="btn btn-white btn-outline-light btn-sm">
              <em className="icon ni ni-arrow-left" />
              <span>Back to Voyages</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout
      title="Voyage Details"
      loading={loading}
      pageActions={pageActions}
    >
      <div className="shifted-top-tab">{_tabView}</div>
    </MainLayout>
  );
}
