import React, { useEffect, useState } from "react";
import { Navigate, useParams, Link, useNavigate } from "react-router-dom";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../fileInput";
import Spinner from "../../utils/spinner";
import { Validator, ValidateGroup, ClearValidator } from "../validator";
import toastr from "../../utils/toastr";
import VoyageActivityService from "../../services/voyageActivityService";
import VoyageService from "../../services/voyageService";
import LocationService from "../../services/locationService";
import SailingService from "../../services/sailingService";
import {
	extractDetailsFromBulkCargo,
	toReadableDate,
} from "../../utils/utilityFunctions";
import {
	camelizeKeys,
	dynamicSort,
	dateToYYYY_MM_DD,
	dateToYYYY_MM_DD_hh_mm,
	convertToUTC,
	localTimeInYMD,
} from "../../utils/utilityFunctions";
import { forEach } from "lodash";
import CargoHandlingService from "../../services/cargoHandlingService";
import VoyageActivityDeckCargoList from "./voyageActivityDeckCargoList";
import VoyageActivityPortBulkCargoList from "./voyageActivityPortBulkCargo";
import VoyageActivityBulkCargoList from "./voyageActivityBulkCargoList";
import VoyageBulkCargoForPortConsumptionBulk from "./voyageBulkCargoListPortConspBulk";
import UpdateCargoLogsComponent from "./updateCargoLogsComponent";
import CustomModal from "../../utils/customModal";
import moment from "moment";

export default function UpdateVoyageActivityComponent({ voyageActivityId }) {
	const params = useParams();
	const [redirectUrl, setRedirectUrl] = useState(null);
	const [initializing, setInit] = useState(true);
	const [loading, setLoading] = useState(true);
	const [payload, setPayload] = useState({});
	const [saving, setSaving] = useState(false);
	const [locations, setLocations] = useState([]);
	const [updating, setUpdating] = useState(false);
	const [voyageActivity, setVoyageActivity] = useState([]);
	const [voyage, setVoyage] = useState([]);
	const [bulkCargos, setBulkCargos] = useState([]);
	const [deckCargos, setDeckCargos] = useState([]);
	const [loadingBulkCargos, setLoadingBulkCargos] = useState([]);
	const [loadingDeckCargos, setLoadingDeckCargos] = useState([]);
	const [dischargingBulkCargos, setDischargingBulkCargos] = useState([]);
	const [dischargingDeckCargos, setDischargingDeckCargos] = useState([]);
	const [offshoreBulkCargos, setOffshoreBulkCargos] = useState([]);
	const [offshoreDeckCargos, setOffshoreDeckCargos] = useState([]);
	const [modalIsClosed, setModalIsClosed] = useState(true);
	const [selectedRecord, setSelectedRecord] = useState(null);
	const [
		deckCargoHandlingEquipmentSaving,
		setDeckCargoHandlingEquipmentSaving,
	] = useState(false);
	const [payloadForDeckCargoHandling, setPayloadForDeckCargoHandling] =
		useState([]);
	const addPayload = new PayloadUpdater({
		payload: payload,
		setPayload: setPayload,
	}).update;
	const validationGroup = "editactivitytimeline";
	useEffect(async () => {
		await init();
	}, []);

	const init = async (_page, _pageSize) => {
		setInit(true);
		const _voyageActivity = await VoyageActivityService?.getVoyageActivity(
			voyageActivityId
		);
		let voyageActivityData = camelizeKeys(_voyageActivity?.Data || []);
		setPayload(voyageActivityData || []);
		setVoyageActivity(voyageActivityData || []);

		let _voyage = await VoyageService.get(voyageActivityData?.voyagePlanningId);
		setVoyage(_voyage || []);
		await retrieveLocations(_voyage.SailingId);
		const _sailingLocations = await SailingService.getSailingLocationBySailing(
			_voyage?.SailingId
		);

		if (
			voyageActivityData &&
			voyageActivityData.activityName === "Port Loading Bulk cargo"
		) {
			const _loadingActivity = await VoyageActivityService.getPortLoadingBulk(
				voyageActivityData?.voyageActivityId
			);

			if (_loadingActivity) {
				let _loadingBulkCargo = _loadingActivity?.map((cargo) => [
					{
						...extractDetailsFromBulkCargo(
							cargo,
							_sailingLocations,
							voyageActivityId
						),
						updateButtonComponent: endActivityUpdates(cargo),
					},
				]);
				const __data = _loadingBulkCargo.flat(2) || [];
				setLoadingBulkCargos(__data);
				setBulkCargos(__data);
			}
		}

		if (
			voyageActivityData &&
			voyageActivityData.activityName === "Port Loading Deck cargo"
		) {
			const _loadingActivity =
				await CargoHandlingService.getPortLoadingBookingEquipmentForDeckCargos(
					voyageActivityData.voyagePlanningId,
					voyageActivityData.locationId
				);

			if (_loadingActivity) {
				const _savedNumberOfLifts =
					await CargoHandlingService.getNumberOfLiftsForBookingEquipmentByVoyageActivity(
						voyageActivityId
					);

				const _deckCargoEquipment = _loadingActivity?.map((equipment) => ({
					...equipment,
					label:
						equipment.CargoCarryingEquipmentType.toLowerCase() ===
						"offshore container"
							? `${equipment?.BookingreferenceNo} - ${equipment?.CargoCarryingEquipmentType} - ${equipment?.CCUNumber}`
							: `${equipment?.BookingreferenceNo} - ${equipment?.CargoCarryingEquipmentType} - ${equipment?.Description}`,
					numberOfLifts:
						_savedNumberOfLifts.length > 0
							? _savedNumberOfLifts.find(
									(activity) =>
										activity.BookingEquipmentDetailId ===
										equipment.BookingEquipmentDetailId
							  ).NumberOfLifts
							: 0,
				}));

				const _deckCargoEquipmentHandlingPayload = _deckCargoEquipment.map(
					(equipment) => ({
						bookingEquipmentDetailId: equipment.BookingEquipmentDetailId,
						label: equipment.label,
						operatorName: equipment.OperatorName,
						offshoreInstallation: equipment.OffshoreInstallationName || "",
						voyageActivityId: voyageActivityId,
						numberOfLifts: equipment.numberOfLifts,
					})
				);
				setPayloadForDeckCargoHandling(_deckCargoEquipmentHandlingPayload);
			}
		}

		if (
			voyageActivityData &&
			voyageActivityData.activityName === "Port Discharging Bulk cargo"
		) {
			const _dischargeActivity =
				await VoyageActivityService.getPortDischargingBulk(
					voyageActivityData?.voyageActivityId
				);

			if (_dischargeActivity) {
				let _dischargingBulkCargo = _dischargeActivity?.map((cargo) => [
					{
						...extractDetailsFromBulkCargo(
							cargo,
							_sailingLocations,
							voyageActivityId
						),
						updateButtonComponent: endActivityUpdates(cargo),
					},
				]);
				setDischargingBulkCargos(_dischargingBulkCargo.flat(2) || []);
				setBulkCargos(_dischargingBulkCargo.flat(2) || []);
			}
		}

		if (
			voyageActivityData &&
			voyageActivityData.activityName === "Port Discharging Deck cargo"
		) {
			let _dischargeActivity =
				await CargoHandlingService.getPortDischargingBookingEquipmentForDeckCargos(
					voyageActivityData.voyagePlanningId,
					voyageActivityData.locationId
				);

			if (_dischargeActivity) {
				const _savedNumberOfLifts =
					await CargoHandlingService.getNumberOfLiftsForBookingEquipmentByVoyageActivity(
						voyageActivityId
					);

				const _deckCargoEquipment = _dischargeActivity?.map((equipment) => ({
					...equipment,
					label:
						equipment.CargoCarryingEquipmentType.toLowerCase() ===
						"offshore container"
							? `${equipment?.BookingreferenceNo} - ${equipment?.CargoCarryingEquipmentType} - ${equipment?.CCUNumber}`
							: `${equipment?.BookingreferenceNo} - ${equipment?.CargoCarryingEquipmentType} - ${equipment?.Description}`,
					numberOfLifts:
						_savedNumberOfLifts.length > 0
							? _savedNumberOfLifts.find(
									(activity) =>
										activity.BookingEquipmentDetailId ===
										equipment.BookingEquipmentDetailId
							  ).NumberOfLifts
							: 0,
				}));

				const _deckCargoEquipmentHandlingPayload = _deckCargoEquipment.map(
					(equipment) => ({
						bookingEquipmentDetailId: equipment.BookingEquipmentDetailId,
						label: equipment.label,
						operatorName: equipment.OperatorName,
						offshoreInstallation: equipment.OffshoreInstallationName || "",
						voyageActivityId: voyageActivityId,
						numberOfLifts: equipment.numberOfLifts,
					})
				);
				setPayloadForDeckCargoHandling(_deckCargoEquipmentHandlingPayload);
			}
		}

		if (
			voyageActivityData &&
			voyageActivityData.activityName == "Offshore Handling Bulk cargo"
		) {
			const _offshoreActivity = await CargoHandlingService.getOffshoreCargos(
				voyageActivityData.voyagePlanningId,
				voyageActivityData.locationId
			);

			if (_offshoreActivity) {
				let _offshoreBulkCargo = _offshoreActivity?.BulkCargos.map((cargo) => [
					{
						...extractDetailsFromBulkCargo(
							cargo,
							_sailingLocations,
							voyageActivityId
						),
						updateButtonComponent: endActivityUpdates(cargo),
					},
				]);
				setOffshoreBulkCargos(_offshoreBulkCargo.flat(2) || []);
				setBulkCargos(_offshoreBulkCargo.flat(2) || []);
			}
		}

		if (
			voyageActivityData &&
			voyageActivityData.activityName == "Port Loading Consumption bulk"
		) {
			const _offshoreActivity = await CargoHandlingService.getOffshoreCargos(
				voyageActivityData.voyagePlanningId,
				voyageActivityData.locationId
			);

			if (_offshoreActivity) {
				let _offshoreBulkCargo = _offshoreActivity?.BulkCargos.map((cargo) => [
					{
						...extractDetailsFromBulkCargo(
							cargo,
							_sailingLocations,
							voyageActivityId
						),
						updateButtonComponent: endActivityUpdates(cargo),
					},
				]);
				setOffshoreBulkCargos(_offshoreBulkCargo.flat(2) || []);
				setBulkCargos(_offshoreBulkCargo.flat(2) || []);
			}
		}

		if (
			voyageActivityData &&
			voyageActivityData.activityName == "Offshore Handling Deck cargo"
		) {
			let _offshoreActivity =
				await CargoHandlingService.getOffshoreHandlingBookingEquipmentForDeckCargos(
					voyageActivityData.voyagePlanningId,
					voyageActivityData.locationId
				);

			if (_offshoreActivity) {
				const _savedNumberOfLifts =
					await CargoHandlingService.getNumberOfLiftsForBookingEquipmentByVoyageActivity(
						voyageActivityId
					);

				const _deckCargoEquipment = _offshoreActivity?.map((equipment) => ({
					...equipment,
					label:
						equipment.CargoCarryingEquipmentType.toLowerCase() ===
						"offshore container"
							? `${equipment?.BookingreferenceNo} - ${equipment?.CargoCarryingEquipmentType} - ${equipment?.CCUNumber}`
							: `${equipment?.BookingreferenceNo} - ${equipment?.CargoCarryingEquipmentType} - ${equipment?.Description}`,
					numberOfLifts:
						_savedNumberOfLifts.length > 0
							? _savedNumberOfLifts.find(
									(activity) =>
										activity.BookingEquipmentDetailId ===
										equipment.BookingEquipmentDetailId
							  ).NumberOfLifts
							: 0,
				}));

				const _deckCargoEquipmentHandlingPayload = _deckCargoEquipment.map(
					(equipment) => ({
						bookingEquipmentDetailId: equipment.BookingEquipmentDetailId,
						label: equipment.label,
						operatorName: equipment.OperatorName,
						offshoreInstallation: equipment.OffshoreInstallationName || "",
						voyageActivityId: voyageActivityId,
						numberOfLifts: equipment.numberOfLifts,
					})
				);
				setPayloadForDeckCargoHandling(_deckCargoEquipmentHandlingPayload);
			}
		}

		setInit(false);
	};

	const retrieveLocations = async (sailingId) => {
		const _locations = await LocationService.list(1, 1000);

		const _sailingLocations = await SailingService.getSailingLocationBySailing(
			sailingId
		);

		const _sailingLocationIds = _sailingLocations.map(
			(location) => location.LocationId
		);

		let _filteredLocations = (_locations || []).filter(
			(location) =>
				location.LocationTypeName.toLowerCase() == "port" ||
				location.LocationTypeName.toLowerCase() == "port terminal" ||
				_sailingLocationIds.includes(location.LocationId)
		);

		let _requiredLocations = _filteredLocations.map((e) => ({
			...e,
			label: e.LocationTypeName
				? e.LocationName + " - " + e.LocationTypeName
				: e.LocationName + " - " + e.LocationType,
			value: e.LocationId,
		}));
		_requiredLocations = [...new Set(_requiredLocations)];

		setLocations(_requiredLocations || []);
	};

	const editCallback = async () => {
		onModalClose();
		await init();
	};

	const onModalClose = () => {
		setModalIsClosed(true);
	};

	const endActivityUpdates = (cargo) => {
		return (
			<div>
				<a
					role="button"
					className="btn btn-sm btn-warning active"
					onClick={() => {
						setModalIsClosed(false);
						setSelectedRecord(cargo);
					}}>
					Update
				</a>
			</div>
		);
	};

	const navigate = useNavigate();

	const updateActivityTimeline = async () => {
		let _payload = { ...payload };

		let _plannedStartDate = convertToUTC(_payload.plannedStartDate);
		let _endDate = convertToUTC(_payload.endDate);
		let _actualStartDate = convertToUTC(_payload.actualStartDate);
		let _actualEndDate = convertToUTC(_payload.actualEndDate);
		let _expectedDateOfDeparture = convertToUTC(voyage.ExpectedDateOfDeparture);
		let _expectedDateOfArrival = convertToUTC(voyage.ExpectedDateOfArrival);

		// if (!_payload.actualStartDate) {
		//   toastr("error", "Select start date");
		//   return;
		// }

		// if (!_payload.actualEndDate) {
		//   toastr("error", "Select end date");
		//   return;
		// }

		if (
			_payload.activityStatus === "not-started" &&
			_payload.plannedStartDate
		) {
			_payload.estimatedStartTime = convertToUTC(_payload.plannedStartDate);
		}

		if (_payload.activityStatus === "not-started" && _payload.endDate) {
			_payload.estimatedEndDate = convertToUTC(_payload.endDate);
		}

		if (_payload.activityStatus === "completed" && _payload.actualStartDate) {
			_payload.estimatedStartTime = convertToUTC(_payload.actualStartDate);
		}

		if (_payload.activityStatus === "completed" && _payload.actualEndDate) {
			_payload.estimatedEndDate = convertToUTC(_payload.actualEndDate);
		}

		setSaving(true);

		const response = await VoyageActivityService.editVoyageActivityAndETA(
			_payload
		);
		if (response) {
			setPayload({});
			toastr("success", "Voyage activity updated");
			// setRedirectUrl(`/voyage-activities/${payload.voyagePlanningId}`);
			// await init();
			navigate(-1);
		} else {
			toastr("error", "Voyage activity not updated");
			setSaving(false);
		}

		setSaving(false);
	};

	const isDeckCargoHandlingActivity = (activity) => {
		if (
			activity.toLowerCase() === "port loading deck cargo" ||
			activity.toLowerCase() === "port discharging deck cargo" ||
			activity.toLowerCase() === "offshore handling deck cargo"
		) {
			return true;
		} else {
			return false;
		}
	};

	const submitCargoHandlingForm = async (e) => {
		e.preventDefault();
		const payload = payloadForDeckCargoHandling?.map((item) => ({
			bookingEquipmentDetailId: item.bookingEquipmentDetailId,
			voyageActivityId: item.voyageActivityId,
			numberOfLifts: item.numberOfLifts,
		}));
		const _payload = [...payload];

		setDeckCargoHandlingEquipmentSaving(true);
		const response =
			await CargoHandlingService.postNumberOfLiftsForDeckCargoBookingEquipment(
				_payload
			);
		if (response) {
			toastr("success", "Number of lifts updated successfully");
			await init();
		} else {
			toastr("error", "Number of lifts not updated");
			setDeckCargoHandlingEquipmentSaving(false);
			return;
		}
		setDeckCargoHandlingEquipmentSaving(false);
	};

	return redirectUrl ? (
		<Navigate to={redirectUrl} replace={true} />
	) : (
		<>
			{initializing ? (
				<div className="w-100 text-center center my-5 py-5">
					<Spinner />
				</div>
			) : (
				<div className="">
					{/* <div className="gap gap-20px" />  */}
					<form>
						<div className="card-inner ml-3 mt-2">
							<div className="row">
								<div className="col-md-12">
									<div className="form-group mb-5">
										<label
											className="form-label"
											style={{ fontSize: "1.15rem" }}>
											Activity name:{" "}
											<span className="fw-normal">
												{voyageActivity?.activityName}
											</span>
										</label>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-3">
									<div className="form-group">
										<label className="form-label" htmlFor="fw-last-name">
											Planned start date / time
										</label>
										<div className="form-control-wrap">
											<input
												type="datetime-local"
												data-msg="Required"
												className="form-control required"
												id="fw-last-name"
												name="fw-last-name"
												required=""
												value={dateToYYYY_MM_DD_hh_mm(
													payload?.plannedStartDate
												)}
												onChange={(e) =>
													addPayload(
														"plannedStartDate",
														moment.utc(e.target.value).format()
													)
												}
												disabled={
													voyageActivity.activityStatus !== "not-started"
														? true
														: false
												}
											/>
										</div>
									</div>
								</div>

								<div className="col-md-3">
									<div className="form-group">
										<label className="form-label" htmlFor="fw-last-name">
											Planned end date / time
										</label>
										<div className="form-control-wrap">
											<input
												type="datetime-local"
												data-msg="Required"
												className="form-control required"
												id="fw-last-name"
												name="fw-last-name"
												required=""
												value={dateToYYYY_MM_DD_hh_mm(payload?.endDate)}
												onChange={(e) =>
													addPayload("endDate", convertToUTC(e.target.value))
												}
												disabled={
													voyageActivity.activityStatus !== "not-started"
														? true
														: false
												}
											/>
										</div>
									</div>
								</div>
								<div className="col-md-3" style={{ zIndex: 7 }}>
									<div className="form-group">
										<label className="form-label" htmlFor="fw-last-name">
											Activity location
										</label>
										<div className="form-control-wrap">
											<Select
												onChange={(item) =>
													addPayload(["locationId"], [item.value])
												}
												value={locations.find(
													(a) => a.value == payload?.locationId
												)}
												options={locations}
												placeholder="select location"
											/>
										</div>
									</div>
								</div>
							</div>
							{voyageActivity.activityStatus !== "completed" ? null : (
								<div className="row mt-4">
									<div className="col-md-3">
										<div className="form-group">
											<label className="form-label" htmlFor="fw-last-name">
												Actual start date / time
											</label>
											<div className="form-control-wrap">
												<input
													type="datetime-local"
													data-msg="Required"
													className="form-control required"
													id="fw-last-name"
													name="fw-last-name"
													required=""
													value={dateToYYYY_MM_DD_hh_mm(
														payload?.actualStartDate
													)}
													onChange={(e) =>
														addPayload(
															"actualStartDate",
															convertToUTC(e.target.value)
														)
													}
													disabled={
														voyageActivity.activityStatus !== "completed"
															? true
															: false
													}
												/>
											</div>
										</div>
									</div>
									<div className="col-md-3">
										<div className="form-group">
											<label className="form-label" htmlFor="fw-last-name">
												Actual end date / time
											</label>
											<div className="form-control-wrap">
												<input
													type="datetime-local"
													data-msg="Required"
													className="form-control required"
													id="fw-last-name"
													name="fw-last-name"
													required=""
													value={dateToYYYY_MM_DD_hh_mm(payload?.actualEndDate)}
													onChange={(e) =>
														addPayload(
															"actualEndDate",
															convertToUTC(e.target.value)
														)
													}
													disabled={
														voyageActivity.activityStatus !== "completed"
															? true
															: false
													}
												/>
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
					</form>

					<div className="card-inner">
						{isDeckCargoHandlingActivity(voyageActivity?.activityName) && (
							<>
								{(!payloadForDeckCargoHandling ||
									!payloadForDeckCargoHandling.length) && (
									<p className="with-caution">
										There are no booking requests with deck cargo to process at
										this location.
									</p>
								)}
								{payloadForDeckCargoHandling.length !== 0 && (
									<>
										<div className="px-4 mt-4">
											<span className="preview-title-lg overline-title">
												Enter Number Of Lifts
											</span>
										</div>
										<form onSubmit={submitCargoHandlingForm}>
											<div className="card-inner">
												{payloadForDeckCargoHandling?.map(
													(equipment, index) => (
														<div key={index}>
															<>
																<div className="row">
																	<div className="col-md-3">
																		<div className="form-group">
																			<label className="form-label small">
																				Booking Equipment
																			</label>
																			<div className="form-control-wrap">
																				<input
																					type="text"
																					className="form-control"
																					value={equipment?.label}
																					disabled
																				/>
																			</div>
																		</div>
																	</div>

																	<div className="col-md-3">
																		<div className="form-group">
																			<label className="form-label small">
																				Operator
																			</label>
																			<div className="form-control-wrap">
																				<input
																					type="text"
																					className="form-control"
																					value={equipment?.operatorName}
																					disabled
																				/>
																			</div>
																		</div>
																	</div>

																	<div className="col-md-3">
																		<div className="form-group">
																			<label className="form-label small">
																				Offshore Installation
																			</label>
																			<div className="form-control-wrap">
																				<input
																					type="text"
																					className="form-control"
																					value={
																						equipment?.offshoreInstallation
																					}
																					disabled
																				/>
																			</div>
																		</div>
																	</div>

																	<div className="col-md-3">
																		<div className="form-group ">
																			<label className="form-label small">
																				Number of Lifts
																			</label>
																			<div className="form-control-wrap">
																				<input
																					type="number"
																					className="form-control"
																					defaultValue={
																						equipment?.numberOfLifts
																					}
																					required
																					step="any"
																					onChange={(e) => {
																						setPayloadForDeckCargoHandling(
																							(prev) => {
																								prev[index].numberOfLifts =
																									e.target.value;
																								return prev;
																							}
																						);
																					}}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className="gap" />
																</div>
															</>
														</div>
													)
												)}
											</div>

											{deckCargoHandlingEquipmentSaving && (
												<div className="">
													<div className="form-group px-2 m-3">
														<button
															type="button"
															className="btn btn-md btn-link m-1">
															<Spinner size="1.5" />
														</button>
													</div>
												</div>
											)}
											{!deckCargoHandlingEquipmentSaving && (
												<div className="">
													<div className="form-group px-2 m-3">
														<button
															type="submit"
															className="btn btn-md btn-dark btn-wide px-5 mr-3">
															Update Lifts
														</button>
													</div>
												</div>
											)}
										</form>
									</>
								)}
							</>
						)}
						{/* {voyageActivity?.activityName !==
            "Port Loading Bulk cargo" ? null : (
              <>
            <div className="gap gap-20px" />
                <div className="table code-block border-lighter">
                <span className="preview-title-lg overline-title">
                  Bulk Cargos to load at port{" "}
                </span>
                <VoyageActivityBulkCargoList dataList={loadingBulkCargos} />
              </div>
              </>
            )} */}

						{voyageActivity?.activityName !==
						"Offshore Handling Bulk cargo" ? null : (
							<>
								<div className="gap gap-20px" />
								<div className="table code-block border-lighter">
									<VoyageActivityBulkCargoList
										dataList={offshoreBulkCargos}
										voyageActivity={voyageActivity}
									/>
								</div>
							</>
						)}
						{voyageActivity?.activityName !==
						"Port Loading Bulk cargo" ? null : (
							// <>
							// 	<div className="gap gap-20px" />
							// 	<div className="table code-block border-lighter">
							// 		<VoyageActivityBulkCargoList
							// 			dataList={offshoreBulkCargos}
							// 			voyageActivity={voyageActivity}
							// 		/>
							// 	</div>
							// </>
							<>
								<div className="gap gap-20px" />
								<div className="table code-block border-lighter">
									<VoyageActivityPortBulkCargoList
										dataList={loadingBulkCargos}
										voyageActivity={voyageActivity}
									/>
								</div>
							</>
						)}
						{voyageActivity?.activityName !==
						"Port Loading Consumption bulk" ? null : (
							<>
								<div className="gap gap-20px" />
								<div className="table code-block border-lighter">
									<span className="preview-title-lg overline-title">
										Bulk Cargos loaded at Port{" "}
									</span>
									<VoyageBulkCargoForPortConsumptionBulk
										dataList={offshoreBulkCargos}
										voyageActivityId={voyageActivityId}
									/>
								</div>
							</>
						)}

						{voyageActivity?.activityName !==
						"Port Discharging Bulk cargo" ? null : (
							<>
								<div className="gap gap-20px" />
								<div className="table code-block border-lighter">
									<VoyageActivityBulkCargoList
										dataList={dischargingBulkCargos}
										voyageActivity={voyageActivity}
									/>
								</div>
							</>
						)}
						{/* <hr className="preview-hr" /> */}
					</div>

					<hr className="preview-hr" />

					<div className="card-inner float-right">
						{saving ? (
							<div className="form-group">
								<Spinner size="1.5" />
							</div>
						) : (
							<div className="form-group">
								<button
									type="button"
									onClick={() => updateActivityTimeline()}
									className="btn btn-md btn-dark btn-wide px-5 mr-3">
									Save
								</button>
								<button
									type="button"
									className="btn btn-sm btn-link m-1"
									onClick={() => window.history.back()}>
									Cancel
								</button>
							</div>
						)}
					</div>

					{!modalIsClosed && selectedRecord && voyageActivity && (
						<CustomModal
							title="Update Cargo Logs"
							content={
								<>
									<UpdateCargoLogsComponent
										cargo={selectedRecord}
										callback={editCallback}
										activityName={voyageActivity.activityName}
										voyageActivityId={params.voyageActivityId}
										selectedBulkCargoDetailId={
											selectedRecord?.BulkCargoDetailId || null
										}
										selectedDeckCargoDetailId={
											selectedRecord?.DeckCargoDetailId || null
										}
									/>
								</>
							}
							onClose={() => setSelectedRecord(null)}
							isClosed={modalIsClosed}
						/>
					)}
				</div>
			)}
		</>
	);
}
