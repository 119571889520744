import React, { useEffect, useState } from "react";
import MyTable from "../dataTable";
import { currencyFormat, toReadableDate } from "../../utils/utilityFunctions";
import BookingRequestService from "../../services/bookingRequestService";
import Spinner from "../../utils/spinner";

export default function DeckCargoListComponent({ dataList }) {
  const [tableData, setTableData] = useState(null);
  const [initializing, setInit] = useState(true);

  const headerList = [
    {
      Header: "Booking Equipment",
      accessor: "BookingEquipmentDescription",
    },
    {
      Header: "Serial Number",
      accessor: "SerialNumber",
    },
    {
      Header: "Gross Weight",
      accessor: "GrossWeightComponent",
    },
    {
      Header: "Net Weight",
      accessor: "NetWeightComponent",
    },
    {
      Header: "Measurement",
      accessor: "MeasurementComponent",
    },
    {
      Header: "UN Number",
      accessor: "UNNumber",
    },
    {
      Header: "DG Classification",
      accessor: "DGClassification",
    },
    {
      Header: "Subsidiary Risks",
      accessor: "SubsidiaryRisk",
    },
    {
      Header: "MARPOL Pollution",
      accessor: "MARPOLPollutionCode",
    },
    {
      Header: "Customs Status",
      accessor: "CustomsStatus",
    },
    {
      Header: "Goods Description",
      accessor: "DescriptionOfGoods",
    },
    {
      Header: "HS Code",
      accessor: "HSCode",
    },
    {
      Header: "Number of Packages",
      accessor: "NumberOfPackages",
    },
    {
      Header: "IMO Hazard Class",
      accessor: "IMOHazardClass",
    },
    {
      Header: "Packing Group",
      accessor: "PackingGroup",
    },
    {
      Header: "Flashpoint",
      accessor: "Flashpoint",
    },
    {
      Header: "EMS",
      accessor: "EmS",
    },
  ];

  useEffect(async () => {
    setInit(true);

    const _data = (dataList || []).map(e=> ({...e, GrossWeightComponent: `${currencyFormat(e.GrossWeightValue,0)}${e.GrossWeightUnit}`,
    NetWeightComponent: `${currencyFormat(e.NetWeightValue,0)}${e.NetWeightUnit}`,
    MeasurementComponent: `${currencyFormat(e.MeasurementValue,0)}${e.MeasurementValueUnit}` }))

    setTableData(_data);
    setInit(false);
  }, [dataList]);

  return (
    <div
      style={{
        overflow: "auto",
        boxShadow: "rgb(221, 221, 221) 2px 2px 5px 0.1px",
        maxHeight: 300,
        width: "100%",
      }}
    >
      {initializing && <Spinner />}
      {!initializing && (
        <MyTable
          columns={headerList}
          cssArray={["font-weight-500", "", "", "text-center"]}
          data={tableData || []}
          disablePageSize={true}
          enableSearch={false}
          emptyMessage="You have not added any deck cargo records"
        />
      )}
    </div>
  );
}
