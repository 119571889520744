import React, { useEffect, useState } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import BookingRequestService from "../../services/bookingRequestService";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../fileInput";
import Spinner from "../../utils/spinner";
import { Validator, ValidateGroup, ClearValidator } from "../validator";
import toastr from "../../utils/toastr";
import VoyageActivityService from "../../services/voyageActivityService";
import ReactTooltip from "react-tooltip";
import EquipmentService from "../../services/equipmentService";
import CustomModal from "../../utils/customModal";
import { camelizeKeys, currencyFormat, toDurationString, toReadableDate } from "../../utils/utilityFunctions";
import { forEach } from "lodash";

export default function VoyageActivityDetailsComponent({ voyageActivityId }) {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [initializing, setInit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [payload, setPayload] = useState({});
  const [saving, setSaving] = useState(false);
  const [voyageActivity, setVoyageActivity] = useState([]);

  useEffect(async () => {
    await init();
  }, []);

  const init = async () => {
    setInit(true);
    const _voyageActivity = await VoyageActivityService.getVoyageActivity(
      voyageActivityId
    );
    let modifiedDataList = camelizeKeys(_voyageActivity?.Data || []);
    let dataList = [];
    dataList.push({ ...modifiedDataList });
    let voyageActivityData = dataList.map((voyageActivity) => ({
      ...voyageActivity,
      plannedStartDate: toReadableDate(voyageActivity.plannedStartDate),
      endDate: toReadableDate(voyageActivity.endDate),
      exactStartDate: toReadableDate(voyageActivity.actualStartDate),
      exactEndDate: toReadableDate(voyageActivity.actualEndDate),
    }));
    setVoyageActivity(voyageActivityData || []);

    setInit(false);
  };

  const dataItem = (label, value, isWide) => {
    if (value == "null") value = "";
    return (
      <li className={`col-${isWide?"12":"6"} col-lg-${isWide?"6":"3"}`}>
        <p>
          <span className="text-soft small">{label}</span> {value || "-"}
        </p>
      </li>
    );
  };

  const affirmStatus = { true: "Yes", false: "No" };

  return (
    <>
      {initializing ? (
        <div className="w-100 text-center center my-5 py-5">
          <Spinner />
        </div>
      ) : (
        <div className="card sp-plan">
          <div className="row no-gutters">
            <div className="col-md-8">
              <div className="sp-plan-info card-inner">
                <div className="row gx-0 gy-3">
                  <div className="col-xl-9 col-sm-8">
                    <div className="sp-plan-name">
                      <h6 className="title">
                        <Link to={"#"}>
                          {voyageActivity[0].activityName}
                          {voyageActivity[0].activityStatus && (
                            <span className="badge badge-light badge-pill ml-3">
                              {voyageActivity[0].activityStatus}
                            </span>
                          )}
                        </Link>
                      </h6>
                      <p style={{ visibility: "hidden" }}>
                        '': <span className="text-base">{"-"}</span>
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-3 col-sm-4"></div>
                </div>
              </div>
              <div className="sp-plan-desc card-inner">
                <p>
                  <span className="preview-title-lg overline-title">
                    Voyage Activity Details
                  </span>{" "}
                </p>
                <ul className="row gx-1">
                  {dataItem(
                    "Activity Category",
                    voyageActivity[0].activityCategory
                  )}
                  {dataItem(
                    "Planned Start Date",
                    voyageActivity[0].plannedStartDate
                  )}
                  {dataItem("Planned End Date", voyageActivity[0].endDate)}
                  {dataItem(
                    "Parallel Activities Allowed",
                    affirmStatus[voyageActivity[0].allowsParallel]
                  )}
                  {dataItem(
                    "Actual Start Date",
                    voyageActivity[0].exactStartDate
                  )}
                  {dataItem(
                    "Actual End Date",
                    voyageActivity[0].exactEndDate || "-"
                  )}
                  {dataItem(
                    "Activity Duration",
                    toDurationString(voyageActivity[0].durationOfActivity) || "-"
                  )}
                  {dataItem(
                    "Activity Description",
                    voyageActivity[0].activityDescription, true
                  )}
                </ul>

                {/* <button
                    // onClick={() => setModalIsClosed(false)}
                    type="button"
                    className="mt-4 btn btn-dark btn-dim btn-sm "
                >
                    <span>Edit Voyage Details</span>
                </button> */}

                <hr style={{ opacity: "0.25" }} />
              </div>
            </div>
            <br />
          </div>
        </div>
      )}
      {/* {!modalIsClosed && <CustomModal title="Edit Booking Request" isClosed={modalIsClosed}
                content={<EditVesselDetailsComponent bookingRequestId={bookingRequestId} callback={() => onModalClose()} />} onClose={() => onModalClose()} />} */}
    </>
  );
}
