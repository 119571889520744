import React, { useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import VesselService from "../../services/vesselService";
import CommercialService from "../../services/commercialService";
import Select from "react-select";
import Spinner from "../../utils/spinner";
import toastr from "../../utils/toastr";
import DataTable from "../../components/dataTable";
import Paginate from "../../components/paginate";
import { exportToCSV } from "../../utils/exportCSV";
import {
  toReadableDate,
  toReadableDateTime,
} from "../../utils/utilityFunctions";
import ReactTooltip from "react-tooltip";
import BookingTable from "../../components/bookingTable";
import { checkUserPermission } from "../../utils/utilityFunctions";
import PartyService from "../../services/partyService";
import CustomModal from "../../utils/customModal";

export default function CommercialListPage() {
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState([10, 20, 50, 100, 150]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [totalRecords, setTotalRecords] = useState(10);
  const [filter, setFilter] = useState(null);
  const [vessels, setVessels] = useState([]);
  const [parties, setParties] = useState([]);
  const [commercials, setCommercials] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [vesselSpecificationId, setVesselSpecificationId] = useState("");
  const [partyId, setPartyId] = useState("");
  const [downloadPopUp, setDownloadPopUp] = useState(false);
  const [downloadStartDate, setDownloadStartDate] = useState("");
  const [downloadEndDate, setDownloadEndDate] = useState("");

  useEffect(async () => {
    await init();
  }, []);

  const init = async () => {
    setLoading(true);
    let _vessels = await VesselService.listAllVessels();
    _vessels = _vessels?.map((c) => ({
      ...c,
      label: c.VesselName,
      value: c.VesselSpecificationId,
    }));
    setVessels(_vessels || []);

    let _parties = await PartyService.listParty();
    _parties = _parties?.map((c) => ({
      ...c,
      label: c.PartyName,
      value: c.PartyId,
    }));
    setParties(_parties || []);

    await loadData();

    setLoading(false);
  };

  const loadData = async (
    _page,
    _pageSize,
    _startDate = "",
    _endDate = "",
    _vesselSpecificationId = "",
    _partyId = ""
  ) => {
    setLoading(true);
    _page = _page || page;
    _pageSize = _pageSize || pageSize;

    const _commercials = await CommercialService.searchCommercial(
      _page,
      _pageSize,
      _startDate,
      _endDate,
      _vesselSpecificationId,
      _partyId
    );
    let modifiedDataList = _commercials?.Data || [];

    setTotalRecords(_commercials?.TotalCount || 0);
    modifiedDataList = modifiedDataList.map((l) => ({
      ...l,
      StartDate: toReadableDateTime(l.ContractStartDate),
      EndDate: toReadableDateTime(l.ContractEndDate),
      actionsComponent: overflowMenu(l),
    }));

    setCommercials(modifiedDataList);

    page != _page && setPage(_page);
    pageSize != _pageSize && setPageSize(_pageSize);
    setStartDate(_startDate || "");
    setEndDate(_endDate || "");
    setVesselSpecificationId(_vesselSpecificationId || "");
    setPartyId(_partyId || "");

    setLoading(false);
  };

  const overflowMenu = (commercial) => {
    return (
      <ul className="nk-tb-actions gx-2">
        {null && (
          <li className="nk-tb-action-hidden">
            <a
              href="#"
              className="btn btn-sm btn-icon btn-trigger"
              data-toggle="tooltip"
              data-placement="top"
              title="Suspend"
            >
              <em className="icon ni ni-user-cross-fill" />
            </a>
          </li>
        )}
        <li>
          <div className="drodown">
            <a
              href="#"
              className="btn btn-sm btn-icon btn-trigger dropdown-toggle"
              data-toggle="dropdown"
            >
              <em className="icon ni ni-more-h" />
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <ul className="link-list-opt no-bdr">
                {checkUserPermission("commercial_view_details") && (
                  <li>
                    <Link to={`/commercial/${commercial.VesselCommercialId}`}>
                      <em className="icon ni ni-eye" />
                      <span>View Details</span>
                    </Link>
                  </li>
                )}
                {checkUserPermission("commercial_edit_commercials") && (
                  <li>
                    <Link
                      to={`/edit-commercial/${commercial.VesselCommercialId}`}
                    >
                      <em className="icon ni ni-file-docs" />
                      <span>Edit Commercial</span>
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </li>
      </ul>
    );
  };

  const headerList = [
    {
      Header: "Vessel",
      accessor: "VesselName",
    },
    {
      Header: "Contract Type",
      accessor: "ContractType",
    },
    {
      Header: "Start Date",
      accessor: "StartDate",
    },
    {
      Header: "End Date",
      accessor: "EndDate",
    },
    {
      Header: "Daily Rate",
      accessor: "DailyRate",
    },
    {
      Header: "Currency",
      accessor: "Currency",
    },
    {
      Header: "Status",
      accessor: "CommercialStatus",
    },
    {
      Header: "",
      accessor: "actionsComponent",
    },
  ];

  const exportPage = () => {
    if (!commercials.length) return;
    const exportData = commercials.map((commercial) => {
      const exportObject = {
        "Vessel Name": commercial.VesselName,
        "Party Name": commercial.PartyName,
        "Currency Name": commercial.Currency,
        "Status ": commercial.Status,
        "Contract Type": commercial.ContractType,
        "Daily Rate": commercial.DailyRate,
        "Commercial Status": commercial.CommercialStatus,
        "Contract Start Date": commercial.StartDate,
        "Contract End Date": commercial.EndDate,
        "Contract Start Comments": commercial.ContractStartComments,
        "Contract End Comments": commercial.ContractEndComments,
        "Contract Options": commercial.ContractOptions,
      };
      return exportObject;
    });
    exportToCSV(exportData, "list-of-commercials");
  };

  const filterByCommercialProperty = async () => {
    await loadData(1, null, startDate, endDate, vesselSpecificationId, partyId);
  };

  const clearFilter = async () => {
    setStartDate("");
    setEndDate("");
    setVesselSpecificationId("");
    setPartyId("");

    await loadData(1, null);
  };

  // const filterByVessel = async (vessel) => {
  //   setFilter(vessel.VesselSpecificationId);
  //   await loadData(1, null, null, null, vessel.VesselSpecificationId, null);
  // };

  // const clearFilter = async () => {
  //   setFilter(null);
  //   await loadData(1, null, null, null, null, null);
  // };

  const pageActions = (
    <div className="toggle-wrap nk-block-tools-toggle">
      <a
        href="#"
        className="btn btn-icon btn-trigger toggle-expand mr-n1"
        data-target="pageMenu"
      >
        <em className="icon ni ni-menu-alt-r" />
      </a>
      <div className="toggle-expand-content" data-content="pageMenu">
        <ul className="nk-block-tools g-3">
          {checkUserPermission("commercial_download_commercials") && (
            <li>
              <a
                role="button"
                onClick={() => setDownloadPopUp(true)}
                target={loading ? "" : "_blank"}
                className={`btn btn-dim btn-sm btn-secondary ${
                  loading ? "disabled" : ""
                }`}
              >
                <em className="icon ni ni-download" />
                <span>Download Commercials</span>
              </a>
            </li>
          )}
          {/* {checkUserPermission("commercial_export_page") && commercials && (
            <li>
              <button
                type="button"
                onClick={exportPage}
                className="btn btn-white btn-outline-light"
              >
                <em className="icon ni ni-download-cloud" />
                <span>Export page</span>
              </button>
            </li>
          )} */}
          {checkUserPermission("commercial_add_commercials") && (
            <li className="nk-block-tools-opt">
              <div className="drodown">
                <a
                  href="#"
                  className="dropdown-toggle btn-sm btn btn-icon btn-primary"
                  data-toggle="dropdown"
                >
                  <em className="icon ni ni-plus" />
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  <ul className="link-list-opt no-bdr">
                    <li>
                      <Link to="/create-commercial">
                        <span>Create Commercial</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout
      title="Commercials"
      pageActions={pageActions}
      loading={loading}
      showFullLoader={loading}
    >
      <div className="table-component">
        <div className="card-inner-group">
          {
            <div className="card-inner position-relative card-tools-toggle">
              {checkUserPermission("commercial_filter_by_vessels") && (
                <div className="card-title-group">
                  <div className="card-tools">
                    <div className="dropdown">
                      <a
                        href="#"
                        className="btn btn-trigger btn-icon dropdown-toggle "
                        data-toggle="dropdown"
                      >
                        <em className="icon ni ni-filter-alt" />
                      </a>
                      <div className="dropdown-menu dropdown-menu-xl dropdown-menu-left p-1">
                        <form style={{ zoom: "72%" }}>
                          <div className="dropdown-head">
                            <span className="sub-title dropdown-title">
                              Filter Commercials
                            </span>
                            <div className="dropdown">
                              <a
                                className="btn btn-sm btn-icon"
                                data-toggle="dropdown"
                              >
                                <em
                                  className="icon ni ni-cross"
                                  title="close"
                                />
                              </a>
                            </div>
                          </div>
                          <div className="dropdown-body dropdown-body-rg">
                            <div className="row gx-6 gy-3">
                              <div className="col-12">
                                <div
                                  className="form-group"
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <label className="overline-title overline-title-alt">
                                    Vessel
                                  </label>
                                  <Select
                                    className="border-transparent"
                                    placeholder="Filter by Vessel"
                                    value={vessels.find(
                                      (vessel) =>
                                        vessel.value === vesselSpecificationId
                                    )}
                                    options={vessels}
                                    onChange={(option) =>
                                      setVesselSpecificationId(option.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-12">
                                <div
                                  className="form-group"
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <label className="overline-title overline-title-alt">
                                    Party
                                  </label>
                                  <Select
                                    className="select-dropdown-sm"
                                    placeholder="Filter by Party"
                                    value={parties.find(
                                      (party) => party.value === partyId
                                    )}
                                    options={parties}
                                    onChange={(e) => setPartyId(e.value)}
                                  />
                                </div>
                              </div>

                              <div className="col-6">
                                <div className="form-group">
                                  <label
                                    className="overline-title overline-title-alt"
                                    htmlFor="from-date-filter"
                                  >
                                    Start Date
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control "
                                    placeholder=""
                                    id="from-date-filter"
                                    value={startDate}
                                    onChange={(e) =>
                                      setStartDate(e.target.value)
                                    }
                                  />
                                </div>
                              </div>

                              <div className="col-6">
                                <div className="form-group">
                                  <label
                                    className="overline-title overline-title-alt"
                                    htmlFor="to-date-filter"
                                  >
                                    End Date
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control "
                                    placeholder=""
                                    id="to-date-filter"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                  />
                                </div>
                              </div>

                              <div className="gap gap-10px" />

                              <div className="col-12">
                                <div className="form-group">
                                  <button
                                    type="button"
                                    onClick={filterByCommercialProperty}
                                    className="btn btn-secondary"
                                  >
                                    <span>Filter</span>
                                  </button>
                                  <a
                                    className="clickable ml-2"
                                    onClick={() => clearFilter()}
                                  >
                                    Reset Filter
                                  </a>
                                </div>
                              </div>
                              <div className="gap gap-50px" />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  {/* <div className="form-inline flex-nowrap gx-3 search-form">
                    <em className="icon ni ni-filter" />

                    <div style={{ width: "220px" }}>
                      <Select
                        className="border-transparent"
                        placeholder="Filter by Vessel"
                        value={vessels
                          .map((c) => ({
                            ...c,
                            label: c.VesselName,
                            value: c.VesselSpecificationId,
                          }))
                          .find(
                            (vessel) => vessel.VesselSpecificationId === filter
                          )}
                        options={vessels.map((c) => ({
                          ...c,
                          label: c.VesselName,
                          value: c.VesselSpecificationId,
                        }))}
                        onChange={(option) => filterByVessel(option)}
                      />
                    </div>

                    {checkUserPermission("commercial_clear_filter") && (
                      <button
                        type="button"
                        onClick={clearFilter}
                        className="btn btn-white btn-outline-light"
                      >
                        <em className="icon ni ni-cross" />
                        <span>Clear Filter</span>
                      </button>
                    )}
                  </div> */}

                  {/* .card-tools */}
                  <div className="card-tools mr-n1">
                    <ul className="btn-toolbar gx-1">
                      <li className="btn-toolbar-sep" />
                      {/* li */}
                      <li>
                        <div className="toggle-wrap">
                          <a
                            href="#"
                            className="btn btn-icon btn-trigger toggle"
                            data-target="cardTools"
                          >
                            <em className="icon ni ni-menu-right" />
                          </a>
                          <div
                            className="toggle-content"
                            data-content="cardTools"
                          >
                            <ul className="btn-toolbar gx-1">
                              <li className="toggle-close">
                                <a
                                  href="#"
                                  className="btn btn-icon btn-trigger toggle"
                                  data-target="cardTools"
                                >
                                  <em className="icon ni ni-arrow-left" />
                                </a>
                              </li>
                              {/* li */}

                              <li>
                                <div className="dropdown">
                                  <a
                                    href="#"
                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                    data-toggle="dropdown"
                                  >
                                    <em className="icon ni ni-setting" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-xs dropdown-menu-right">
                                    <ul className="link-check">
                                      {checkUserPermission(
                                        "commercial_show_icon"
                                      ) && (
                                        <li>
                                          <span>Show</span>
                                        </li>
                                      )}
                                      {pages.map((item, index) => (
                                        <li
                                          onClick={() => loadData(null, item)}
                                          key={index}
                                          className={`${
                                            page == item && "active"
                                          }`}
                                        >
                                          <Link to={"#"}>{item}</Link>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                                {/* .dropdown */}
                              </li>
                              {/* li */}
                            </ul>
                            {/* .btn-toolbar */}
                          </div>
                          {/* .toggle-content */}
                        </div>
                        {/* .toggle-wrap */}
                      </li>
                      {/* li */}
                    </ul>
                    {/* .btn-toolbar */}
                  </div>
                  {/* .card-tools */}
                </div>
              )}
            </div>
          }
          {/* .card-inner */}
        </div>
        {/* .card-inner-group */}
      </div>

      {/* {loading ? (
        <div className="mt-5 pt-5 mx-auto">
          <Spinner />
        </div>
      ) : ( */}
      <>
        <BookingTable
          columns={headerList}
          data={commercials}
          cssArray={["font-weight-500", "", "", "text-left", "", ""]}
          enableSearch={false}
          emptyMessage="No commercial record to show on page"
          disablePageSize={true}
        />
        <br />
        {checkUserPermission("commercial_pagination") && (
          <div className="">
            <Paginate
              onChange={(p) => loadData(p)}
              currentPage={page}
              pageSize={pageSize}
              totalRecordsCount={totalRecords}
            />
          </div>
        )}
      </>
      {downloadPopUp == true && (
        <CustomModal
          title={
            <span>
              <em class="icon ni ni-info-i"></em> Enter Download Time Range
            </span>
          }
          content={
            <>
              <div className="card-inner p-2">
                <form>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group" style={{ zIndex: "10" }}>
                        <label className="form-label small">Start Date</label>
                        <div className="form-control-wrap">
                          <input
                            type="date"
                            className="form-control required"
                            onChange={(e) =>
                              setDownloadStartDate(e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group" style={{ zIndex: "10" }}>
                        <label className="form-label small">End Date</label>
                        <div className="form-control-wrap">
                          <input
                            type="date"
                            className="form-control required"
                            onChange={(e) => setDownloadEndDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <div className="gap gap-30px" />

                <div className="form-group">
                  <a
                    className="btn btn-sm btn-dark active"
                    href={`${process.env.REACT_APP_API}/api/Commercials/DownloadCommercialCSV?startDate=${downloadStartDate}&endDate=${downloadEndDate}&authorization=${localStorage.token}`}
                    onClick={() => {
                      setDownloadPopUp(false);
                      setDownloadStartDate("");
                      setDownloadEndDate("");
                    }}
                  >
                    Download
                  </a>
                </div>
              </div>
            </>
          }
          onClose={() => setDownloadPopUp(false)}
          isClosed={false}
        />
      )}
      {/* )} */}
    </MainLayout>
  );
}
