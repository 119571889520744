import React, { Component, useEffect, useState } from "react";
import ReactModal from "react-modal";
import Spinner from "../../utils/spinner";
import MainLayout from "../../layouts/mainLayout";
import CountryService from "../../services/countriesService";
import payloadUpdater from "../../utils/payloadUpdater";
import toastr from "../../utils/toastr";
import { defaultModalStyle } from "../../utils/customStyles";
import Select from "react-select";
import FileInput from "../../components/fileInput";
import CertificateService from "../../services/certificateService";
import { dateToYYYY_MM_DD } from "../../utils/utilityFunctions";
import { camelCase } from "lodash";
import ComplianceService from "../../services/complianceService";

export default function ComplianceModal({ callback, recordToEdit }) {
  const [showModal, setShowModal] = useState(true);
  const [countryList, setCountry] = useState([]);
  const [gettingData, setGettingData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [issuerList, setIssuerList] = useState([]);
  const [issuerTypes, setIssuerTypes] = useState([]);
  const [noExpiryDate, setNoExpiryDate] = useState(
    !recordToEdit ? false : recordToEdit.ExpiryDate ? false : true
  );

  const [payload, setPayload] = useState(
    (recordToEdit && {
      ...recordToEdit,
      ExpiryDate: recordToEdit.ExpiryDate || "",
    }) ||
      {}
  );
  const [certificates, setCertificates] = useState([]);
  const [certificateGroups, setCertificateGroups] = useState([]);

  const [statusList, setStatusList] = useState([]);
  const [useFreeTextIssuingAuthority, setUseFreeTextIssuingAuthority] =
    useState(false);
  const [useFreeTextCertificate, setUseFreeTextCertificate] = useState(false);

  const updatePayload = new payloadUpdater({
    payload: payload,
    setPayload: setPayload,
  }).update;
  const validationGroup = "complianceModal";
  ReactModal.setAppElement("body");

  useEffect(() => {
    const fetchData = async () => {
      const _countryList = await CountryService.listCountry();
      setCountry(_countryList || []);

      const _certificates = await CertificateService.listAll();
      setCertificates(_certificates || []);

      const _statusList = await CertificateService.statusList();
      setStatusList(_statusList || []);

      const _issuerList = await CertificateService.listIssuers();
      setIssuerList(_issuerList || []);

      const _issuerTypes = await ComplianceService.issuerTypesList();
      setIssuerTypes(_issuerTypes || []);

      const _cg = await CertificateService.listCertificateGroup();
      setCertificateGroups(_cg || []);

      if (recordToEdit) {
        !recordToEdit.ExpiryDate && setNoExpiryDate(true);
        setPayload({
          ...recordToEdit,
          ExpiryDate: recordToEdit.ExpiryDate || "",
        });
      }

      setGettingData(false);
    };

    fetchData();
  }, [recordToEdit]);

  const reloadIssuers = async () => {
    const _issuerList = await CertificateService.listIssuers(false);
    setIssuerList(_issuerList || []);
  };

  const reloadCertificates = async () => {
    const _certificates = await CertificateService.listAll(false);
    setCertificates(_certificates || []);
  };

  const dateInPast = (date) => {
    const today = new Date();
    date = new Date(date);
    if (today.setHours(0, 0, 0, 0) > date.setHours(0, 0, 0, 0)) {
      return true;
    }

    return false;
  };

  const validateStatusAndExpiryDate = (status, expiryDate) => {
    if (
      `${status}`.toLocaleLowerCase() === "active" &&
      dateInPast(expiryDate)
    ) {
      return false;
    }

    return true;
  };

  const handleCloseModal = () => {
    setPayload({});
    setShowModal(false);
    callback(null);
  };

  const camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => camelizeKeys(v));
    } else if (obj != null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  const sendPayload = async () => {
    const _payload = camelizeKeys({ ...payload });

    if (
      !_payload.placeOfIssue ||
      !_payload.issueDate ||
      (!noExpiryDate && !_payload.expiryDate) ||
      (!_payload.fileContent && !_payload.certificateDocumentId) ||
      !_payload.status
    ) {
      toastr("error", "All fields are compulsory");
      return;
    }

    if (!noExpiryDate) {
      let _issueDate = new Date(_payload.issueDate);
      let _expiryDate = new Date(_payload.expiryDate);

      if (_expiryDate < _issueDate) {
        toastr("error", "Expiry date cannot be earlier than issue date");
        return;
      }
    } else {
      _payload.expiryDate = null;
      if (!_payload.issueDate) {
        toastr(
          "error",
          "Please specify certificate commencement or issue date"
        );
        return;
      }
    }

    if (
      !noExpiryDate &&
      !validateStatusAndExpiryDate(_payload.status, _payload.expiryDate)
    ) {
      toastr("error", "If status is active, expiry date must be in the future");
      return;
    }

    if (_payload.issuerName && !_payload.issuerId) {
      //here is where we create a new issuer if user entered issuer name by free text
      if (!_payload.issuerAbbreviation) {
        toastr("error", "Issuer abbreviation is compulsory");
        return;
      }

      if (!_payload.issuerTypeId) {
        toastr("error", "Issuer type is compulsory");
        return;
      }
      setLoading(true);
      const _issuer = await CertificateService.postIssuer({
        issuerName: _payload.issuerName,
        issuerAbbreviation: _payload.issuerAbbreviation,
        countryId: _payload.placeOfIssue,
        issuerTypeId: _payload.issuerTypeId,
      });

      if (!_issuer) {
        toastr("error", `Unable to add new issuer ${_payload.issuerName}`);
        setLoading(false);
        return;
      }

      await reloadIssuers();
      _payload.issuerId = _issuer.IssuerId;
      delete _payload.issuerTypeId;
      delete _payload.issuerAbbreviation;
    }

    if (_payload.certificateName && !_payload.certificateNameId) {
      //here is where we create a new issuer if user entered issuer name by free text
      if (!_payload.certificateGroupId) {
        toastr("error", "Certificate group selection is compulsory");
        return;
      }
      setLoading(true);
      const _c = await CertificateService.postMasterList({
        certificateName: _payload.certificateName,
        certificateGroupId: _payload.certificateGroupId,
      });

      if (!_c) {
        toastr(
          "error",
          `Unable to add new certificate ${_payload.certificateName}`
        );
        setLoading(false);
        return;
      }

      await reloadCertificates();
      _payload.certificateNameId = _c.CertificateNameId;
      delete _payload.certificateGroupId;
    }

    setLoading(false);
    setShowModal(false);
    callback && callback(_payload);
  };

  return (
    showModal && (
      <>
        <ReactModal
          isOpen={true}
          contentLabel=""
          style={(defaultModalStyle, { overlay: { zIndex: "1200" } })}
        >
          <MainLayout
            loading={gettingData || loading}
            showFullLoader={gettingData}
            preTitle={
              <span className="ml-2">
                <em
                  className="icon ni ni-arrow-left pointer"
                  onClick={() => handleCloseModal()}
                />
                <span className="pointer" onClick={() => handleCloseModal()}>
                  Return
                </span>
                <h4 className="ml-2">
                  {recordToEdit ? "Edit" : "Add"} Compliance Document
                </h4>
              </span>
            }
          >
            <div className="card  col">
              <form className=" nk-wizard-simple is-alter p-3">
                <div className="nk-wizard-head">
                  {/* There should be something here */}
                </div>
                <div className="nk-wizard-content">
                  <div className="align-items-center">
                    <div className="row gy-4 p-3">
                      <div className="col-12">
                        <p>
                          <span className="preview-title-lg overline-title">
                            Compliance
                          </span>
                        </p>
                      </div>
                      <div className="col-sm-12" style={{ zIndex: "10" }}>
                        <div className="row">
                          <div className="col-md-8">
                            <div className="form-group">
                              <label className="form-label">
                                Issuing authority{" "}
                              </label>
                              <small className="text-danger ml-1">*</small>
                              <div className="input-group mb-3">
                                <div
                                  className="form-control-wrap"
                                  style={{
                                    flex: "1 1 auto",
                                    width: "1%",
                                    maxWidth: "415.35px",
                                  }}
                                >
                                  <div className="">
                                    {!useFreeTextIssuingAuthority && (
                                      <Select
                                        options={issuerList.map((a) => ({
                                          ...a,
                                          label: `${a.IssuerName} (${a.IssuerAbbreviation})`,
                                          value: a.IssuerId,
                                        }))}
                                        value={issuerList
                                          .map((a) => ({
                                            ...a,
                                            label: `${a.IssuerName} (${a.IssuerAbbreviation})`,
                                            value: a.IssuerId,
                                          }))
                                          .find(
                                            (a) =>
                                              a.value ==
                                              (payload.IssuerId ||
                                                payload.issuerId)
                                          )}
                                        onChange={(option) => {
                                          updatePayload(
                                            ["issuerId", "issuerName"],
                                            [option.value, option.label]
                                          );
                                        }}
                                      />
                                    )}

                                    {useFreeTextIssuingAuthority && (
                                      <input
                                        type="text"
                                        className="form-control required"
                                        placeholder="Name of issuing authority"
                                        onChange={(e) =>
                                          updatePayload(
                                            ["issuerId", "issuerName"],
                                            [null, e.target.value]
                                          )
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                                <div className="input-group-append">
                                  <span
                                    className="input-group-text text-primary clickable border-0"
                                    style={{ minWidth: "136px" }}
                                    onClick={() =>
                                      setUseFreeTextIssuingAuthority(
                                        !useFreeTextIssuingAuthority
                                      )
                                    }
                                  >
                                    {useFreeTextIssuingAuthority
                                      ? "select from list"
                                      : "add new authority"}
                                  </span>
                                </div>
                              </div>
                            </div>
                            {useFreeTextIssuingAuthority && (
                              <div className="form-group row">
                                <div className="col-4">
                                  <input
                                    type="text"
                                    className="form-control required"
                                    placeholder="Issuer Abbreviation"
                                    onChange={(e) =>
                                      updatePayload(
                                        ["issuerAbbreviation"],
                                        [e.target.value]
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-8">
                                  <Select
                                    options={issuerTypes.map((a) => ({
                                      ...a,
                                      label: a.IssuerTypeName,
                                      value: a.IssuerTypeId,
                                    }))}
                                    placeholder="Select issuer type"
                                    value={issuerTypes
                                      .map((a) => ({
                                        ...a,
                                        label: a.IssuerTypeName,
                                        value: a.IssuerTypeId,
                                      }))
                                      .find(
                                        (a) => a.value == payload.IssuerTypeId
                                      )}
                                    onChange={(option) => {
                                      updatePayload(
                                        ["IssuerTypeId"],
                                        [option.value]
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12" style={{ zIndex: "9" }}>
                        <div className="row">
                          <div className="col-md-8">
                            <div className="form-group">
                              <label className="form-label">Certificate </label>
                              <small className="text-danger ml-1">*</small>
                              <div className="input-group mb-3">
                                <div
                                  className="form-control-wrap"
                                  style={{
                                    flex: "1 1 auto",
                                    width: "1%",
                                    maxWidth: "415.35px",
                                  }}
                                >
                                  <div className="">
                                    {!useFreeTextCertificate && (
                                      <Select
                                        options={certificates.map((a) => ({
                                          ...a,
                                          label: a.CertificateName,
                                          value: a.CertificateNameId,
                                        }))}
                                        value={certificates
                                          .map((a) => ({
                                            ...a,
                                            label: a.CertificateName,
                                            value: a.CertificateNameId,
                                          }))
                                          .find(
                                            (a) =>
                                              a.value ==
                                              (payload.certificateNameId ||
                                                payload.CertificateNameId)
                                          )}
                                        onChange={(option) => {
                                          updatePayload(
                                            [
                                              "certificateNameId",
                                              "certificateName",
                                            ],
                                            [option.value, option.label]
                                          );
                                        }}
                                      />
                                    )}
                                    {useFreeTextCertificate && (
                                      <input
                                        type="text"
                                        className="form-control required"
                                        onChange={(e) =>
                                          updatePayload(
                                            [
                                              "certificateNameId",
                                              "certificateName",
                                            ],
                                            [null, e.target.value]
                                          )
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                                <div className="input-group-append">
                                  <span
                                    className="input-group-text text-primary clickable border-0"
                                    style={{ minWidth: "136px" }}
                                    onClick={() =>
                                      setUseFreeTextCertificate(
                                        !useFreeTextCertificate
                                      )
                                    }
                                  >
                                    {useFreeTextCertificate
                                      ? "select from list"
                                      : "add new certificate"}
                                  </span>
                                </div>
                              </div>
                            </div>
                            {useFreeTextCertificate && (
                              <div
                                className="form-group"
                                style={{ maxWidth: "413px" }}
                              >
                                <Select
                                  options={certificateGroups.map((a) => ({
                                    ...a,
                                    label: a.CertificateGroup,
                                    value: a.CertificateGroupId,
                                  }))}
                                  placeholder="Certificate group"
                                  value={certificateGroups
                                    .map((a) => ({
                                      ...a,
                                      label: a.CertificateGroup,
                                      value: a.CertificateGroupId,
                                    }))
                                    .find(
                                      (a) =>
                                        a.value == payload.CertificateGroupId
                                    )}
                                  onChange={(option) => {
                                    updatePayload(
                                      ["CertificateGroupId"],
                                      [option.value]
                                    );
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <hr style={{ opacity: "0.5" }} />
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group" style={{ zIndex: "8" }}>
                          <label className="form-label">Place of Issue </label>
                          <small className="text-danger ml-1">*</small>
                          <div className="form-control-wrap">
                            <div className="">
                              <Select
                                options={countryList.map((e) => ({
                                  ...e,
                                  label: e.CountryName,
                                  value: e.CountryId,
                                }))}
                                value={countryList
                                  .map((e) => ({
                                    ...e,
                                    label: e.CountryName,
                                    value: e.CountryId,
                                  }))
                                  .find(
                                    (a) =>
                                      a.value ==
                                      (payload.placeOfIssue ||
                                        payload.PlaceOfIssue)
                                  )}
                                onChange={(option) => {
                                  updatePayload(
                                    ["placeOfIssue", "placeOfIssueName"],
                                    [option.value, option.label]
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group ">
                          <label className="form-label">Status</label>
                          <small className="text-danger ml-1">*</small>
                          <div
                            className="form-control-wrap"
                            style={{ zIndex: 6 }}
                          >
                            <div className="" tabIndex="5">
                              <Select
                                options={statusList.map((e) => ({
                                  ...e,
                                  label: e.Status,
                                  value: e.StatusId,
                                }))}
                                value={statusList
                                  .map((e) => ({
                                    ...e,
                                    label: e.Status,
                                    value: e.StatusId,
                                  }))
                                  .find(
                                    (a) =>
                                      a.value ==
                                      (payload.StatusId || payload.statusId)
                                  )}
                                onChange={(option) => {
                                  updatePayload(
                                    ["status", "StatusId"],
                                    [option.label, option.value]
                                  );
                                }}
                                isDisabled={noExpiryDate}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="row">
                          <div className="form-group col">
                            <label className="form-label">
                              Commencement date
                            </label>
                            <small className="text-danger ml-1">*</small>
                            <div className="form-control-wrap">
                              <input
                                type="date"
                                className="form-control required"
                                value={(
                                  payload.issueDate || payload.IssueDate
                                )}
                                onChange={(e) =>
                                  updatePayload("issueDate", e.target.value)
                                }
                              />
                            </div>

                            <div className="form-control-wrap mt-1">
                              <input
                                type="checkbox"
                                id="indefinite"
                                className="mr-1"
                                value="indefinite"
                                checked={noExpiryDate}
                                onChange={(e) => {
                                  setNoExpiryDate(e.target.checked);

                                  const activeStatus = statusList.find(
                                    (status) => status.Status == "Active"
                                  );

                                  updatePayload(
                                    recordToEdit ? "ExpiryDate" : "expiryDate",
                                    ""
                                  );

                                  if (e.target.checked) {
                                    updatePayload(
                                      ["status", "StatusId"],
                                      [
                                        activeStatus.Status,
                                        activeStatus.StatusId,
                                      ]
                                    );
                                  }
                                }}
                              />

                              <label htmlFor="indefinite">
                                Does not expire
                              </label>
                            </div>
                          </div>
                          <div className="form-group col">
                            <label className="form-label">Expiry date</label>
                            {noExpiryDate ? (
                              ""
                            ) : (
                              <small className="text-danger ml-1">*</small>
                            )}
                            <div className="form-control-wrap">
                              <input
                                type="date"
                                className="form-control required"
                                value={(
                                  payload.expiryDate || payload.ExpiryDate
                                )}
                                onChange={(e) =>
                                  updatePayload("expiryDate", e.target.value)
                                }
                                disabled={noExpiryDate}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="customMultipleFilesLabel"
                          >
                            {payload.CertificateDocumentId ||
                            payload.certificateDocumentId
                              ? "Uploaded document"
                              : "Upload document"}
                          </label>
                          <small className="text-danger ml-1">*</small>
                          <div className="form-control-wrap">
                            <FileInput
                              onChange={(file) => {
                                updatePayload(
                                  ["fileContent", "fileName", "mime"],
                                  [file.base64, `${file.name}`, file.type]
                                );
                              }}
                              fileValue={{
                                name: payload.fileName,
                                size: payload.fileContent?.length || 0,
                                fileContent: payload.fileContent,
                                fileRef:
                                  payload.CertificateDocumentId ||
                                  payload.certificateDocumentId,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gap"></div>
                <div className="mt-5 mb-5">
                  <button
                    type="button"
                    className="btn btn-md btn-light btn-dim"
                    disabled={loading}
                    onClick={() => handleCloseModal()}
                  >
                    {" "}
                    <em className="icon ni ni-arrow-left pointer mr-2" /> Go
                    back
                  </button>
                  <button
                    type="button"
                    onClick={async () => await sendPayload()}
                    className="btn btn-md btn-dark float-right"
                    disabled={loading}
                  >
                    {loading ? (
                      <span className="mx-4 mb-1">
                        <Spinner size="1" color="white" />
                      </span>
                    ) : (
                      <span>{recordToEdit ? "Edit" : "Add"} Document</span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </MainLayout>
        </ReactModal>
      </>
    )
  );
}
