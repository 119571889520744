import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import BookingRequestService from "../../services/bookingRequestService";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../../components/fileInput";
import Spinner from "../../utils/spinner";
import {
  Validator,
  ValidateGroup,
  ClearValidator,
} from "../../components/validator";
import toastr from "../../utils/toastr";
import VoyageService from "../../services/voyageService";
import ReactTooltip from "react-tooltip";
import EquipmentService from "../../services/equipmentService";
import CustomModal from "../../utils/customModal";
import { toReadableDate } from "../../utils/utilityFunctions";
import UpdateBulkListComponent from "../../components/voyage/updateBulkList";
import UpdateDeckListComponent from "../../components/voyage/updateDeckList";
import DeckListComponent from "../../components/voyage/deckList";
import { forEach } from "lodash";

export default function AddNewCargo() {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [bulkLoading, setBulkLoading] = useState(false);
  const [deckLoading, setDeckLoading] = useState(false);
  const [payload, setPayload] = useState({});
  const [availableBookings, setAvailableBookings] = useState([]);
  const [saving, setSaving] = useState(false);
  const [bulkSaving, setBulkSaving] = useState(false);
  const [deckSaving, setDeckSaving] = useState(false);
  const [bookingList, setBookingList] = useState(null);
  const [bookingIds, setBookingIds] = useState([]);
  const [bulkCargo, setBulkCargo] = useState([]);
  const [deckCargo, setDeckCargo] = useState([]);
  const [bulkCargoId, setBulkCargoId] = useState([]);
  const [deckCargoId, setDeckCargoId] = useState([]);
  const [selectedBulkCargo, setSelectedBulkCargo] = useState([]);
  const [selectedDeckCargo, setSelectedDeckCargo] = useState([]);
  const [voyage, setVoyage] = useState({});

  const [postVoyageDetails, setPostVoyageDetails] = useState({
    BulkCargos: [],
    DeckCargos: [],
  });

  const addPayload = new PayloadUpdater({
    payload: postVoyageDetails,
    setPayload: setPostVoyageDetails,
  }).update;
  const validationGroup = "createVoyage";

  useEffect(async () => {
    await init();
  }, []);

  const init = async () => {
    setLoading(true);
    let _voyage = await VoyageService.get(params.voyagePlanningId);
    setVoyage(_voyage || []);

    await retrieveBookings(_voyage.SailingId);

    setLoading(false);
  };

  const retrieveBookings = async (sailingId) => {
    let _availableBookings = await BookingRequestService.getAvailableBookings();
    if (_availableBookings) {
      _availableBookings = _availableBookings.map((c) => ({
        ...c,
        label:
          c.BookingReferenceNumber +
          " - " +
          c.offshoreInstallationName +
          " - " +
          c.Direction,
        value: c.BookingRequestId,
      }));
    }
    setAvailableBookings(
      _availableBookings.filter((booking) => booking.SailingId == sailingId)
    );
  };

  const handleBookingChange = (option) => {
    let _bookingId = option?.map(function (booking) {
      return booking.BookingRequestId;
    });
    setBookingIds(_bookingId || []);

    let _bulkCargo = option
      ?.map(function (bulkcargo) {
        return bulkcargo?.cargos;
      })
      ?.flat();
    setBulkCargo(_bulkCargo || []);

    let _deckCargo = option
      ?.map(function (deckcargo) {
        return deckcargo?.deckCargos;
      })
      ?.flat();
    setDeckCargo(_deckCargo || []);
  };

  const handleBulkTypeChange = (option) => {
    let _bulkCargoId = option?.map(function (a) {
      return a.BulkCargoDetailId;
    });
    setBulkCargoId(_bulkCargoId || []);

    let _selectedBulkCargo = option?.map(function (bulkselected) {
      return bulkselected;
    });
    setSelectedBulkCargo(_selectedBulkCargo || []);
  };

  const handleDeckTypeChange = (option) => {
    let _deckCargoId = option?.map(function (a) {
      return a.DeckCargoDetailId;
    });
    setDeckCargoId(_deckCargoId || []);

    let _selectedDeckCargo = option?.map(function (deckselected) {
      return deckselected;
    });
    setSelectedDeckCargo(_selectedDeckCargo || []);
  };

  postVoyageDetails.DeckCargos = deckCargoId;
  postVoyageDetails.BulkCargos = bulkCargoId;

  const addBulkCargo = async () => {
    if (postVoyageDetails.BulkCargos.length == 0) {
      toastr("error", "Select a bulk cargo item");
      return;
    }
    setBulkSaving(true);
    const request = await VoyageService.updateBulkCargo(
      params.voyagePlanningId,
      postVoyageDetails.BulkCargos
    );
    if (request) {
      setPostVoyageDetails({});
      toastr("success", "Bulk cargo added");
      await init();
      // setTimeout(() => window.location.reload(false), 5000);
    } else {
      toastr("error", "Bulk cargo not updated");
      setLoading(false);
      return;
    }
    setBulkSaving(false);
  };

  const addDeckCargo = async () => {
    if (postVoyageDetails.DeckCargos.length == 0) {
      toastr("error", "Select a deck cargo item");
      return;
    }
    setDeckSaving(true);
    const request = await VoyageService.updateDeckCargo(
      params.voyagePlanningId,
      postVoyageDetails.DeckCargos
    );
    if (request) {
      setPostVoyageDetails({});
      toastr("success", "Deck cargo added");
      await init();
      // setTimeout(() => window.location.reload(false), 5000);
    } else {
      toastr("error", "Deck cargo not updated");
      setLoading(false);
      return;
    }
    setDeckSaving(false);
  };

  const pageActions = (
    <div className="toggle-wrap nk-block-tools-toggle">
      <a
        href="#"
        className="btn btn-icon btn-trigger toggle-expand mr-n1"
        data-target="pageMenu"
      >
        <em className="icon ni ni-menu-alt-r" />
      </a>
      <div className="toggle-expand-content" data-content="pageMenu">
        <ul className="nk-block-tools g-3">
          <li>
            <button
              onClick={() => window.history.back()}
              className="btn btn-white btn-outline-light"
            >
              <em className="icon ni ni-arrow-left" />
              <span>Return to Previous Page</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout title="Add Cargo" loading={loading} pageActions={pageActions}>
      {loading ? (
        <div className="w-100 text-center center my-5 py-5">
          <Spinner />
        </div>
      ) : (
        <form>
          <div className="card-inner">
            <div className="row">
              <div className="col-12">
                <p>
                  <span className="preview-title-lg overline-title">
                    Booking Information
                  </span>{" "}
                </p>
              </div>
              <div className="col-md-8">
                <div className="form-group" style={{ zIndex: "40" }}>
                  <label className="form-label small">
                    Booking Reference Number(s)
                  </label>
                  <div className="form-control-wrap">
                    <Select
                      onChange={(option) => {
                        handleBookingChange(option);
                      }}
                      isMulti={true}
                      options={availableBookings}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <br /> */}

          <div className="card-inner">
            <div className="row">
              <div className="col-12">
                <p>
                  <span className="preview-title-lg overline-title">
                    Bulk Cargo Selection
                  </span>{" "}
                </p>
              </div>

              <div className="col-md-8">
                <div className="form-group" style={{ zIndex: "30" }}>
                  <label className="form-label small">Bulk Cargo(s)</label>
                  <div className="form-control-wrap">
                    <Select
                      onChange={(option) => {
                        handleBulkTypeChange(option);
                      }}
                      isMulti={true}
                      options={bulkCargo?.map((c) => ({
                        ...c,
                        label: c.BookingReference + " - " + c.ProductName,
                        value: c.BulkCargoDetailId,
                      }))}
                    />
                  </div>
                </div>
              </div>

              {bulkSaving && (
                <button type="button" className="btn btn-md btn-link mt-4">
                  <Spinner size="1.5" />
                </button>
              )}

              {!bulkSaving && (
                <button
                  type="button"
                  className="btn btn-md btn-dim btn-secondary btn-dark mt-4"
                  onClick={addBulkCargo}
                >
                  Add Bulk Cargo
                </button>
              )}
            </div>
            <br />

            {loading && (
              <div>
                {postVoyageDetails.BulkCargos.length == 0 ? null : (
                  <div className="row">
                    <div className="col-12">
                      <p>
                        <span className="preview-title-lg overline-title">
                          Bulk Cargo Details
                        </span>{" "}
                      </p>
                    </div>
                    <UpdateBulkListComponent dataList={selectedBulkCargo} />
                  </div>
                )}
              </div>
            )}
            <br />
            <br />

            <div className="row">
              <div className="col-12">
                <p>
                  <span className="preview-title-lg overline-title">
                    Deck Cargo Selection
                  </span>{" "}
                </p>
              </div>

              <div className="col-md-8">
                <div className="form-group" style={{ zIndex: "20" }}>
                  <label className="form-label small">Deck Cargo(s)</label>
                  <div className="form-control-wrap">
                    <Select
                      onChange={(option) => {
                        handleDeckTypeChange(option);
                      }}
                      isMulti={true}
                      options={deckCargo?.map((c) => ({
                        ...c,
                        label:
                          c.BookingReference + " - " + c.DescriptionOfGoods,
                        value: c.DeckCargoDetailId,
                      }))}
                    />
                  </div>
                </div>
              </div>

              {deckSaving && (
                <button type="button" className="btn btn-md btn-link mt-4">
                  <Spinner size="1.5" />
                </button>
              )}

              {!deckSaving && (
                <button
                  type="button"
                  className="btn btn-md btn-dim btn-secondary btn-dark mt-4"
                  onClick={addDeckCargo}
                >
                  Add Deck Cargo
                </button>
              )}
            </div>
            <br />

            {loading && (
              <div>
                {postVoyageDetails.DeckCargos.length == 0 ? null : (
                  <div className="row">
                    <div className="col-12">
                      <p>
                        <span className="preview-title-lg overline-title">
                          Deck Cargo Details
                        </span>{" "}
                      </p>
                    </div>
                    <UpdateDeckListComponent dataList={selectedDeckCargo} />
                  </div>
                )}
              </div>
            )}
          </div>
        </form>
      )}

      {/* <div className="row">
        <div className="col text-right mb-4 pr-5 mt-5">
            <>
              {
                saving ?
                 <button type="button"
                  className="btn btn-lg btn-light"><Spinner size="1" /> </button> :
                <button
                  type="button"
                  onClick={() => saveEntireForm()}
                  className="btn btn-lg btn-dark"
                >
                  <em className="icon ni ni-save mr-2" />
                  Save Voyage{" "}
                </button>
              }
            </>
        </div>
      </div>
      <hr className="preview-hr" style={{ visibility: "hidden" }} /> */}
    </MainLayout>
  );
}
