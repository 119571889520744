import apiCall from "../utils/apiCall";

export default class DashboardService {
  static getExtraVoyageCostsDashboardList = async (
    pageCount,
    noPerPage,
    vesselSpecificationId,
    timeFrom,
    timeTo,
    voyageId,
    voyageRef
  ) => {
    pageCount = pageCount || 1;
    noPerPage = noPerPage || 20;
    timeFrom = timeFrom || "";
    timeTo = timeTo || "";
    voyageId = voyageId || "";
    vesselSpecificationId = vesselSpecificationId || "";
    voyageRef = voyageRef || "";
    let response = await apiCall(
      `/api/Dashboard/uninvoiced-voyages?page=${pageCount}&pageSize=${noPerPage}&timeFrom=${timeFrom}&timeTo=${timeTo}&vesselSpecificationId=${vesselSpecificationId}&voyageId=${voyageId}&voyageRef=${voyageRef}`,
      "GET"
    );
    return response;
  };

  static getVoyagesForFinalizationList = async (
    pageCount,
    noPerPage,
    vesselSpecificationId,
    timeFrom,
    timeTo,
    voyageId,
    voyageRef
  ) => {
    pageCount = pageCount || 1;
    noPerPage = noPerPage || 20;
    timeFrom = timeFrom || "";
    timeTo = timeTo || "";
    voyageId = voyageId || "";
    vesselSpecificationId = vesselSpecificationId || "";
    voyageRef = voyageRef || "";
    let response = await apiCall(
      `/api/Dashboard/unfinalized-voyages?page=${pageCount}&pageSize=${noPerPage}&timeFrom=${timeFrom}&timeTo=${timeTo}&vesselSpecificationId=${vesselSpecificationId}&voyageId=${voyageId}&voyageRef=${voyageRef}`,
      "GET"
    );
    return response;
  };
}
