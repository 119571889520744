import apiCall from "../utils/apiCall";

export default class ContactPersonService {
  static listAllRoles = async () => {
    const response = await apiCall(`/api/contactpersonrole/get`, "GET", null, null, null, true);
    return response?.Data;
  };

  static postContactPerson = async (payload) => {
    return await apiCall(`/api/contactperson/post`, "POST", payload);
  };

  static postEditedContactPerson = async (id, payload) => {
    return await apiCall(`/api/ContactPerson/Put/${id}`, "PUT", payload);
  };

  static listAllContactPersons = async (pageCount, noPerPage) => {
    pageCount = pageCount || 1;
    noPerPage = noPerPage || 1000;
    const response = await apiCall(
      `/api/ContactPerson/Get?pageCount=${pageCount}&noPerPage=${noPerPage}`
    );
    return response?.Data;
  };
}
