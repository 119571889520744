import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import Tabs from "../../utils/tabs";
import BookingRequestDetailsComponent from "../../components/bookingRequest/bookingRequestDetailsComponent";
import BookingRequestService from "../../services/bookingRequestService";
import toastr from "../../utils/toastr";
import { checkUserPermission } from "../../utils/utilityFunctions";

export default function BookingRequestDetailsPage() {
  const params = useParams(); //we are expecting params.bookingRequesId as bookingRequestId

  const [redirectUrl, setRedirectUrl] = useState(null);
  const [initializing, setInit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [booking, setBooking] = useState([]);
  const [toCancelled, setToCancelled] = useState(null);
  const [payload, setPayload] = useState({});
  const [saving, setSaving] = useState(false);

  useEffect(async () => {
    setInit(true);

    const _bookingRequest = await BookingRequestService.getBookingRequest(
      params.bookingRequestId
    );
    setBooking(_bookingRequest || []);

    setInit(false);
  }, [params.bookingRequestId]);

  const tabHeaders = [{ icon: "icon ni ni-view-grid-wd", text: "Details" }];

  const tabContent = [
    <BookingRequestDetailsComponent
      bookingRequestId={params.bookingRequestId}
    />,
  ];

  const _tabView = (
    <Tabs headers={tabHeaders} contents={tabContent} contentPadding="3" />
  );

  const cancelRequest = async () => {
    let toCancelled = await BookingRequestService.cancelBooking(
      params.bookingRequestId
    );
    if (toCancelled) {
      setPayload({});
      window.location.reload(false);
      // setRedirectUrl(`/booking-requests`);
      toastr("success", "Booking cancelled successfully");
    }
    setSaving(false);
  };

  const confirmRequest = async () => {
    const toConfirm = await BookingRequestService.confirmBooking(
      params.bookingRequestId
    );
    if (toConfirm) {
      window.location.reload(false);
      toastr("success", "Booking confirmed successfully");
    } else {
      toastr("error", "Booking not confirmed. No voyage linked to booking");
    }
    setSaving(false);
  };

  const pageActions = (
    <div className="toggle-wrap nk-block-tools-toggle">
      <a
        href="#"
        className="btn btn-icon btn-trigger toggle-expand mr-n1"
        data-target="pageMenu"
      >
        <em className="icon ni ni-menu-alt-r" />
      </a>
      <div className="toggle-expand-content" data-content="pageMenu">
        <ul className="nk-block-tools g-3">
          {booking.length == 0 ||
          booking.BookingStatus == "cancelled" ||
          booking.BookingStatus == "confirmed" ? null : (
            <>
              {booking.LockedStatus !== "unlocked" ? null : (
                <li>
                 {checkUserPermission("booking_cancel_bookings") && ( 
                 <button
                    onClick={() => cancelRequest()}
                    className="btn btn-white btn-outline-light btn-sm"
                  >
                    <em className="icon ni ni-cross" />
                    <span>Cancel Booking Request</span>
                  </button>
                  )}
                </li>
              )}
                 {checkUserPermission("booking_confirm_bookings") && ( 

              <li>
                <button
                  onClick={() => confirmRequest()}
                  className="btn btn-white btn-outline-light  btn-sm"
                >
                  <em className="icon ni ni-check" />
                  <span>Confirm Booking Request</span>
                </button>
              </li>
                  )}

            </>
          )}

          <li>
            <button
              onClick={() => window.history.back()}
              className="btn btn-white btn-outline-light  btn-sm"
            >
              <em className="icon ni ni-arrow-left" />
              <span>Back to Bookings</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout title="Booking Request Details" pageActions={pageActions}>
      <div className="">{_tabView}</div>
    </MainLayout>
  );
}
