import React, { useEffect, useState } from "react";
import MainLayout from '../../layouts/mainLayout';
import ActivityService from "../../services/activityService";
import { Link, Navigate, useParams } from "react-router-dom";
import Tabs from '../../utils/tabs';
import Spinner from "../../utils/spinner";



export default function ViewActivity() {
  const params = useParams();
  const [activityId, setactivityId] = useState(params.activityId);

  const [activityDetails, setActivityDetails] = useState({});
  const [initializing, setInitialization] = useState(true);

  useEffect(async () => {
    setInitialization(true);
    await retrieveActivity();
    setInitialization(false);

  }, []);

  const retrieveActivity = async () => {
    const selectedActivity = await ActivityService.singleActivity(activityId);
    setActivityDetails(selectedActivity);
  }
  const parrallelActivitiesList = activityDetails?.parallelActivities?.map((items) => (
    <ol> {items?.ActivityName}  ({items?.ActivityShortName})  </ol>
  ))

  let allowParall = activityDetails?.AllowsParallel;
  const tabHeaders = [
    { icon: "icon ni ni-user-list", text: "Activity Details" },
  ];
  const tabContent = [
    <div className="card text-center">
      <div className="row">
        <div className="col-12">
          <div className="card-inner ">
            <p className="card-text"><b>Activity Name: </b> <span> {activityDetails?.ActivityName}</span></p>
            <p className="card-text"><b>Short Name :</b>  {activityDetails?.ActivityShortName}</p>
            <p className="card-text"><b>Description :</b>  {activityDetails?.ActivityDescription}</p>
            <p className="card-text"><b>Allocation Category :</b>  {activityDetails?.AllocationCategory || "N/A"}</p>
            <p className="card-text"><b>Activity Productive? :</b>  {(activityDetails?.IsProductive) ? "Yes" : "No"}</p>
            <p className="card-text"><b>Location Group :</b>  {(activityDetails?.LocationGroup) || "N/A"}</p>
            <p className="card-text"><b>Allows Parrallel ? :</b>  {allowParall ? "Yes" : "No"}</p>
            <p className="card-text"><b>Parrallel Activities :</b> {(parrallelActivitiesList?.length > 0) ? parrallelActivitiesList : "No parallel activities"}  </p>
          </div>
        </div>
      </div>
    </div>
  ]

  const _tabView = (
    <Tabs
      headers={tabHeaders}
      contents={tabContent} contentPadding="0"
    />
  );
  return (
    <MainLayout title="View Activity Details" showFullLoader={initializing}>
      <div className="pl-3">
        {_tabView}

        <div className="mt-5">
          <Link to={'/activity-list'}> <button className="btn btn-md btn-light btn-dim" >
            <em className="icon ni ni-arrow-left pointer mr-2" />  Go back
          </button></Link>

        </div>

      </div>
    </MainLayout>

  )
}