import apiCall from "../utils/apiCall";


export default class BookingService {
    static saveBookingDetails = async (payload) => {
        return await apiCall(`/api/BookingRequest`, "POST", payload);
    }
    static updateBookingDetails = async ( payload) => {
        return await apiCall(`/api/BookingRequest/update`, "POST", payload);
    }
    static products = async (currentPage, pageSize) => {
        currentPage = currentPage || 1;
        pageSize = pageSize || 50;
        return await apiCall(`/api/Product/search?page=${currentPage}&pageSize=${pageSize}`, "GET", null, null, null, true);
    }

    static typeOfEquipment = async (currentPage, pageSize) => {
        currentPage = currentPage || 1;
        pageSize = pageSize || 50;
        return await apiCall(`/api/CargoCarryingEquipmentTypes/Get?pageCount=${currentPage}&pageSize=${pageSize}`,
            "GET", null, null, null, true);
    }
    static cargoEquipment = async () => {
        return await apiCall(`/api/CargoCarryingEquipmentTypes/CombinedList`, "GET", null, null, null, true);
    }
    static dgClassificationList = async () => {
        return await apiCall(`/api/ConfigurationList`, "GET", null, null, null, true);
    }
    static searchBookingRequest = async (page, pageSize, manifestNumber, bookingStatus, bookingRef, sailingId ) => {
        page = page || 1;
        pageSize = pageSize || 50;
        manifestNumber = manifestNumber || '';
        bookingStatus = bookingStatus || '';
        bookingRef = bookingRef || '';
        sailingId = sailingId || '';
        return await apiCall(`/api/BookingRequest/search?page=${page}&pageSize=${pageSize}&manifestNumber=${manifestNumber}&bookingStatus=${bookingStatus}&bookingRef=${bookingRef}&sailingId=${sailingId}`);
    }
    
    static getBookingRequest = async ( bookingRequestId ) => {
        bookingRequestId = bookingRequestId || null;
        return await apiCall(`/api/BookingRequest/${bookingRequestId}`);
    }
    
    static getBulkCargo = async ( bookingRequestId ) => {
        if(!bookingRequestId) return null;
        return await apiCall(`/api/BookingRequest/bulkCargo/list/${bookingRequestId}`);
    }
    
    static getDeckCargo = async ( bookingRequestId ) => {
        if(!bookingRequestId) return null;
        return await apiCall(`/api/BookingRequest/deckCargo/list/${bookingRequestId}`);
    }
    
    static getEquipment = async ( bookingRequestId ) => {
        if(!bookingRequestId) return null;
        return await apiCall(`/api/BookingRequest/bookingEquipment/list/${bookingRequestId}`);
    }
    
    static getAvailableBookings = async () => {
        return await apiCall(`/api/BookingRequest/listavailableforvoyageplanning`);
    }
    
    static cancelBooking = async ( bookingRequestId ) => {
        return await apiCall(`/api/BookingRequest/cancel/${bookingRequestId}`, "POST");
    }
    
    static getUnconfirmedBookings = async (page, pageSize) => {
        page = page || 1;
        pageSize = pageSize || 50;
        return await apiCall(`/api/BookingRequest/SearchUnconfirmedBooking?page=${page}&pageSize=${pageSize}`);
    }
    
    static confirmBooking = async ( bookingRequestId ) => {
        return await apiCall(`/api/BookingRequest/ConfirmBooking/${bookingRequestId}`, "PUT");
    }
    
    static editBooking = async (id, payload ) => {
        return await apiCall(`/api/BookingRequest/Put/${id}`, "PUT", payload);
    }
    static editDeckCargo = async (id, payload ) => {
        return await apiCall(`/api/BookingRequest/deckCargo/update/${id}`, "PUT", payload);
    }
    static editBulkCargo = async (id, payload ) => {
        return await apiCall(`/api/BookingRequest/bulkCargo/update/${id}`, "PUT", payload);
    }
    static editBookingEquipment = async (id, payload ) => {
        return await apiCall(`/api/BookingRequest/bookingEquipment/update/${id}`, "PUT", payload);
    }
    
    static bookingDashboard = async ( page, pageSize,  query = "") => {

        page = page || 1;
        pageSize = pageSize || 10;
        return await apiCall(`/api/BookingRequest/dashboard/bookingrequests?pageCount=${page}&noPerPage=${pageSize}&${query}`);
      };
}