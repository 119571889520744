import apiCall from "../utils/apiCall";
import { clearAPICache, dynamicSort } from "../utils/utilityFunctions";

export default class CommercialService {
  static searchCommercial = async (
    page,
    pageSize,
    startDate,
    endDate,
    vesselSpecificationId,
    partyId
  ) => {
    page = page || 1;
    pageSize = pageSize || 20;
    startDate = startDate || "";
    endDate = endDate || "";
    vesselSpecificationId = vesselSpecificationId || "";
    partyId = partyId || "";
    return await apiCall(
      `/api/Commercials?page=${page}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}&vesselSpecificationId=${vesselSpecificationId}&partyId=${partyId}`,
      "GET", null, null, null, true
    );
  };

  static getListOfCommercialForVessel = async (vesselSpecificationId) => {
    vesselSpecificationId = vesselSpecificationId || null;
    return await apiCall(
      `/api/Commercials/listForVessel?vesselSpecificationId=${vesselSpecificationId}`,
      "GET", null, null, null, true
    );
  };

  static getCommercial = async (vesselCommercialId) => {
    if (!vesselCommercialId) return null;
    return await apiCall(`/api/Commercials/get/${vesselCommercialId}`);
  };

  static getActiveCommercial = async (vesselSpecificationId) => {
    if (!vesselSpecificationId) return null;
    return await apiCall(
      `/api/Commercials/getactive?vesselSpecificationId=${vesselSpecificationId}`
    );
  };

  static postCommercial = async (payload) => {
    clearAPICache("Commercial");
    return await apiCall(`/api/Commercials`, "POST", payload);
  };

  static updateCommercial = async (payload) => {
    clearAPICache("Commercial");
    return await apiCall(`/api/Commercials/update`, "POST", payload);
  };

  static cancelCommercial = async (payload) => {
    clearAPICache("Commercial");
    return await apiCall(`/api/Commercials/cancel`, "POST", payload);
  };

  static getConfigList = async () => {
    return await apiCall(`/api/ConfigurationList`, "GET", null, null, null, true);
  };

  // Offhire
  static getOffhirePeriods = async (vesselSpecId) => {
    if (!vesselSpecId) return null;
    const res = await apiCall(
      `/api/OffHireCommercial/OffHirePeriod/${vesselSpecId}`
    );

    return res?.Data;
  };
}
