import React, { useEffect, useState } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import VoyageService from "../../services/voyageService";
import StartVoyageActivityComponent from "../../components/voyageReporting/startVoyageActivityComponent";
import StartSailingVoyageActivityComponent from "../../components/voyageReporting/startSailingVoyageActivityComponent";
import Tabs from "../../utils/tabs";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../../components/fileInput";
import Spinner from "../../utils/spinner";
import {
  Validator,
  ValidateGroup,
  ClearValidator,
} from "../../components/validator";
import toastr from "../../utils/toastr";
import ReactTooltip from "react-tooltip";
import CustomModal from "../../utils/customModal";
import { toReadableDate } from "../../utils/utilityFunctions";
import RemoveBulkListComponent from "../../components/voyage/removeBulkList";
import RemoveDeckListComponent from "../../components/voyage/removeDeckList";
import DeckListComponent from "../../components/voyage/deckList";
import { forEach } from "lodash";

export default function StartSailingVoyageActivityPage() {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({});
  const [saving, setSaving] = useState(false);
  const [bulkCargo, setBulkCargo] = useState([]);
  const [deckCargo, setDeckCargo] = useState([]);
  const [voyage, setVoyage] = useState([]);

  useEffect(async () => {
    await init();
  }, [params.voyagePlanningId]);

  const init = async () => {};

  const tabHeaders = [
    // { icon: "icon ni ni-view-grid-wd", text: "Details" },
    // { icon: "icon ni ni-files", text: "Voyage Documents" },
  ];

  const tabContent = [
    <StartSailingVoyageActivityComponent
      voyagePlanningId={params.voyagePlanningId}
    />,
  ];

  const _tabView = (
    <Tabs headers={tabHeaders} contents={tabContent} contentPadding="3" />
  );

  const pageActions = (
    <div className="toggle-wrap nk-block-tools-toggle">
    
      <div className="toggle-expand-content" data-content="pageMenu">
        <ul className="nk-block-tools g-3">
          <li>
            <button
              onClick={() => window.history.back()}
              className="btn btn-white btn-outline-light btn-sm"
            >
              <em className="icon ni ni-arrow-left" />
              <span>Back to Voyage Activities</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout
      title="Sailing Activity - Start"
      loading={loading}
      pageActions={pageActions}
    >
      <div className="">{_tabView}</div>
    </MainLayout>
  );
}
