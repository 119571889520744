import React, { useEffect, useState } from "react";
import { toReadableDate } from "../../utils/utilityFunctions";
import Spinner from "../../utils/spinner";
import VesselService from "../../services/vesselService";
import PayloadUpdater from "../../utils/payloadUpdater";
import toastr from "../../utils/toastr";
import moment from "moment";

import CommercialService from "../../services/commercialService";

export default function SetVesselHireComponent({
  vesselSpecId,
  callback,
  isOffHire,
  activeContract,
  vesselName,
}) {
  const [payload, setUpdatePayload] = useState({
    vesselSpecificationId: vesselSpecId,
  });
  const [initializing, setInit] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [contractExtension, setContractExtension] = useState(false);
  const [contractEndDate, setContractEndDate] = useState(null);

  const updatePayload = new PayloadUpdater({
    payload: payload,
    setPayload: setUpdatePayload,
  }).update;

  const toggleHireStatus = async (payload) => {
    if (isOffHire) {
      payload.offHireCommercialId =
        activeContract?.OffHireStatus?.offHires[0]?.OffHireCommercialId;
    }
    const res = isOffHire
      ? await VesselService.setVesselOnHire(payload)
      : await VesselService.setVesselOffHire(payload);

    return res;
  };

  const validateDates = () => {
    if (
      !isOffHire &&
      moment(payload.offHireEndDate).isBefore(payload.offHireStartDate)
    ) {
      toastr("error", "End date should be after the start date");
      return false;
    }

    if (
      contractExtension &&
      moment(contractEndDate).isBefore(activeContract.ContractEndDate)
    ) {
      toastr("error", "Contract extension date falls before previous date");
      return false;
    }

    // if the off-hire date doesn't fall within the extension period throw an error
    if (
      contractExtension &&
      (!moment(payload.offHireStartDate).isBetween(
        activeContract.ContractStartDate,
        contractEndDate
      ) ||
        !moment(payload.offHireEndDate).isBetween(
          activeContract.ContractStartDate,
          contractEndDate
        ))
    ) {
      toastr(
        "error",
        "Provided dates fall outside the contract extension duration"
      );
      return false;
    }

    // if the offhire date doesn't fall within the contract period throw an error
    if (
      !contractExtension &&
      (!moment(payload.offHireStartDate).isBetween(
        activeContract.ContractStartDate,
        activeContract.ContractEndDate
      ) ||
        !moment(payload.offHireEndDate).isBetween(
          activeContract.ContractStartDate,
          activeContract.ContractEndDate
        ))
    ) {
      toastr("error", "Provided dates fall outside the contract duration");
      return false;
    }
    return true;
  };

  const submitUpdate = async (e) => {
    e.preventDefault();

    if (!validateDates()) return;

    setUpdateLoading(true);
    let response = false;
    if (contractExtension) {
      response = await CommercialService.updateCommercial({
        ...activeContract,
        ContractEndDate: contractEndDate,
      });

      if (!response) return setUpdateLoading(false);
    }
    response = await toggleHireStatus(payload);

    if (response) {
      toastr("success", `${vesselName}'s hire status has been updated`);
      callback && callback();
    } else {
      toastr("error", "Update failed");
    }

    setUpdateLoading(false);
  };

  const formLabel = (label, toolTip) => {
    return (
      <label className="form-label" title={toolTip || label}>
        <small>{label}</small>
      </label>
    );
  };

  return initializing ? (
    <div className="w-100 text-center my-5 py-5">
      <Spinner />
    </div>
  ) : (
    <form onSubmit={(e) => submitUpdate(e)} className="form-validate is-alter">
      <div className="mb-2">
        <h6>
          Contract Duration: {toReadableDate(activeContract.ContractStartDate)}{" "}
          - {toReadableDate(activeContract.ContractEndDate)}
        </h6>
      </div>
      <div className="form-group row">
        {!isOffHire && (
          <div className="col">
            {formLabel("Off-Hire Start Date")}
            <div className="form-control-wrap">
              <input
                type="datetime-local"
                className="form-control "
                required
                onChange={(e) =>
                  updatePayload("offHireStartDate", e.target.value)
                }
              />
            </div>
          </div>
        )}

        <div className="col">
          {formLabel("Off-Hire End Date")}
          <div className="form-control-wrap">
            <input
              type="datetime-local"
              className="form-control "
              required
              onChange={(e) => updatePayload("offHireEndDate", e.target.value)}
            />
          </div>
        </div>
      </div>

      {contractExtension && (
        <div className="form-group row">
          <div className="col">
            {formLabel("Contract End Date")}
            <div className="form-control-wrap">
              <input
                type="datetime-local"
                className="form-control "
                required
                value={contractEndDate}
                onChange={(e) => setContractEndDate(e.target.value)}
              />
            </div>
          </div>
        </div>
      )}

      {!isOffHire && (
        <div className="form-control-wrap mb-3">
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              id="customSwitchActive"
              checked={contractExtension}
              onChange={() => setContractExtension(!contractExtension)}
            />
            <label
              className="custom-control-label"
              htmlFor="customSwitchActive"
            >
              Contract Extension
            </label>
          </div>
        </div>
      )}

      <div className="form-group">
        <button
          type="submit"
          disabled={updateLoading}
          className="btn  btn-primary"
        >
          {updateLoading ? <Spinner size="1" color="white" /> : "Submit"}
        </button>
      </div>
    </form>
  );
}
