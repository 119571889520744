import React, { useEffect, useState, useRef } from "react";
import { Navigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import Tabs from "../../utils/tabs";
import VesselDetailsComponent from "../../components/vessel/vesselDetailsComponent";
import VesselPartiesComponent from "../../components/vessel/vesselPartiesComponent";
import ComplianceComponent from "../../components/vessel/complianceComponent";
import EquipmentListComponent from "../../components/vessel/equipmentListComponent";
import CostsLocationComponent from "../../components/costs/CostsLocationComponent";
import CostsVesselsComponent from "../../components/costs/CostsVesselsComponent";
import LocationService from "../../services/locationService";
import CommercialService from "../../services/commercialService";

export default function Costs() {
	const params = useParams();

	const [redirectUrl, setRedirectUrl] = useState(null);
	const [loading, setLoading] = useState(true);
	const [locationTypes, setLocationTypes] = useState([]);
	const [currency, setCurrency] = useState([]);

	const portID = useRef();
	const terminalID = useRef();

	const getLocationTypes = async () => {
		const _locationTypes = await LocationService.listLocationTypes();
		setLocationTypes(_locationTypes || []);
		portID.current = _locationTypes?.find(
			(location) => location.LocationType === "Port"
		)?.LocationTypeId;

		terminalID.current = _locationTypes?.find(
			(location) => location.LocationType === "Terminal"
		)?.LocationTypeId;
	};

	const currencyConfigurations = async () => {
		let _configList = await CommercialService.getConfigList();

		if (_configList) {
			let _currency = _configList?.filter(
				(l) =>
					l.CorrespondingData.toLowerCase() == "usd" ||
					l.CorrespondingData.toLowerCase() == "naira" ||
					l.CorrespondingData.toLowerCase() == "gbp"
			);
			setCurrency(_currency);
		}
	};

	useEffect(async () => {
		await getLocationTypes();
		await currencyConfigurations();
		setLoading(false);
	}, []);

	const tabHeaders = [
		{ icon: "icon ni ni-files", text: "Ports" },
		{ icon: "icon ni ni-reports-alt", text: "Terminals" },
		// { icon: "icon ni ni-view-grid-wd", text: "Vessels" },
		// { icon: "icon ni ni-files", text: "Equipment" },
	];
	const tabContent = [
		// for ports
		<CostsLocationComponent
			locationTypeId={portID.current}
			currency={currency}
		/>,
		// For terminals
		<CostsLocationComponent
			locationTypeId={terminalID.current}
			currency={currency}
		/>,
		// <CostsVesselsComponent vesselSpecId={params.vesselId} />,
	];

	const _tabView = (
		<Tabs headers={tabHeaders} contents={tabContent} contentPadding="3" />
	);

	return redirectUrl ? (
		<Navigate to={redirectUrl} replace={true} />
	) : (
		<MainLayout title="Costs" loading={loading} showFullLoader={loading}>
			{locationTypes?.length && <div className="">{_tabView}</div>}
		</MainLayout>
	);
}
