import React, { useEffect, useState } from 'react';
import MainLayout from '../../layouts/mainLayout';
import payloadUpdater from "../../utils/payloadUpdater";
import { Validator, ValidateGroup } from "../../components/validator";
import Select from "react-select";
import toastr from '../../utils/toastr';
import FleetService from '../../services/fleetService';
import VesselService from '../../services/vesselService';
import ZoneService from '../../services/zoneService';
import Spinner from '../../utils/spinner';


export default function AddVesselToFleet() {
    const [fleets, setFleets] = useState([]);
    const [zones, setZones] = useState([]);
    const [selectedZone, setSelectedZone] = useState([]);
    const [vessels, setVessels] = useState([]);
    const [fleetName, setFleetName] = useState(null);
    const [vesselName, setVesselName] = useState(null);

    const [postVesselToFleetData, setPostVesselToFleetData] = useState({
        VesselSpecificationId: "",
        FleetId: "",
        IsActive: true,
    });
    const [finalSaveloading, setFinalSaveLoading] = useState(false);

    useEffect(async () => {
        await retrieveFleets();
        await retrieveZones();
        await retrieveVessels();
    }, []);

    const retrieveFleets = async () => {
        const fleetList = (await FleetService.listFleet()) || [];
        setFleets(fleetList.map(e=> ({...e, label: e.FleetName, value: e.FleetId, id: "FleetId"})));

    }
    const retrieveZones = async () => {
        const zoneList = await ZoneService.fetchZones();
        if(!zoneList  || zoneList==null)  setZones([]);
       else setZones(zoneList);
    }
    const retrieveVessels = async () => {
        const VesselList = (await VesselService.listAllVessels()) || [];
        setVessels(VesselList.map(e=> ({...e,  id: "VesselSpecificationId",  label: e.VesselName, value: e.VesselSpecificationId})));

    }
    const selectZoneByFleet = async (fleetId) => {
        if (!fleetId)  return;
        var locationId = fleets.find(a=> a.FleetId === fleetId).LocationId;
        var zoneName = zones.find(a=> a.LocationId === locationId).LocationName;
        setSelectedZone(zoneName);
    }
   

    const handleChange = (e) => {
        setPostVesselToFleetData({
          ...postVesselToFleetData,
          [e.id]: e.value,
        });
        
      };
      const compare =  ( a, b )=> {
        if ( a.FleetName < b.FleetName ){
          return -1;
        }
        if ( a.FleetName > b.FleetName ){
          return 1;
        }
        return 0;
      }
    const AddVessel = async ()=>{
        var hasFieldErrs = false;
        if(postVesselToFleetData.VesselSpecificationId == ""){
            toastr("error", "Please select vessel to add to fleet");
            hasFieldErrs = true;
        }else  if(postVesselToFleetData.FleetId == ""){
            toastr("error", "Please select a valid fleet to add vesel to");
            hasFieldErrs = true;
        }

        if(hasFieldErrs) return;
        setFinalSaveLoading(true);
        const vesselAdded = await FleetService.addVesselToFleet(postVesselToFleetData);
        if(!vesselAdded){
            toastr("error", "Vessel could not be added to fleet");
        }else{
            setPostVesselToFleetData({
                VesselSpecificationId: "",
                FleetId: "",
                IsActive: true,
            }); //reset payload
            toastr("success", "Vessel successfully added to fleet");
        }

        setFinalSaveLoading(false);
    }
    return (
        <MainLayout title="Add Vessel to Fleet">


            <div className="">
                <div className="card-inner">
                    <form>
                        <div className="row align-items-center">
                            <div className="row gy-4 p-3">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label className="form-label">Fleet Name</label>
                                        <div className="form-control-wrap">

                                        <Select onChange={(item) => {handleChange(item);  selectZoneByFleet(item.value); setFleetName(item.label)}}  
                                            value={fleets.filter(f=> f.value == postVesselToFleetData.FleetId)} 
                                             id="FleetId" name="FleetId"  options={fleets.sort(compare) }   />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group ">
                                        <label className="form-label" htmlFor="fw-last-name">Zone</label>
                                        <div className="form-control-wrap">
                                            <input disabled className="form-control" type="text" value={selectedZone} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label className="form-label">Name of Vessel</label>
                                        <div className="form-control-wrap">
                                             <Select onChange={(item) => {handleChange(item); setVesselName(item.label) }} 
                                              value={vessels.filter(function(option) {
                                                return option.value === postVesselToFleetData.VesselSpecificationId;
                                              })}
                                             id="VesselSpecificationId" name="VesselSpecificationId"  options={vessels }   />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="nk-tb-col"><span>&nbsp;</span>
                        </div>
                        {!finalSaveloading &&<div className="form-group">
                            {(!vesselName || !fleetName) && <button type="button" className="btn btn-primary mb-4"  disabled >+ Add to fleet</button>}
                            {vesselName && fleetName && <button type="button" className="btn btn-primary mb-4"  onClick={() => AddVessel()} >+ Add {vesselName} to {fleetName}</button>}
                           
                        </div>}
                        {finalSaveloading && <div className="form-group">
                            <button type="button" className="btn btn-primary mb-4"  disabled ><Spinner size="1.5" /></button>
                        </div>}
                        <hr className="preview-hr" />
                    </form>
                </div>
            </div>
        </MainLayout>
    );
}
